import * as React from "react";

import Stack from "@mui/material/Stack";

export type OverlayProps = React.PropsWithChildren;

export default function Overlay({ children }: OverlayProps): React.JSX.Element {
    return (
        <Stack
            sx={{
                position: "absolute",
                top: "10px",
                left: "10px",
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            {children}
        </Stack>
    );
}
