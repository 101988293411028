import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const VOLLEY_LOGO_URL = new URL(
    "../../static/img/icon-volley-green.png",
    import.meta.url,
);

interface Props {
    open?: boolean;
}

export default function Loading({ open = true }: Props): React.JSX.Element {
    const theme = useTheme();

    return (
        <Dialog
            fullScreen
            open={open}
            PaperProps={{ sx: { background: theme.palette.primary.main } }}
            transitionDuration={{ exit: 400 }}
        >
            <Box component="div" sx={{ m: "auto", textAlign: "center" }}>
                <img
                    src={VOLLEY_LOGO_URL.toString()}
                    alt="green volley logo"
                    height={60}
                />
                <Typography variant="h3" color="white" sx={{ mt: 2, mb: 4 }}>
                    Loading ...
                </Typography>
                <CircularProgress
                    sx={{ color: theme.palette.secondary.main }}
                />
            </Box>
        </Dialog>
    );
}
