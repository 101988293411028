import { createTheme } from "@mui/material/styles";

import "./styles/fonts.css";

const headerRule = {
    fontFamily: ["Gotham", "sans-serif"].join(", "),
    lineHeight: "1.2em",
    letterSpacing: "0.025rem",
};

export default createTheme({
    typography: {
        fontFamily: "'Fira Sans', sans-serif",
        button: {
            fontFamily: "Gotham, sans-serif",
            letterSpacing: "0.025rem",
        },
        h1: { ...headerRule, textTransform: "uppercase", fontSize: "3rem" },
        h2: {
            ...headerRule,
            textTransform: "uppercase",
            fontSize: "2rem",
            fontWeight: "bold",
        },
        h3: { ...headerRule, textTransform: "uppercase", fontSize: "1.25rem" },
        h4: { ...headerRule, textTransform: "uppercase", fontSize: "1rem" },
        h5: { ...headerRule, textTransform: "uppercase", fontSize: "0.75rem" },
    },
    palette: {
        background: {
            default: "rgb(234, 236, 242)", // #EAECF2
        },
        primary: {
            main: "rgb(21, 39, 86)", // #152756
            dark: "rgb(11, 23, 48)", // #0B1730"
            light: "rgba(9, 110, 229, 1)", // #096EE5
        },
        secondary: {
            main: "rgb(60, 233, 124)", // #3CE97C
        },
        info: {
            main: "rgb(9, 110, 229)", // #096EE5
        },
    },
    components: {
        MuiDialogContentText: {
            defaultProps: {
                align: "center",
                variant: "body2",
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                dividers: {
                    marginLeft: "16px",
                    marginRight: "16px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderBottom: "none",
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    "@fontFace": ["'Fira Sans'", "'Gotham'"],
                },
            },
        },
    },
});
