import * as React from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { PlayMode } from "@volley/shared/apps/app-common-models";

import { CoordWithSys } from "../../../../util/position-types";
import { useSelectedSport } from "../../../common/context/sport";
import { isCenterWorkout, sideKeys } from "../../Position/util";

export interface SideSelectorProps {
    disabled: boolean;
    playerPosition: CoordWithSys;
    playMode: PlayMode;
    workoutX: number;
    setPlayMode: (updated: PlayMode) => void;
    forceShowBoth?: boolean;
}

export default function SideSelector({
    disabled,
    playerPosition,
    playMode,
    workoutX,
    setPlayMode,
    forceShowBoth = false,
}: SideSelectorProps): React.JSX.Element {
    const { selected: selectedSport } = useSelectedSport();

    const { adKey, deuceKey } = React.useMemo(() => {
        if (playerPosition) {
            return sideKeys(playerPosition, selectedSport);
        }

        return {
            adKey: "standard",
            deuceKey: "mirror",
        };
    }, [selectedSport, playerPosition]);

    const showBoth = React.useMemo(
        () => isCenterWorkout(workoutX, selectedSport),
        [selectedSport, workoutX],
    );

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography
                variant="caption"
                sx={{
                    minWidth: "30px",
                }}
            >
                Side
            </Typography>
            <ButtonGroup
                disabled={disabled}
                fullWidth
                size="small"
                color="primary"
            >
                <Button
                    variant={playMode === deuceKey ? "contained" : "outlined"}
                    color="info"
                    sx={{
                        color:
                            playMode === deuceKey
                                ? "common.white"
                                : "primary.light",
                        backgroundColor:
                            playMode === deuceKey
                                ? "primary.light"
                                : "common.white",
                        fontSize: "10px",
                    }}
                    onClick={() => {
                        setPlayMode(deuceKey as PlayMode);
                    }}
                >
                    deuce
                </Button>
                <Button
                    variant={playMode === adKey ? "contained" : "outlined"}
                    color="info"
                    sx={{
                        color:
                            playMode === adKey
                                ? "common.white"
                                : "primary.light",
                        backgroundColor:
                            playMode === adKey
                                ? "primary.light"
                                : "common.white",
                        fontSize: "10px",
                    }}
                    onClick={() => {
                        setPlayMode(adKey as PlayMode);
                    }}
                >
                    ad
                </Button>
                {(forceShowBoth || showBoth) && (
                    <Button
                        variant={playMode === "dual" ? "contained" : "outlined"}
                        color="info"
                        sx={{
                            color:
                                playMode === "dual"
                                    ? "common.white"
                                    : "primary.light",
                            backgroundColor:
                                playMode === "dual"
                                    ? "primary.light"
                                    : "common.white",
                            fontSize: "10px",
                        }}
                        onClick={() => {
                            setPlayMode("dual");
                        }}
                    >
                        both
                    </Button>
                )}
            </ButtonGroup>
        </Stack>
    );
}
