import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import { WorkoutStatus } from "@volley/shared/coach-models";

import useDialog from "../../../../Dialog/useDialog";

const tooManyPeopleImg = new URL(
    "../../../../../static/img/sv-too-many.png",
    import.meta.url,
).toString();
const noPersonImg = new URL(
    "../../../../../static/img/sv-no-person.png",
    import.meta.url,
).toString();

import ContactSupport from "./ContactSupport";

interface ContentProps {
    visualizerComponent: React.JSX.Element;
}

function TooManyPeopleContent({ visualizerComponent }: ContentProps) {
    return (
        <DialogContent>
            <Box component="div" sx={{ mb: 3 }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h4">
                        Too Many People Detected
                    </Typography>
                    <Box
                        component="img"
                        src={tooManyPeopleImg as string}
                        alt="Too many people icon"
                        sx={{ ml: 1 }}
                    />
                </Box>
                <Typography mb={2}>
                    There are too many people in the service box. There can only
                    be one person in the service box (pink area below image)
                    during Serve and Volley play.
                </Typography>
                <ContactSupport />
                <Box mt={2}>{visualizerComponent}</Box>
            </Box>
        </DialogContent>
    );
}

function NoPersonContent({ visualizerComponent }: ContentProps) {
    return (
        <DialogContent>
            <Box component="div" sx={{ mb: 3 }}>
                <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="h4">No Person Detected</Typography>
                    <Box
                        component="img"
                        src={noPersonImg as string}
                        alt="No person icon"
                        sx={{ ml: 1 }}
                    />
                </Box>
                <Typography>
                    This trainer&apos;s camera is not able to detect a person on
                    the court. Please check the following:
                </Typography>
                <Typography component="ul" mb={2}>
                    <li>
                        The trainer should not be pointed into harsh lighting
                        conditions (sunrise/sunset)
                    </li>
                    <li>Stand in the pink area shown in the below image</li>
                    <li>If you are still not detected, restart the trainer</li>
                </Typography>
                <ContactSupport />
            </Box>
            <Box sx={{ height: "300px" }}>{visualizerComponent}</Box>
        </DialogContent>
    );
}

function GenericErrorContent() {
    return (
        <DialogContent>
            <Box component="div" sx={{ mb: 3 }}>
                <Typography variant="h4" mb={1}>
                    Error
                </Typography>
                <Typography>
                    An error occurred while trying play Serve and Volley. Please
                    try again later.
                </Typography>
            </Box>
        </DialogContent>
    );
}

interface Props {
    errorDialogOpen: boolean;
    workoutStatus: WorkoutStatus | undefined;
    setErrorDialogOpen: (open: boolean) => void;
    visualizerComponent: React.JSX.Element;
}

export default function ErrorDialog({
    errorDialogOpen,
    workoutStatus,
    setErrorDialogOpen,
    visualizerComponent,
}: Props) {
    const { setDialogType } = useDialog();
    let content = <GenericErrorContent />;

    if (
        workoutStatus?.appErrors?.some(
            (error) => error.cause?.code === "ERRORTOOMANY",
        )
    ) {
        content = (
            <TooManyPeopleContent visualizerComponent={visualizerComponent} />
        );
    }

    let showRestartButton = false;
    if (
        workoutStatus?.appErrors?.some(
            (error) => error.cause?.code === "ERRORNOPERSON",
        )
    ) {
        content = <NoPersonContent visualizerComponent={visualizerComponent} />;
        showRestartButton = true;
    }

    return (
        <Dialog open={errorDialogOpen}>
            {content}
            <DialogActions>
                {showRestartButton && (
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => {
                            setDialogType("VisionFaultServeAndVolleyDialog");
                            setErrorDialogOpen(false);
                        }}
                    >
                        Restart Trainer
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setErrorDialogOpen(false)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
