import * as React from "react";

import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getEnv } from "../../util";

import TutorialsSwipe from "./TutorialsSwipe";
import DATA from "./data";
import { Slug } from "./types";

const bucket = () => {
    const env = getEnv();
    if (env === "production") return "volley-production-media";
    if (env === "rc") return "volley-rc-media";
    return "volley-staging-media";
};

const cdnUrl = (fileName: Slug) =>
    `/cdn/gcs/${bucket()}/tutorials/${fileName}.jpg`;

interface Props {
    slugs?: Slug[];
    onDone?: () => void;
}

export default function TutorialsStepper({
    slugs,
    onDone,
}: Props): React.JSX.Element {
    const [index, setIndex] = React.useState(0);
    const [textExpanded, setTextExpanded] = React.useState(false);
    const data = React.useMemo(
        () =>
            slugs
                ? DATA.filter(({ slug: pageSlug }) => slugs.includes(pageSlug))
                : DATA.slice(1),
        [slugs],
    );

    const onChangeIndex = React.useCallback((nextIndex: number) => {
        setTextExpanded(false);
        setIndex(nextIndex);
    }, []);

    const isLastSlide = index === data.length - 1;

    return (
        <Stack spacing={1}>
            <TutorialsSwipe index={index} onChangeIndex={onChangeIndex}>
                {data.map((page) => (
                    <Box
                        component="div"
                        key={page.slug}
                        display="flex"
                        justifyContent="center"
                        position="relative"
                        width="100%"
                    >
                        <img
                            src={cdnUrl(page.slug)}
                            alt={page.slug}
                            style={{
                                aspectRatio: "9 / 16",
                                maxHeight: "80vh",
                                minHeight: "80vh",
                            }}
                            loading="lazy"
                        />
                        {page.title && (
                            <Stack
                                sx={{
                                    px: 1,
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    overflow: "hidden",
                                    whiteSpace: "pre-wrap",
                                    maxHeight: "3rem",
                                    background: "rgba(0, 0, 0, 0.6)",
                                    color: "#fff",
                                    transition: "max-height 0.3s ease-in-out",
                                    ...(textExpanded
                                        ? {
                                              maxHeight: "100%",
                                              background: "rgba(0, 0, 0, 0.9)",
                                          }
                                        : undefined),
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    component="h1"
                                    sx={{ pb: 0, pt: 1 }}
                                >
                                    {page.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        cursor: "pointer",
                                        ...(!textExpanded
                                            ? {
                                                  pr: 5.5,
                                                  maxHeight: "1.5rem",
                                                  overflow: "hidden",
                                                  "&::after": {
                                                      content: '"…(more)"',
                                                      position: "absolute",
                                                      bottom: 2,
                                                      right: 0,
                                                      pr: 1,
                                                  },
                                              }
                                            : {
                                                  p: 1,
                                              }),
                                    }}
                                    onClick={() =>
                                        setTextExpanded(!textExpanded)
                                    }
                                >
                                    {`${page.text}${!textExpanded ? "…(more)" : ""}`}
                                </Typography>
                            </Stack>
                        )}
                    </Box>
                ))}
            </TutorialsSwipe>
            <MobileStepper
                variant={data.length <= 10 ? "dots" : "text"}
                position="static"
                steps={data.length}
                activeStep={index}
                backButton={
                    <Button
                        size="small"
                        disabled={index === 0}
                        onClick={() => setIndex(index - 1)}
                    >
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                }
                nextButton={
                    isLastSlide ? (
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={onDone}
                        >
                            Done
                            <DoneIcon />
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            onClick={() => setIndex(index + 1)}
                        >
                            Next
                            <KeyboardArrowRight />
                        </Button>
                    )
                }
                sx={{ my: 2, background: "transparent" }}
            />
        </Stack>
    );
}
