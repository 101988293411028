import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BallAndArrowSquare(
    props: SvgIconProps,
): React.JSX.Element {
    return (
        <SvgIcon viewBox="0 0 200 200" {...props}>
            <defs>
                <filter
                    id="a"
                    x="0"
                    y="0"
                    width="96.065"
                    height="207"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="4" in="SourceAlpha" />
                    <feGaussianBlur result="blur" stdDeviation="4" />
                    <feFlood floodOpacity=".161" />
                    <feComposite in2="blur" operator="in" result="result1" />
                    <feComposite in="SourceGraphic" in2="result1" />
                </filter>
                <radialGradient
                    id="b"
                    cx="-341.91"
                    cy="962.08"
                    r=".603"
                    gradientTransform="matrix(200 1.8 1.8 -200 66763 193114)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F5EF63" offset="0" />
                    <stop stopColor="#CCCB29" offset=".692" />
                    <stop stopColor="#C8DB34" offset="1" />
                </radialGradient>
            </defs>
            <path
                d="m99.5-9.9e-7c55.221 0 100 44.779 100 100 0 55.221-44.779 100-100 100-55.221 0-100-44.779-100-100 0-55.221 44.779-100 100-100z"
                fill="url(#b) #C8DB34"
                strokeWidth=".88496"
            />
            <g
                transform="matrix(-1.0821 0 0 -1.0821 151.97 207.35)"
                filter="url(#a) none"
            >
                <path
                    transform="rotate(180,42.03,95.5)"
                    d="m36.032 0-36.032 46.6h15.142l20.858 136.4h0.072l20.851-136.4h15.142z"
                    fill="#096ee5"
                />
            </g>
        </SvgIcon>
    );
}
