import * as React from "react";
import { Link as RouterLink } from "react-router";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import HistoryIcon from "@mui/icons-material/History";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PasswordIcon from "@mui/icons-material/Password";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { PartnerLocationWithRelations } from "@volley/data";

import config from "../../config";
import fetchApi, { logFetchError } from "../../util/fetchApi";
import { useCurrentUser } from "../hooks/currentUser";

import { useSelectedSport, type Sport } from "./context/sport";

const DISPLAY_CLUB_SUMMARY_CACHE_KEY = "volley.displayClubSummary";

interface UserHamburgerMenuProps {
    open: boolean;
    onClose: () => void;
}

interface UserMenuItemsProps {
    closeMenu: () => void;
}

function UserMenuItems({ closeMenu }: UserMenuItemsProps): React.JSX.Element {
    const { isAdmin } = useCurrentUser();
    const { selected, friendlyName, available, updateSelected } =
        useSelectedSport();
    const [open, setOpen] = React.useState(false);

    const [displayClubSummary, setDisplayClubSummary] = React.useState(false);

    React.useEffect(() => {
        if (isAdmin()) {
            setDisplayClubSummary(true);
        } else {
            const cachedDisplayClubSummary = window.sessionStorage.getItem(
                DISPLAY_CLUB_SUMMARY_CACHE_KEY,
            );
            if (cachedDisplayClubSummary === null) {
                fetchApi<PartnerLocationWithRelations[]>(
                    "/api/partner-locations",
                )
                    .then((assignedLocations) => {
                        const hasAssignedLocations =
                            assignedLocations.length > 0;
                        setDisplayClubSummary(hasAssignedLocations);
                        window.sessionStorage.setItem(
                            DISPLAY_CLUB_SUMMARY_CACHE_KEY,
                            String(hasAssignedLocations),
                        );
                    })
                    .catch((error) =>
                        logFetchError(
                            error,
                            "GET /api/partner-locations failed in UserHamburgerMenu component",
                        ),
                    );
            } else if (cachedDisplayClubSummary === "true") {
                setDisplayClubSummary(true);
            }
        }
    }, [isAdmin]);

    return (
        <>
            <ListItemButton
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                }}
            >
                <ListItemIcon>
                    <SportsTennisIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary={friendlyName} sx={{ color: "white" }} />
                {open ? (
                    <ExpandLessIcon sx={{ color: "white" }} />
                ) : (
                    <ExpandMoreIcon sx={{ color: "white" }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.keys(available).map((sport) => (
                        <ListItemButton
                            key={sport}
                            sx={{ pl: 4 }}
                            onClick={() => {
                                updateSelected(sport as Sport);
                                setOpen(false);
                                closeMenu();
                            }}
                        >
                            <ListItemText
                                primary={available[sport as Sport]}
                                sx={{
                                    color:
                                        sport === selected
                                            ? "secondary.main"
                                            : "white",
                                }}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
            <ListItemButton component={RouterLink} to="/tutorials">
                <ListItemIcon>
                    <HelpIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Tutorial" sx={{ color: "white" }} />
            </ListItemButton>
            <ListItemButton
                component={RouterLink}
                to="/content/history-stats/mine"
            >
                <ListItemIcon>
                    <HistoryIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                    primary="Sharing & Stats"
                    sx={{ color: "white" }}
                />
            </ListItemButton>
            {displayClubSummary && (
                <ListItemButton
                    component={RouterLink}
                    to="/content/club-summary"
                >
                    <ListItemIcon>
                        <LeaderboardIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Club Summary"
                        sx={{ color: "white" }}
                    />
                </ListItemButton>
            )}
            <ListItemButton component={RouterLink} to="/ball-cabinet-unlock">
                <ListItemIcon>
                    <PasswordIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                    primary="Ball Cabinet Unlock"
                    sx={{ color: "white" }}
                />
            </ListItemButton>
            {isAdmin() && (
                <>
                    <Divider
                        sx={{
                            borderColor: "white",
                            width: "80%",
                            margin: "auto",
                        }}
                    />
                    <ListItemButton component={RouterLink} to="/admin">
                        <ListItemIcon>
                            <AdminPanelSettingsIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Admin Panel"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        component={RouterLink}
                        to="/content/apps/workouts/experimental/point-click"
                    >
                        <ListItemText
                            inset
                            primary="Point & Click"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        component={RouterLink}
                        to="/content/apps/workouts/experimental/lobapp"
                    >
                        <ListItemText
                            inset
                            primary="Lob App"
                            sx={{ color: "white" }}
                        />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText
                            inset
                            primary="Software Build:"
                            secondary={config.version}
                            sx={{
                                color: "white",
                                "& .MuiListItemText-secondary": {
                                    color: "white",
                                },
                            }}
                        />
                    </ListItemButton>
                </>
            )}
            {/* TODO: Add lob app link here */}
        </>
    );
}

const DRAWER_WIDTH = 240;

export default function UserHamburgerMenu({
    open,
    onClose,
}: UserHamburgerMenuProps): React.JSX.Element {
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    if (isSmUp) {
        return (
            <Drawer
                variant="permanent"
                open={open}
                onClose={onClose}
                PaperProps={{
                    sx: {
                        backgroundColor: (t) => t.palette.primary.main,
                        borderBottomRightRadius: 24,
                        borderTopRightRadius: 24,
                        width: DRAWER_WIDTH,
                    },
                }}
            >
                <Toolbar />
                <List>
                    <UserMenuItems closeMenu={onClose} />
                </List>
            </Drawer>
        );
    }

    return (
        <Drawer
            variant="temporary"
            open={open}
            onClose={onClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    backgroundColor: (t) => t.palette.primary.main,
                    borderBottomRightRadius: 24,
                    borderTopRightRadius: 24,
                    width: DRAWER_WIDTH,
                },
            }}
        >
            <Toolbar sx={{ justifyContent: "flex-end" }}>
                <IconButton onClick={onClose}>
                    <CloseIcon sx={{ color: "white" }} />
                </IconButton>
            </Toolbar>
            <List onClick={onClose}>
                <UserMenuItems closeMenu={onClose} />
            </List>
        </Drawer>
    );
}
