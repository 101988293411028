import * as React from "react";

import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrowSharp";
import { styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { PlayState } from "@volley/shared/app-models";

import { usePairingContext } from "../../../hooks/pairingStatus";
import { CaptureStatus } from "../useAppWorkoutPlay";

const PlayPauseFab = styled(Fab)(({ theme }) => ({
    position: "absolute",
    width: "80px",
    height: "80px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    fontSize: "25px",
    zIndex: 2,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    "&:hover": {
        backgroundColor: theme.palette.secondary.main,
    },
    "&:disabled": {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.disabled,
    },
})) as typeof Fab;

const Pulsing = styled(Typography)({
    "@keyframes pulse": {
        "0%": {
            opacity: 1.0,
        },
        "50%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1.0,
        },
    },
    animation: "pulse 2000ms ease-in-out infinite",
    paddingRight: "4px",
    color: "red",
});

const Solid = styled(Typography)({
    paddingRight: "4px",
    color: "red",
});

interface Props {
    playDisabled: boolean;
    pauseDisabled: boolean;
    showRecord: boolean;
    recordDisabled: boolean;
    recordingStatus: CaptureStatus;
    playState: PlayState;
    playSummary: string | undefined;
    onPlayClicked: () => void;
    onPauseClicked: () => void;
    onRecordClicked: () => void;
}

export default function PlayAppBar({
    pauseDisabled,
    playDisabled,
    playState,
    playSummary,
    showRecord,
    recordDisabled,
    recordingStatus,
    onPlayClicked,
    onPauseClicked,
    onRecordClicked,
}: Props): React.JSX.Element {
    const { status, promptToPair } = usePairingContext();

    const playPauseDisabled = React.useMemo(() => {
        if (status === "requested" || status === "validating") {
            return true;
        }

        if (playState === "playing") {
            return pauseDisabled;
        }

        return playDisabled;
    }, [status, playState, playDisabled, pauseDisabled]);

    const playPauseClicked = React.useCallback(() => {
        if (status === "paired") {
            if (playState === "paused" || playState === "stopped") {
                onPlayClicked();
            } else {
                onPauseClicked();
            }
        }

        if (status === "unpaired") {
            promptToPair(true);
        }
    }, [status, playState, promptToPair, onPauseClicked, onPlayClicked]);

    return (
        <AppBar
            position="fixed"
            color="primary"
            sx={{
                width: { sm: "calc(100% - 240px)" },
                ml: { sm: "240px" },
                top: "auto",
                bottom: 0,
                zIndex: (theme) => theme.zIndex.drawer - 1,
            }}
        >
            <Toolbar>
                <Box component="div">
                    <Typography variant="h3">{playSummary}</Typography>
                </Box>
                <PlayPauseFab
                    disabled={playPauseDisabled}
                    onClick={() => playPauseClicked()}
                >
                    {playState === "playing" ? (
                        <PauseIcon
                            sx={{
                                fontSize: "64px",
                            }}
                        />
                    ) : (
                        <PlayArrowIcon
                            sx={{
                                fontSize: "64px",
                            }}
                        />
                    )}
                </PlayPauseFab>
                {showRecord && (
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => onRecordClicked()}
                        disabled={recordDisabled || status !== "paired"}
                        sx={{
                            position: "absolute",
                            top: "8px",
                            right: "29px",
                            color: (t) => t.palette.common.black,
                            borderColor: (t) => t.palette.common.black,
                            backgroundColor: (t) => t.palette.common.white,
                            "&:hover": {
                                backgroundColor: (t) => t.palette.common.white,
                            },
                            "&:disabled": {
                                backgroundColor: (t) => t.palette.grey[300],
                                color: (t) => t.palette.text.disabled,
                            },
                        }}
                    >
                        {recordingStatus === "Recording" && (
                            <Pulsing>⬤</Pulsing>
                        )}
                        {recordingStatus !== "Recording" && <Solid>⬤</Solid>}
                        <Typography variant="button">REC</Typography>
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
}
