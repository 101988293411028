import * as React from "react";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import TopNavBar from "../common/TopNavBar";

import TutorialsStepper from "./TutorialsStepper";

export default function Tutorials(): React.JSX.Element {
    const navigate = useNavigate();
    return (
        <Box
            component="div"
            sx={{ backgroundColor: "white", minHeight: "100%" }}
        >
            <TopNavBar />
            <Box component="main">
                <Container maxWidth="xs" sx={{ px: 0 }}>
                    <TutorialsStepper onDone={() => navigate("/")} />
                </Container>
            </Box>
        </Box>
    );
}
