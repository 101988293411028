import * as React from "react";

import Box from "@mui/material/Box";

interface Props {
    index: number;
    onChangeIndex: (index: number) => void;
}

export default function TutorialsSwipe({
    index,
    onChangeIndex,
    children,
}: React.PropsWithChildren<Props>): React.JSX.Element {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const scrollTimeout = React.useRef<number>(undefined);

    const onScroll = React.useCallback(
        ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
            if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
            scrollTimeout.current = window.setTimeout(() => {
                const pageWidth =
                    currentTarget.scrollWidth /
                    (currentTarget.children.length || 1);
                onChangeIndex(Math.round(currentTarget.scrollLeft / pageWidth));
            }, 100);
        },
        [onChangeIndex],
    );

    React.useEffect(() => {
        containerRef.current?.children[index]?.scrollIntoView({
            behavior: "auto",
        });
    }, [index]);

    return (
        <Box
            component="div"
            ref={containerRef}
            onScroll={onScroll}
            sx={{
                display: "flex",
                scrollSnapType: "x mandatory",
                overflowX: "scroll",
                scrollbarWidth: "none",
                "& > *": {
                    width: "100%",
                    flexShrink: 0,
                    scrollSnapAlign: "center",
                    scrollSnapStop: "always",
                },
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            }}
        >
            {children}
        </Box>
    );
}
