import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";

import AccordionSlider from "./AccordionSlider";

interface ThrowMarks {
    value: number;
    label: string;
}

export interface ThrowCountProps {
    disabled?: boolean;
    selectedThrowCount: number;
    onUserThrowCountChanged: (updated: number) => void;
    label?: string;
    marks?: ThrowMarks[];
    min?: number;
    max?: number;
    labelWrapperSx?: SxProps<Theme>;
    sliderWrapperSx?: SxProps<Theme>;
    sliderSx?: SxProps<Theme>;
    wrapperSx?: SxProps<Theme>;
}

export default function ThrowCount({
    disabled = false,
    selectedThrowCount,
    onUserThrowCountChanged,
    label = "Shots",
    marks = [
        { value: 1, label: "1" },
        { value: 15, label: "15" },
        { value: 30, label: "30" },
        { value: 45, label: "45" },
        { value: 60, label: "All" },
    ],
    min = 1,
    max = 60,
    labelWrapperSx = {},
    sliderWrapperSx = {},
    sliderSx = {},
    wrapperSx = {},
}: ThrowCountProps): React.JSX.Element {
    return (
        <Stack direction="row" spacing={0.5} alignItems="center" sx={wrapperSx}>
            <Box component="div" sx={labelWrapperSx}>
                <Typography variant="caption" sx={{ minWidth: "30px" }}>
                    {label}
                </Typography>
            </Box>
            <Box
                component="div"
                sx={{
                    flexGrow: 1,
                    ...sliderWrapperSx,
                }}
            >
                <AccordionSlider
                    disabled={disabled}
                    min={min}
                    max={max}
                    marks={marks}
                    value={selectedThrowCount}
                    onChange={(_, v) => onUserThrowCountChanged(v as number)}
                    sx={{
                        display: "table",
                        margin: "0px auto 20px auto",
                        maxWidth: "80%",
                        "& .MuiSlider-valueLabel": {
                            fontSize: "32px",
                        },
                        ...sliderSx,
                    }}
                    valueLabelDisplay="auto"
                />
            </Box>
        </Stack>
    );
}
