import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { KnownFeatures } from "../util";

export interface FeatureNotifierProps {
    missing: KnownFeatures[];
}

export default function FeatureNotifier({
    missing,
}: FeatureNotifierProps): React.JSX.Element {
    const [dismissed, setDismissed] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [buttonText, setButtonText] = React.useState<"more" | "less">("more");

    React.useEffect(() => {
        if (expanded) {
            setButtonText("less");
        } else {
            setButtonText("more");
        }
    }, [expanded]);

    const endIcon = expanded ? (
        <KeyboardArrowUpIcon />
    ) : (
        <KeyboardArrowDownIcon />
    );

    const action = (
        <IconButton aria-label="close" onClick={() => setDismissed(true)}>
            <CloseIcon />
        </IconButton>
    );

    return (
        <Snackbar
            open={missing.length > 0 && !dismissed}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            sx={{
                bottom: 40,
            }}
        >
            <Alert severity="warning" action={action}>
                <AlertTitle>Compatibility Warning</AlertTitle>
                <Collapse in={expanded}>
                    <Stack spacing={1}>
                        <Typography>
                            The trainer you&apos;re connected to doesn&apos;t
                            support some of the features used by this workout.
                        </Typography>
                        <Typography>
                            Ask your Pro or contact Volley about updating the
                            trainer to get access to the full features.
                        </Typography>
                    </Stack>
                </Collapse>
                <Button
                    onClick={() => setExpanded(!expanded)}
                    variant="text"
                    fullWidth
                    endIcon={endIcon}
                    color="warning"
                    size="small"
                >
                    {buttonText}
                </Button>
            </Alert>
        </Snackbar>
    );
}
