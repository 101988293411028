import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AccordionSlider from "./AccordionSlider";

export interface SetsProps {
    sets: number;
    setSets: (sets: number) => void;
    disabled?: boolean;
}

export default function Sets({
    sets,
    setSets,
    disabled = false,
}: SetsProps): React.JSX.Element {
    const setsMarks = React.useMemo(() => {
        const marks: { label: string; value: number }[] = [];
        for (let i = 0; i <= 10; i += 1) {
            marks.push({ label: i.toString(), value: i });
        }
        return marks;
    }, []);

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="caption" minWidth="30px">
                Sets
            </Typography>
            <Box component="div" flexGrow={1}>
                <AccordionSlider
                    disabled={disabled}
                    min={1}
                    max={10}
                    marks={setsMarks}
                    value={sets}
                    valueLabelDisplay="auto"
                    onChange={(_, v) => setSets(v as number)}
                    sx={{
                        display: "table",
                        margin: "0px auto 20px auto",
                        maxWidth: "80%",
                        ".MuiSlider-track": {
                            color: "primary.light",
                        },
                        "& .MuiSlider-valueLabel": {
                            fontSize: "32px",
                        },
                    }}
                />
            </Box>
        </Stack>
    );
}
