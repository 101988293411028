import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MultiShotIcon(props: SvgIconProps): React.JSX.Element {
    return (
        <SvgIcon
            width="54"
            height="49"
            viewBox="0 0 54 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M41.83 47.0101C47.8112 47.0101 52.66 42.1613 52.66 36.1801C52.66 30.1989 47.8112 25.3501 41.83 25.3501C35.8488 25.3501 31 30.1989 31 36.1801C31 42.1613 35.8488 47.0101 41.83 47.0101Z"
                stroke="#3CE97C"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M31 36.0202C34.72 35.3702 35.88 29.7902 40.93 29.2302C45.98 28.6702 49.32 33.6102 48.52 37.7802C47.28 44.2502 39.92 42.5602 37.83 46.3802"
                stroke="#3CE97C"
                strokeWidth="1.8"
                strokeMiterlimit="10"
            />
            <path
                d="M11.83 47.0101C17.8112 47.0101 22.66 42.1613 22.66 36.1801C22.66 30.1989 17.8112 25.3501 11.83 25.3501C5.84876 25.3501 1 30.1989 1 36.1801C1 42.1613 5.84876 47.0101 11.83 47.0101Z"
                stroke="#3CE97C"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M1 36.0202C4.72 35.3702 5.88 29.7902 10.93 29.2302C15.98 28.6702 19.32 33.6102 18.52 37.7802C17.28 44.2502 9.92 42.5602 7.83 46.3802"
                stroke="#3CE97C"
                strokeWidth="1.8"
                strokeMiterlimit="10"
            />
            <path
                d="M26.83 23.0101C32.8112 23.0101 37.66 18.1613 37.66 12.1801C37.66 6.19885 32.8112 1.3501 26.83 1.3501C20.8488 1.3501 16 6.19885 16 12.1801C16 18.1613 20.8488 23.0101 26.83 23.0101Z"
                stroke="#3CE97C"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M16 12.0202C19.72 11.3702 20.88 5.79015 25.93 5.23015C30.98 4.67015 34.32 9.61015 33.52 13.7802C32.28 20.2502 24.92 18.5602 22.83 22.3802"
                stroke="#3CE97C"
                strokeWidth="1.8"
                strokeMiterlimit="10"
            />
        </SvgIcon>
    );
}
