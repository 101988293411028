import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { EnableGesture } from "@volley/shared/http/trainer-control";

import { pairedFetchApi, logFetchError } from "../../../util/fetchApi";
import { usePairingContext } from "../../hooks/pairingStatus";
import { useStatus } from "../../hooks/status";

import { InfoListItem } from "./listItems";

const GestureSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: theme.palette.success.main,
    },
    "& .MuiSwitch-switchBase.Mui-unchecked": {
        color: theme.palette.grey,
    },
}));

export default function GestureControlListItem(): React.JSX.Element {
    const { status: trainerStatus } = useStatus();
    const { status } = usePairingContext();
    const [gestEnabled, setGestEnabled] = React.useState<boolean>(false);
    const enableGesture = React.useCallback(
        async (enable: boolean) => {
            try {
                await pairedFetchApi(
                    trainerStatus?.clientId,
                    "/api/enable-gesture/set",
                    "POST",
                    { enable },
                );
            } catch (ex) {
                logFetchError(ex);
            }
        },
        [trainerStatus?.clientId],
    );

    React.useEffect(() => {
        const fetchData = async () => {
            const res = await pairedFetchApi<EnableGesture>(
                trainerStatus?.clientId,
                "/api/enable-gesture/get",
                "GET",
            );
            setGestEnabled(res.enable);
        };
        fetchData().catch((err) => {
            logFetchError(err);
        });
    }, [trainerStatus?.clientId]);
    const gestureToggle = React.useCallback(async () => {
        setGestEnabled(!gestEnabled);
        await enableGesture(!gestEnabled);
    }, [gestEnabled, enableGesture]);

    return (
        <InfoListItem>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" component="span">
                    Gestures:
                </Typography>
                <Box component="div">
                    <GestureSwitch
                        size="small"
                        name="gestureEnable"
                        checked={gestEnabled}
                        onChange={gestureToggle}
                        disabled={status !== "paired"}
                    />
                </Box>
            </Stack>
        </InfoListItem>
    );
}
