import * as React from "react";
import { useNavigate, useParams } from "react-router";

import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import TuneIcon from "@mui/icons-material/Tune";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { convert, model, sim } from "@volley/physics";
import { getPhysicsModel } from "@volley/physics/dist/conversions";
import type {
    AOI,
    ServePosition,
    Shot,
} from "@volley/shared/apps/app-common-models";
import type {
    ServeAndVolleyParameters,
    DifficultyLevel,
    ServeAndVolleyAppConfig,
    TargetAOI,
    ServeAndVolleyV3State,
} from "@volley/shared/apps/serveandvolley-models";
import { JSONObject } from "@volley/shared/common-models";

import logger from "../../../../../log";
import { logFetchError } from "../../../../../util/fetchApi";
import { CoordWithSys } from "../../../../../util/position-types";
import useDialog from "../../../../Dialog/useDialog";
import Loading from "../../../../common/Loading";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import { VisualizerAOI } from "../../../../common/Visualizer/models";
import { Sport, useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useStatus } from "../../../../hooks/status";
import useIntercom from "../../../../hooks/useIntercom";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import usePrevious from "../../../../hooks/usePrevious";
import { useTrainerFeatures } from "../../../../hooks/useTrainerFeatures";
import CaptureToast from "../../Shared/CaptureToast";
import DelaySlider from "../../Shared/DelaySlider";
import OptionSelector from "../../Shared/OptionSelector";
import PlayAppBar from "../../Shared/PlayAppBar";
import SpeedAdjustment from "../../Shared/SpeedAdjustment";
import ThrowCount from "../../Shared/ThrowCount";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import ServePlus1LevelSelector from "../10-serve-and-volley/ServePlus1Selector";
import WorkoutErrorDialog from "../4-multi-shot/play/ErrorDialog";
import BasicShotSelector from "../shared/BasicShotSelector";
import { overlayDefaultVisionPipelineConfig } from "../shared/app-common";

import ErrorDialog from "./ErrorDialog";
import InstructionDialog from "./InstructionDialog";
import LocalizingDialog from "./LocalizingDialog";
import StatsAlert from "./StatsAlert";
import VisionSystemStartingDialog from "./VIsionSystemStartingDialog";

// player positions for deuce and ad
const PLAYER_POSITIONS: Record<
    Sport,
    { ad: CoordWithSys; deuce: CoordWithSys }
> = {
    PLATFORM_TENNIS: {
        deuce: {
            x: 2000,
            y: -400,
            sys: "physics",
        },
        ad: {
            x: 4100,
            y: -400,
            sys: "physics",
        },
    },
    TENNIS: {
        deuce: { x: -4.689375, y: 13.8318125, sys: "physics" },
        ad: { x: 4.689375, y: 13.8318125, sys: "physics" },
    },
    PADEL: {
        deuce: { x: 0, y: 0, sys: "physics" },
        ad: { x: 0, y: 0, sys: "physics" },
    },
    PICKLEBALL: {
        deuce: { x: 0, y: 0, sys: "physics" },
        ad: { x: 0, y: 0, sys: "physics" },
    },
};

const SMALLE_MAX_BALLS = 60;

function createVisualizerAOI(aoi: AOI, color: string): VisualizerAOI {
    return {
        size: [
            Math.abs(aoi.lowerRightX - aoi.upperLeftX),
            Math.abs(aoi.lowerRightY - aoi.upperLeftY),
        ],
        position: {
            x: aoi.upperLeftX + (aoi.lowerRightX - aoi.upperLeftX) / 2,
            y: aoi.lowerRightY + (aoi.upperLeftY - aoi.lowerRightY) / 2,
            z: 0.05,
        },
        color,
        opacity: 0.6,
    };
}

function getDefaultParams(
    workout: AppWorkoutWithSummary,
    trainerFeatures: string[],
): ServeAndVolleyParameters {
    const hasPoseServeDetection =
        trainerFeatures.includes("poseServeDetection");

    const appConfig = workout.config as unknown as ServeAndVolleyAppConfig;
    const defaultDifficultyLevel = 1;
    const defaultServePosition: ServePosition = "deuce";

    let defaultShots: Shot[] = [];
    if (appConfig.shots[defaultDifficultyLevel].length > 0) {
        defaultShots = [appConfig.shots[defaultDifficultyLevel][0]];
    }

    return {
        shots: defaultShots,
        difficultyLevel: defaultDifficultyLevel,
        shotDelayMilliseconds: hasPoseServeDetection ? 500 : 0,
        servePosition: defaultServePosition,
        serveAOI: appConfig.serveAOI[defaultServePosition],
        serveExitAOI: appConfig.exitAOI[defaultServePosition],
        targetAOI: appConfig.targetAOI[defaultServePosition],
        targetAOIs: appConfig.availableTargetAOIs[defaultServePosition],
        serviceBoxAOI: appConfig.serviceBoxAOI[defaultServePosition],
        selectedTargetAOI: "center",
        serveCount: 30,
        serveChallengeMode: false,
    };
}

type ParamsAction =
    | { type: "difficultyLevel"; value: DifficultyLevel }
    | { type: "servePosition"; value: ServePosition }
    | { type: "shotDelay"; value: number }
    | { type: "selectedAOI"; value: string }
    | { type: "speedAdjustment"; value?: number }
    | { type: "serveCount"; value: number }
    | { type: "selectedShots"; value: Shot[] };

function paramsReducer(
    state: ServeAndVolleyParameters,
    action: ParamsAction,
): ServeAndVolleyParameters {
    switch (action.type) {
        case "difficultyLevel":
            return {
                ...state,
                difficultyLevel: action.value,
            };
        case "servePosition": {
            return {
                ...state,
                servePosition: action.value,
            };
        }
        case "shotDelay":
            return {
                ...state,
                shotDelayMilliseconds: action.value * 1000, // convert to milliseconds
            };
        case "selectedAOI":
            return {
                ...state,
                selectedTargetAOI: action.value,
            };
        case "speedAdjustment":
            return {
                ...state,
                speedAdjustment: action.value,
            };
        case "serveCount":
            return {
                ...state,
                serveCount: action.value,
            };
        case "selectedShots":
            return {
                ...state,
                shots: action.value,
            };
        default:
            return state;
    }
}

interface ServeAndVolleyProps {
    workout: AppWorkoutWithSummary;
    trainerFeatures: string[];
}

function ServeAndVolley({
    workout,
    trainerFeatures,
}: ServeAndVolleyProps): JSX.Element {
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        getDefaultParams(workout, trainerFeatures),
    );
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const { status } = useStatus();
    const workoutStatus = status?.workouts;
    const { selected: selectedSport } = useSelectedSport();
    const { physicsModelName } = usePhysicsModelContext();

    const { position, isVisionStarting, isVisionFaulted, method, cancel } =
        usePosition();

    const { checkForLift, stop: stopLift } = useLift();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    // const { isAdmin } = useCurrentUser();
    const [instructionDialogOpen, setInstructionDialogOpen] =
        React.useState<boolean>(false);
    const [adjustDialogOpen, setAdjustDialogOpen] =
        React.useState<boolean>(false);

    const [targetMode, setTargetMode] = React.useState<"serviceBox" | "aoi">(
        "serviceBox",
    );

    // we use this ref to track if the settings have been changed and we should start a new game
    const modifiedRef = React.useRef(true);

    // we use this ref to track if a stop has been requested
    const stopRequestedRef = React.useRef(false);

    // we use this ref to force localization
    const forceLocalizationRef = React.useRef(true);

    const [localizingDialogOpen, setLocalizingDialogOpen] =
        React.useState(false);

    const [visionErrorDialogOpen, setVisionErrorDialogOpen] =
        React.useState(false);

    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

    const { setDialogType } = useDialog();

    const appConfig = React.useMemo<ServeAndVolleyAppConfig>(() => {
        const ac = workout.config as unknown as ServeAndVolleyAppConfig;
        if (trainerFeatures.includes("serveAndVolleyCloudDefaults")) {
            // Use vision config defaults to replace any missing DB values
            overlayDefaultVisionPipelineConfig(ac, workout.sport.name);
            // Ensure ball tracking is on for S&V V3
            // NOTE: turn this on in DB!
            // General serve and volley default overlay doesn't include it
            // because it's V3+ only.
            if (ac.visionPipelineConfig.ballTrackingMode === undefined) {
                ac.visionPipelineConfig.ballTrackingMode = "CLASSICAL";
            }
            logger.info(
                `ServeAndVolley V3: App Config (post overlay): ${JSON.stringify(ac, undefined, 2)}`,
            );
        }
        return ac;
    }, [workout, trainerFeatures]);

    const workoutParams = React.useMemo(() => {
        const shots = paramsState.shots;
        const serveAOI = appConfig.serveAOI[paramsState.servePosition];

        const availableTargetAOIs =
            appConfig.availableTargetAOIs[paramsState.servePosition];

        // target AOI is a bare AOI
        // const targetAOI: AOI = (
        //     availableTargetAOIs.find(
        //         (aoi) => aoi.name === paramsState.selectedTargetAOI,
        //     ) ?? availableTargetAOIs[0]
        // ).aoi;

        let targetAOIs: TargetAOI[] = [];
        // if the target mode is serviceBox, we only want the service box AOI
        // as the target
        if (targetMode === "serviceBox") {
            targetAOIs = [
                {
                    aoi: appConfig.serviceBoxAOI[paramsState.servePosition],
                    name: "Service Box",
                    value: 1,
                },
            ];
        } else {
            if (paramsState.selectedTargetAOI === "random") {
                targetAOIs = [...availableTargetAOIs];
            } else {
                const t = availableTargetAOIs.find(
                    (aoi) => aoi.name === paramsState.selectedTargetAOI,
                );

                if (t) {
                    targetAOIs = [t];
                }
            }
        }

        const serveExitAOI = appConfig.exitAOI[paramsState.servePosition];
        const serviceBoxAOI =
            appConfig.serviceBoxAOI[paramsState.servePosition];
        const visionPipelineConfig = trainerFeatures.includes("serveChallenge")
            ? appConfig.visionPipelineConfig
            : undefined;

        // we need to convert params to older version if trainer doesn't support V3
        if (trainerFeatures.includes("serveAndVolleyV3")) {
            // we pass in full config to the trainer
            return {
                ...paramsState,
                shots,
                serveAOI,
                // targetAOI,
                targetAOIs,
                serveExitAOI,
                serviceBoxAOI,
                visionPipelineConfig,
                serveChallengeMode: paramsState.serveChallengeMode,
                serveCount: paramsState.serveCount,
            };
        } else {
            // we're using the old version of the app
            return {
                shots,
                shotDelayMilliseconds: paramsState.shotDelayMilliseconds,
                difficultyLevel: paramsState.difficultyLevel,
                servePosition: paramsState.servePosition,
                serveAOI,
                serveExitAOI,
                // targetAOI,
                visionPipelineConfig,
                speedAdjustment: paramsState.speedAdjustment,
            };
        }
    }, [
        appConfig.availableTargetAOIs,
        appConfig.exitAOI,
        appConfig.serveAOI,
        appConfig.serviceBoxAOI,
        appConfig.visionPipelineConfig,
        paramsState,
        targetMode,
        trainerFeatures,
    ]);

    const [forceUsePlanned, setForceUsePlanned] = React.useState(false);
    const workoutPosition = React.useMemo(() => {
        return appConfig.trainerPosition[paramsState.servePosition];
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const plannedTrainerPosition = React.useMemo(() => {
        const pos = appConfig.trainerPosition[paramsState.servePosition];
        return {
            x: pos.positionX,
            y: pos.positionY,
            yaw: pos.positionYaw,
            heightIn: pos.positionHeight,
        };
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    // Only allow 'use planned' for Tennis
    const handleUsePlanned = React.useCallback(() => {
        setLocalizingDialogOpen(false);
        setForceUsePlanned(true);
        setPlayClicked(true);
    }, []);

    // localizedPositionForWorkout
    // If we have a localization height that is different from the workout height,
    // the localizedPosition that we send to appWorkoutPlay is NOT the localized postion
    // computed by usePosition because it will have the localization height. As a result,
    // if the localization height is different from the workout height, we need to do the adjustment.
    const localizedPositionForWorkout = React.useMemo(() => {
        if (forceUsePlanned) {
            // Tennis workouts can bypass localization to handle cases were the algorithm struggles due to ambient conditions
            if (appConfig.pitchOverride || appConfig.yOffset) {
                // If the app config has pitchOverride oryOffset values, we use them to "spoof" a localization result
                logger.info(
                    `[serveAndVolley] - Converting Planned Position to Localized: ${JSON.stringify(plannedTrainerPosition)}`,
                );
                const ts = new sim.TrainerSim(
                    physicsModelName as model.PhysicsModelName,
                    selectedSport,
                );
                ts.SetPositionManual({
                    h: convert.launchHeightInches2HeadHeight(
                        plannedTrainerPosition.heightIn,
                    ),
                    x: plannedTrainerPosition.x,
                    y: plannedTrainerPosition.y - (appConfig.yOffset ?? 0),
                    yaw: plannedTrainerPosition.yaw,
                });
                const localized = ts.GetLocalizedCameraPosition();

                const masqueradePosition = {
                    x: localized.x,
                    y: localized.y,
                    z: localized.z,
                    pitch:
                        appConfig.pitchOverride ??
                        1.45 - (90.0 * Math.PI) / 180.0,
                    roll: 0,
                    yaw: localized.yaw,
                };

                logger.info(
                    `[serveAndVolley] - Sending masquerade localization position: ${JSON.stringify(masqueradePosition)}`,
                );
                return masqueradePosition;
            }

            // Otherwise, we return undefined here to use the planned position and let
            // the trainer software try to configure the pipeline based on that...
            logger.info(
                "[serveAndVolley] - Localization process bypassed, using ONLY planned position",
            );

            return undefined;
        }
        logger.info(
            `[serveAndVolley] - validaing localized position for workout (method: ${method})`,
        );
        if (
            position &&
            appConfig.localizationHeightIn &&
            plannedTrainerPosition.heightIn !==
                appConfig.localizationHeightIn &&
            method === "one-shot"
        ) {
            const fp = { ...position };
            logger.info(
                `[serveAndVolley] - localization height: ${appConfig.localizationHeightIn} in`,
            );
            logger.info(
                `[serveAndVolley] - workout height: ${plannedTrainerPosition.heightIn} in`,
            );
            logger.info(
                `[serveAndVolley] - localized with one-shot at different height than workout - adjusting localized z coord`,
            );
            const heightDiffMilli =
                (appConfig.localizationHeightIn -
                    plannedTrainerPosition.heightIn) *
                25.4;
            // the position z coord is in millimeters
            logger.info(
                `[serveAndVolley] - localized position at loc height: ${JSON.stringify(position)} in`,
            );
            fp.z = fp.z - heightDiffMilli;
            logger.info(
                `[serveAndVolley] - workout localized height is z = ${fp.z} mm (adjusted by ${heightDiffMilli})`,
            );
            return fp;
        } else if (position && method === "smart") {
            const fp = { ...position };
            logger.info(
                `[serveAndVolley] - localized with smart multi-height - adjusting localized z coord to workout height`,
            );
            const physicsModel = getPhysicsModel();
            const launchPointToCameraDistanceMeters =
                physicsModel.trainerGeometry.cameraOffsetFromHead.z -
                physicsModel.trainerGeometry.launchOriginOffset.z;
            logger.info(
                `[serveAndVolley] - launchPointToCameraDistanceMeters = ${launchPointToCameraDistanceMeters} (${launchPointToCameraDistanceMeters * 39.3701} in)`,
            );
            const plannedCameraHeightMilli =
                plannedTrainerPosition.heightIn * 25.4 +
                launchPointToCameraDistanceMeters * 1000.0;
            fp.z = plannedCameraHeightMilli;
            logger.info(
                `[serveAndVolley] - workout localized height is z = ${position.z} mm (${position.z / 25.4} in), adjusted to ${plannedCameraHeightMilli} mm (${plannedCameraHeightMilli / 25.4} in)`,
            );
            return fp;
        } else {
            return position;
        }
    }, [
        position,
        method,
        forceUsePlanned,
        plannedTrainerPosition,
        physicsModelName,
        selectedSport,
        appConfig.localizationHeightIn,
        appConfig.yOffset,
        appConfig.pitchOverride,
    ]);

    const {
        start,
        playState,
        playInitiated,
        workoutState,
        playDisabled,
        pauseDisabled,
        captureDisabled,
        captureVideo,
        captureStatus,
        stop: stopWorkout,
    } = useAppWorkoutPlay({
        workout: { ...workout, config: {}, ...workoutPosition },
        parameters: workoutParams as unknown as JSONObject,
        localizedPosition: localizedPositionForWorkout,
    });

    const summaryText = React.useMemo(() => {
        const state = workoutState as unknown as ServeAndVolleyV3State;
        if (state?.results) {
            return `${state.results.length}/${paramsState.serveCount}`;
        }
        return "";
    }, [workoutState, paramsState.serveCount]);

    const handleStopWorkout = React.useCallback(async () => {
        stopRequestedRef.current = true;
        await stopWorkout();
    }, [stopWorkout]);

    const liftTargetHeight = React.useMemo(() => {
        if (playInitiated) {
            return workout?.positionHeight;
        }

        return undefined;
    }, [workout, playInitiated]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped" || playInitiated) {
            await handleStopWorkout();
        }
    }, [handleStopWorkout, playInitiated, playState, stopLift]);

    const handlePlayClicked = React.useCallback(() => {
        stopRequestedRef.current = false;
        setLocalizingDialogOpen(true);
    }, []);

    const lastPlayState = usePrevious(playState);

    React.useEffect(() => {
        if (lastPlayState === "playing" && playState === "stopped") {
            modifiedRef.current = true;
        }
    }, [lastPlayState, playState]);

    React.useEffect(() => {
        if (
            workoutStatus?.appErrors &&
            workoutStatus?.playState === "playing" &&
            !stopRequestedRef.current
        ) {
            if (!errorDialogOpen) {
                setErrorDialogOpen(true);
            }
        }
    }, [errorDialogOpen, workoutStatus?.appErrors, workoutStatus?.playState]);

    // Start the workout when the play button is clicked
    React.useEffect(() => {
        if (playClicked && workout) {
            setPlayClicked(false);
            checkForLift();
            start().catch(logFetchError);
        }
    }, [checkForLift, playClicked, start, workout]);

    // complete player serving position
    const servePosition = React.useMemo(() => {
        const position = PLAYER_POSITIONS[selectedSport];
        const pos =
            paramsState.servePosition === "deuce"
                ? { x: position.deuce.x, y: position.deuce.y }
                : { x: position.ad.x, y: position.ad.y };
        return pos;
    }, [paramsState.servePosition, selectedSport]);

    // serve launch AOI in visualizer format for 3D render
    const visServeAOI = React.useMemo(() => {
        return createVisualizerAOI(
            appConfig.serveAOI[paramsState.servePosition],
            "magenta",
        );
    }, [appConfig.serveAOI, paramsState.servePosition]);

    const visExitAoi = React.useMemo(() => {
        return createVisualizerAOI(
            appConfig.exitAOI[paramsState.servePosition],
            "yellow",
        );
    }, [appConfig, paramsState.servePosition]);

    const visShots = React.useMemo(() => {
        const shots = paramsState.shots.map((shot) => ({
            ...shot,
            pan: paramsState.servePosition === "deuce" ? shot.pan : -shot.pan,
        }));
        return shots;
    }, [paramsState.servePosition, paramsState.shots]);

    const localizationSucceeded = React.useCallback(async () => {
        if (modifiedRef.current) {
            await stopWorkout();
            setPlayClicked(true);
        } else {
            setPlayClicked(true);
        }
    }, [stopWorkout]);

    const workoutForVisualizer = React.useMemo(
        () =>
            localizingDialogOpen
                ? undefined
                : {
                      trainer: plannedTrainerPosition,
                      player: [servePosition],
                      shots: visShots,
                      AOIs: [visServeAOI, visExitAoi],
                  },
        [
            localizingDialogOpen,
            plannedTrainerPosition,
            servePosition,
            visExitAoi,
            visShots,
            visServeAOI,
        ],
    );

    const workoutForLocalizingDialog = React.useMemo(
        () => ({
            trainer: plannedTrainerPosition,
            localized: position && {
                ...position,
                heightIn: plannedTrainerPosition.heightIn,
            },
            player: [servePosition],
            shots: visShots,
            AOIs: [visServeAOI, visExitAoi],
        }),
        [
            plannedTrainerPosition,
            position,
            servePosition,
            visShots,
            visServeAOI,
            visExitAoi,
        ],
    );

    const onChangeSpeedAdjustment = React.useCallback((value?: number) => {
        paramsDispatch({
            type: "speedAdjustment",
            value,
        });
    }, []);

    const availableTargetAOISelection = React.useMemo(() => {
        if (appConfig === null || !appConfig.availableTargetAOIs) {
            return [];
        }

        const targets = appConfig.availableTargetAOIs[
            paramsState.servePosition
        ].map((aoi) => aoi.name);

        return [...targets, "random"];
    }, [appConfig, paramsState.servePosition]);

    const paramsSummary = React.useMemo(() => {
        const { servePosition, shotDelayMilliseconds, serveCount } =
            paramsState;

        let serveCountText = "";
        if (!serveCount || serveCount === SMALLE_MAX_BALLS) {
            serveCountText = "Unlimited";
        } else {
            serveCountText = serveCount.toString();
        }

        return `${serveCountText} serves from ${servePosition} with return delay of ${shotDelayMilliseconds / 1000}s`;
    }, [paramsState]);

    if (appConfig === null) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <>
            <Stack
                spacing={1}
                sx={(t) => ({
                    p: 1,
                    backgroundColor: t.palette.background.paper,
                    alignItems: "center",
                    minHeight: "calc(100vh - 110px)",
                })}
            >
                <StatsAlert appId={workout.appId} workoutId={workout.id} />

                <Stack spacing={1} sx={{ width: "100%" }} direction="column">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="h4" mb={1}>
                            {`${workout.name}:`}
                        </Typography>

                        <Button onClick={() => {}} variant="text">
                            <Typography
                                variant="h4"
                                color="info.main"
                                onClick={() => setInstructionDialogOpen(true)}
                            >
                                Instructions
                            </Typography>
                        </Button>
                    </Stack>
                    <Box
                        component="div"
                        mb={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                    >
                        {status && isVisionFaulted && (
                            <Button
                                variant="text"
                                startIcon={<ErrorIcon />}
                                onClick={() =>
                                    setDialogType(
                                        "VisionFaultServeAndVolleyDialog",
                                    )
                                }
                                color="error"
                            >
                                <Typography variant="h4">
                                    Camera system unavailable
                                </Typography>
                            </Button>
                        )}

                        {status && isVisionStarting && (
                            <Button
                                variant="text"
                                startIcon={<HourglassEmptyIcon />}
                                onClick={() => setVisionErrorDialogOpen(true)}
                                color="warning"
                            >
                                <Typography variant="h4">
                                    Camera system still starting...
                                </Typography>
                            </Button>
                        )}
                    </Box>
                    <ResizableWorkoutVisualizer
                        positionProximity="Unavailable"
                        workout={workoutForVisualizer}
                        renderCameraControls={false}
                        maxHeight={225}
                        enableCameraControl={false}
                    />
                    <Stack
                        spacing={1}
                        sx={{
                            height: "55vh",
                            overflowY: "auto",
                        }}
                    >
                        <OptionSelector
                            disabled={playState === "playing"}
                            label="Position"
                            labelWrapperSx={{ flex: 1 }}
                            toggleButtonSx={{ flex: 2 }}
                            options={[
                                { value: "ad", label: "Ad" },
                                { value: "deuce", label: "Deuce" },
                            ]}
                            selected={paramsState.servePosition}
                            setOption={(value) => {
                                if (value !== null) {
                                    paramsDispatch({
                                        type: "servePosition",
                                        value,
                                    });
                                    modifiedRef.current = true;
                                    // force localization if we change the serve position
                                    forceLocalizationRef.current = true;
                                }
                            }}
                        />

                        <Typography
                            variant="caption"
                            textAlign="center"
                            width="100%"
                        >
                            {paramsSummary}
                        </Typography>

                        <Button
                            size="small"
                            color="info"
                            startIcon={<TuneIcon />}
                            onClick={() => setAdjustDialogOpen(true)}
                        >
                            Adjust Workout
                        </Button>

                        <BasicShotSelector
                            availableShots={
                                appConfig.shots[paramsState.difficultyLevel] ??
                                []
                            }
                            selectedShots={paramsState.shots ?? []}
                            setSelectedShots={(value) =>
                                paramsDispatch({ type: "selectedShots", value })
                            }
                            setAnimatedShot={() => {}}
                            disabled={playState === "playing"}
                        />
                    </Stack>
                </Stack>
            </Stack>

            <LocalizingDialog
                dialogOpen={localizingDialogOpen}
                onLocalized={(result) => {
                    setLocalizingDialogOpen(false);
                    if (result === "good") {
                        forceLocalizationRef.current = false;
                        void localizationSucceeded();
                    } else {
                        forceLocalizationRef.current = true;
                    }
                }}
                onCanceled={() => {
                    cancel();
                    setLocalizingDialogOpen(false);
                }}
                onUsePlanned={
                    selectedSport === "PLATFORM_TENNIS"
                        ? undefined
                        : handleUsePlanned
                }
                plannedPosition={plannedTrainerPosition}
                force={forceLocalizationRef.current}
                workout={workoutForLocalizingDialog}
                localizationHeightIn={
                    appConfig.localizationHeightIn ??
                    plannedTrainerPosition.heightIn
                }
            />

            <PlayAppBar
                onPauseClicked={handleStopWorkout}
                onPlayClicked={handlePlayClicked}
                pauseDisabled={pauseDisabled}
                playDisabled={
                    visionUnavailable ||
                    playDisabled ||
                    paramsState.shots.length === 0
                }
                playState={playState}
                showRecord={false}
                onRecordClicked={() => captureVideo()}
                playSummary={summaryText}
                recordDisabled={visionUnavailable || captureDisabled}
                recordingStatus={captureStatus}
            />
            <CaptureToast captureStatus={captureStatus} />
            <LiftModal
                stop={handleLiftStop}
                targetHeight={liftTargetHeight}
                message="The trainer is adjusting the head height"
            />

            <InstructionDialog
                instructionDialogOpen={instructionDialogOpen}
                setInstructionDialogOpen={setInstructionDialogOpen}
                sport={selectedSport}
            />

            <VisionSystemStartingDialog
                open={visionErrorDialogOpen}
                setDialogOpen={setVisionErrorDialogOpen}
            />

            <ErrorDialog
                errorDialogOpen={errorDialogOpen}
                workoutStatus={workoutStatus}
                setErrorDialogOpen={async () => {
                    await handleStopWorkout();
                    setErrorDialogOpen(false);
                }}
                visualizerComponent={
                    <ResizableWorkoutVisualizer
                        workout={{
                            trainer: plannedTrainerPosition,
                            player: [servePosition],
                            shots: [],
                            AOIs: [visServeAOI, visExitAoi],
                        }}
                        positionProximity="Good"
                    />
                }
            />

            <Dialog
                fullWidth
                open={adjustDialogOpen}
                onClose={() => setAdjustDialogOpen(false)}
            >
                <DialogTitle>Adjust Workout</DialogTitle>
                <Stack sx={{ p: 2 }} spacing={2}>
                    <OptionSelector
                        disabled={playState === "playing"}
                        label="Target Mode"
                        labelWrapperSx={{ flex: 1 }}
                        toggleButtonSx={{ flex: 2 }}
                        options={[
                            {
                                value: "serviceBox",
                                label: "Service Box",
                            },
                            {
                                value: "aoi",
                                label: "Target AOI",
                            },
                        ]}
                        selected={targetMode}
                        setOption={(value) => {
                            if (value !== null) {
                                setTargetMode(value);
                                modifiedRef.current = true;
                            }
                        }}
                    />
                    {targetMode === "aoi" && (
                        <OptionSelector
                            disabled={playState === "playing"}
                            label="Target"
                            labelWrapperSx={{ flex: 1 }}
                            toggleButtonSx={{ flex: 2 }}
                            options={availableTargetAOISelection.map(
                                (option) => ({
                                    value: option,
                                    label: option,
                                }),
                            )}
                            selected={paramsState.selectedTargetAOI ?? ""}
                            setOption={(value) => {
                                if (value !== null) {
                                    paramsDispatch({
                                        type: "selectedAOI",
                                        value,
                                    });
                                    modifiedRef.current = true;
                                }
                            }}
                        />
                    )}
                    {selectedSport === "TENNIS" && (
                        <ServePlus1LevelSelector
                            disabled={playState === "playing"}
                            label="Difficulty Level"
                            labelWrapperSx={{ flex: 1 }}
                            level={paramsState.difficultyLevel}
                            shots={appConfig.shots}
                            toggleButtonSx={{ flex: 2 }}
                            setLevel={(value) => {
                                paramsDispatch({
                                    type: "difficultyLevel",
                                    value: value as DifficultyLevel,
                                });
                                modifiedRef.current = true;
                                paramsDispatch({
                                    type: "selectedShots",
                                    value: [
                                        appConfig.shots[
                                            value as DifficultyLevel
                                        ][0],
                                    ],
                                });
                            }}
                        />
                    )}
                    <ThrowCount
                        disabled={playState === "playing"}
                        label="Serve Count"
                        labelWrapperSx={{ flex: 1 }}
                        sliderWrapperSx={{ flex: 2 }}
                        sliderSx={{
                            margin: "0px 0px 20px 0px",
                            maxWidth: "inherit",
                        }}
                        selectedThrowCount={paramsState.serveCount ?? 0}
                        onUserThrowCountChanged={(value) => {
                            paramsDispatch({ type: "serveCount", value });
                            modifiedRef.current = true;
                        }}
                    />
                    {(!status ||
                        trainerFeatures.includes(
                            "serveAndVolleyShotDelay",
                        )) && (
                        <DelaySlider
                            disabled={playState === "playing"}
                            label="Return Delay"
                            labelWrapperSx={{ flex: 1 }}
                            sliderWrapperSx={{ flex: 2 }}
                            selectedDelay={
                                workoutParams.shotDelayMilliseconds / 1000
                            }
                            onDelayChanged={(value) => {
                                paramsDispatch({
                                    type: "shotDelay",
                                    value,
                                });
                                modifiedRef.current = true;
                            }}
                        />
                    )}

                    <SpeedAdjustment
                        disabled={playState === "playing"}
                        value={paramsState.speedAdjustment}
                        sport="PLATFORM_TENNIS"
                        cacheKey="serve-and-volley"
                        onChange={onChangeSpeedAdjustment}
                    />
                </Stack>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DoneIcon />}
                        onClick={() => setAdjustDialogOpen(false)}
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default function ServeAndVolleyRoot(): JSX.Element {
    const [workout, setWorkout] = React.useState<AppWorkoutWithSummary | null>(
        null,
    );
    const [error, setError] = React.useState<string | null>(null);

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const idMaybe = React.useMemo(() => parseInt(id ?? "", 10), [id]);

    const { getWorkout, addWorkout } = useAppWorkouts();
    const { physicsModelName } = usePhysicsModelContext();
    const trainerFeatures = useTrainerFeatures();
    const { status } = useStatus();
    const intercom = useIntercom();
    const { selected: sport } = useSelectedSport();

    React.useEffect(() => {
        if (!idMaybe) {
            setError("No workout id provided");
        } else {
            getWorkout(idMaybe)
                .then((result) => {
                    if (result) {
                        setWorkout(result);
                    } else {
                        setError("Failed to load serve and volley config");
                    }
                })
                .catch((e) =>
                    logFetchError(e, "Failed to load serve and volley config"),
                );
        }
    }, [getWorkout, addWorkout, idMaybe, physicsModelName]);

    if (error) {
        <WorkoutErrorDialog
            buttonText="Back to Workouts"
            header="Workout not found"
            text={error}
            onClick={() => {
                navigate("/");
            }}
        />;
    }

    if (!workout) {
        return <Loading />;
    }

    if (status && !trainerFeatures.includes("serveAndVolleyV3")) {
        return (
            <Box
                component="div"
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    mt: 5,
                    alignItems: "center",
                    backgroundColor: "background.default",
                }}
            >
                <Typography variant="h3" mb={4}>
                    Trainer update required.
                </Typography>
                <Typography px={2} mb={4}>
                    To play the Serve and Volley app, your trainer needs to be
                    updated. Please press the icon below to message support, and
                    we will update it for you.
                </Typography>
                <IconButton
                    onClick={() => {
                        intercom.newMessage();
                    }}
                    color="primary"
                    size="large"
                >
                    <HelpIcon />
                </IconButton>
            </Box>
        );
    }

    const cameraOnlineAndOriented =
        status?.vision.camera.state === "Online" &&
        status?.vision.camera.orientation;
    const canPlayS1 = status === null || cameraOnlineAndOriented;
    if (sport === "TENNIS" && !canPlayS1) {
        return (
            <Box
                component="div"
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    mt: 5,
                    alignItems: "center",
                    backgroundColor: "background.default",
                }}
            >
                <Typography variant="h3" mb={4}>
                    Trainer calibration required.
                </Typography>
                <Typography px={2} mb={4}>
                    To play the Serve +1 app, your trainer needs to be
                    calibrated. Please press the icon below to message support,
                    and we will update it for you.
                </Typography>
                <IconButton
                    onClick={() => {
                        intercom.newMessage();
                    }}
                    color="primary"
                    size="large"
                >
                    <HelpIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <ServeAndVolley workout={workout} trainerFeatures={trainerFeatures} />
    );
}
