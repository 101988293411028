import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as uuid from "uuid";

import type { CuratedWorkoutShot } from "@volley/shared/apps/curated-workout-models";

import { PositionLike } from "../../../util/position-types";
import { useSelectedSport } from "../../common/context/sport";
import useRanges from "../../hooks/ranges";
import { LiftModal, useLift } from "../../hooks/useLift";
import { mph2mps, mps2mph } from "../util/conversions";
import useThrowNow, { ThrowingModal } from "../util/useThrowNow";

import AimAccordion from "./Accordions/AimAccordion";
import ShotTimingAccordion from "./Accordions/ShotTiming";
import SpeedAccordion from "./Accordions/SpeedAccordion";
import SpinAccordion from "./Accordions/SpinAccordion";
import ErrorDialog from "./apps/4-multi-shot/play/ErrorDialog";

interface ShotEditModalProps {
    defaultInterval: number;
    plannedPosition: PositionLike | undefined;
    shot: Partial<CuratedWorkoutShot>;
    height: number;
    onSave: (shot: CuratedWorkoutShot) => void;
    onDelete: (id: string) => void;
    onCancel: () => void;
}

export default function ShotEditModal({
    defaultInterval,
    plannedPosition,
    shot,
    height,
    onSave,
    onDelete,
    onCancel,
}: ShotEditModalProps): React.JSX.Element {
    const { limits } = useSelectedSport();
    const [editing, setEditing] =
        React.useState<Partial<CuratedWorkoutShot>>(shot);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [showError, setShowError] = React.useState(false);
    const { pan: panRange, tilt: tiltRange } = useRanges();
    const { error: liftError, stop } = useLift();

    React.useEffect(() => {
        setExpanded(false);
    }, []);

    const { canThrow, launchData, maxSpinLevel, makeShot, throwing } =
        useThrowNow({
            launchSpeed:
                editing?.launchSpeed ?? mph2mps(limits.speed.minLaunchSpeed),
            pan: editing?.pan ?? 0,
            tilt: editing?.tilt ?? 0,
            spinAxis: editing?.spinDirection ?? 0,
            spinLevel: editing?.spinLevel ?? 0,
            plannedPosition,
            height,
        });

    const firstBounce = React.useMemo(() => {
        if (launchData) {
            if (launchData.bounces.length === 0) {
                return null;
            }

            return launchData.bounces[0].timePosition.t;
        }

        return null;
    }, [launchData]);

    const handleDelete = React.useCallback(() => {
        if (editing?.id !== undefined) {
            onDelete(editing.id);
        }
    }, [editing, onDelete]);

    const handleSave = React.useCallback(() => {
        if (editing.id === undefined) {
            editing.id = uuid.v4();
        }
        onSave({
            ...(editing as CuratedWorkoutShot),
            name: editing.name?.trim() || undefined,
        });
    }, [onSave, editing]);

    React.useEffect(() => {
        if (liftError) {
            setShowError(true);
        }
    }, [liftError]);

    const handlePanelChange =
        (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    if (showError && liftError !== null) {
        return (
            <ErrorDialog
                header="Encountered an Error"
                text={liftError}
                buttonText="Set Workout Height"
                onDismiss={() => onCancel()}
                onClick={() => onCancel()}
            />
        );
    }

    return (
        <Dialog
            open
            fullWidth
            maxWidth="xl"
            sx={{
                "& .MuiDialog-paperFullWidth": {
                    width: "calc(100% - 10px)",
                    marginLeft: 0,
                    marginRight: 0,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row">
                    <Box component="div">
                        <Typography
                            variant="h4"
                            sx={{
                                height: "36px",
                                lineHeight: "36px",
                            }}
                        >
                            {editing?.id ? "Editing Shot" : "New Shot"}
                        </Typography>
                    </Box>
                    <Box component="div" sx={{ flexGrow: 1 }} />
                    <Box component="div">
                        <Button
                            variant="contained"
                            onClick={() => handleDelete()}
                            disabled={editing?.id === undefined}
                        >
                            Delete Shot
                        </Button>
                    </Box>
                </Stack>
                <Divider sx={{ mt: 1 }} />
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1} sx={{ my: 1 }}>
                    <TextField
                        type="text"
                        label="Name"
                        autoComplete="off"
                        onChange={(e) =>
                            setEditing({
                                ...editing,
                                name: e.currentTarget.value,
                            })
                        }
                        value={editing.name}
                        slotProps={{ htmlInput: { maxLength: 128 } }}
                    />
                    <SpeedAccordion
                        elevation={6}
                        expanded={expanded === "Speed"}
                        onChange={handlePanelChange("Speed")}
                        onSpeedChanged={(s) =>
                            setEditing({
                                ...editing,
                                launchSpeed: mph2mps(s),
                            })
                        }
                        onSpeedVariationChanged={(v) =>
                            setEditing({
                                ...editing,
                                launchSpeedVariance: v,
                            })
                        }
                        selectedSpeed={mps2mph(editing?.launchSpeed ?? 0)}
                        selectedSpeedVariation={
                            editing?.launchSpeedVariance ?? 0
                        }
                    />
                    <SpinAccordion
                        elevation={6}
                        expanded={expanded === "Spin"}
                        onChange={handlePanelChange("Spin")}
                        onSpinChanged={(s) =>
                            setEditing({
                                ...editing,
                                spinDirection: s,
                            })
                        }
                        onSpinIntensityChanged={(s) =>
                            setEditing({
                                ...editing,
                                spinLevel: s,
                            })
                        }
                        selectedSpin={editing?.spinDirection ?? 0}
                        selectedSpinIntensity={editing?.spinLevel ?? 0}
                        maxSpinLevel={maxSpinLevel}
                    />
                    <AimAccordion
                        elevation={6}
                        expanded={expanded === "Arc"}
                        onChange={handlePanelChange("Arc")}
                        aimRange={tiltRange}
                        label="Arc"
                        onAimChanged={(a) =>
                            setEditing({
                                ...editing,
                                tilt: a,
                            })
                        }
                        onAimVariationChanged={(v) =>
                            setEditing({
                                ...editing,
                                tiltVariance: v,
                            })
                        }
                        selectedAim={editing?.tilt ?? 0}
                        selectedAimVariation={editing?.tiltVariance ?? 0}
                    />
                    <AimAccordion
                        elevation={6}
                        expanded={expanded === "Aim"}
                        onChange={handlePanelChange("Aim")}
                        aimRange={panRange}
                        label="Aim"
                        onAimChanged={(a) =>
                            setEditing({
                                ...editing,
                                pan: a,
                            })
                        }
                        onAimVariationChanged={(v) =>
                            setEditing({
                                ...editing,
                                panVariance: v,
                            })
                        }
                        selectedAim={editing?.pan ?? 0}
                        selectedAimVariation={editing?.panVariance ?? 0}
                    />
                    <ShotTimingAccordion
                        elevation={6}
                        expanded={expanded === "Timing"}
                        onChange={handlePanelChange("Timing")}
                        onIntervalChanged={(t) => {
                            if (t > defaultInterval) {
                                setEditing({
                                    ...editing,
                                    intervalOverride: t,
                                });
                            } else {
                                setEditing({
                                    ...editing,
                                    intervalOverride: undefined,
                                });
                            }
                        }}
                        selectedInterval={
                            editing.intervalOverride || defaultInterval
                        }
                        defaultInterval={defaultInterval}
                        firstBounce={firstBounce}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    sx={{
                        width: "100%",
                    }}
                >
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "33%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            color="info"
                            onClick={() => onCancel()}
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "33%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            color="info"
                            disabled={throwing || !canThrow}
                            onClick={() => makeShot(height)}
                        >
                            Test
                        </Button>
                    </Box>
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "33%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            disabled={!canThrow}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleSave()}
                        >
                            Save
                        </Button>
                    </Box>
                </Stack>
                <ThrowingModal open={throwing} />
            </DialogActions>
            <LiftModal stop={() => stop()} targetHeight={height} />
        </Dialog>
    );
}
