import * as React from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import SupportLink from "../common/SupportLink";
import { usePairingContext } from "../hooks/pairingStatus";

export default function FatalErrorDialog(): React.JSX.Element {
    const { unpair } = usePairingContext();

    return (
        <>
            <CloseableDialogTitle variant="h4">
                Something Happened.
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    We can&apos;t recover from this, read this article to learn
                    how to shutdown the machine.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={() => unpair()}
                >
                    Shutdown Trainer
                </Button>
            </DialogActions>
            <SupportLink preText="Having Trouble?">Click here.</SupportLink>
        </>
    );
}
