import * as React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";

import { useCurrentUser } from "../../../hooks/currentUser";

interface Props {
    contentProviderId: number | null;
    setContentProviderId: (contentProviderId: number | null) => void;
}

export default function PublishSelect({
    contentProviderId,
    setContentProviderId,
}: Props): React.JSX.Element | null {
    const { currentUser } = useCurrentUser();

    if (!currentUser) return null;

    return (
        <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="contentProviderId">
                Publish For
            </InputLabel>
            <NativeSelect
                onChange={(e) =>
                    setContentProviderId(
                        parseInt(e.currentTarget.value, 10) || null,
                    )
                }
                inputProps={{
                    name: "contentProviderId",
                    id: "contentProviderId",
                }}
                value={contentProviderId ?? 0}
            >
                <option value={0}>(none)</option>
                {currentUser.contentProviderPublishers.map((cp) => (
                    <option
                        value={cp.contentProviderId}
                        key={cp.contentProviderId}
                    >
                        {cp.contentProvider.label}
                    </option>
                ))}
            </NativeSelect>
        </FormControl>
    );
}
