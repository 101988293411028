import * as React from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";

import {
    CoordWithSys,
    PositionLike,
} from "../../../../../../../util/position-types";
import {
    degToRad,
    physicsToCourt,
    radToDeg,
} from "../../../../../../../util/positionUtil";
import { useSelectedSport } from "../../../../../../common/context/sport";
import TrainerCourtPosition from "../../../../../Position/TrainerCourtPosition";

import { POSITION_TABLE } from "./constants";

interface Props {
    selectedPosition: PositionLike | undefined;
    onPositionChanged: (value: PositionLike) => void;
}

const yawLabel = (yaw: number, desc?: string) =>
    yaw === 0
        ? "Forward"
        : (desc ?? `${Math.round(radToDeg(yaw ?? 0)).toString()}°`);

export default function ModuleTrainerPosition({
    selectedPosition,
    onPositionChanged,
}: Props): React.JSX.Element {
    const [angleOptions, setAngleOptions] = React.useState<
        { label: string; value: number }[]
    >(
        selectedPosition === undefined
            ? []
            : [
                  {
                      label: yawLabel(selectedPosition.yaw),
                      value: radToDeg(selectedPosition.yaw),
                  },
              ],
    );
    const { selected: selectedSport } = useSelectedSport();
    const manualPositions = POSITION_TABLE[selectedSport];

    const convertPosition = React.useCallback(
        (input: CoordWithSys): CoordWithSys => {
            let { x, y } = input;
            if (selectedSport === "PLATFORM_TENNIS") {
                const court = physicsToCourt(input);
                x = court.x;
                y = court.y;
            }
            return { ...input, x, y };
        },
        [selectedSport],
    );

    const onPointSelected = React.useCallback(
        (selected?: CoordWithSys[]) => {
            if (!selected || !selected.length) {
                setAngleOptions([]);
                return;
            }

            setAngleOptions(
                selected.map((s) => ({
                    label: yawLabel(s.yaw ?? 0, s.yawDescription),
                    value: radToDeg(s.yaw ?? 0),
                })),
            );

            onPositionChanged({
                ...convertPosition(selected[0]),
                yaw: selected[0].yaw ?? 0,
            });
        },
        [onPositionChanged, convertPosition],
    );

    return (
        <Stack spacing={1} pb={2}>
            <TrainerCourtPosition
                interactive
                mode="manual"
                points={manualPositions}
                selectedPosition={selectedPosition}
                scale={selectedSport === "PICKLEBALL" ? 0.6 : 0.8}
                size="HALF"
                onPointSelected={onPointSelected}
            />
            {!!selectedPosition && (
                <Box component="div" display="flex" justifyContent="center">
                    <FormControl>
                        <RadioGroup
                            value={radToDeg(selectedPosition?.yaw ?? 0)}
                            onChange={(_, val) => {
                                if (!selectedPosition) return;
                                onPositionChanged({
                                    ...selectedPosition,
                                    yaw: degToRad(parseInt(val, 10)),
                                });
                            }}
                            name="position-angle"
                            aria-labelledby="angle-label"
                        >
                            {angleOptions.map(({ label, value }) => (
                                <FormControlLabel
                                    key={value}
                                    value={value}
                                    label={label}
                                    control={<Radio />}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            )}
        </Stack>
    );
}
