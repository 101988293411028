import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { LevelNumber } from "@volley/shared/apps/curated-workout-models";

import { pluralize } from "../../../../util/text";
import NotchedOutline from "../../../common/NotchedOutline";
import SlimNumberInput from "../../util/SlimNumberInput";
import AccordionSlider from "../Shared/AccordionSlider";
import LevelSelector from "../Shared/LevelSelector";

const FEED_INTERVAL = 0.25;
const INTERVAL_OFFSET = 6;

type KnownFeedSpeed = "very slow" | "slow" | "moderate" | "fast" | "very fast";
const KnownFeedSpeeds: Record<number, KnownFeedSpeed> = {
    1: "very fast",
    1.25: "very fast",
    1.5: "very fast",
    1.75: "very fast",
    2: "fast",
    2.25: "fast",
    2.5: "fast",
    2.75: "fast",
    3: "moderate",
    3.25: "moderate",
    3.5: "moderate",
    3.75: "moderate",
    4: "slow",
    4.25: "slow",
    4.5: "slow",
    4.75: "slow",
    5: "very slow",
};

const rateMarks = Object.keys(KnownFeedSpeeds)
    .filter((v) => parseFloat(v) % 1 === 0)
    .map((v) => ({ label: `${6 - parseFloat(v)} sec.`, value: parseFloat(v) }));

export const SMALLE_MAX_BALLS = 60;

type KnownBallCount = "1" | "15" | "30" | "45" | "All";
const KnownBallCounts: Record<KnownBallCount, number> = {
    1: 1,
    15: 15,
    30: 30,
    45: 45,
    All: SMALLE_MAX_BALLS,
};

const ballCountMarks = Object.keys(KnownBallCounts).map((k) => ({
    label: k,
    value: KnownBallCounts[k as KnownBallCount],
}));

function intervalToKnownSpeed(interval: number): KnownFeedSpeed | undefined {
    return KnownFeedSpeeds[interval];
}

interface ParamsAccordionProps extends Omit<AccordionProps, "children"> {
    selectedInterval: number;
    selectedShotCount: number;
    onIntervalChanged: (value: number) => void;
    onShotCountChanged: (value: number) => void;
    level?: LevelNumber;
    setLevel: (level: LevelNumber) => void;
    copyLevel: (type: "shots" | "feed", fromLevel: LevelNumber) => void;
    validationError?: string | undefined;
}

export default function ParamsAccordion({
    selectedInterval,
    selectedShotCount,
    validationError,
    onIntervalChanged,
    onShotCountChanged,
    level,
    setLevel,
    copyLevel,
    ...props
}: ParamsAccordionProps): React.JSX.Element {
    const [userInterval, setUserInterval] = React.useState(
        INTERVAL_OFFSET - selectedInterval,
    );
    const [userShotCount, setUserShotCount] = React.useState(selectedShotCount);
    const [durationText, setDurationText] = React.useState("calculating...");

    React.useEffect(() => {
        setUserInterval(INTERVAL_OFFSET - selectedInterval);
    }, [selectedInterval]);

    React.useEffect(() => {
        setUserShotCount(selectedShotCount);
    }, [selectedShotCount]);

    React.useEffect(() => {
        if (userShotCount === SMALLE_MAX_BALLS) {
            setDurationText("as long as you keep the bin full.");
        } else {
            const time = userShotCount * (INTERVAL_OFFSET - userInterval);
            if (time < 60) {
                setDurationText(`about ${time} sec.`);
            } else {
                let minutes: string | number = Math.floor(time / 60);
                const seconds = time % 60;
                if (seconds > 40) {
                    minutes += 1;
                }
                if (seconds > 20 && seconds <= 40) {
                    minutes = `${minutes}.5`;
                }
                setDurationText(
                    `about ${minutes} ${minutes === 1 ? "minute" : "minutes"}.`,
                );
            }
        }
    }, [userShotCount, userInterval, setDurationText]);

    const summary = React.useMemo(() => {
        const countText =
            userShotCount === SMALLE_MAX_BALLS ? "All" : userShotCount;
        const feedRateText =
            intervalToKnownSpeed(selectedInterval) || "Unknown";
        return `${countText} ${pluralize(userShotCount, "Ball")}, ${feedRateText}`;
    }, [userShotCount, selectedInterval]);

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {!props.expanded && (
                    <Box component="div">
                        <Typography variant="h4" color="primary.main">
                            Feed
                        </Typography>
                        <Typography variant="h4" color="primary.main">
                            {summary}
                        </Typography>
                    </Box>
                )}
                {props.expanded && (
                    <Typography variant="h3" color="primary.main">
                        Feed
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    {level && (
                        <LevelSelector
                            type="feed"
                            level={level}
                            setLevel={setLevel}
                            onCopyLevel={copyLevel}
                        />
                    )}
                    <NotchedOutline label="Rate">
                        <Stack>
                            <SlimNumberInput
                                minValue={1}
                                maxValue={5}
                                decimalPrecision={2}
                                showDecimal
                                onChange={(value) => {
                                    setUserInterval(INTERVAL_OFFSET - value);
                                    onIntervalChanged(value);
                                }}
                                incrementValue={FEED_INTERVAL}
                                value={INTERVAL_OFFSET - userInterval}
                                reverseIncrement
                            />
                            <AccordionSlider
                                min={1}
                                max={5}
                                marks={rateMarks}
                                step={FEED_INTERVAL}
                                value={userInterval}
                                track={false}
                                onChange={(_, v) =>
                                    setUserInterval(v as number)
                                }
                                onChangeCommitted={(_, v) =>
                                    onIntervalChanged(
                                        INTERVAL_OFFSET - (v as number),
                                    )
                                }
                                sx={{
                                    display: "table",
                                    margin: "0px auto 20px auto",
                                    maxWidth: "80%",
                                    ".MuiSlider-track": {
                                        color: "primary.light",
                                    },
                                }}
                            />
                            {validationError && (
                                <Typography variant="caption" align="center">
                                    {validationError}
                                </Typography>
                            )}
                        </Stack>
                    </NotchedOutline>
                    <NotchedOutline label="Number of Balls">
                        <Stack>
                            <SlimNumberInput
                                onChange={(value) => {
                                    setUserShotCount(value);
                                    onShotCountChanged(value);
                                }}
                                value={userShotCount}
                                incrementValue={1}
                                maxValue={SMALLE_MAX_BALLS}
                                minValue={1}
                                showDecimal={false}
                                showInfinity
                            />
                            <AccordionSlider
                                min={1}
                                max={SMALLE_MAX_BALLS}
                                marks={ballCountMarks}
                                value={userShotCount}
                                onChange={(_, v) =>
                                    setUserShotCount(v as number)
                                }
                                onChangeCommitted={(_, v) =>
                                    onShotCountChanged(v as number)
                                }
                                sx={{
                                    display: "table",
                                    margin: "0px auto 20px auto",
                                    maxWidth: "80%",
                                }}
                            />
                        </Stack>
                    </NotchedOutline>
                    <Box component="div">
                        <Typography variant="body2" textAlign="center">
                            {`Duration of the workout will be ${durationText}`}
                        </Typography>
                    </Box>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
