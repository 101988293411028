import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

import type { CaptureStatus } from "../useAppWorkoutPlay";

interface CaptureToastProps {
    captureStatus: CaptureStatus;
}

export default function CaptureToast({
    captureStatus,
}: CaptureToastProps): React.JSX.Element {
    const [dismissed, setDismissed] = React.useState(false);

    React.useEffect(() => {
        if (captureStatus === "Requested") {
            setDismissed(false);
        }
    }, [captureStatus]);

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setDismissed(true);
    };

    const open = React.useMemo(() => {
        if (dismissed) {
            return false;
        }

        if (
            captureStatus === "NothingToRecord" ||
            captureStatus === "Starting" ||
            captureStatus === "Complete"
        ) {
            return true;
        }

        return false;
    }, [captureStatus, dismissed]);

    const message = React.useMemo(() => {
        switch (captureStatus) {
            case "Complete":
                return "Clips for the previous three shots have been uploaded";
            case "Starting":
                return "The camera is starting up, try again in a moment.";
            case "NothingToRecord":
                return "Capture records the previous 3 shots fired by the trainer. Play a workout to use this feature.";
            default:
                return "";
        }
    }, [captureStatus]);

    const warningStatus: CaptureStatus[] = ["NothingToRecord", "Starting"];
    const severity = warningStatus.includes(captureStatus)
        ? "warning"
        : "success";

    const action =
        captureStatus === "Starting" ||
        captureStatus === "NothingToRecord" ||
        captureStatus === "Complete" ? (
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setDismissed(true)}
            >
                <CloseIcon />
            </IconButton>
        ) : undefined;

    return (
        <Snackbar
            open={open}
            onClose={(e, r) => handleClose(e, r)}
            anchorOrigin={{
                horizontal: "center",
                vertical: "bottom",
            }}
            sx={{
                bottom: 110,
            }}
        >
            <Alert action={action} severity={severity} sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
