import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";

import useUnclosableDialog from "./common/useUnclosableDialog";

interface PoweringOffDialogProps {
    onPoweredOff: () => void;
    full: boolean;
}
export default function PoweringOffDialog({
    onPoweredOff,
    full = false,
}: PoweringOffDialogProps): React.JSX.Element {
    useUnclosableDialog();
    const { status, networkStateCode } = useStatus();
    const [started, setStarted] = React.useState(false);
    const [closedSession, setClosedSession] = React.useState(false);
    const { unpair } = usePairingContext();

    React.useEffect(() => {
        async function powerOff() {
            if (!started) {
                setStarted(true);
                await pairedFetchApi(
                    status?.clientId,
                    "/api/shutdown",
                    "POST",
                    { soft: !full },
                );
            }
        }

        void powerOff().catch((e) => logFetchError(e));
    }, [full, started, status]);

    React.useEffect(() => {
        const powerState = status?.trainer?.powerState;
        // we're shutdown if motion reports OFF or trainer goes offline (shut down
        // can happen before we get OFF reported)
        if (
            (powerState === "L4" ||
                powerState === "L5" ||
                powerState === "L6" ||
                networkStateCode === "ConnectionReestablishing") &&
            !closedSession
        ) {
            setClosedSession(true);
            unpair();
            onPoweredOff();
        }
    }, [closedSession, networkStateCode, onPoweredOff, status, unpair]);

    return (
        <>
            <CloseableDialogTitle>Shutting Down</CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    The trainer is shutting down. It may take up to a minute for
                    the two lights on the back to turn off. You can put the
                    trainer away in the meantime.
                </DialogContentText>
                <Box component="div" sx={{ textAlign: "center", mt: 2 }}>
                    <CircularProgress size={80} color="info" />
                </Box>
            </DialogContent>
        </>
    );
}
