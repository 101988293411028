import * as React from "react";
import { useNavigate } from "react-router";

import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";

import fetchApi, { logFetchError } from "../../../util/fetchApi";
import useDialog from "../../Dialog/useDialog";

type CancelButtonText = "Cancel" | "Skip for Now";

interface APTAIdDialogProps {
    cancelText: CancelButtonText;
}

export function APTAIdDialog({
    cancelText = "Cancel",
}: APTAIdDialogProps): React.JSX.Element {
    const navigate = useNavigate();
    const { onClose, setDialogType } = useDialog();
    const [aptaId, setAptaId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");

    const onSubmit = React.useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            setErrorMessage("");
            let playerId;
            try {
                const res = await fetchApi<{ playerId: string }>(
                    "/api/pti/register",
                    "POST",
                    {
                        aptaPlayerId: aptaId.trim(),
                    },
                );
                ({ playerId } = res);
            } catch (err) {
                logFetchError(err);
                setErrorMessage((err as Error).message);
                return;
            }

            setDialogType(null);
            navigate("/player-pti", {
                state: { aptaPlayerId: parseInt(playerId, 10) },
            });
        },
        [aptaId, navigate, setDialogType],
    );

    return (
        <>
            <DialogContent>
                <DialogContentText align="left" fontWeight="bold" fontSize={16}>
                    Link your APTA Account to receive more personalized content,
                    monitor PTI and view all your match stats.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="APTA Number"
                    name="aptaId"
                    id="aptaId"
                    value={aptaId}
                    onChange={(e) => setAptaId(e.currentTarget.value)}
                    helperText={errorMessage || undefined}
                    error={!!errorMessage}
                    fullWidth
                    variant="outlined"
                />
                <DialogContentText align="left">
                    To Obtain Your APTA Number:
                    <List component="ol" type="1" dense>
                        <ListItem
                            sx={{
                                display: "list-item",
                                listStyleType: "decimal",
                                listStylePosition: "inside",
                            }}
                        >
                            Follow this link to the APTA site:&nbsp;
                            <Link
                                href="https://www.platformtennisonline.org/MemberNumberLookup.aspx"
                                target="_blank"
                            >
                                LINK
                            </Link>
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                listStyleType: "decimal",
                                listStylePosition: "inside",
                            }}
                        >
                            Search for your name
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                listStyleType: "decimal",
                                listStylePosition: "inside",
                            }}
                        >
                            Select your name from the results
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                listStyleType: "decimal",
                                listStylePosition: "inside",
                            }}
                        >
                            Copy the APTA Number (XX-XXXXX) to the text box
                            above
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                listStyleType: "decimal",
                                listStylePosition: "inside",
                            }}
                        >
                            Submit
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                listStyleType: "decimal",
                                listStylePosition: "inside",
                            }}
                        >
                            View your PTI stats by scrolling to the bottom of
                            the app homepage
                        </ListItem>
                    </List>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={() => onClose()}>
                    {cancelText}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DoneIcon />}
                    disabled={aptaId.length < 4}
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    );
}

export function FirstTimeAPTAIdDialog(): React.JSX.Element {
    return <APTAIdDialog cancelText="Skip for Now" />;
}
