import * as React from "react";
import { useNavigate } from "react-router";

import InsightsIcon from "@mui/icons-material/Insights";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import fetchApi, { logFetchError } from "../../../../../util/fetchApi";

interface AppHistoryStats {
    totalSession: number;
    totalWorkoutThrows: number;
}

interface StatsAlertProps {
    appId: number;
    workoutId: number;
}

export default function StatsAlert({
    appId,
    workoutId,
}: StatsAlertProps): JSX.Element {
    const navigate = useNavigate();
    const [stats, setStats] = React.useState<AppHistoryStats | null>(null);

    React.useEffect(() => {
        async function fetchStats() {
            try {
                const response = await fetchApi<AppHistoryStats>(
                    `/api/sessions/history/${workoutId}/stats`,
                );
                if (response) {
                    setStats(response);
                }
            } catch (error) {
                logFetchError(error);
                setStats(null);
            }
        }
        fetchStats();
    }, [workoutId]);

    // If no stats or no workout throws, return empty fragment
    if (!stats || stats.totalWorkoutThrows === 0) {
        return <></>;
    }

    return (
        <Alert
            severity="success"
            icon={<InsightsIcon fontSize="inherit" />}
            action={
                <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                        navigate(
                            `/content/apps/workouts/plugin/history/${appId}/${workoutId}`,
                            { replace: true },
                        );
                    }}
                >
                    Go
                </Button>
            }
            sx={{ width: "100%" }}
        >
            See how your {stats.totalWorkoutThrows.toLocaleString()} serves
            stack up.
        </Alert>
    );
}
