import * as React from "react";
import { useNavigate, useParams } from "react-router";

import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutWithSummary,
} from "@volley/data";
import type { ServePosition } from "@volley/shared/apps/app-common-models";
import {
    ServeChallengeAppConfig,
    ServeChallengeParameters,
} from "@volley/shared/apps/servechallenge-models";
import type { JSONObject } from "@volley/shared/common-models";

import { logFetchError } from "../../../../../util/fetchApi";
import useDialog from "../../../../Dialog/useDialog";
import Loading from "../../../../common/Loading";
import { useStatus } from "../../../../hooks/status";
import useFreshchat from "../../../../hooks/useFreshchat";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import { useTrainerFeatures } from "../../../../hooks/useTrainerFeatures";
import OptionSelector from "../../Shared/OptionSelector";
import PlayAppBar from "../../Shared/PlayAppBar";
import ThrowCount from "../../Shared/ThrowCount";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import LocalizingDialog from "../10-serve-and-volley/LocalizingDialog";
import WorkoutErrorDialog from "../4-multi-shot/play/ErrorDialog";

import Court from "./Court";
import InstructionDialog from "./InstructionDialog";
import Scoreboard from "./Scoreboard";
import { Serve } from "./models";

const defaultParams: ServeChallengeParameters = {
    servePosition: "deuce",
    serveCount: 10,
    targetAOIs: [],
    serveAOI: {
        upperLeftX: 0,
        upperLeftY: 0,
        lowerRightX: 0,
        lowerRightY: 0,
    },
    detectRegions: [],
};

type ParamsAction =
    | { type: "servePosition"; value: ServePosition }
    | { type: "serveCount"; value: number };

function paramsReducer(
    params: ServeChallengeParameters,
    action: ParamsAction,
): ServeChallengeParameters {
    switch (action.type) {
        case "servePosition":
            return { ...params, servePosition: action.value };
        case "serveCount":
            return { ...params, serveCount: action.value };
        default:
            return params;
    }
}

type KnownBallCount = "5" | "10" | "15" | "20";
const KnownBallCounts: Record<KnownBallCount, number> = {
    5: 5,
    10: 10,
    15: 15,
    20: 20,
};

const ballCountMarks = Object.keys(KnownBallCounts).map((k) => ({
    label: k,
    value: KnownBallCounts[k as KnownBallCount],
}));

interface ServeChallengeProps {
    workout: AppWorkoutWithSummary;
}

function ServeChallenge({ workout }: ServeChallengeProps) {
    const appConfig = workout.config as unknown as ServeChallengeAppConfig;
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        defaultParams,
    );
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const [localizingDialogOpen, setLocalizingDialogOpen] =
        React.useState(false);

    const [instructionDialogOpen, setInstructionDialogOpen] =
        React.useState<boolean>(false);

    const { status } = useStatus();
    // const workoutStatus = status?.workouts;

    const { position, isVisionStarting, isVisionFaulted, cancel } =
        usePosition();

    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";

    const { checkForLift, stop: stopLift } = useLift();

    // we use this ref to track if the settings have been changed and we should start a new game
    const modifiedRef = React.useRef(true);

    // we use this ref to track if a stop has been requested
    const stopRequestedRef = React.useRef(false);

    // we use this ref to force localization
    const forceLocalizationRef = React.useRef(true);

    const { setDialogType } = useDialog();

    const workoutParams = React.useMemo(
        () => ({
            ...paramsState,
            targetAOIs: appConfig.targetAOIs[paramsState.servePosition],
            serveAOI: appConfig.serveAOI[paramsState.servePosition],
            visionConfig: appConfig.visionConfig,
            detectRegions: appConfig.detectRegions[paramsState.servePosition],
        }),
        [appConfig, paramsState],
    );

    const workoutPosition = React.useMemo(() => {
        return appConfig.trainerPosition[paramsState.servePosition];
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const {
        start,
        playState,
        playInitiated,
        workoutState,
        playDisabled,
        pauseDisabled,
        captureVideo,
        captureStatus,
        stop: stopWorkout,
    } = useAppWorkoutPlay({
        workout: { ...workout, ...workoutPosition },
        parameters: workoutParams as unknown as JSONObject,
        localizedPosition: position,
    });

    const plannedTrainerPosition = React.useMemo(() => {
        const pos = appConfig.trainerPosition[paramsState.servePosition];
        return {
            x: pos.positionX,
            y: pos.positionY,
            yaw: pos.positionYaw,
            heightIn: pos.positionHeight,
        };
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const workoutForLocalizingDialog = React.useMemo(
        () => ({
            trainer: plannedTrainerPosition,
            localized: position && {
                ...position,
                heightIn: plannedTrainerPosition.heightIn,
            },
            player: [],
            shots: [],
            AOIs: [],
        }),
        [plannedTrainerPosition, position],
    );

    const handlePlayClicked = React.useCallback(() => {
        stopRequestedRef.current = false;
        setLocalizingDialogOpen(true);
    }, []);

    // Start the workout when the play button is clicked
    React.useEffect(() => {
        if (playClicked) {
            setPlayClicked(false);
            checkForLift();
            void start();
        }
    }, [checkForLift, playClicked, start]);

    const handleStopWorkout = React.useCallback(async () => {
        stopRequestedRef.current = true;
        await stopWorkout();
    }, [stopWorkout]);

    const liftTargetHeight = React.useMemo(() => {
        if (playInitiated) {
            return workout?.positionHeight;
        }

        return undefined;
    }, [workout, playInitiated]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped" || playInitiated) {
            await handleStopWorkout();
        }
    }, [handleStopWorkout, playInitiated, playState, stopLift]);

    const playSummary = React.useMemo(() => {
        if (workoutState) {
            const total = (workoutState.results as [] | undefined)?.length || 0;
            return `${total} / ${paramsState.serveCount}`;
        }

        return "";
    }, [paramsState.serveCount, workoutState]);

    return (
        <>
            <Stack
                sx={{
                    backgroundColor: "background.default",
                    mx: -1,
                    p: 2,
                }}
            >
                <Typography variant="h3" mb={2}>
                    Serve Challenge (ALPHA)
                </Typography>
                <Box component="div" mb={2}>
                    <Button onClick={() => {}} variant="text">
                        <Typography
                            variant="h4"
                            color="info.main"
                            onClick={() => setInstructionDialogOpen(true)}
                        >
                            View Instructions
                        </Typography>
                    </Button>
                </Box>

                {status && isVisionFaulted && (
                    <Button
                        variant="text"
                        startIcon={<ErrorIcon />}
                        onClick={() =>
                            setDialogType("VisionFaultServeAndVolleyDialog")
                        }
                        color="error"
                    >
                        <Typography variant="h4">
                            Camera system unavailable
                        </Typography>
                    </Button>
                )}

                <Box mb={1}>
                    <OptionSelector
                        disabled={playState === "playing"}
                        label="Server Position"
                        labelWrapperSx={{ flex: 1 }}
                        toggleButtonSx={{ flex: 2 }}
                        options={[
                            { value: "ad", label: "Ad" },
                            { value: "deuce", label: "Deuce" },
                        ]}
                        selected={paramsState.servePosition}
                        setOption={(value) => {
                            if (value !== null) {
                                paramsDispatch({
                                    type: "servePosition",
                                    value,
                                });
                                modifiedRef.current = true;
                                // force localization if we change the serve position
                                forceLocalizationRef.current = true;
                            }
                        }}
                    />
                </Box>
                <Box mb={1}>
                    <ThrowCount
                        label="Serves"
                        disabled={playState === "playing"}
                        selectedThrowCount={paramsState.serveCount}
                        onUserThrowCountChanged={(number) => {
                            paramsDispatch({
                                type: "serveCount",
                                value: number,
                            });
                            modifiedRef.current = true;
                        }}
                        marks={ballCountMarks}
                        min={5}
                        max={20}
                    />
                </Box>
                <Box mb={1}></Box>

                <Box pt={8} />

                <Box display="flex" justifyContent="center">
                    <Scoreboard
                        serves={workoutState?.results as Serve[] | undefined}
                    />
                </Box>

                <Box pt={2} display="flex" justifyContent="center">
                    <Court
                        serves={
                            (workoutState?.results as Serve[] | undefined) || []
                        }
                        targetAOIs={workoutParams.targetAOIs || []}
                    />
                </Box>
            </Stack>

            <LocalizingDialog
                dialogOpen={localizingDialogOpen}
                onLocalized={(result) => {
                    setLocalizingDialogOpen(false);
                    if (result === "good") {
                        forceLocalizationRef.current = false;
                        setPlayClicked(true);
                    } else {
                        forceLocalizationRef.current = true;
                    }
                }}
                onCanceled={() => {
                    cancel();
                    setLocalizingDialogOpen(false);
                }}
                plannedPosition={plannedTrainerPosition}
                force={forceLocalizationRef.current}
                workout={workoutForLocalizingDialog}
            />

            <PlayAppBar
                playState={playState}
                playDisabled={playDisabled || visionUnavailable}
                pauseDisabled={pauseDisabled}
                showRecord={false}
                recordDisabled={true}
                recordingStatus={captureStatus}
                playSummary={playSummary}
                onPlayClicked={handlePlayClicked}
                onPauseClicked={handleStopWorkout}
                onRecordClicked={() => captureVideo()}
            />

            <LiftModal
                stop={handleLiftStop}
                targetHeight={liftTargetHeight}
                message="The trainer is adjusting the head height"
            />

            <InstructionDialog
                instructionDialogOpen={instructionDialogOpen}
                setInstructionDialogOpen={setInstructionDialogOpen}
            />
        </>
    );
}

export default function ServeChallengeRoot() {
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const [error, setError] = React.useState<string | null>(null);

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const idMaybe = React.useMemo(() => parseInt(id ?? "", 10), [id]);

    const { getWorkout } = useAppWorkouts();

    const trainerFeatures = useTrainerFeatures();
    const { status } = useStatus();
    const freshchat = useFreshchat();

    React.useEffect(() => {
        getWorkout(idMaybe)
            .then((loaded) => {
                if (loaded) {
                    setWorkout(loaded);
                } else {
                    setError("Failed to load serve challenge config");
                }
            })
            .catch((e) =>
                logFetchError(e, "Failed to load serve challenge config"),
            );
    }, [getWorkout, idMaybe]);

    if (error) {
        return (
            <WorkoutErrorDialog
                buttonText="Back to Workouts"
                header="Workout not Found"
                text={error}
                onClick={() => navigate("/")}
            />
        );
    }

    if (!workout) {
        return <Loading />;
    }

    if (status && !trainerFeatures.includes("serveChallenge")) {
        return (
            <Box
                component="div"
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    mt: 5,
                    alignItems: "center",
                    backgroundColor: "background.default",
                }}
            >
                <Typography variant="h3" mb={4}>
                    Trainer update required.
                </Typography>
                <Typography px={2} mb={4}>
                    To play the Serve Challenge app, your trainer needs to be
                    updated. Please press the icon below to message support, and
                    we will update it for you.
                </Typography>
                <IconButton
                    onClick={() => {
                        freshchat.open();
                    }}
                    color="primary"
                    size="large"
                >
                    <HelpIcon />
                </IconButton>
            </Box>
        );
    }

    return <ServeChallenge workout={workout} />;
}
