import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface ResetConfirmationDialogProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export default function ResetConfirmationDialog({
    open,
    onCancel,
    onConfirm,
}: ResetConfirmationDialogProps): React.JSX.Element {
    return (
        <Dialog open={open}>
            <DialogTitle>Reset</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <Typography>
                        This will reset the workout to the default settings.
                    </Typography>
                    <Button
                        onClick={() => onConfirm()}
                        variant="contained"
                        fullWidth
                        color="secondary"
                    >
                        Confirm
                    </Button>
                    <Button
                        onClick={() => onCancel()}
                        variant="contained"
                        fullWidth
                        color="info"
                    >
                        Cancel
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
