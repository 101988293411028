import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import logger from "../../../log";
import { usePairingContext } from "../../hooks/pairingStatus";
import { useStatus } from "../../hooks/status";
import useDialog from "../useDialog";

import { ActionListItem, InfoListItem } from "./listItems";

const specificFaultTypes = [
    "FeedFailure",
    "FeedFailureNoPowerDrop",
    "BallBinEmpty",
    "SafetyIssue",
    "LowBattery",
    "EStop",
];

export default function HeadUnitListItem(): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { status, hasFault } = useStatus();
    const { autoCalibrate } = usePairingContext();

    // don't show calibrate is other fault, user should take care of other
    // fault before calibrating
    const calibrated = status?.trainer.calibrated ?? "Calibrated";
    const showCalibration = !hasFault && calibrated !== "Calibrated";
    const init = status?.ready === "INIT" || autoCalibrate;

    React.useEffect(() => {
        if (calibrated === "Calibrating") {
            setDialogType("Calibrating");
        }
    }, [calibrated, setDialogType]);

    if (
        hasFault &&
        !specificFaultTypes.includes(status?.fault?.failures[0].type || "")
    ) {
        return (
            <ActionListItem
                action={() => {
                    logger.warn(
                        "Unexpected error, showing HardwareFailure dialog",
                        { fault: status?.fault },
                    );
                    setDialogType("HardwareFailure");
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">Error:</Typography>
                    <Typography
                        variant="h4"
                        component="span"
                        color="text.primary"
                    >
                        Press to Resolve
                    </Typography>
                </Stack>
            </ActionListItem>
        );
    }

    if (init) {
        return (
            <ListItem
                sx={{
                    py: 2,
                    backgroundColor: (theme) => theme.palette.common.white,
                }}
            >
                <ListItemText>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h4" component="span">
                            Head Unit:
                        </Typography>
                        <Typography
                            variant="h4"
                            component="span"
                            color="success.main"
                        >
                            Starting Up
                        </Typography>
                    </Stack>
                </ListItemText>
                <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                    <CircularProgress
                        size={16}
                        color="success"
                        sx={{ textAlign: "right" }}
                    />
                </ListItemIcon>
            </ListItem>
        );
    }

    if (showCalibration) {
        return (
            <ActionListItem action={() => setDialogType("Calibrating")}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">Calibrate:</Typography>
                    <Typography variant="h4" color="text.primary">
                        Press to Start
                    </Typography>
                </Stack>
            </ActionListItem>
        );
    }

    return (
        <InfoListItem>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" component="span">
                    Head Unit:
                </Typography>
                <Typography variant="h4" component="span" color="success.main">
                    Ready
                </Typography>
            </Stack>
        </InfoListItem>
    );
}
