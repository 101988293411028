import * as React from "react";

import Alert from "@mui/material/Alert";
import Snackbar, { type SnackbarCloseReason } from "@mui/material/Snackbar";

interface Props {
    message: string | null;
    onClose: (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => void;
}

export default function ModuleEditFeedbackAlert({
    message,
    onClose,
}: Props): React.JSX.Element {
    return (
        <Snackbar
            open={message !== null}
            onClose={onClose}
            autoHideDuration={5_000}
        >
            <Alert
                onClose={onClose}
                variant="filled"
                severity="success"
                sx={{ w: "100%" }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}
