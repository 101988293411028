import * as React from "react";

import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import Battery2BarIcon from "@mui/icons-material/Battery2Bar";
import Battery3BarIcon from "@mui/icons-material/Battery3Bar";
import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";
import Stack from "@mui/material/Stack";
import { SvgIconProps } from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import { useStatus } from "../../hooks/status";
import useDialog from "../useDialog";

import { ActionListItem } from "./listItems";

const BATTERY_THRESHOLDS = {
    FULL: 70,
    HIGH: 46,
    MEDIUM: 23,
    LOW: 15,
} as const;

interface BatteryIconProps extends SvgIconProps {
    level?: number | null;
}

export function BatteryIcon({
    level,
    ...rest
}: BatteryIconProps): React.JSX.Element | undefined {
    if (!level && level !== 0) {
        return <BatteryUnknownIcon {...rest} />;
    }

    if (level >= BATTERY_THRESHOLDS.FULL) return <BatteryFullIcon {...rest} />;
    if (level >= BATTERY_THRESHOLDS.HIGH) return <Battery4BarIcon {...rest} />;
    if (level >= BATTERY_THRESHOLDS.MEDIUM)
        return <Battery3BarIcon {...rest} />;
    if (level >= BATTERY_THRESHOLDS.LOW)
        return <Battery2BarIcon color="warning" {...rest} />;

    return <Battery1BarIcon color="error" />;
}

export default function BatteryListItem(): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { status } = useStatus();
    const batteryLevel = status?.trainer.battery.level;

    if (
        status?.hardwarePlatform === "atom" &&
        status.fault?.failures[0].type === "LowBattery"
    ) {
        return (
            <ActionListItem
                action={() => setDialogType("ReplaceBatteries")}
                icon={<Battery1BarIcon color="error" fontSize="medium" />}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">Low Battery:</Typography>
                    <Typography variant="h4" color="text.primary">
                        Exchange
                    </Typography>
                </Stack>
            </ActionListItem>
        );
    }

    return (
        <ActionListItem
            action={() => setDialogType("ReplaceBatteries")}
            priority={false}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4">Battery:</Typography>
                <Typography variant="h4" color="success.main">
                    <BatteryIcon level={batteryLevel} />
                </Typography>
            </Stack>
        </ActionListItem>
    );
}
