import { Sport } from "../../common/context/sport";

import { CourtDimensions, CourtSize } from "./types";

const padelCourtImage = new URL(
    "../../../static/img/padel-full-court.png",
    import.meta.url,
).toString();
const padelHalfCourtImage = new URL(
    "../../../static/img/padel-half-court.png",
    import.meta.url,
).toString();
const platformCourtImage = new URL(
    "../../../static/img/platform-full-court.png",
    import.meta.url,
).toString();
const platformHalfCourtImage = new URL(
    "../../../static/img/platform-half-court.png",
    import.meta.url,
).toString();
const tennisCourtImage = new URL(
    "../../../static/img/tennis-full-court.png",
    import.meta.url,
).toString();
const tennisHalfCourtImage = new URL(
    "../../../static/img/tennis-half-court.png",
    import.meta.url,
).toString();

const pickleballCourtImage = new URL(
    "../../../static/models/textures/pickeball-court.jpg",
    import.meta.url,
).toString();
const pickleballHalfCourtImage = new URL(
    "../../../static/models/textures/pickleball-court-half.jpg",
    import.meta.url,
).toString();

export const CourtImages: Record<CourtSize, Record<Sport, string>> = {
    FULL: {
        PADEL: padelCourtImage as string,
        PLATFORM_TENNIS: platformCourtImage as string,
        TENNIS: tennisCourtImage as string,
        PICKLEBALL: pickleballCourtImage,
    },
    HALF: {
        PADEL: padelHalfCourtImage as string,
        PLATFORM_TENNIS: platformHalfCourtImage as string,
        TENNIS: tennisHalfCourtImage as string,
        PICKLEBALL: pickleballHalfCourtImage,
    },
};

export const IconDimensions = {
    trainerMarkerWidth: 48,
    trainerMarkerHeight: 48,
    trainerRingWidth: 66,
    trainerRingHeight: 98,
    trainerSolidWidth: 48,
    trainerSolidHeight: 72,
    circleMarkerWidth: 36,
    circleMarkerHeight: 36,
};

export const CourtTable: Record<Sport, CourtDimensions> = {
    PADEL: {
        widthMeters: 10.2,
        heightMeters: {
            FULL: 20.2,
            HALF: 10.62,
        },
        width: 400,
        height: {
            FULL: 778,
            HALF: 409,
        },
        xOffset: 200,
        yOffset: {
            FULL: 389,
            HALF: 5,
        },
    },
    PICKLEBALL: {
        widthMeters: 7.92,
        heightMeters: {
            FULL: 16.46,
            HALF: 8.23,
        },
        width: 482,
        height: {
            FULL: 1000,
            HALF: 500,
        },
        xOffset: 240,
        yOffset: {
            FULL: 500,
            HALF: -50,
        },
    },
    PLATFORM_TENNIS: {
        widthMeters: 9.144,
        heightMeters: {
            FULL: 18.288,
            HALF: 11.6,
        },
        width: 400,
        height: {
            FULL: 800,
            HALF: 507,
        },
        xOffset: 200,
        yOffset: {
            FULL: 400,
            HALF: 107,
        },
    },
    TENNIS: {
        widthMeters: 18.3,
        heightMeters: {
            FULL: 36.58,
            HALF: 24.7,
        },
        width: 400,
        height: {
            FULL: 800,
            HALF: 540,
        },
        xOffset: 200,
        yOffset: {
            FULL: 400,
            HALF: 140,
        },
    },
};
