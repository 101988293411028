import * as React from "react";
import { useNavigate, useParams } from "react-router";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function NoHistory(): JSX.Element {
    const navigate = useNavigate();
    const { appId, id: workoutId } = useParams();

    return (
        <Stack
            sx={{
                position: "relative",
                overscrollBehavior: "contain",
                backgroundColor: "background.default",
                padding: 2,
            }}
        >
            <Typography>No history available. Click below to play.</Typography>
            <Button
                variant="contained"
                color="secondary"
                sx={{ marginTop: 2 }}
                onClick={() => {
                    navigate(
                        `/content/apps/workouts/plugin/play/${appId}/${workoutId}`,
                    );
                }}
            >
                Play
            </Button>
        </Stack>
    );
}
