import * as React from "react";

import AbcIcon from "@mui/icons-material/Abc";
import AdjustIcon from "@mui/icons-material/Adjust";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import DescriptionIcon from "@mui/icons-material/Description";
import PublishIcon from "@mui/icons-material/Publish";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";

import type { Tag } from "@volley/data";
import type { SingleShotConfig } from "@volley/shared/apps/single-shot-models";

import fetchApi, { logFetchError } from "../../../../../../util/fetchApi";
import { useCurrentUser } from "../../../../../hooks/currentUser";
import PublishSelect from "../../../ContentManagement/PublishSelect";
import Tagging from "../../../Shared/Tagging";
import ThrowCount from "../../../Shared/ThrowCount";
import ThrowInterval from "../../../Shared/ThrowInterval";
import { WorkoutAction, WorkoutForm } from "../reducer";

const THROW_MARKS = [
    { value: 1, label: "1" },
    { value: 15, label: "15" },
    { value: 30, label: "30" },
    { value: 45, label: "45" },
    { value: 60, label: "All" },
];

type TabValue =
    | "name"
    | "description"
    | "defaults"
    | "contentProvider"
    | "tags";

interface Props {
    workout: WorkoutForm;
    dispatch: React.Dispatch<WorkoutAction>;
}

export default function WorkoutControls({
    workout,
    dispatch,
}: Props): React.JSX.Element {
    const { currentUser } = useCurrentUser();
    const [tab, setTab] = React.useState<TabValue>("name");
    const [tagsLoading, setTagsLoading] = React.useState(false);

    React.useEffect(() => {
        async function fetchTags() {
            const result = await fetchApi<Record<number, Tag[]>>(
                `/api/app-workouts/${workout.id}/tags`,
                "GET",
            );
            return result;
        }
        if (!tagsLoading && workout.tags === null && workout.id !== 0) {
            setTagsLoading(true);
            fetchTags()
                .then((result) => {
                    dispatch({ type: "tags", value: result });
                })
                .catch((error) => {
                    logFetchError(
                        error,
                        `Failed to fetch tags for workout ${workout.id}`,
                    );
                })
                .finally(() => {
                    setTagsLoading(false);
                });
        }
    }, [workout, tagsLoading, dispatch]);

    const config = workout.config as unknown as SingleShotConfig;

    return (
        <>
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={(_e, value: TabValue) => setTab(value)}
                sx={{
                    "& .MuiTab-root": {
                        fontSize: 10,
                        minHeight: 24,
                        p: 0,
                        pb: 1,
                    },
                    minHeight: 24,
                }}
            >
                <Tab label="Name" value="name" icon={<AbcIcon />} />
                <Tab
                    label="Description"
                    value="description"
                    icon={<DescriptionIcon />}
                />
                <Tab label="Defaults" value="defaults" icon={<AdjustIcon />} />
                <Tab
                    label="Tagging"
                    value="tags"
                    icon={<BookmarksIcon />}
                    disabled={tagsLoading}
                />
                {currentUser?.contentProviderPublishers.length &&
                    workout.workoutCollaborators.some(
                        (c) => c.user.id === currentUser?.id,
                    ) && (
                        <Tab
                            label="Publisher"
                            value="contentProvider"
                            icon={<PublishIcon />}
                        />
                    )}
            </Tabs>
            {!!tab && <Box component="div" pt={2} />}
            {tab === "name" && (
                <TextField
                    label="Name"
                    name="name"
                    value={workout.name}
                    onChange={(e) =>
                        dispatch({ type: "name", value: e.currentTarget.value })
                    }
                />
            )}
            {tab === "description" && (
                <TextField
                    label="Description"
                    multiline
                    name="description"
                    value={workout.description}
                    onChange={(e) =>
                        dispatch({
                            type: "description",
                            value: e.currentTarget.value,
                        })
                    }
                />
            )}
            {tab === "defaults" && (
                <Stack spacing={2}>
                    <ThrowInterval
                        selectedThrowInterval={config.intervalOverride ?? 2}
                        onUserThrowIntervalChanged={(intervalOverride) =>
                            dispatch({
                                type: "updateConfig",
                                value: { ...config, intervalOverride },
                            })
                        }
                    />
                    <ThrowCount
                        label="Shots"
                        selectedThrowCount={config.numberOfBalls ?? 60}
                        onUserThrowCountChanged={(numberOfBalls) =>
                            dispatch({
                                type: "updateConfig",
                                value: { ...config, numberOfBalls },
                            })
                        }
                        marks={THROW_MARKS}
                        min={1}
                        max={60}
                    />
                </Stack>
            )}
            {tab === "contentProvider" && (
                <PublishSelect
                    contentProviderId={workout.contentProviderId}
                    setContentProviderId={(value) =>
                        dispatch({ type: "contentProviderId", value })
                    }
                />
            )}
            <Tagging
                open={tab === "tags"}
                onCancel={() => setTab("name")}
                onFinish={(updated) => {
                    setTab("name");
                    dispatch({ type: "tags", value: updated });
                }}
                selectedTags={workout.tags ?? {}}
            />
        </>
    );
}
