import {
    CourtGeometry,
    CourtGeometryBySport,
    Sport,
} from "@volley/physics/dist/models";
import {
    Keypoint,
    BboxImage,
    PointQuad,
    People,
    Track,
    Point,
    CourtPosition,
    CourtAOI,
} from "@volley/shared/vision-models";

import {
    drawCircle,
    drawDiamond,
    drawLine,
    drawRectangle,
    StyleOptions,
} from "./canvasDrawing";

const DEFAULT_COLOR = "#FDDA0D";
const WHITE = "#FFFFFF";

// Court
const COURT_DARK_BLUE = "#224790";
const COURT_GREEN = "#518d47";
const COURT_LINE_COLOR = WHITE;
const COURT_OUT_OF_BOUNDS_COLOR = "#42695C";
const NET_COLOR = WHITE;
const COURT_LINE_WIDTH = 2;
const NET_LINE_WIDTH = 1;

// Person/Pose
const POSE_RADIUS = 3;
const POSE_CONNECTION_COLOR = "#FDDA0D";
const POSE_CONNECTION_WIDTH = 1;
const POSE_KEYPOINT_COLOR = "#00FF00";
const POSE_KEYPOINT_LINE_WIDTH = 1;
const PERSON_AOI_COLOR = "#00FFFF";
const PERSON_AOI_LINE_WIDTH = 1;
const PERSON_BOX_COLOR = "#00FF00";
const PERSON_BOX_LINE_WIDTH = 1;

// Ball tracking
const BALL_TRACK_COLOR = "#0000FF";
const BALL_TRACK_COLOR_LEADING = "#FF0000";
const BALL_TRACK_LINE_WIDTH = 1;
const BALL_TRACK_RADIUS = 2;
const BALL_BOUNCE_DETECT_COLOR = "#00FFFF";

const COURT_CORNERS_COLOR = "#0F1DF5";
const IMAGE_SPACE_COLOR = "#FF0000";
const POINT_QUAD_LINE_WIDTH = 1;

const drawPointQuad = (
    context: CanvasRenderingContext2D,
    pointQuad: PointQuad,
    scaleFactor: number,
    color = DEFAULT_COLOR,
) => {
    const style = { strokeStyle: color, lineWidth: POINT_QUAD_LINE_WIDTH };

    if (
        !pointQuad.point1 ||
        !pointQuad.point2 ||
        !pointQuad.point3 ||
        !pointQuad.point4
    ) {
        return;
    }

    drawLine(context, pointQuad.point1, pointQuad.point2, style, scaleFactor);
    drawLine(context, pointQuad.point2, pointQuad.point4, style, scaleFactor);
    drawLine(context, pointQuad.point4, pointQuad.point3, style, scaleFactor);
    drawLine(context, pointQuad.point3, pointQuad.point1, style, scaleFactor);
};

export const drawFilterImageSpace = (
    context: CanvasRenderingContext2D,
    filterImageSpace: PointQuad,
    scaleFactor: number,
) => {
    drawPointQuad(context, filterImageSpace, scaleFactor, IMAGE_SPACE_COLOR);
};

export const drawCourtCornersImageSpace = (
    context: CanvasRenderingContext2D,
    courtCornersImageSpace: PointQuad,
    scaleFactor: number,
) => {
    drawPointQuad(
        context,
        courtCornersImageSpace,
        scaleFactor,
        COURT_CORNERS_COLOR,
    );
};

/*
 * Draw a person AOI on the video canvas
 */
export const drawPersonAoi = (
    context: CanvasRenderingContext2D,
    personAoi: BboxImage,
    scaleFactor: number,
) => {
    const styleOptions = {
        strokeStyle: PERSON_AOI_COLOR,
        lineWidth: PERSON_AOI_LINE_WIDTH,
    };

    drawRectangle(context, personAoi, styleOptions, scaleFactor);
};

const drawPoseLine = (
    context: CanvasRenderingContext2D,
    scaleFactor: number,
    a: Keypoint,
    b: Keypoint,
    color = POSE_CONNECTION_COLOR,
    width = POSE_CONNECTION_WIDTH,
) => {
    if (a.x === 0 && a.y === 0) {
        return;
    }
    if (b.x === 0 && b.y === 0) {
        return;
    }
    drawLine(
        context,
        a,
        b,
        { strokeStyle: color, lineWidth: width },
        scaleFactor,
    );
};

export const drawPerson = (
    context: CanvasRenderingContext2D,
    person: People,
    scaleFactor: number,
    drawBox: boolean,
    drawPose: boolean,
) => {
    const { bboxImage, pose } = person;
    if (drawBox) {
        const styleOptions = {
            strokeStyle: PERSON_BOX_COLOR,
            lineWidth: PERSON_BOX_LINE_WIDTH,
        };
        drawRectangle(context, bboxImage, styleOptions, scaleFactor);
    }
    if (drawPose && pose) {
        const poseStyle = {
            strokeStyle: POSE_KEYPOINT_COLOR,
            lineWidth: POSE_KEYPOINT_LINE_WIDTH,
            fillStyle: POSE_KEYPOINT_COLOR,
        };
        pose.keypoints?.forEach((keypoint) => {
            drawCircle(context, keypoint, POSE_RADIUS, poseStyle, scaleFactor);
        });
        if (pose.leftAnkle) {
            drawCircle(
                context,
                pose.leftAnkle,
                POSE_RADIUS,
                { ...poseStyle, strokeStyle: "red", fillStyle: "red" },
                scaleFactor,
            );
        }
        if (pose.rightAnkle) {
            drawCircle(
                context,
                pose.rightAnkle,
                POSE_RADIUS,
                { ...poseStyle, strokeStyle: "red", fillStyle: "red" },
                scaleFactor,
            );
        }
        const connections: [Keypoint | undefined, Keypoint | undefined][] = [
            [pose.neck, pose.nose],
            [pose.nose, pose.leftEye],
            [pose.leftEye, pose.leftEar],
            [pose.nose, pose.rightEye],
            [pose.rightEye, pose.rightEar],
            [pose.leftShoulder, pose.rightShoulder],
            [pose.leftShoulder, pose.leftElbow],
            [pose.leftElbow, pose.leftWrist],
            [pose.rightShoulder, pose.rightElbow],
            [pose.rightElbow, pose.rightWrist],
            [pose.leftShoulder, pose.leftHip],
            [pose.leftHip, pose.rightHip],
            [pose.rightHip, pose.rightShoulder],
            [pose.rightHip, pose.rightKnee],
            [pose.rightKnee, pose.rightAnkle],
            [pose.leftHip, pose.leftKnee],
            [pose.leftKnee, pose.leftAnkle],
        ];
        connections.forEach(([start, end]) => {
            if (start && end) {
                drawPoseLine(context, scaleFactor, start, end);
            }
        });
    }
};

export const drawServeData = (
    context: CanvasRenderingContext2D,
    person: People,
    scaleFactor: number,
) => {
    const serveData = person.serveData;
    if (!serveData) {
        return;
    }
    let color = WHITE;
    if (serveData.serveState === "TRIGGERED") {
        color = "yellow";
    } else if (serveData.serveState === "SERVE") {
        color = "red";
    }
    const { bboxImage, pose } = person;
    const styleOptions = {
        strokeStyle: color,
        lineWidth: PERSON_BOX_LINE_WIDTH,
    };
    drawRectangle(context, bboxImage, styleOptions, scaleFactor);
    const fontSize = 18 * scaleFactor;
    context.font = `${fontSize}px Arial`;
    context.textBaseline = "bottom";
    const y = bboxImage.ymin * scaleFactor - 5;
    if (pose) {
        let armColor = "white";
        if (serveData.leftArmMetric >= 2.85) {
            armColor = "red";
        } else if (serveData.leftArmMetric >= 2.6) {
            armColor = "yellow";
        }
        const leftArmConnections: [
            Keypoint | undefined,
            Keypoint | undefined,
        ][] = [
            [pose.leftShoulder, pose.leftElbow],
            [pose.leftElbow, pose.leftWrist],
        ];
        leftArmConnections.forEach(([start, end]) => {
            if (start && end) {
                drawPoseLine(context, scaleFactor, start, end, armColor, 2);
            }
        });
        const leftMetricText = `L: ${serveData.leftArmMetric.toFixed(2)}`;
        const leftEventText = serveData.leftArmEvent || "";
        context.fillStyle = armColor;
        const rightEdge = (bboxImage.xmin + bboxImage.width) * scaleFactor;
        context.fillText(leftMetricText, rightEdge + 5, y - fontSize);
        if (leftEventText && leftEventText !== "NONE") {
            context.fillText(leftEventText, rightEdge + 5, y);
        }
        armColor = "white";
        if (serveData.rightArmMetric >= 2.85) {
            armColor = "red";
        } else if (serveData.rightArmMetric >= 2.6) {
            armColor = "yellow";
        }
        const rightArmConnections: [
            Keypoint | undefined,
            Keypoint | undefined,
        ][] = [
            [pose.rightShoulder, pose.rightElbow],
            [pose.rightElbow, pose.rightWrist],
        ];
        rightArmConnections.forEach(([start, end]) => {
            if (start && end) {
                drawPoseLine(context, scaleFactor, start, end, armColor, 2);
            }
        });
        const rightMetricText = `R: ${serveData.rightArmMetric.toFixed(2)}`;
        const rightEventText = serveData.rightArmEvent || "";
        context.fillStyle = armColor;
        const leftEdge = bboxImage.xmin * scaleFactor;
        context.fillText(rightMetricText, leftEdge - 50, y - fontSize);
        if (rightEventText && rightEventText !== "NONE") {
            context.fillText(rightEventText, leftEdge - 50, y);
        }
    }
    context.fillStyle = color;
    context.lineWidth = 2;
    context.fillText(
        serveData.serveState,
        bboxImage.xmin * scaleFactor,
        bboxImage.ymin * scaleFactor - 15,
    );
    context.fillText(
        serveData.serveEvent,
        bboxImage.xmin * scaleFactor,
        bboxImage.ymin * scaleFactor - 5,
    );
};

export const drawPersonHighlight = (
    context: CanvasRenderingContext2D,
    person: People,
    scaleFactor: number,
    color = "rgba(255, 0, 0, 0.2)",
) => {
    const { bboxImage } = person;
    const styleOptions = {
        strokeStyle: "red",
        fillStyle: color,
        lineWidth: PERSON_BOX_LINE_WIDTH,
    };
    drawRectangle(context, bboxImage, styleOptions, scaleFactor);
};

const COLORS = [
    "red",
    "blue",
    "green",
    "yellow",
    "white",
    "purple",
    "orange",
    "pink",
    "aquamarine",
    "chartreuse",
    "coral",
    "darkorange",
];

function getColorById(id: number): string {
    const index = id % COLORS.length;
    return COLORS[index];
}

/*
 * Draw a ball track on the video canvas
 */
export const drawBallTrack = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
    scaleFactor: number,
) => {
    const { track, bounces } = ballTrack;
    const balls = ballTrack.track;
    const trackColor = getColorById(ballTrack.id);
    balls.forEach((ball, index) => {
        const { bboxImage: ballBox } = ball;
        if (index > 0) {
            const previousBall = track[index - 1];
            const trackLineStyle = {
                strokeStyle: trackColor,
                lineWidth: BALL_TRACK_LINE_WIDTH,
            };
            drawLine(
                context,
                previousBall.positionImage,
                ball.positionImage,
                trackLineStyle,
                scaleFactor,
            );
        }
        const ballStyle = {
            strokeStyle: index === 0 ? BALL_TRACK_COLOR_LEADING : trackColor,
        };
        drawCircle(
            context,
            ball.positionImage,
            ballBox.width / (index === 0 ? 2 : 4),
            ballStyle,
            scaleFactor,
        );
    });
    const bounceStyle = { strokeStyle: BALL_BOUNCE_DETECT_COLOR, lineWidth: 2 };
    bounces.forEach((bounce) => {
        drawDiamond(
            context,
            bounce.positionImage,
            20,
            bounceStyle,
            scaleFactor,
        );
    });
    const lastBall = balls[0];
    const textPosition = lastBall ? lastBall.positionImage : { x: 10, y: 30 };
    context.fillStyle = trackColor;
    context.font = "16px Arial";
    context.fillText(
        `${ballTrack.id}`,
        textPosition.x * scaleFactor,
        textPosition.y * scaleFactor,
    );
};

/**
 * Calculates the scale and center coordinates for canvas transformations.
 */
function calculateCanvasTransformation(
    canvasWidth: number,
    canvasHeight: number,
    realWidth: number,
    realHeight: number,
) {
    const scaleFactorX = canvasWidth / realWidth;
    const scaleFactorY = canvasHeight / realHeight;
    const scale = Math.min(scaleFactorX, scaleFactorY);
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    return { scale, centerX, centerY };
}

/**
 * Converts a point from real-world coordinates to canvas coordinates.
 * The full-court conversion is used by default.
 */
export function convertPointToCanvasCoordinates(
    point: Point,
    canvasWidth: number,
    canvasHeight: number,
    realWidth: number,
    realHeight: number,
): Point {
    const { scale, centerX, centerY } = calculateCanvasTransformation(
        canvasWidth,
        canvasHeight,
        realWidth,
        realHeight,
    );
    const x = centerX + point.x * scale;
    const y = centerY - point.y * scale;
    return { x, y, z: point.z };
}

/**
 * Converts a bounding box from real-world coordinates to canvas coordinates.
 */
export function convertBoundingBoxToCanvasCoords(
    bbox: BboxImage,
    canvasWidth: number,
    canvasHeight: number,
    realWidth: number,
    realHeight: number,
): BboxImage {
    const { scale, centerX, centerY } = calculateCanvasTransformation(
        canvasWidth,
        canvasHeight,
        realWidth,
        realHeight,
    );
    const xMinCanvas = centerX + bbox.xmin * scale;
    const yMinCanvas = centerY - bbox.ymin * scale;
    const widthCanvas = bbox.width * scale;
    const heightCanvas = bbox.height * scale;
    return {
        xmin: xMinCanvas,
        ymin: yMinCanvas - heightCanvas,
        width: widthCanvas,
        height: heightCanvas,
    };
}

function getCourtGeomtryForSport(sport: Sport): CourtGeometry {
    return CourtGeometryBySport[sport];
}

/**
 * Utility: Creates a custom conversion function for a half-court view.
 * This function computes the same transformation as used in drawCourt when a half-court is requested.
 */
export const createHalfCourtConversion = (
    context: CanvasRenderingContext2D,
    sport: Sport,
    courtHalf: "near" | "far",
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const buffer = 0.025 * courtGeometry.COURT_LENGTH;
    let yMin: number, yMax: number;
    if (courtHalf === "near") {
        yMin = 0 - buffer;
        yMax = courtGeometry.COURT_LENGTH / 2 + buffer;
    } else {
        yMin = -courtGeometry.COURT_LENGTH / 2 - buffer;
        yMax = 0 + buffer;
    }
    const xMin = -courtGeometry.COURT_WIDTH / 2 - buffer;
    const xMax = courtGeometry.COURT_WIDTH / 2 + buffer;
    const realWidth = xMax - xMin;
    const realHeight = yMax - yMin;
    const canvasWidth = context.canvas.width;
    const canvasHeight = context.canvas.height;
    const scaleX = canvasWidth / realWidth;
    const scaleY = canvasHeight / realHeight;
    const scale = Math.min(scaleX, scaleY);
    const offsetX = (canvasWidth - realWidth * scale) / 2;
    const offsetY = (canvasHeight - realHeight * scale) / 2;
    return (point: Point): Point => ({
        x: (point.x - xMin) * scale + offsetX,
        y: (yMax - point.y) * scale + offsetY,
        z: point.z,
    });
};

export const createInverseHalfCourtConversion = (
    context: CanvasRenderingContext2D,
    sport: Sport,
    courtHalf: "near" | "far",
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const buffer = 0.025 * courtGeometry.COURT_LENGTH;
    let yMin: number, yMax: number;
    if (courtHalf === "near") {
        yMin = 0 - buffer;
        yMax = courtGeometry.COURT_LENGTH / 2 + buffer;
    } else {
        yMin = -courtGeometry.COURT_LENGTH / 2 - buffer;
        yMax = 0 + buffer;
    }
    const xMin = -courtGeometry.COURT_WIDTH / 2 - buffer;
    const xMax = courtGeometry.COURT_WIDTH / 2 + buffer;
    const realWidth = xMax - xMin;
    const realHeight = yMax - yMin;
    const canvasWidth = context.canvas.width;
    const canvasHeight = context.canvas.height;
    const scaleX = canvasWidth / realWidth;
    const scaleY = canvasHeight / realHeight;
    const scale = Math.min(scaleX, scaleY);
    const offsetX = (canvasWidth - realWidth * scale) / 2;
    const offsetY = (canvasHeight - realHeight * scale) / 2;

    return (canvasPoint: Point): Point => ({
        x: (canvasPoint.x - offsetX) / scale + xMin,
        y: yMax - (canvasPoint.y - offsetY) / scale,
        z: canvasPoint.z,
    });
};

export const drawTennisCourt = (context: CanvasRenderingContext2D) => {
    return drawCourt(context, "TENNIS");
};

export const drawPlatformCourt = (context: CanvasRenderingContext2D) => {
    return drawCourt(context, "PLATFORM_TENNIS", "far");
};

export const drawPickleballCourt = (context: CanvasRenderingContext2D) => {
    return drawCourt(context, "PICKLEBALL");
};

/**
 * Modified drawCourt function.
 * When the optional parameter `courtHalf` is provided ("near" or "far"), a custom conversion is computed
 * and used so that the half-court (plus a buffer) fills the entire canvas.
 */
export const drawCourt = (
    context: CanvasRenderingContext2D,
    sport: Sport,
    courtHalf?: "near" | "far",
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    let convertPointFn: (point: Point) => Point = (point: Point) =>
        convertPointToCanvasCoordinates(
            point,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        );
    if (courtHalf) {
        convertPointFn = createHalfCourtConversion(context, sport, courtHalf);
    }
    const convertBbox = (bbox: BboxImage): BboxImage => {
        const topLeft = convertPointFn({
            x: bbox.xmin,
            y: bbox.ymin + bbox.height,
            z: 0,
        });
        const bottomRight = convertPointFn({
            x: bbox.xmin + bbox.width,
            y: bbox.ymin,
            z: 0,
        });
        return {
            xmin: topLeft.x,
            ymin: topLeft.y,
            width: bottomRight.x - topLeft.x,
            height: bottomRight.y - topLeft.y,
        };
    };
    const platformStyle = {
        strokeStyle: COURT_LINE_COLOR,
        lineWidth: COURT_LINE_WIDTH,
        fillStyle: COURT_OUT_OF_BOUNDS_COLOR,
    };
    drawRectangle(
        context,
        convertBbox({
            xmin: -courtGeometry.PLATFORM_WIDTH / 2,
            ymin: -courtGeometry.PLATFORM_LENGTH / 2,
            width: courtGeometry.PLATFORM_WIDTH,
            height: courtGeometry.PLATFORM_LENGTH,
        }),
        platformStyle,
        1,
    );
    const courtStyle = {
        strokeStyle: COURT_LINE_COLOR,
        lineWidth: COURT_LINE_WIDTH,
        fillStyle: COURT_DARK_BLUE,
    };
    drawRectangle(
        context,
        convertBbox({
            xmin: -courtGeometry.COURT_WIDTH / 2,
            ymin: -courtGeometry.COURT_LENGTH / 2,
            width: courtGeometry.COURT_WIDTH,
            height: courtGeometry.COURT_LENGTH,
        }),
        courtStyle,
        1,
    );
    if (sport === "PICKLEBALL") {
        const kitchenStyle = {
            strokeStyle: COURT_LINE_COLOR,
            lineWidth: COURT_LINE_WIDTH,
            fillStyle: COURT_GREEN,
        };
        drawRectangle(
            context,
            convertBbox({
                xmin: -courtGeometry.COURT_WIDTH / 2,
                ymin: -courtGeometry.SERVICE_LENGTH / 2,
                width: courtGeometry.COURT_WIDTH,
                height: courtGeometry.SERVICE_LENGTH,
            }),
            kitchenStyle,
            1,
        );
    }
    const netStart = convertPointFn({
        x: -(courtGeometry.COURT_WIDTH / 2) - 0.1,
        y: 0,
        z: 0,
    });
    const netEnd = convertPointFn({
        x: courtGeometry.COURT_WIDTH / 2 + 0.1,
        y: 0,
        z: 0,
    });
    const netStyle = { strokeStyle: NET_COLOR, lineWidth: NET_LINE_WIDTH };
    drawLine(context, netStart, netEnd, netStyle);
    const lineStyle = {
        strokeStyle: COURT_LINE_COLOR,
        lineWidth: COURT_LINE_WIDTH,
    };
    const singlesOffset =
        (courtGeometry.COURT_WIDTH - courtGeometry.SERVICE_WIDTH) / 2;
    const singlesLineStart = convertPointFn({
        x: -courtGeometry.COURT_WIDTH / 2 + singlesOffset,
        y: courtGeometry.COURT_LENGTH / 2,
        z: 0,
    });
    const singlesLineEnd = convertPointFn({
        x: -courtGeometry.COURT_WIDTH / 2 + singlesOffset,
        y: -courtGeometry.COURT_LENGTH / 2,
        z: 0,
    });
    drawLine(context, singlesLineStart, singlesLineEnd, lineStyle);
    const otherSinglesLineStart = convertPointFn({
        x: courtGeometry.COURT_WIDTH / 2 - singlesOffset,
        y: courtGeometry.COURT_LENGTH / 2,
        z: 0,
    });
    const otherSinglesLineEnd = convertPointFn({
        x: courtGeometry.COURT_WIDTH / 2 - singlesOffset,
        y: -courtGeometry.COURT_LENGTH / 2,
        z: 0,
    });
    drawLine(context, otherSinglesLineStart, otherSinglesLineEnd, lineStyle);
    const serviceLineStart = convertPointFn({
        x: -courtGeometry.COURT_WIDTH / 2 + singlesOffset,
        y: courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    const serviceLineEnd = convertPointFn({
        x: courtGeometry.COURT_WIDTH / 2 - singlesOffset,
        y: courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    drawLine(context, serviceLineStart, serviceLineEnd, lineStyle);
    const otherServiceLineStart = convertPointFn({
        x: -courtGeometry.COURT_WIDTH / 2 + singlesOffset,
        y: -courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    const otherServiceLineEnd = convertPointFn({
        x: courtGeometry.COURT_WIDTH / 2 - singlesOffset,
        y: -courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    drawLine(context, otherServiceLineStart, otherServiceLineEnd, lineStyle);
    const centerLineStart = convertPointFn({
        x: 0,
        y: courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    const centerLineEnd = convertPointFn({
        x: 0,
        y: -courtGeometry.SERVICE_LENGTH / 2,
        z: 0,
    });
    drawLine(context, centerLineStart, centerLineEnd, lineStyle);
    if (sport === "PLATFORM_TENNIS") {
        const centerTickStart = convertPointFn({
            x: 0,
            y: courtGeometry.COURT_LENGTH / 2,
            z: 0,
        });
        const centerTickEnd = convertPointFn({
            x: 0,
            y: courtGeometry.COURT_LENGTH / 2 - 0.1016,
            z: 0,
        });
        drawLine(context, centerTickStart, centerTickEnd, lineStyle);
        const centerTickStartNear = convertPointFn({
            x: 0,
            y: -courtGeometry.COURT_LENGTH / 2,
            z: 0,
        });
        const centerTickEndNear = convertPointFn({
            x: 0,
            y: -courtGeometry.COURT_LENGTH / 2 + 0.1016,
            z: 0,
        });
        drawLine(context, centerTickStartNear, centerTickEndNear, lineStyle);
    }
};

/**
 * Converts a CourtAOI to a BboxImage.
 */
export function convertCourtAoiToBboxImage(
    courtAoi: CourtAOI,
    context: CanvasRenderingContext2D,
    sport: Sport,
): BboxImage {
    const courtGeometry = CourtGeometryBySport[sport];
    const convertPoint = (point: Point) =>
        convertPointToCanvasCoordinates(
            point,
            context.canvas.width,
            context.canvas.height,
            courtGeometry.PLATFORM_WIDTH,
            courtGeometry.PLATFORM_LENGTH,
        );
    const topLeft = convertPoint({
        x: courtAoi.upperLeftX,
        y: courtAoi.upperLeftY,
        z: 0,
    });
    const bottomRight = convertPoint({
        x: courtAoi.lowerRightX,
        y: courtAoi.lowerRightY,
        z: 0,
    });
    return {
        xmin: topLeft.x,
        ymin: topLeft.y,
        width: bottomRight.x - topLeft.x,
        height: bottomRight.y - topLeft.y,
    };
}

export const drawRegions = (
    context: CanvasRenderingContext2D,
    regions: CourtAOI[],
    person: People,
    scaleFactor: number,
    sport: Sport = "PLATFORM_TENNIS",
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const personLocation = convertPointToCanvasCoordinates(
        person?.location || { x: 0, y: 0, z: 0 },
        context.canvas.width,
        context.canvas.height,
        courtGeometry.PLATFORM_WIDTH,
        courtGeometry.PLATFORM_LENGTH,
    );
    regions.forEach((region, index) => {
        const regionBbox = convertCourtAoiToBboxImage(region, context, sport);
        const isInRegion =
            personLocation.x > regionBbox.xmin &&
            personLocation.x < regionBbox.xmin + regionBbox.width &&
            personLocation.y > regionBbox.ymin &&
            personLocation.y < regionBbox.ymin + regionBbox.height;
        const regionStyle = {
            strokeStyle: isInRegion ? "red" : getColorById(index),
            lineWidth: 2,
            fillStyle: isInRegion
                ? "rgba(255, 255, 0, 0.2)"
                : "rgba(0, 0, 0, 0)",
        };
        drawRectangle(context, regionBbox, regionStyle, scaleFactor);
    });
    regions.forEach((region, index) => {
        const regionStyle = {
            strokeStyle: getColorById(index),
            lineWidth: 2,
        };
        drawRectangle(
            context,
            convertCourtAoiToBboxImage(region, context, sport),
            regionStyle,
            scaleFactor,
        );
    });
};

export const drawAOIForTennis = (
    context: CanvasRenderingContext2D,
    aoi: CourtAOI,
    rectangleStyle?: StyleOptions,
) => {
    return drawAOI(context, aoi, rectangleStyle, "TENNIS");
};

export const drawAOIForPickleball = (
    context: CanvasRenderingContext2D,
    aoi: CourtAOI,
    rectangleStyle?: StyleOptions,
) => {
    return drawAOI(context, aoi, rectangleStyle, "PICKLEBALL");
};

export const drawAOIForPlatformTennis = (
    context: CanvasRenderingContext2D,
    aoi: CourtAOI,
    rectangleStyle?: StyleOptions,
) => {
    return drawAOI(context, aoi, rectangleStyle, "PLATFORM_TENNIS");
};

export const drawAOI = (
    context: CanvasRenderingContext2D,
    aoi: CourtAOI,
    rectangleStyle?: StyleOptions,
    sport: Sport = "PLATFORM_TENNIS",
    convertPointFn?: (point: Point) => Point,
) => {
    const style = {
        strokeStyle: "red",
        lineWidth: 2,
        ...rectangleStyle,
    };

    // Use the custom conversion function if provided; otherwise default to the full-court conversion.
    const courtGeometry = CourtGeometryBySport[sport];
    const conv =
        convertPointFn ||
        ((point: Point) =>
            convertPointToCanvasCoordinates(
                point,
                context.canvas.width,
                context.canvas.height,
                courtGeometry.PLATFORM_WIDTH,
                courtGeometry.PLATFORM_LENGTH,
            ));

    // Convert the upper left and lower right points of the AOI using the chosen conversion.
    const topLeft = conv({ x: aoi.upperLeftX, y: aoi.upperLeftY, z: 0 });
    const bottomRight = conv({ x: aoi.lowerRightX, y: aoi.lowerRightY, z: 0 });
    const bbox: BboxImage = {
        xmin: topLeft.x,
        ymin: topLeft.y,
        width: bottomRight.x - topLeft.x,
        height: bottomRight.y - topLeft.y,
    };

    drawRectangle(context, bbox, style, 1);
};

export const drawPersonCourtForTennis = (
    context: CanvasRenderingContext2D,
    person: People,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawPersonCourt(context, person, "TENNIS", convertPointFn);
};

export const drawPersonCourtForPickleball = (
    context: CanvasRenderingContext2D,
    person: People,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawPersonCourt(context, person, "PICKLEBALL", convertPointFn);
};

export const drawPersonCourtForPlatformTennis = (
    context: CanvasRenderingContext2D,
    person: People,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawPersonCourt(context, person, "PLATFORM_TENNIS", convertPointFn);
};

/**
 * Draw a person on the court canvas.
 * Accepts an optional conversion function so that it can work with a half-court view.
 */
export const drawPersonCourt = (
    context: CanvasRenderingContext2D,
    person: People,
    sport: Sport = "PLATFORM_TENNIS",
    convertPointFn?: (point: Point) => Point,
) => {
    const courtGeometry = getCourtGeomtryForSport(sport);
    const conv =
        convertPointFn ||
        ((point: Point) =>
            convertPointToCanvasCoordinates(
                point,
                context.canvas.width,
                context.canvas.height,
                courtGeometry.PLATFORM_WIDTH,
                courtGeometry.PLATFORM_LENGTH,
            ));
    const { location } = person;
    if (!location) {
        return;
    }
    const personStyle = {
        strokeStyle: POSE_CONNECTION_COLOR,
        lineWidth: 2,
    };
    drawCircle(context, conv(location), 3, personStyle, 1);
};

export const drawBallForTennis = (
    context: CanvasRenderingContext2D,
    ball: Point,
    ballStyle?: StyleOptions,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawBall(context, ball, ballStyle, "TENNIS", convertPointFn);
};

export const drawBallForPickleball = (
    context: CanvasRenderingContext2D,
    ball: Point,
    ballStyle?: StyleOptions,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawBall(context, ball, ballStyle, "PICKLEBALL", convertPointFn);
};

export const drawBallForPlatformTennis = (
    context: CanvasRenderingContext2D,
    ball: Point,
    ballStyle?: StyleOptions,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawBall(
        context,
        ball,
        ballStyle,
        "PLATFORM_TENNIS",
        convertPointFn,
    );
};

/**
 * Draw a ball using an optional conversion function.
 */
export const drawBall = (
    context: CanvasRenderingContext2D,
    ball: Point,
    ballStyle?: StyleOptions,
    sport: Sport = "PLATFORM_TENNIS",
    convertPointFn?: (point: Point) => Point,
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const style = ballStyle || {
        strokeStyle: "yellow",
        lineWidth: 2,
    };
    const conv =
        convertPointFn ||
        ((point: Point) =>
            convertPointToCanvasCoordinates(
                point,
                context.canvas.width,
                context.canvas.height,
                courtGeometry.PLATFORM_WIDTH,
                courtGeometry.PLATFORM_LENGTH,
            ));
    drawCircle(context, conv(ball), 2, style, 1);
};

export const drawCameraCourtForTennis = (
    context: CanvasRenderingContext2D,
    camera: CourtPosition,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawCameraCourt(context, camera, "TENNIS", convertPointFn);
};

export const drawCameraCourtForPickleball = (
    context: CanvasRenderingContext2D,
    camera: CourtPosition,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawCameraCourt(context, camera, "PICKLEBALL", convertPointFn);
};

export const drawCameraCourtForPlatformTennis = (
    context: CanvasRenderingContext2D,
    camera: CourtPosition,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawCameraCourt(context, camera, "PLATFORM_TENNIS", convertPointFn);
};

/**
 * Draw a camera position on the court.
 * Accepts an optional conversion function.
 */
export const drawCameraCourt = (
    context: CanvasRenderingContext2D,
    camera: CourtPosition,
    sport: Sport = "PLATFORM_TENNIS",
    convertPointFn?: (point: Point) => Point,
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const conv =
        convertPointFn ||
        ((point: Point) =>
            convertPointToCanvasCoordinates(
                point,
                context.canvas.width,
                context.canvas.height,
                courtGeometry.PLATFORM_WIDTH,
                courtGeometry.PLATFORM_LENGTH,
            ));
    drawCircle(context, conv(camera), 3, {
        strokeStyle: "red",
        lineWidth: 2,
    });
};

export const drawBallTrackForTennis = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawBallTrackCourt(context, ballTrack, "TENNIS", convertPointFn);
};

export const drawBallTrackForPickleball = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawBallTrackCourt(context, ballTrack, "PICKLEBALL", convertPointFn);
};

export const drawBallTrackForPlatformTennis = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
    convertPointFn?: (point: Point) => Point,
) => {
    return drawBallTrackCourt(
        context,
        ballTrack,
        "PLATFORM_TENNIS",
        convertPointFn,
    );
};

/*
 * Draw a ball track on the court.
 * Accepts an optional conversion function.
 */
export const drawBallTrackCourt = (
    context: CanvasRenderingContext2D,
    ballTrack: Track,
    sport: Sport = "PLATFORM_TENNIS",
    convertPointFn?: (point: Point) => Point,
) => {
    const courtGeometry = CourtGeometryBySport[sport];
    const conv =
        convertPointFn ||
        ((point: Point) =>
            convertPointToCanvasCoordinates(
                point,
                context.canvas.width,
                context.canvas.height,
                courtGeometry.PLATFORM_WIDTH,
                courtGeometry.PLATFORM_LENGTH,
            ));
    const { track, bounces } = ballTrack;
    track.forEach((ball, index) => {
        const ballLocation = conv(ball.location);
        const ballStyle = {
            strokeStyle:
                index === 0 ? BALL_TRACK_COLOR_LEADING : BALL_TRACK_COLOR,
        };
        drawCircle(context, ballLocation, BALL_TRACK_RADIUS, ballStyle);
    });
    const bounceStyle = { strokeStyle: BALL_BOUNCE_DETECT_COLOR, lineWidth: 2 };
    bounces.forEach((bounce) => {
        const bounceLocation = conv(bounce.position);
        drawDiamond(context, bounceLocation, 4, bounceStyle);
    });
};

export const getCourtDrawingFunctionsForSport = (sport: Sport) => ({
    drawCourt: (context: CanvasRenderingContext2D) =>
        drawCourt(context, sport, undefined),
    drawBallCourt: (
        context: CanvasRenderingContext2D,
        ball: Point,
        convertPointFn?: (point: Point) => Point,
    ) => drawBall(context, ball, undefined, sport, convertPointFn),
    drawPersonCourt: (
        context: CanvasRenderingContext2D,
        person: People,
        convertPointFn?: (point: Point) => Point,
    ) => drawPersonCourt(context, person, sport, convertPointFn),
    drawCameraCourt: (
        context: CanvasRenderingContext2D,
        camera: CourtPosition,
        convertPointFn?: (point: Point) => Point,
    ) => drawCameraCourt(context, camera, sport, convertPointFn),
    drawAOICourt: (context: CanvasRenderingContext2D, aoi: CourtAOI) =>
        drawAOI(context, aoi, undefined, sport),
    drawBallTrackCourt: (
        context: CanvasRenderingContext2D,
        ballTrack: Track,
        convertPointFn?: (point: Point) => Point,
    ) => drawBallTrackCourt(context, ballTrack, sport, convertPointFn),
});

export const drawLegend = (
    context: CanvasRenderingContext2D,
    tracks: Track[],
    canvasWidth: number,
    scaleFactor: number,
) => {
    const startX = canvasWidth - 200 * scaleFactor;
    const startY = 20 * scaleFactor;
    const lineHeight = 20 * scaleFactor;
    context.fillStyle = "rgba(255, 255, 255, 0.8)";
    context.fillRect(
        startX,
        startY,
        180 * scaleFactor,
        tracks.length * lineHeight + 10 * scaleFactor,
    );
    context.font = `${16 * scaleFactor}px Arial`;
    context.fillStyle = "black";
    tracks.forEach((track, index) => {
        const textY = startY + (index + 1) * lineHeight;
        context.fillStyle = "black";
        context.fillText(
            `${track.id}: len=${track.track.length}`,
            startX + 10 * scaleFactor,
            textY,
        );
    });
};

export const drawFrameId = (
    context: CanvasRenderingContext2D,
    frameId: number,
    timestamp: number,
    scaleFactor: number,
) => {
    const startX = 10 * scaleFactor;
    const startY = 20 * scaleFactor;
    context.fillStyle = "rgba(255, 255, 255, 0.8)";
    context.fillRect(
        startX,
        startY - 16 * scaleFactor,
        180 * scaleFactor,
        45 * scaleFactor,
    );
    context.font = `${16 * scaleFactor}px Arial`;
    context.fillStyle = "black";
    context.fillText(`Frame ID: ${frameId}`, startX + 5, startY);
    context.fillText(`Timestamp: ${timestamp}`, startX + 5, startY + 10);
};
