import * as React from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import CloseableDialogTitle from "../common/CloseableDialogTitle";

import useDialog from "./useDialog";

interface ConfirmDialogProps {
    title: string;
    contentText: string;
    buttonLabel: string;
    onConfirm: () => void;
    onClose?: () => void;
}
export default function ConfirmDialog({
    title,
    contentText,
    buttonLabel,
    onConfirm,
    onClose,
}: ConfirmDialogProps): React.JSX.Element {
    const { onClose: defaultClose } = useDialog();

    const handleClose = React.useCallback(() => {
        if (onClose) {
            onClose();
        } else {
            defaultClose();
        }
    }, [onClose, defaultClose]);

    return (
        <>
            <CloseableDialogTitle variant="h4" onClose={handleClose}>
                {title}
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>{contentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={onConfirm}
                >
                    {buttonLabel}
                </Button>
            </DialogActions>
        </>
    );
}
