import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";

import CloseableDialogTitle from "../../../common/CloseableDialogTitle";

interface ConfirmDialogProps {
    providerName: string;
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
}
export default function PublishConfirmation({
    open,
    providerName,
    onConfirm,
    onClose,
}: ConfirmDialogProps): React.JSX.Element {
    return (
        <Dialog open={open}>
            <CloseableDialogTitle variant="h4" onClose={onClose}>
                Publish Workout
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText align="left">
                    <Typography>
                        Publishing a workout makes it available to all Volley
                        users.
                    </Typography>
                    <Typography>
                        {`Are you sure you want to publish this workout to ${providerName}?`}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={onConfirm}
                >
                    Publish
                </Button>
            </DialogActions>
        </Dialog>
    );
}
