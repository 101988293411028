import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";

import type {
    DifficultyLevel,
    Shots,
} from "@volley/shared/apps/serveandvolley-models";

interface Props {
    label?: string;
    disabled?: boolean;
    level: DifficultyLevel;
    shots: Shots;
    setLevel: (level: DifficultyLevel) => void;
    labelWrapperSx?: SxProps<Theme>;
    toggleButtonSx?: SxProps<Theme>;
    wrapperSx?: SxProps<Theme>;
}

export default function ServePlus1LevelSelector({
    disabled = false,
    label = "Level",
    labelWrapperSx = {},
    level,
    shots,
    setLevel,
    toggleButtonSx = {},
    wrapperSx = {},
}: Props): React.JSX.Element | null {
    const selectedLevel = Math.min(level, 3); // only expecting shots from 1 to 3
    const levels: { level: 1 | 2 | 3; label: string }[] = [
        { level: 1, label: "Easy" },
        { level: 2, label: "Medium" },
        { level: 3, label: "Hard" },
    ];

    if ([1, 2, 3].filter((l) => shots[l as 1 | 2 | 3].length > 0).length < 2) {
        return null;
    }

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" sx={wrapperSx}>
            <Box component="div" sx={labelWrapperSx}>
                <Typography variant="caption" sx={{ minWidth: "30px" }}>
                    {label}
                </Typography>
            </Box>
            <Box component="div" sx={toggleButtonSx}>
                <ButtonGroup
                    disabled={disabled}
                    fullWidth
                    size="small"
                    color="primary"
                >
                    {levels
                        .filter((l) => shots[l.level].length > 0)
                        .map((l) => (
                            <Button
                                key={l.label}
                                variant={
                                    selectedLevel === l.level
                                        ? "contained"
                                        : "outlined"
                                }
                                size="small"
                                sx={{
                                    padding: "2px",
                                    color:
                                        selectedLevel === l.level
                                            ? "common.white"
                                            : "primary.light",
                                    backgroundColor:
                                        selectedLevel === l.level
                                            ? "primary.light"
                                            : "common.white",
                                    fontSize: "10px",
                                    "&:hover": {
                                        bgcolor: "primary.light",
                                    },
                                }}
                                onClick={() => setLevel(l.level)}
                            >
                                {l.label}
                            </Button>
                        ))}
                </ButtonGroup>
            </Box>
        </Stack>
    );
}
