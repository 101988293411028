import * as React from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import cdnUrl from "../../../../CampaignClubs/cdnUrl";
import CloseableDialogTitle from "../../../../common/CloseableDialogTitle";
import { Sport } from "../../../../common/context/sport";

const tooManyPeopleImg = new URL(
    "../../../../../static/img/sv-too-many.png",
    import.meta.url,
).toString();
const noPersonImg = new URL(
    "../../../../../static/img/sv-no-person.png",
    import.meta.url,
).toString();

interface Props {
    sport: Sport;
    instructionDialogOpen: boolean;
    setInstructionDialogOpen: (open: boolean) => void;
}

export default function InstructionDialog({
    sport,
    instructionDialogOpen,
    setInstructionDialogOpen,
}: Props) {
    const objective =
        sport === "PLATFORM_TENNIS"
            ? "Practice the volley that occurs after a serve."
            : "Practice the return shot after a serve.";

    const setup1 =
        sport === "PLATFORM_TENNIS"
            ? "The trainer must be placed in the location shown below. Front axle is centered where the singles line meets the baseline. Then angle the trainer so that it points towards the center of the net."
            : "The trainer must be placed in the location shown below. The front axle is on the baseline, and the trainer is centered between the center of the baseline and the singles sideline. The trainer is pointed directly forwards.";

    const setup2 =
        sport === "PLATFORM_TENNIS"
            ? "Only one player should be on the court at a time. The following icons indicate errors for when the trainer can't find a player or there's too many players in the service box."
            : "Only one player should be on the court at a time. The following icons indicate errors for when the trainer can't find a player or there are too many players on the court.";

    const details =
        sport === "PLATFORM_TENNIS"
            ? "Serve and volley works by detecting when you serve the ball or move towards the net, then it throws a return shot. If you are picking up balls or doing something other than playing the game, please pause the workout. Otherwise, the trainer may throw a ball at you as you move around."
            : "Serve + 1 works by detecting when you serve the ball, then it throws a return shot. If you are picking up balls or doing something other than playing the game, please pause the workout. Pausing will prevent accidental throws when its not expected.";

    const trainerLocationImg = cdnUrl(
        `instructions/${sport}-sv-trainer-location.jpg`,
    );

    return (
        <Dialog open={instructionDialogOpen}>
            <CloseableDialogTitle
                onClose={() => setInstructionDialogOpen(false)}
            />
            <DialogContent>
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Objective
                    </Typography>
                    <Typography>{objective}</Typography>
                </Box>
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Setup
                    </Typography>
                    <Typography>{setup1}</Typography>
                </Box>

                <Typography mb={3}>{setup2}</Typography>

                <Stack mb={3} direction="row" spacing={2}>
                    <Box
                        component="img"
                        src={noPersonImg as string}
                        alt="No person icon"
                    />

                    <Box
                        component="img"
                        src={tooManyPeopleImg as string}
                        alt="Too many people icon"
                    />
                </Stack>

                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Important Details
                    </Typography>
                    <Typography>{details}</Typography>
                </Box>

                <Box
                    component="img"
                    src={trainerLocationImg as string}
                    alt="Trainer location"
                    sx={{ width: "100%", mb: 2 }}
                />
            </DialogContent>
        </Dialog>
    );
}
