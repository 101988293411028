import * as React from "react";

import TuneIcon from "@mui/icons-material/Tune";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithSummary } from "@volley/data";
import type { PlayMode } from "@volley/shared/apps/app-common-models";
import {
    ModuleParameters,
    type ModuleConfig,
    type ModuleState,
} from "@volley/shared/apps/module-models";
import type { JSONObject } from "@volley/shared/common-models";
import type { Position } from "@volley/shared/vision-models";

import logger from "../../../../../../log";
import { logFetchError, pairedFetchApi } from "../../../../../../util/fetchApi";
import { Sport } from "../../../../../common/context/sport";
import { usePairingContext } from "../../../../../hooks/pairingStatus";
import { LiftModal, useLift } from "../../../../../hooks/useLift";
import CaptureToast from "../../../Shared/CaptureToast";
import PlayAppBar from "../../../Shared/PlayAppBar";
import Sets from "../../../Shared/Sets";
import SpeedAdjustment from "../../../Shared/SpeedAdjustment";
import ThrowCount from "../../../Shared/ThrowCount";
import ThrowInterval from "../../../Shared/ThrowInterval";
import ThrowIntervalAfterSet from "../../../Shared/ThrowIntervalAfterSet";
import useAppWorkoutPlay from "../../../useAppWorkoutPlay";

import parametersReducer, { type ParameterAction } from "./parametersReducer";

const THROW_MARKS = [
    { value: 1, label: "1" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
];

interface Props {
    workout: AppWorkoutWithSummary;
    playMode: PlayMode;
    position?: Position;
}

export default function ModuleStartReady({
    workout,
    playMode,
    position,
}: Props): React.JSX.Element {
    const { trainerId } = usePairingContext();
    const { stop: stopLift, checkForLift } = useLift();
    const config = workout.config as unknown as ModuleConfig;
    const [parameters, dispatch] = React.useReducer(parametersReducer, {
        playMode,
        numberOfBalls: config.numberOfBalls,
        sets: config.sets,
        interval: config.interval,
        intervalAfterSet: config.intervalAfterSet,
    } as ModuleParameters);
    const lastPlayParameters = React.useRef<ModuleParameters | null>(null);
    const [advancedExpanded, setAdvancedExpanded] = React.useState(false);
    const [pendingAction, setPendingAction] = React.useState<ParameterAction<
        keyof ModuleParameters
    > | null>(null);
    const confirmedOnce = React.useRef(false);

    const {
        pause,
        start,
        stop,
        pauseDisabled,
        playDisabled,
        playState,
        playInitiated,
        workoutState,
        captureVideo,
        captureDisabled,
        captureStatus,
    } = useAppWorkoutPlay({
        workout,
        parameters: parameters as unknown as JSONObject,
        localizedPosition: position,
    });
    const moduleState = workoutState as unknown as ModuleState | null;
    const playSummary =
        playInitiated && moduleState
            ? `Set ${moduleState.currentSetNumber}`
            : "";

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        await stop();
    }, [stopLift, stop]);

    const stopWorkout = React.useCallback(async () => {
        try {
            await pairedFetchApi(trainerId, "/api/apps/workouts/stop", "POST");
        } catch (e) {
            logFetchError(e, "Could not stop module workout");
        }
    }, [trainerId]);

    const onParameterChange = React.useCallback(
        <K extends keyof ModuleParameters>(action: ParameterAction<K>) => {
            if (
                playState !== "stopped" &&
                !confirmedOnce.current &&
                lastPlayParameters.current &&
                lastPlayParameters.current[action.type] !== action.value
            ) {
                setPendingAction(action);
            } else {
                dispatch(action);
            }
        },
        [playState],
    );

    const onChangeSpeedAdjustment = React.useCallback(
        (value?: number) => {
            onParameterChange({
                type: "speedAdjustment",
                value,
            });
        },
        [onParameterChange],
    );

    const onPlayClicked = React.useCallback(async () => {
        checkForLift();
        await start();
        confirmedOnce.current = false;
        lastPlayParameters.current = parameters;
    }, [checkForLift, start, parameters]);

    React.useEffect(() => {
        onParameterChange({ type: "playMode", value: playMode });
    }, [playMode, onParameterChange]);

    // When this component unmounts, run a cleanup that stops the workout
    React.useEffect(
        () => () => {
            stopWorkout().catch((e) =>
                logger.warn(`Could not stop module workout ${e}`),
            );
        },
        [stopWorkout],
    );

    const setsSummary = parameters.sets > 1 ? `${parameters.sets} × ` : "";
    const setsInterval =
        parameters.sets > 1
            ? `, ${parameters.intervalAfterSet} sec. rests`
            : "";
    const ballsSummary = `${parameters.numberOfBalls} ball${parameters.numberOfBalls > 1 ? "s" : ""}`;

    return (
        <>
            <Typography
                variant="caption"
                textAlign="center"
                width="100%"
                component="div"
            >
                {`${setsSummary}${ballsSummary}, ${parameters.interval} sec. rate${setsInterval}`}
            </Typography>
            {!advancedExpanded && (
                <Box component="div">
                    <Button
                        fullWidth
                        size="small"
                        endIcon={<TuneIcon />}
                        disabled={playDisabled}
                        onClick={() => setAdvancedExpanded(true)}
                        sx={{ mt: 0 }}
                    >
                        Advanced
                    </Button>
                </Box>
            )}
            <Collapse in={advancedExpanded}>
                <Stack spacing={2}>
                    <ThrowCount
                        label="Shots per Set"
                        min={1}
                        max={30}
                        marks={THROW_MARKS}
                        selectedThrowCount={parameters.numberOfBalls}
                        onUserThrowCountChanged={(value) =>
                            onParameterChange({ type: "numberOfBalls", value })
                        }
                        disabled={playDisabled}
                    />
                    <Sets
                        sets={parameters.sets}
                        setSets={(value) =>
                            onParameterChange({ type: "sets", value })
                        }
                        disabled={playDisabled}
                    />
                    <ThrowInterval
                        selectedThrowInterval={parameters.interval}
                        onUserThrowIntervalChanged={(value) =>
                            onParameterChange({ type: "interval", value })
                        }
                        disabled={playDisabled}
                    />
                    <ThrowIntervalAfterSet
                        intervalAfterSet={parameters.intervalAfterSet}
                        setIntervalAfterSet={(value) =>
                            onParameterChange({
                                type: "intervalAfterSet",
                                value,
                            })
                        }
                        disabled={playDisabled}
                    />
                    <SpeedAdjustment
                        disabled={playState === "playing"}
                        value={parameters.speedAdjustment}
                        sport={workout.sport.name as Sport}
                        cacheKey={workout.id.toString()}
                        onChange={onChangeSpeedAdjustment}
                    />
                </Stack>
            </Collapse>
            <PlayAppBar
                onPlayClicked={onPlayClicked}
                onPauseClicked={() => pause()}
                pauseDisabled={pauseDisabled}
                playDisabled={playDisabled}
                showRecord={false}
                onRecordClicked={() => captureVideo()}
                playState={playState}
                playSummary={playSummary}
                recordDisabled={captureDisabled}
                recordingStatus={captureStatus}
            />
            <LiftModal
                stop={handleLiftStop}
                targetHeight={
                    playInitiated ? workout.positionHeight : undefined
                }
                message="The trainer is adjusting the head height."
            />
            <CaptureToast captureStatus={captureStatus} />
            <Dialog open={!!pendingAction}>
                <DialogTitle>Confirm Changes</DialogTitle>
                <DialogContent>
                    Adjusting this value will start the workout over at set 1.
                    Continue or cancel changes?
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        variant="contained"
                        color="info"
                        onClick={() => setPendingAction(null)}
                    >
                        Cancel Changes
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ color: "primary.main" }}
                        onClick={async () => {
                            if (!pendingAction) return;
                            dispatch(pendingAction);
                            setPendingAction(null);
                            confirmedOnce.current = true;
                            await stop();
                        }}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
