import * as React from "react";

import { usePairingContext } from "../hooks/pairingStatus";

import useDialog from "./useDialog";

export default function HardwareShutdownDialog(): React.JSX.Element | null {
    const { unpair } = usePairingContext();
    const { setDialogType } = useDialog();

    React.useEffect(() => {
        unpair();
        setDialogType("ShutdownHardwareChecklist");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
