import * as React from "react";

import ErrorIcon from "@mui/icons-material/Error";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useUpdate } from "../../hooks/useUpdate";
import useDialog from "../useDialog";

import { ActionListItem, InfoListItem } from "./listItems";

export default function AboutListItem(): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { showAvailableUpdate, versionString } = useUpdate();

    if (showAvailableUpdate) {
        return (
            <ActionListItem
                action={() => setDialogType("UserUpdate")}
                priority
                icon={<ErrorIcon color="error" fontSize="small" />}
            >
                <Stack direction="row">
                    <Typography variant="h4" component="span">
                        Update available
                    </Typography>
                </Stack>
            </ActionListItem>
        );
    }

    return (
        <InfoListItem>
            <Stack direction="row">
                <Typography variant="h4" component="span">
                    About:
                </Typography>
                <Typography
                    variant="h4"
                    component="span"
                    ml="auto"
                    sx={{
                        color: "success.main",
                    }}
                >
                    {versionString}
                </Typography>
            </Stack>
        </InfoListItem>
    );
}
