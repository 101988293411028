import {
  Box3,
  Float32BufferAttribute,
  InstancedBufferGeometry,
  InstancedInterleavedBuffer,
  InterleavedBufferAttribute,
  Sphere,
  Vector3,
  WireframeGeometry,
} from 'three'

const _box = /* @__PURE__ */ new Box3()
const _vector = /* @__PURE__ */ new Vector3()

class LineSegmentsGeometry extends InstancedBufferGeometry {
  constructor() {
    super()

    this.isLineSegmentsGeometry = true

    this.type = 'LineSegmentsGeometry'

    const positions = [-1, 2, 0, 1, 2, 0, -1, 1, 0, 1, 1, 0, -1, 0, 0, 1, 0, 0, -1, -1, 0, 1, -1, 0]
    const uvs = [-1, 2, 1, 2, -1, 1, 1, 1, -1, -1, 1, -1, -1, -2, 1, -2]
    const index = [0, 2, 1, 2, 3, 1, 2, 4, 3, 4, 5, 3, 4, 6, 5, 6, 7, 5]

    this.setIndex(index)
    this.setAttribute('position', new Float32BufferAttribute(positions, 3))
    this.setAttribute('uv', new Float32BufferAttribute(uvs, 2))
  }

  applyMatrix4(matrix) {
    const start = this.attributes.instanceStart
    const end = this.attributes.instanceEnd

    if (start !== undefined) {
      start.applyMatrix4(matrix)

      end.applyMatrix4(matrix)

      start.needsUpdate = true
    }

    if (this.boundingBox !== null) {
      this.computeBoundingBox()
    }

    if (this.boundingSphere !== null) {
      this.computeBoundingSphere()
    }

    return this
  }

  setPositions(array) {
    let lineSegments

    if (array instanceof Float32Array) {
      lineSegments = array
    } else if (Array.isArray(array)) {
      lineSegments = new Float32Array(array)
    }

    const instanceBuffer = new InstancedInterleavedBuffer(lineSegments, 6, 1) // xyz, xyz

    this.setAttribute('instanceStart', new InterleavedBufferAttribute(instanceBuffer, 3, 0)) // xyz
    this.setAttribute('instanceEnd', new InterleavedBufferAttribute(instanceBuffer, 3, 3)) // xyz

    //

    this.computeBoundingBox()
    this.computeBoundingSphere()

    return this
  }

  setColors(array, itemSize = 3) {
    let colors

    if (array instanceof Float32Array) {
      colors = array
    } else if (Array.isArray(array)) {
      colors = new Float32Array(array)
    }

    const instanceColorBuffer = new InstancedInterleavedBuffer(colors, itemSize * 2, 1) // rgb(a), rgb(a)

    this.setAttribute('instanceColorStart', new InterleavedBufferAttribute(instanceColorBuffer, itemSize, 0)) // rgb(a)
    this.setAttribute('instanceColorEnd', new InterleavedBufferAttribute(instanceColorBuffer, itemSize, itemSize)) // rgb(a)

    return this
  }

  fromWireframeGeometry(geometry) {
    this.setPositions(geometry.attributes.position.array)

    return this
  }

  fromEdgesGeometry(geometry) {
    this.setPositions(geometry.attributes.position.array)

    return this
  }

  fromMesh(mesh) {
    this.fromWireframeGeometry(new WireframeGeometry(mesh.geometry))

    // set colors, maybe

    return this
  }

  fromLineSegments(lineSegments) {
    const geometry = lineSegments.geometry

    this.setPositions(geometry.attributes.position.array) // assumes non-indexed

    // set colors, maybe

    return this
  }

  computeBoundingBox() {
    if (this.boundingBox === null) {
      this.boundingBox = new Box3()
    }

    const start = this.attributes.instanceStart
    const end = this.attributes.instanceEnd

    if (start !== undefined && end !== undefined) {
      this.boundingBox.setFromBufferAttribute(start)

      _box.setFromBufferAttribute(end)

      this.boundingBox.union(_box)
    }
  }

  computeBoundingSphere() {
    if (this.boundingSphere === null) {
      this.boundingSphere = new Sphere()
    }

    if (this.boundingBox === null) {
      this.computeBoundingBox()
    }

    const start = this.attributes.instanceStart
    const end = this.attributes.instanceEnd

    if (start !== undefined && end !== undefined) {
      const center = this.boundingSphere.center

      this.boundingBox.getCenter(center)

      let maxRadiusSq = 0

      for (let i = 0, il = start.count; i < il; i++) {
        _vector.fromBufferAttribute(start, i)
        maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(_vector))

        _vector.fromBufferAttribute(end, i)
        maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(_vector))
      }

      this.boundingSphere.radius = Math.sqrt(maxRadiusSq)

      if (isNaN(this.boundingSphere.radius)) {
        console.error(
          'THREE.LineSegmentsGeometry.computeBoundingSphere(): Computed radius is NaN. The instanced position data is likely to have NaN values.',
          this,
        )
      }
    }
  }

  toJSON() {
    // todo
  }

  applyMatrix(matrix) {
    console.warn('THREE.LineSegmentsGeometry: applyMatrix() has been renamed to applyMatrix4().')

    return this.applyMatrix4(matrix)
  }
}

export { LineSegmentsGeometry }
