import * as React from "react";

import BallAndArrow from "../../../common/icons/BallAndArrowSquare";
import BallIcon from "../../../common/icons/BallSquare";

const IMG_WIDTH = 200;
const IMG_HEIGHT = 200;

interface SpinIllustrationProps {
    imgScale: number;
    spinIntensity: number;
    spinOrientation: number;
}

export default function SpinIllustration({
    imgScale,
    spinIntensity,
    spinOrientation,
}: SpinIllustrationProps): React.JSX.Element {
    if (spinIntensity === 0) {
        return (
            <BallIcon
                style={{
                    width: imgScale * IMG_WIDTH,
                    height: imgScale * IMG_HEIGHT,
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                }}
            />
        );
    }

    return (
        <BallAndArrow
            style={{
                width: imgScale * IMG_WIDTH,
                height: imgScale * IMG_HEIGHT,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                transform: `rotate(${spinOrientation}deg)`,
            }}
        />
    );
}
