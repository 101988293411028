import * as React from "react";

import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import PersonIcon from "@mui/icons-material/Person";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import type { CameraView, CameraViewStatus } from "./types";

interface CameraViewButtonsProps {
    cameraRequestedView: CameraView;
    setCameraRequestedView: React.Dispatch<React.SetStateAction<CameraView>>;
    cameraViewStatus: CameraViewStatus;
    setCameraViewStatus: React.Dispatch<React.SetStateAction<CameraViewStatus>>;
}

export default function CameraViewButtons({
    cameraRequestedView,
    setCameraRequestedView,
    cameraViewStatus,
    setCameraViewStatus,
}: CameraViewButtonsProps): React.JSX.Element {
    const [dialOpen, setDialOpen] = React.useState(false);

    const onViewButtonClick = React.useCallback(
        (toBeRequestedCameraView: CameraView) => {
            setDialOpen(false);
            if (cameraRequestedView === toBeRequestedCameraView) {
                setCameraViewStatus("requested");
            } else {
                setCameraRequestedView(toBeRequestedCameraView);
            }
        },
        [cameraRequestedView, setCameraViewStatus, setCameraRequestedView],
    );

    return (
        <SpeedDial
            ariaLabel="camera menu"
            sx={{ position: "absolute", bottom: "5px", right: "5px" }}
            icon={<FlipCameraIosIcon />}
            onClose={() => setDialOpen(false)}
            onOpen={() => setDialOpen(true)}
            open={dialOpen}
            FabProps={{
                size: "small",
                color: "info",
            }}
        >
            <SpeedDialAction
                icon={<VisibilityIcon />}
                tooltipTitle="Above"
                tooltipOpen
                onClick={() => onViewButtonClick("bird")}
                FabProps={{
                    size: "small",
                    disabled:
                        cameraRequestedView === "bird" &&
                        cameraViewStatus === "reached",
                }}
            />
            <SpeedDialAction
                icon={<PersonIcon />}
                tooltipTitle="Player"
                tooltipOpen
                onClick={() => onViewButtonClick("person")}
                FabProps={{
                    size: "small",
                    disabled:
                        cameraRequestedView === "person" &&
                        cameraViewStatus === "reached",
                }}
            />
            <SpeedDialAction
                icon={<RestartAltIcon />}
                tooltipTitle="Trainer"
                tooltipOpen
                onClick={() => onViewButtonClick("default")}
                FabProps={{
                    size: "small",
                    disabled:
                        cameraRequestedView === "default" &&
                        cameraViewStatus === "reached",
                }}
            />
        </SpeedDial>
    );
}
