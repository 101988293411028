import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import VideocamIcon from "@mui/icons-material/Videocam";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";
import useIntercom from "../hooks/useIntercom";

import useDialog from "./useDialog";

export interface RemoveBallsDialogProps {
    onClear: () => void;
}
export default function RemoveBallsDialog({
    onClear,
}: RemoveBallsDialogProps): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { hasFault, status } = useStatus();
    const [clearing, setClearing] = React.useState(false);
    const intercom = useIntercom();

    React.useEffect(() => {
        if (clearing && !hasFault) {
            onClear();
        }
    }, [clearing, hasFault, onClear, status?.fault]);

    const onClearClick = React.useCallback(async () => {
        setClearing(true);
        try {
            await pairedFetchApi(status?.clientId, "/api/clear-fault", "POST");
        } catch (ex) {
            logFetchError(
                ex,
                "Failed to clear fault from RemoveBallsDialog > Clear Clicked",
            );
        }
    }, [status?.clientId]);

    const disableButton = React.useMemo(() => {
        // disable clear button if we're in L6
        // or either battery is not present
        const L6 = status?.trainer.powerState === "L6";
        const missingBattery =
            !status?.trainer.battery.present?.a ||
            !status?.trainer.battery.present?.b;

        return L6 || missingBattery;
    }, [status]);

    return (
        <>
            <CloseableDialogTitle
                variant="h4"
                onClose={() => setDialogType("Device")}
            >
                Remove Balls
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    <Typography variant="body1" gutterBottom textAlign="left">
                        <ul>
                            <li>For safety, remove one battery.</li>
                            <li>Remove balls and clear jam.</li>
                            <li>Re-insert battery.</li>
                        </ul>
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack width="100%">
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        loading={clearing}
                        onClick={onClearClick}
                        disabled={disableButton}
                    >
                        Ball Jam Cleared
                    </Button>
                    <Stack mt={1}>
                        <Button
                            onClick={() => {
                                window.open(
                                    "https://www.youtube.com/watch?v=8Yp8J8tvWMs",
                                    "_blank",
                                );
                            }}
                            startIcon={<VideocamIcon />}
                        >
                            Watch how
                        </Button>
                        <Button
                            onClick={() => {
                                intercom.newMessage();
                            }}
                            startIcon={<HelpIcon />}
                        >
                            Need help? Chat with support.
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </>
    );
}
