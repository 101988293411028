import * as React from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import CloseableDialogTitle from "../../../../common/CloseableDialogTitle";

const trainerLocationImg = new URL(
    "../../../../../static/img/3sd-trainer-location.png",
    import.meta.url,
).toString();

interface InstructionsDialogProps {
    open: boolean;
    onClose: () => void;
}

function InstructionDialog({
    open,
    onClose,
}: InstructionsDialogProps): React.JSX.Element {
    return (
        <Dialog open={open} onClose={onClose}>
            <CloseableDialogTitle onClose={onClose} />
            <DialogContent>
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Objective
                    </Typography>
                    <Typography>
                        Hit the ball into the kitchen. Play alone or with a
                        partner. The trainer will keep score.
                    </Typography>
                </Box>
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Setup
                    </Typography>
                    <Typography>
                        The trainer must be placed in the following location.
                        Front axle is centered on the baseline. Player(s) should
                        stand on the baseline.
                    </Typography>
                </Box>
                <Box
                    component="img"
                    src={trainerLocationImg as string}
                    alt="Trainer location"
                    sx={{ width: "100%", mb: 2 }}
                />
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Scoring
                    </Typography>
                    <Typography>
                        2 points for hitting the center of the kitchen. 1 point
                        for hitting the sides.
                    </Typography>
                </Box>
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Trainer not keeping score correctly
                    </Typography>
                    <Typography>
                        Poor lighting conditions (indoor glare, sunrise, sunset)
                        can cause the trainer to have trouble keeping score. You
                        can try moving the trainer to the other side of the
                        court, or move to a new court entirely.
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default InstructionDialog;
