import * as React from "react";

import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";

import logger from "../../../log";
import { buildLabelToVersionString } from "../../../util/buildLabel";
import { pairedFetchApi } from "../../../util/fetchApi";
import CloseableDialogTitle from "../../common/CloseableDialogTitle";
import { usePairingContext } from "../../hooks/pairingStatus";
import { useStatus } from "../../hooks/status";
import { useUpdate } from "../../hooks/useUpdate";
import useUnclosableDialog from "../common/useUnclosableDialog";
import useDialog from "../useDialog";

const MIN_BATTERY_LEVEL = 35;
const MIN_BATTERY_LEVEL_USER = 20;

interface TitleHelpButtonProps {
    addMargin?: boolean;
}

export function TitleHelpButton({
    addMargin = false,
}: TitleHelpButtonProps): React.JSX.Element {
    return (
        <IconButton
            aria-label="help"
            color="inherit"
            sx={{
                position: "absolute",
                right: addMargin ? 40 : 6,
                top: 6,
            }}
        >
            <ContactSupportIcon />
        </IconButton>
    );
}

interface UpdateFlowStartProps {
    onConfirm: () => void;
}
export function UpdateFlowStart({
    onConfirm,
}: UpdateFlowStartProps): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { availableBuilds } = useUpdate();
    const { status } = useStatus();

    const activeVersion = availableBuilds?.active.control
        ? buildLabelToVersionString(availableBuilds.active.control)
        : "N/A";

    React.useEffect(() => {
        if (status?.update?.inProgress) {
            onConfirm();
        }
    }, [onConfirm, status?.update?.inProgress]);

    return (
        <>
            <CloseableDialogTitle
                variant="h4"
                onClose={() => setDialogType(null)}
            >
                Update
            </CloseableDialogTitle>
            <DialogContent>
                <DialogContentText>
                    This will update the trainer to {activeVersion}. The length
                    of time required to update depends on network conditions.
                </DialogContentText>
                <DialogContentText variant="body1" fontWeight="bold" mt={2}>
                    Do not remove the batteries during this time or shut down
                    the trainer. This could permanently damage the trainer.
                </DialogContentText>
                <DialogContentText sx={{ mt: 4 }}>
                    Do you want to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={onConfirm}
                >
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
}

export function UserUpdateFlowStart({
    onConfirm,
}: UpdateFlowStartProps): React.JSX.Element {
    useUnclosableDialog();
    const { setDialogType } = useDialog();
    const { status } = useStatus();

    const batteryLevelGood = React.useMemo(() => {
        const batteryLevel = status?.trainer?.battery?.level;
        return batteryLevel && batteryLevel >= MIN_BATTERY_LEVEL_USER;
    }, [status?.trainer?.battery?.level]);

    React.useEffect(() => {
        if (status?.update?.inProgress) {
            onConfirm();
        }
    }, [onConfirm, status?.update?.inProgress]);

    const dismiss = React.useCallback(() => {
        setDialogType(null);
        logger.info("User chose to dismiss the update dialog", {
            trainerId: status?.clientId,
        });
    }, [setDialogType, status?.clientId]);

    return (
        <>
            <CloseableDialogTitle variant="h4">
                Update
                <TitleHelpButton />
            </CloseableDialogTitle>
            <DialogContent>
                <DialogContentText mt={2}>
                    The trainer should be updated before use. This could take up
                    to 10 minutes.&nbsp; Click &quot;Update Now&quot; to start
                    the update or &quot;Not Now&quot; to update another time.
                </DialogContentText>
                <DialogContentText variant="body1" fontWeight="bold" mt={4}>
                    Do not remove the batteries during this time or shut down
                    the trainer. This could permanently damage the trainer.
                </DialogContentText>
                {!batteryLevelGood && (
                    <DialogContentText mt={2} color="error">
                        Battery level is too low to update.
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={onConfirm}
                        disabled={!batteryLevelGood}
                        sx={{ mb: 2 }}
                    >
                        Update now
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        size="large"
                        fullWidth
                        onClick={dismiss}
                    >
                        Not now
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}

export function ForceUpdateFlowStart({
    onConfirm,
}: UpdateFlowStartProps): React.JSX.Element {
    useUnclosableDialog();
    const { status } = useStatus();

    const batteryLevelGood = React.useMemo(() => {
        const batteryLevel = status?.trainer?.battery?.level;
        return batteryLevel && batteryLevel >= MIN_BATTERY_LEVEL;
    }, [status?.trainer?.battery?.level]);

    React.useEffect(() => {
        if (status?.update?.inProgress) {
            onConfirm();
        }
    }, [onConfirm, status?.update?.inProgress]);

    return (
        <>
            <CloseableDialogTitle variant="h4">
                Update
                <TitleHelpButton />
            </CloseableDialogTitle>
            <DialogContent>
                <DialogContentText mt={2}>
                    The trainer should be updated before use. This could take up
                    to 30 minutes, depending on network conditions&nbsp; Click
                    &quot;Update Now&quot; to start the update or &quot;Not
                    Now&quot; to update another time.
                </DialogContentText>
                <DialogContentText variant="body1" fontWeight="bold" mt={4}>
                    Do not remove the batteries during this time or shut down
                    the trainer. This could permanently damage the trainer.
                </DialogContentText>
                {!batteryLevelGood && (
                    <DialogContentText mt={2} color="error">
                        Battery level is too low to update.
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={onConfirm}
                        disabled={!batteryLevelGood}
                        sx={{ mb: 2 }}
                    >
                        Update now
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}

interface UpdateFlowUpdatingProps {
    onError: () => void;
    onDone: () => void;
}

export function UpdateFlowUpdating({
    onError,
    onDone,
}: UpdateFlowUpdatingProps): React.JSX.Element {
    useUnclosableDialog();
    const [updateRequested, setUpdateRequested] = React.useState(0);
    const [updateStarted, setUpdateStarted] = React.useState(false);
    const { status, startRapidPoll, removeRapidPollCheck } = useStatus();
    const { unpair } = usePairingContext();

    React.useEffect(() => {
        const update = async () => {
            try {
                const updateState = status?.update?.updateState;
                const validStates = ["Idle", "Cancelled", "Error"];
                if (
                    updateState &&
                    validStates.includes(updateState) &&
                    !updateRequested
                ) {
                    await pairedFetchApi(
                        status?.clientId,
                        "/api/user-update",
                        "POST",
                    );
                }
            } catch (err: unknown) {
                logger.error(
                    `Error requesting update: ${(err as Error).message}`,
                    err as Error,
                );
                onError();
            }
            setUpdateRequested(performance.now());
        };

        void update();

        // we only want this to happen on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (updateRequested && !updateStarted && status?.update?.inProgress) {
            setUpdateStarted(true);
        }
    }, [status?.update?.inProgress, updateRequested, updateStarted]);

    // rapid poll status to clear dialog asap
    React.useEffect(() => {
        startRapidPoll(1_000, () => false, "userUpdate");
        return () => removeRapidPollCheck("userUpdate");
    }, [removeRapidPollCheck, startRapidPoll]);

    React.useEffect(() => {
        if (
            updateRequested &&
            updateStarted &&
            status?.update?.updateState === "Error"
        ) {
            logger.error("Error in user update flow", status?.update);
            setUpdateRequested(0);
            setUpdateStarted(false);
            onError();
        }

        if (status?.update?.updateState === "Complete") {
            setUpdateRequested(0);
            setUpdateStarted(false);
            onDone();
        }
    }, [onDone, onError, status?.update, updateRequested, updateStarted]);

    const updateText = React.useMemo(() => {
        if (!updateStarted) {
            return "Update Requested...";
        }

        if (status?.update?.updateState === "Downloading") {
            return "Downloading...";
        }

        if (status?.update?.updateState === "Installing") {
            return "Installing update...";
        }

        if (status?.update.inProgress) {
            return "Updating...";
        }

        return "";
    }, [updateStarted, status?.update?.updateState, status?.update.inProgress]);

    const downloadInfo = React.useMemo(() => {
        let progress = 0;
        const { updateState } = status?.update ?? {};
        const { loaded, total, type } = status?.update?.download ?? {};

        if (updateState === "Downloading" && total && loaded) {
            progress = (loaded / total) * 100;
        }

        return {
            progress,
            index: !type || type === "control" ? 0 : 1,
        };
    }, [status?.update]);

    return (
        <>
            <CloseableDialogTitle variant="h4">
                Update
                <TitleHelpButton />
            </CloseableDialogTitle>
            <DialogContent>
                <DialogContentText>{updateText}</DialogContentText>
                <DialogContentText variant="body1" fontWeight="bold" mt={2}>
                    Do not remove the batteries during this time or shut down
                    the trainer. This could permanently damage the trainer.
                </DialogContentText>
                {(updateRequested || status?.update.inProgress) && (
                    <DialogContentText sx={{ mt: 2 }}>
                        <CircularProgress />
                    </DialogContentText>
                )}
            </DialogContent>
            {updateStarted && status?.update?.updateState === "Downloading" && (
                <DialogContent>
                    <DialogContentText>
                        Download {downloadInfo.index + 1} of 2
                    </DialogContentText>
                    <LinearProgress
                        variant="determinate"
                        value={downloadInfo.progress}
                    />
                </DialogContent>
            )}
            <DialogActions>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => unpair()}
                        sx={{ mb: 2 }}
                    >
                        Disconnect (update will continue)
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}

export function UpdateFlowDone(): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { unpair } = usePairingContext();

    const onConfirm = React.useCallback(() => {
        unpair();
        setDialogType(null);
    }, [setDialogType, unpair]);

    return (
        <>
            <CloseableDialogTitle
                variant="h4"
                onClose={() => setDialogType(null)}
            >
                Update
                <TitleHelpButton addMargin />
            </CloseableDialogTitle>
            <DialogContent>
                <DialogContentText>
                    The update is complete. The trainer will now restart. This
                    may take a few minutes.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={onConfirm}
                >
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
}

interface UpdateFlowErrorProps {
    onDismiss: () => void;
}

export function UpdateFlowError({
    onDismiss,
}: UpdateFlowErrorProps): React.JSX.Element {
    return (
        <>
            <CloseableDialogTitle variant="h4">
                Update
                <TitleHelpButton />
            </CloseableDialogTitle>
            <DialogContent>
                <DialogContentText>Update failed.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={onDismiss}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
}

const enum UpdateFlowSteps {
    Start,
    Update,
    Done,
    Error,
}

interface UpdateFlowProps {
    force: boolean;
    // this is an indicate an optional user initiated update
    isUserUpdate: boolean;
}

export default function UpdateFlow({
    force = false,
    isUserUpdate = false,
}: UpdateFlowProps): React.JSX.Element | null {
    const [step, setStep] = React.useState<UpdateFlowSteps>(
        UpdateFlowSteps.Start,
    );
    const { status } = useStatus();
    const { setDialogType } = useDialog();

    React.useEffect(() => {
        async function pauseWorkout() {
            if (status?.clientId) {
                try {
                    await pairedFetchApi(
                        status?.clientId,
                        "/api/apps/workouts/pause",
                        "POST",
                    );
                } catch (err: unknown) {
                    logger.error("Error pausing workout", err as Error);
                }
            }
        }

        void pauseWorkout();
    }, [status?.clientId]);

    if (step === UpdateFlowSteps.Start) {
        let StartComponent = UpdateFlowStart;

        if (force) {
            StartComponent = ForceUpdateFlowStart;
        } else if (isUserUpdate) {
            StartComponent = UserUpdateFlowStart;
        }

        return (
            <StartComponent onConfirm={() => setStep(UpdateFlowSteps.Update)} />
        );
    }

    if (step === UpdateFlowSteps.Update) {
        return (
            <UpdateFlowUpdating
                onError={() => setStep(UpdateFlowSteps.Error)}
                onDone={() => setStep(UpdateFlowSteps.Done)}
            />
        );
    }

    if (step === UpdateFlowSteps.Done) {
        return <UpdateFlowDone />;
    }

    if (step === UpdateFlowSteps.Error) {
        return <UpdateFlowError onDismiss={() => setDialogType(null)} />;
    }

    return null;
}

export function ForceUpdateFlow(): React.JSX.Element {
    return <UpdateFlow force isUserUpdate={false} />;
}

// this is what we use for user updates
export function UserUpdateFlow(): React.JSX.Element {
    return <UpdateFlow force={false} isUserUpdate />;
}
