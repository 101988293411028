import * as React from "react";
import { Navigate } from "react-router";

import { AuthState, useCurrentUser } from "../hooks/currentUser";

import Loading from "./Loading";

interface Props {
    children: React.JSX.Element;
}

export default function RequireAdmin({ children }: Props): React.JSX.Element {
    const { isAdmin, authState } = useCurrentUser();

    if (authState === AuthState.LOADING) {
        return <Loading />;
    }

    return isAdmin() ? children : <Navigate to="/" />;
}
