import * as React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { round } from "../../../../util/positionUtil";
import { pluralize } from "../../../../util/text";

import AccordionSlider from "./AccordionSlider";

function range(start: number, end: number, step: number): number[] {
    const result = [];
    for (let i = start; i <= end; i += step) {
        result.push(i);
    }
    return result;
}

const valueLabelFormat = (v: number) => `${v} sec`;

interface Props {
    firstBounce: number | null;
    defaultInterval: number;
    selectedInterval: number;
    onIntervalChanged: (value: number) => void;
}

const FEED_STEP = 0.25;

export default function Pad({
    firstBounce,
    defaultInterval,
    selectedInterval,
    onIntervalChanged,
}: Props): React.JSX.Element {
    const theme = useTheme();

    const roundedFirstBounce = React.useMemo(
        () => (firstBounce ? round(firstBounce, 0.25) : null),
        [firstBounce],
    );
    const diff = React.useMemo(
        () => selectedInterval - defaultInterval,
        [selectedInterval, defaultInterval],
    );

    const rateMarks = React.useMemo(
        () =>
            range(1, 10, 1).map((v) => {
                if (v % 2 === 0 || v === defaultInterval || v === firstBounce) {
                    return { label: `${v}`, value: v };
                }

                return { label: "", value: v };
            }),
        [firstBounce, defaultInterval],
    );

    const sliderStyles = React.useMemo(() => {
        const darkGrey = theme.palette.grey[500];
        const lightGrey = theme.palette.grey[200];
        const darkGreyBreak = (defaultInterval - 1) * (100 / 9);
        const baseStyle: Record<string, unknown> = {
            zIndex: 1,
            "& .MuiSlider-rail": {
                opacity: 1,
                background: `linear-gradient(90deg, ${lightGrey} ${darkGreyBreak}%, ${darkGrey} 0%)`,
            },
            "& .MuiSlider-valueLabel": {
                fontSize: "32px",
            },
        };
        range(1, 10, 1).forEach((v) => {
            const key = `& .MuiSlider-markLabel[data-index="${v}"]`;
            let color = v >= defaultInterval ? "primary.main" : "grey";
            let fontWeight = v >= defaultInterval ? "400" : "100";
            if (v + 1 === defaultInterval) {
                color = "primary.light";
                fontWeight = "800";
            }
            if (v + 1 === round(firstBounce ?? 0)) {
                color = "secondary.main";
                fontWeight = "800";
            }
            baseStyle[key] = { color, fontWeight };
        });
        return baseStyle;
    }, [defaultInterval, theme, firstBounce]);

    return (
        <Stack spacing={2} px={2}>
            <Typography>
                The default feed rate is{" "}
                <Typography component="span" color="info.main" fontWeight={800}>
                    {defaultInterval}
                </Typography>
                {" seconds."}
            </Typography>

            {roundedFirstBounce && (
                <Typography>
                    Time to first bounce is{" "}
                    <Typography
                        component="span"
                        color="secondary.main"
                        fontWeight={800}
                    >
                        {roundedFirstBounce}
                    </Typography>
                    {` ${pluralize(roundedFirstBounce, "second")}`}
                </Typography>
            )}
            <AccordionSlider
                min={1}
                max={10}
                marks={rateMarks}
                step={FEED_STEP}
                value={selectedInterval}
                getAriaValueText={(v) => valueLabelFormat(v)}
                valueLabelFormat={(v) => valueLabelFormat(v)}
                valueLabelDisplay="auto"
                track={false}
                onChange={(_, v) =>
                    onIntervalChanged(Math.max(v as number, defaultInterval))
                }
                sx={sliderStyles}
            />
            {selectedInterval > defaultInterval && (
                <Typography>
                    This throw will pause{" "}
                    <Typography
                        component="span"
                        color="info.main"
                        fontWeight={800}
                    >
                        {diff}
                    </Typography>
                    {` ${pluralize(diff, "second")} longer than default before the next throw.`}
                </Typography>
            )}
            {selectedInterval <= defaultInterval && (
                <Typography>
                    This throw will use the default feed rate.
                </Typography>
            )}
        </Stack>
    );
}
