import * as React from "react";
import { useNavigate, useParams } from "react-router";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    ServeResult,
    TargetAOI,
} from "@volley/shared/apps/serveandvolley-models";

import Court from "../Court";

import ChartSummary from "./ChartSummary";
import NoHistory from "./NoHistory";
import SessionSummary from "./SessionSummary";
import SummaryCards from "./SummaryCards";
import { ServeAndVolleyResultSummary } from "./models";
import useSessionsHistory from "./useSessionsHistory";

// In = in the target AOI
// Miss = in the service box but not in the target AOI
// Out = out of the service box
function calculateSummary(
    serves: ServeResult[] = [],
): ServeAndVolleyResultSummary {
    const summary = serves.reduce(
        (acc, result) => {
            if (result.inAoi) {
                acc.in += 1;
            } else if (result.inServiceBox) {
                acc.miss += 1;
            } else {
                acc.out += 1;
            }
            acc.total += 1;

            if (typeof result.speed === "number") {
                acc.totalSpeed += result.speed;
                acc.speedCount += 1;
            }
            return acc;
        },
        { miss: 0, in: 0, out: 0, total: 0, totalSpeed: 0, speedCount: 0 },
    );

    const avgSpeed =
        summary.speedCount > 0
            ? summary.totalSpeed / summary.speedCount
            : undefined;

    return {
        miss: summary.miss,
        in: summary.in,
        out: summary.out,
        total: summary.total,
        avgSpeed,
    };
}

export default function ServeAndVolleyHistory(): JSX.Element {
    const navigate = useNavigate();
    const { appId, id: workoutId } = useParams();

    const { history, activeSessionId, setActiveSessionId } = useSessionsHistory(
        { workoutId: Number(workoutId), count: 10 },
    );

    const [selectedAOI, setSelectedAOI] = React.useState<TargetAOI | null>(
        null,
    );

    // Active session selected by the user
    const activeSession = React.useMemo(() => {
        if (!history) return null;

        return history.find((s) => s.id === activeSessionId);
    }, [activeSessionId, history]);

    // We score an entire session, not just a single play so first we need to
    // combine serves from all the workout plays from the active session
    const activeSessionServes = React.useMemo(() => {
        return activeSession?.WorkoutPlay.reduce((acc, workoutPlay) => {
            if (workoutPlay.workoutResult) {
                return acc.concat(workoutPlay.workoutResult.resultData);
            }
            return acc;
        }, [] as ServeResult[]);
    }, [activeSession]);

    const workoutResultSummary = React.useMemo(
        () => calculateSummary(activeSessionServes),
        [activeSessionServes],
    );
    const activeSessionAOIs = React.useMemo(() => {
        if (!activeSession) return [];
        const allAOIs = activeSession.WorkoutPlay.reduce((acc, workoutPlay) => {
            if (
                workoutPlay.params.targetAOIs &&
                workoutPlay.workoutResult &&
                workoutPlay.workoutResult.resultData.length > 0
            ) {
                return acc.concat(workoutPlay.params.targetAOIs);
            }
            return acc;
        }, [] as TargetAOI[]);

        // Create a map keyed by name to ensure uniqueness
        return Array.from(
            new Map(allAOIs.map((aoi) => [aoi.name, aoi])).values(),
        );
    }, [activeSession]);

    React.useEffect(() => {
        if (activeSessionAOIs.length === 1) {
            setSelectedAOI(activeSessionAOIs[0]);
        }
    }, [activeSessionAOIs]);

    // if no active AOI, all serves are highlighted
    // if an AOI is selected, only serves targeting that AOI are highlighted
    const highlightServes = React.useMemo(() => {
        if (!selectedAOI) {
            return activeSessionServes;
        }

        if (!activeSessionServes) {
            return [];
        }

        return activeSessionServes.filter((result) => {
            if (typeof result.target === "string") {
                return result.target === selectedAOI.name;
            }

            return result.target.name === selectedAOI.name;
        });
    }, [activeSessionServes, selectedAOI]);

    const filteredWorkoutResultSummary = React.useMemo(
        () => calculateSummary(highlightServes),
        [highlightServes],
    );

    const handleSessionClick = React.useCallback(
        (sessionId: number) => {
            setActiveSessionId(sessionId);
            setSelectedAOI(null);
        },
        [setActiveSessionId],
    );

    const handleAOIClick = React.useCallback((targetAOI: TargetAOI | null) => {
        setSelectedAOI(targetAOI);
    }, []);

    if (!history) {
        return <Typography>Loading...</Typography>;
    }

    if (history.length === 0) {
        return <NoHistory />;
    }

    return (
        <>
            <Stack
                sx={{
                    position: "relative",
                    overscrollBehavior: "contain",
                    backgroundColor: "background.default",
                    padding: 2,
                }}
            >
                {/* header */}
                <Box display="flex" justifyContent="center">
                    <Typography variant="h4">
                        Serve and Volley History
                    </Typography>
                </Box>

                {/* session score horizontal scroll */}
                <Stack direction="row" spacing={2} mt={1} alignItems="center">
                    <Box sx={{ width: "50px" }}>
                        <Typography variant="h5">Volley Score</Typography>
                    </Box>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            width: "100%",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                            maskImage:
                                "linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
                        }}
                    >
                        {history.map((session) => (
                            <SessionSummary
                                key={session.id}
                                session={session}
                                active={session.id === activeSessionId}
                                onClick={() => handleSessionClick(session.id)}
                            />
                        ))}
                    </Stack>
                </Stack>

                {/* session details */}
                {activeSession && (
                    <Stack justifyContent={"center"}>
                        <Box display="flex" justifyContent="center" mt={1}>
                            <Typography variant="body2">
                                {new Date(
                                    activeSession.startTime,
                                ).toLocaleString()}
                            </Typography>
                        </Box>

                        <Box pt={4}>
                            <Typography variant="h4">Serve Results</Typography>
                            <Box pt={2}>
                                <ChartSummary summary={workoutResultSummary} />
                            </Box>
                            {workoutResultSummary.avgSpeed && (
                                <Box
                                    pt={2}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Typography variant="body1">
                                        Average speed:{" "}
                                        {Math.round(
                                            workoutResultSummary.avgSpeed,
                                        )}{" "}
                                        MPH
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <Box pt={2}>
                            <Typography variant="h4">Serve Map</Typography>

                            <Stack
                                pt={2}
                                display="flex"
                                justifyContent="center"
                                direction="column"
                            >
                                {activeSessionAOIs.length > 1 && (
                                    <Stack
                                        mb={1}
                                        direction="row"
                                        spacing={1}
                                        justifyContent="center"
                                    >
                                        <Chip
                                            label="View All"
                                            onClick={() => handleAOIClick(null)}
                                            color={
                                                selectedAOI === null
                                                    ? "primary"
                                                    : "default"
                                            }
                                        />
                                        {activeSessionAOIs.map((aoi) => (
                                            <Chip
                                                key={aoi.name}
                                                label={aoi.name}
                                                onClick={() =>
                                                    handleAOIClick(aoi)
                                                }
                                                color={
                                                    selectedAOI?.name ===
                                                    aoi.name
                                                        ? "primary"
                                                        : "default"
                                                }
                                            />
                                        ))}
                                    </Stack>
                                )}
                                <Court
                                    serves={activeSessionServes ?? []}
                                    highlightServes={highlightServes}
                                    targetAOIs={activeSessionAOIs ?? []}
                                    highlightAOI={selectedAOI || undefined}
                                    onAOIClick={
                                        (targetAOI) =>
                                            handleAOIClick(
                                                targetAOI as TargetAOI,
                                            ) // TODO: fix type
                                    }
                                />
                            </Stack>
                        </Box>

                        <Box pt={2} display="flex" justifyContent="center">
                            <SummaryCards
                                summary={
                                    selectedAOI
                                        ? filteredWorkoutResultSummary
                                        : workoutResultSummary
                                }
                            />
                        </Box>
                    </Stack>
                )}
            </Stack>
            <Box sx={{ position: "fixed", bottom: "20px", right: "20px" }}>
                <Fab
                    size="large"
                    color="secondary"
                    onClick={() => {
                        navigate(
                            `/content/apps/workouts/plugin/play/${appId}/${workoutId}`,
                            { replace: true },
                        );
                    }}
                >
                    Play
                </Fab>
            </Box>
        </>
    );
}
