import * as React from "react";
import { useNavigate, useParams } from "react-router";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutWithSummary,
} from "@volley/data";
import { ServePosition } from "@volley/shared/apps/app-common-models";
import {
    BaselineDepthAppConfig,
    BaselineDepthParameters,
} from "@volley/shared/apps/baselinedepth-models";
import { JSONObject } from "@volley/shared/common-models";

import { logFetchError } from "../../../../../util/fetchApi";
import Loading from "../../../../common/Loading";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import { useCurrentUser } from "../../../../hooks/currentUser";
import { useStatus } from "../../../../hooks/status";
import { useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import { useTrainerFeatures } from "../../../../hooks/useTrainerFeatures";
import OptionSelector from "../../Shared/OptionSelector";
import PlayAppBar from "../../Shared/PlayAppBar";
import ThrowCount from "../../Shared/ThrowCount";
import ThrowInterval from "../../Shared/ThrowInterval";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import LocalizingDialog from "../10-serve-and-volley/LocalizingDialog";
import WorkoutErrorDialog from "../4-multi-shot/play/ErrorDialog";
import { overlayDefaultVisionPipelineConfig } from "../shared/app-common";

import Court from "./Court";

const defaultParams: BaselineDepthParameters = {
    servePosition: "deuce",
    shots: [],
    targets: [],
    shotCount: 5,
    shotInterval: 5,
    detectRegions: [],
};

type ParamsAction =
    | { type: "servePosition"; value: ServePosition }
    | { type: "shotCount"; value: number }
    | { type: "shotInterval"; value: number };

function paramsReducer(
    state: BaselineDepthParameters,
    action: ParamsAction,
): BaselineDepthParameters {
    switch (action.type) {
        case "servePosition":
            return { ...state, servePosition: action.value };
        case "shotCount":
            return { ...state, shotCount: action.value };
        case "shotInterval":
            return { ...state, shotInterval: action.value };
        default:
            return state;
    }
}

type KnownBallCount = "5" | "10" | "15" | "20";
const KnownBallCounts: Record<KnownBallCount, number> = {
    5: 5,
    10: 10,
    15: 15,
    20: 20,
};

const ballCountMarks = Object.keys(KnownBallCounts).map((k) => ({
    label: k,
    value: KnownBallCounts[k as KnownBallCount],
}));

interface BaselineDepthProps {
    workout: AppWorkoutWithSummary;
    trainerFeatures: string[];
}

function BaselineDepth({
    workout,
    trainerFeatures,
}: BaselineDepthProps): React.JSX.Element {
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        defaultParams,
    );
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const [usePlannedPosition, setUsePlannedPosition] = React.useState(false);
    const { status } = useStatus();
    const { isAdmin } = useCurrentUser();

    const { position, isVisionStarting, cancel } = usePosition();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const { checkForLift } = useLift();

    // we use this ref to track if the settings have been changed and we should start a new game
    const modifiedRef = React.useRef(true);

    // we use this ref to track if a stop has been requested
    const stopRequestedRef = React.useRef(false);

    // we use this ref to force localization
    const forceLocalizationRef = React.useRef(true);

    const [localizingDialogOpen, setLocalizingDialogOpen] =
        React.useState(false);

    const appConfig = React.useMemo<BaselineDepthAppConfig>(() => {
        const ac = workout.config as unknown as BaselineDepthAppConfig;
        if (trainerFeatures.includes("serveAndVolleyCloudDefaults")) {
            // Use vision config defaults to replace any missing DB values
            overlayDefaultVisionPipelineConfig(ac, workout.sport.name);
        }
        return ac;
    }, [workout, trainerFeatures]);

    const workoutPosition = React.useMemo(() => {
        return appConfig.trainerPosition[paramsState.servePosition];
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const workoutParams: BaselineDepthParameters = React.useMemo(() => {
        const shots = appConfig.shots["1"]; // TODO (cbley): is difficulty level required?
        const targets = appConfig.targetAOIs[paramsState.servePosition];
        const detectRegions =
            appConfig.detectRegions[paramsState.servePosition];
        const visionPipelineConfig = trainerFeatures.includes(
            "serveAndVolleyCloudDefaults",
        )
            ? appConfig.visionPipelineConfig
            : undefined;

        return {
            ...paramsState,
            shots,
            targets,
            detectRegions,
            visionPipelineConfig,
            allowedLocalizationXYDelta: appConfig.allowedLocalizationXYDelta,
            allowedLocalizationHeightDelta:
                appConfig.allowedLocalizationHeightDelta,
        };
    }, [appConfig, paramsState, trainerFeatures]);

    const {
        start,
        playState,
        playDisabled,
        pauseDisabled,
        captureDisabled,
        captureVideo,
        captureStatus,
        stop: stopWorkout,
        workoutState,
    } = useAppWorkoutPlay({
        workout: { ...workout, ...workoutPosition },
        parameters: workoutParams as unknown as JSONObject,
        localizedPosition: usePlannedPosition ? undefined : position,
    });

    const handleStopWorkout = React.useCallback(async () => {
        stopRequestedRef.current = true;
        await stopWorkout();
    }, [stopWorkout]);

    // Start the workout when the play button is clicked
    React.useEffect(() => {
        if (playClicked && workout) {
            setPlayClicked(false);
            checkForLift();
            void start();
        }
    }, [checkForLift, playClicked, start, workout]);

    const handlePlayClicked = React.useCallback(() => {
        stopRequestedRef.current = false;
        setLocalizingDialogOpen(true);
    }, []);

    const plannedTrainerPosition = React.useMemo(() => {
        const pos = appConfig.trainerPosition[paramsState.servePosition];
        return {
            x: pos.positionX,
            y: pos.positionY,
            yaw: pos.positionYaw,
            heightIn: pos.positionHeight,
        };
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const workoutForVisualizer = React.useMemo(
        () =>
            localizingDialogOpen
                ? undefined
                : {
                      trainer: plannedTrainerPosition,
                      shots: [],
                      AOIs: [],
                      player: [],
                  },
        [localizingDialogOpen, plannedTrainerPosition],
    );

    const workoutForLocalizingDialog = React.useMemo(
        () => ({
            trainer: plannedTrainerPosition,
            localized: position && {
                ...position,
                heightIn: plannedTrainerPosition.heightIn,
            },
            player: [],
            shots: [],
            AOIs: [],
        }),
        [plannedTrainerPosition, position],
    );

    const targetAOIs = appConfig.targetAOIs[paramsState.servePosition].map(
        (t) => t.aoi,
    );

    return (
        <>
            <Stack
                spacing={0}
                sx={{
                    backgroundColor: "background.default",
                    marginLeft: "-8px",
                    marginRight: "-8px",
                }}
            >
                <div>
                    <h1>Baseline Depth</h1>
                </div>

                <Typography variant="h3" mb={2} color="primary.main">
                    Setup
                </Typography>

                <Box sx={{ mb: 4 }}>
                    <OptionSelector
                        disabled={playState === "playing"}
                        label="Server Position"
                        labelWrapperSx={{ flex: 1 }}
                        toggleButtonSx={{ flex: 2 }}
                        options={[
                            { value: "ad", label: "Ad" },
                            { value: "deuce", label: "Deuce" },
                        ]}
                        selected={paramsState.servePosition}
                        setOption={(value) => {
                            if (value !== null) {
                                paramsDispatch({
                                    type: "servePosition",
                                    value,
                                });
                                modifiedRef.current = true;
                                // force localization if we change the serve position
                                forceLocalizationRef.current = true;
                            }
                        }}
                    />
                </Box>

                <Box mb={1}>
                    <ThrowCount
                        label="Shots"
                        disabled={playState === "playing"}
                        selectedThrowCount={paramsState.shotCount}
                        onUserThrowCountChanged={(number) => {
                            paramsDispatch({
                                type: "shotCount",
                                value: number,
                            });
                            modifiedRef.current = true;
                        }}
                        marks={ballCountMarks}
                        min={5}
                        max={20}
                    />
                </Box>

                <Box mb={1}>
                    <ThrowInterval
                        disabled={playState === "playing"}
                        selectedThrowInterval={paramsState.shotInterval}
                        onUserThrowIntervalChanged={(value) => {
                            paramsDispatch({
                                type: "shotInterval",
                                value,
                            });
                            modifiedRef.current = true;
                        }}
                    />
                </Box>

                <Box mb={1}>
                    {workoutForVisualizer && plannedTrainerPosition && (
                        <ResizableWorkoutVisualizer
                            workout={workoutForVisualizer}
                            positionProximity="Unavailable"
                            maxHeight={225}
                        />
                    )}
                </Box>

                <Box
                    component="div"
                    mb={8}
                    display="flex"
                    justifyContent="center"
                    sx={{
                        marginLeft: "-8px",
                        marginRight: "-8px",
                        paddingBottom: "80px",
                    }}
                >
                    <Court
                        targetAOIs={targetAOIs}
                        detections={workoutState?.results || []}
                    />
                </Box>

                <Box>
                    <Typography variant="h5" mb={2} color="primary.main">
                        Play State
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "monospace",
                            whiteSpace: "pre-wrap",
                            fontSize: "0.6rem",
                        }}
                    >
                        {JSON.stringify(playState, null, 2)}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="h5" mb={2} color="primary.main">
                        Workout State
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "monospace",
                            whiteSpace: "pre-wrap",
                            fontSize: "0.6rem",
                        }}
                    >
                        {JSON.stringify(workoutState, null, 2)}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="h5" mb={2} color="primary.main">
                        Params State
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "monospace",
                            whiteSpace: "pre-wrap",
                            fontSize: "0.6rem",
                        }}
                    >
                        {JSON.stringify(paramsState, null, 2)}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="h5" mb={2} color="primary.main">
                        Workout Params
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "monospace",
                            whiteSpace: "pre-wrap",
                            fontSize: "0.6rem",
                        }}
                    >
                        {JSON.stringify(workoutParams, null, 2)}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="h5" mb={2} color="primary.main">
                        App Config
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "monospace",
                            whiteSpace: "pre-wrap",
                            fontSize: "0.6rem",
                        }}
                    >
                        {JSON.stringify(appConfig, null, 2)}
                    </Typography>
                </Box>

                <LocalizingDialog
                    dialogOpen={localizingDialogOpen}
                    onLocalized={(result) => {
                        setLocalizingDialogOpen(false);
                        if (result === "good") {
                            forceLocalizationRef.current = false;
                            setPlayClicked(true);
                        } else {
                            forceLocalizationRef.current = true;
                        }
                    }}
                    onCanceled={() => {
                        cancel();
                        setLocalizingDialogOpen(false);
                    }}
                    onUsePlanned={() => {
                        setUsePlannedPosition(true);
                        setLocalizingDialogOpen(false);
                        setPlayClicked(true);
                    }}
                    plannedPosition={plannedTrainerPosition}
                    force={forceLocalizationRef.current}
                    workout={workoutForLocalizingDialog}
                    allowedLocalizationXYDelta={
                        appConfig.allowedLocalizationXYDelta
                    }
                    allowedLocalizationHeightDelta={
                        appConfig.allowedLocalizationHeightDelta
                    }
                />

                <PlayAppBar
                    onPauseClicked={handleStopWorkout}
                    onPlayClicked={handlePlayClicked}
                    pauseDisabled={pauseDisabled}
                    playDisabled={visionUnavailable || playDisabled}
                    playState={playState}
                    showRecord={isAdmin()}
                    onRecordClicked={() => captureVideo()}
                    playSummary={""} // TODO summary text
                    recordDisabled={visionUnavailable || captureDisabled}
                    recordingStatus={captureStatus}
                />
            </Stack>
        </>
    );
}

export default function BaselineDepthRoot() {
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const [error, setError] = React.useState<string | null>(null);

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const idMaybe = React.useMemo(() => parseInt(id ?? "", 10), [id]);

    const { getWorkout } = useAppWorkouts();
    const trainerFeatures = useTrainerFeatures();

    React.useEffect(() => {
        getWorkout(idMaybe)
            .then((loaded) => {
                if (loaded) {
                    setWorkout(loaded);
                } else {
                    setError("Unable to load baselined depth workout");
                }
            })
            .catch((e) =>
                logFetchError(e, "Failed to l oad baseline depth workout"),
            );
    }, [idMaybe, getWorkout]);

    if (error) {
        return (
            <WorkoutErrorDialog
                buttonText="Back to Workouts"
                header="Workout not Found"
                text={error}
                onClick={() => navigate("/")}
            />
        );
    }

    if (!workout) {
        return <Loading />;
    }

    return (
        <BaselineDepth workout={workout} trainerFeatures={trainerFeatures} />
    );
}
