import * as React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router";

export default function AuthAction(): React.JSX.Element {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        const mode = queryParams.get("mode");
        const code = queryParams.get("oobCode");
        const apiKey = queryParams.get("apiKey");

        if (mode === "resetPassword") {
            navigate("/auth/reset-password", {
                state: {
                    code,
                    apiKey,
                },
            });
        }
    }, [queryParams, navigate]);

    // we shouldn't get here, but if we do navigate to root
    return <Navigate to="/" replace />;
}
