import * as React from "react";

import logger from "../../log";
import {
    buildLabelToVersionString,
    expandLabelToComponents,
    isValidBuildLabel,
    isVersionGreater,
} from "../../util/buildLabel";
import { logFetchError, pairedFetchApi } from "../../util/fetchApi";

import { usePairingContext } from "./pairingStatus";
import { useStatus } from "./status";
import { useTrainerFeatures } from "./useTrainerFeatures";

interface UpdateValue {
    availableBuilds: AvailableBuilds | null;
    buildsReadyToInstall: boolean;
    hasAvailableBuilds: boolean;
    showAvailableUpdate: boolean | null;
    updateRequired: boolean;
    versionString: string;
}

export const UpdateContext = React.createContext<UpdateValue>({
    availableBuilds: null,
    buildsReadyToInstall: false,
    hasAvailableBuilds: false,
    showAvailableUpdate: null,
    updateRequired: false,
    versionString: "",
});

interface AvailableBuilds {
    active: {
        control: string | null;
        vision: string | null;
    };
    minSupported: {
        control: string | null;
        vision: string | null;
    };
}

interface Props {
    children: React.ReactNode;
}
export function UpdateProvider({ children }: Props) {
    const { status } = useStatus();
    const trainerFeatures = useTrainerFeatures();
    const { status: pairingStatus } = usePairingContext();
    const [availableBuilds, setAvailableBuilds] =
        React.useState<AvailableBuilds | null>(null);

    const checkVersion = React.useCallback(async () => {
        if (status?.clientId) {
            try {
                const builds = await pairedFetchApi<AvailableBuilds>(
                    status?.clientId,
                    "/api/update/check-version",
                );
                if (builds) {
                    setAvailableBuilds(builds);
                }
            } catch (e) {
                logFetchError(e);
            }
        }
    }, [status?.clientId]);

    // Check version on mount and when pairing status changes, but only if we're paired
    React.useEffect(() => {
        if (pairingStatus === "paired") {
            checkVersion().catch(() => {
                logger.error("Could not check version");
            });
        }
    }, [checkVersion, pairingStatus]);

    const buildsReadyToInstall = React.useMemo<boolean>(() => {
        if (!status?.update?.systems) return false;

        const controlUpdateStatus = status.update.systems.control;
        const visionUpdateStatus = status.update.systems.vision;

        if (!controlUpdateStatus || !visionUpdateStatus) return false;

        const downloaded =
            controlUpdateStatus.readyToInstall &&
            visionUpdateStatus.readyToInstall;

        const outOfDate =
            controlUpdateStatus.outOfDate || visionUpdateStatus.outOfDate;

        // we can't know if the vision software is out of date if we don't know the version
        if (status.vision.visionSoftwareVersion === "Unknown") {
            return false;
        }

        // `outOfDate` will be wrong for 3.10.1 trainers because the `name` of builds
        // was changed to use control style software labels instead of version strings,
        // however the version reported by vision is still using version strings.
        //
        // check if the next build is a valid build label and the current build is not
        // if so, we need to convert the next build label to a version string and compare
        if (
            visionUpdateStatus.next &&
            visionUpdateStatus.current &&
            isValidBuildLabel(visionUpdateStatus.next) &&
            !isValidBuildLabel(visionUpdateStatus.current)
        ) {
            const versionStringNext = buildLabelToVersionString(
                visionUpdateStatus.next,
            );

            return (
                downloaded && versionStringNext !== visionUpdateStatus.current
            );
        }

        return downloaded && !!outOfDate;
    }, [status?.update?.systems, status?.vision?.visionSoftwareVersion]);

    const updateRequired = React.useMemo(() => {
        // If there are no available builds, we can't determine if an update is required
        if (!availableBuilds) {
            return false;
        }

        let controlRequiresUpdate = false;
        if (
            availableBuilds.minSupported.control &&
            status?.softwareBuildLabel
        ) {
            const components = expandLabelToComponents(
                status.softwareBuildLabel,
            );
            const version = `${components.major}.${components.minor}.${components.patch}`;

            const minComponents = expandLabelToComponents(
                availableBuilds.minSupported.control,
            );
            const minVersion = `${minComponents.major}.${minComponents.minor}.${minComponents.patch}`;

            try {
                controlRequiresUpdate = isVersionGreater(minVersion, version);
            } catch {
                logger.error("Error comparing control versions", {
                    minVersion,
                    version,
                });
            }
        }

        let visionRequiresUpdate = false;
        if (
            availableBuilds.minSupported.vision &&
            status?.vision?.visionSoftwareVersion
        ) {
            try {
                visionRequiresUpdate = isVersionGreater(
                    availableBuilds.minSupported.vision,
                    status.vision.visionSoftwareVersion,
                );
            } catch {
                logger.error("Error comparing vision versions", {
                    minVersion: availableBuilds.minSupported.vision,
                    version: status.vision.visionSoftwareVersion,
                });
            }
        }

        return controlRequiresUpdate || visionRequiresUpdate;
    }, [
        availableBuilds,
        status?.softwareBuildLabel,
        status?.vision?.visionSoftwareVersion,
    ]);

    const showAvailableUpdate = React.useMemo(() => {
        if (!status?.update?.systems) return false;
        // This could be due to a fault or the vision computer is not fully up and running yet
        if (
            status?.vision?.visionSoftwareVersion?.toLocaleLowerCase() ===
            "unknown"
        )
            return false;

        const { control, vision } = status.update.systems;
        if (!control || !vision) return false;

        const isControlUpdateReady =
            control.outOfDate && control.readyToInstall;
        const isVisionUpdateReady = vision.outOfDate && vision.readyToInstall;
        const isUserUpdateSupported = trainerFeatures.includes("userUpdate");

        return (
            isControlUpdateReady && isVisionUpdateReady && isUserUpdateSupported
        );
    }, [status, trainerFeatures]);

    const hasAvailableBuilds = React.useMemo(() => {
        if (!availableBuilds || !status) {
            return false;
        }

        const {
            active: { control, vision },
        } = availableBuilds;
        if (control !== null && control !== status.softwareBuildLabel) {
            return true;
        }

        if (vision !== null && vision !== status.vision.visionSoftwareVersion) {
            return true;
        }

        return false;
    }, [availableBuilds, status]);

    const versionString = React.useMemo(
        () =>
            status?.softwareBuildLabel
                ? buildLabelToVersionString(status.softwareBuildLabel)
                : "",
        [status?.softwareBuildLabel],
    );

    const value = React.useMemo(
        () => ({
            availableBuilds,
            buildsReadyToInstall,
            hasAvailableBuilds,
            showAvailableUpdate,
            updateRequired,
            versionString,
        }),
        [
            availableBuilds,
            buildsReadyToInstall,
            hasAvailableBuilds,
            showAvailableUpdate,
            updateRequired,
            versionString,
        ],
    );

    return (
        <UpdateContext.Provider value={value}>
            {children}
        </UpdateContext.Provider>
    );
}

export function useUpdate(): UpdateValue {
    return React.useContext(UpdateContext);
}
