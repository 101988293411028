import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";

import { useLogMountTime } from "../../log";
import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import EmptyBallBinIcon from "../common/icons/EmptyBallBinIcon";
import { useStatus } from "../hooks/status";
import { useLift } from "../hooks/useLift";

import useUnclosableDialog from "./common/useUnclosableDialog";

const RAPID_POLL = 1000;

export interface RefillBallBinDialogProps {
    onFilled: () => void;
    onLowerHead: () => void;
    manual?: boolean;
}
export default function RefillBallBinDialog({
    onFilled,
    onLowerHead,
    manual = false,
}: RefillBallBinDialogProps): React.JSX.Element {
    useLogMountTime("RefillBallBinDialog");
    useUnclosableDialog();

    const { status, startRapidPoll, removeRapidPollCheck } = useStatus();
    const [loading, setLoading] = React.useState(false);
    const [loadingLower, setLoadingLower] = React.useState(false);
    const filled = React.useRef(false);
    const { height, refillHeight, isLifting } = useLift();
    const lowEnough = React.useMemo(
        () => height <= refillHeight,
        [height, refillHeight],
    );

    const hasEmptyBinFault = React.useMemo(
        () => status?.fault?.failures.some((f) => f.type === "BallBinEmpty"),
        [status],
    );

    // rapid poll status to clear dialog asap
    React.useEffect(() => {
        startRapidPoll(
            RAPID_POLL,
            (c) => !c.fault?.failures.some((f) => f.type === "BallBinEmpty"),
            "ballbin",
        );
        return () => removeRapidPollCheck("ballbin");
    }, [startRapidPoll, removeRapidPollCheck]);

    React.useEffect(() => {
        // if user has clicked filled and we no longer have
        // a BallBinEmpty fault, close modal.
        if (filled.current && !hasEmptyBinFault) {
            onFilled();
        }
    }, [hasEmptyBinFault, onFilled]);

    const onLowerHeadClick = React.useCallback(async () => {
        setLoadingLower(true);
        try {
            await pairedFetchApi(status?.clientId, "/api/clear-fault", "POST");
            onLowerHead();
        } catch (ex) {
            logFetchError(
                ex,
                "Failed to clear fault from RefillBallBin > Lower Head Clicked",
            );
        }
    }, [onLowerHead, status?.clientId]);

    const onClick = React.useCallback(async () => {
        // if this is a manual refill, we don't need to clear the fault
        if (manual) {
            onFilled();
            return;
        }

        // otherwise, clear the fault and wait for it to clear
        setLoading(true);
        filled.current = true;

        try {
            await pairedFetchApi(status?.clientId, "/api/clear-fault", "POST");
        } catch (ex) {
            logFetchError(
                ex,
                "Failed to clear fault from RefillBallBin > Resume Clicked",
            );
        }
    }, [manual, onFilled, status?.clientId]);

    return (
        <>
            <CloseableDialogTitle variant="h4">
                Ball Bin Empty
            </CloseableDialogTitle>
            <DialogContent dividers sx={{ mx: "auto" }}>
                <Box component="div" sx={{ mx: "auto" }}>
                    <EmptyBallBinIcon sx={{ fontSize: 64 }} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Stack sx={{ width: "100%" }}>
                    {!lowEnough && !isLifting && (
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            loading={loadingLower}
                            onClick={onLowerHeadClick}
                            sx={{ mb: 2 }}
                        >
                            Lower Head
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="info"
                        fullWidth
                        loading={loading}
                        onClick={onClick}
                        sx={{ mb: 2 }}
                    >
                        Close
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}
