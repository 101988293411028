import * as React from "react";

import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { PlayMode } from "@volley/shared/apps/app-common-models";
import type { Side } from "@volley/shared/http/side";

import { degToRad, shouldImprovePosition } from "../../../util/positionUtil";
import LocalizationProgress from "../../Trainer/Localization/LocalizationProgress";
import { useLift } from "../../hooks/useLift";
import usePosition, { PositionProximity } from "../../hooks/usePosition";
import CloseableDialogTitle from "../CloseableDialogTitle";
import { useSelectedSport } from "../context/sport";

import ResizableWorkoutVisualizer from "./ResizableWorkoutVisualizer";
import { WorkoutForVisualizer } from "./types";

interface LocalizationModalProps extends Omit<DialogProps, "children"> {
    workout?: WorkoutForVisualizer;
    playMode: PlayMode;
    side: Side | "both";
    hasLocalized: boolean;
    oneShot?: boolean;
    onClose: () => void;
    setHasLocalized: () => void;
}

export default function LocalizationDialog({
    workout,
    playMode,
    side,
    hasLocalized,
    setHasLocalized,
    onClose,
    oneShot = false,
    ...props
}: LocalizationModalProps): React.JSX.Element | null {
    const { selected } = useSelectedSport();
    const { safeHeight } = useLift();

    const {
        error,
        position,
        isLocalizing,
        isVisionStarting,
        isVisionFaulted,
        cancel,
        updatePosition,
        localizationStatus,
        delta,
    } = usePosition();

    React.useEffect(() => {
        // in case of page refresh - this will hide the "Check Position" button so the status shows
        if (isLocalizing || error === "NOT_READY" || error === "FAULTED") {
            setHasLocalized();
        }
    }, [isLocalizing, error, setHasLocalized]);

    // cancel localization job if we unmount
    React.useEffect(() => () => cancel(), [cancel]);

    const positionProximity = React.useMemo((): PositionProximity => {
        if (isVisionStarting) return "Not Ready";
        if (isVisionFaulted) return "Unavailable";
        if (hasLocalized && !isLocalizing) {
            if (position === undefined || error || !delta) return "Unsure";
            if (shouldImprovePosition(delta)) return "Improve";
        }
        return "Good";
    }, [
        isVisionStarting,
        isVisionFaulted,
        position,
        error,
        hasLocalized,
        isLocalizing,
        delta,
    ]);

    const yawProximity = React.useMemo((): PositionProximity => {
        if (positionProximity !== "Good") return positionProximity;
        if (!position || workout?.trainer.yaw === undefined) return "Unsure";
        if (Math.abs(position.yaw - workout.trainer.yaw) > degToRad(4))
            return "Improve";
        return "Good";
    }, [position, workout?.trainer.yaw, positionProximity]);

    const improveMode = React.useMemo(() => {
        if (positionProximity === "Improve") return "xy";
        if (positionProximity === "Good" && yawProximity === "Improve")
            return "yaw";
        return undefined;
    }, [positionProximity, yawProximity]);

    const combinedProximity = React.useMemo(() => {
        if (positionProximity === "Good") return yawProximity;
        return positionProximity;
    }, [positionProximity, yawProximity]);

    const summary = React.useMemo(() => {
        const description =
            selected === "PLATFORM_TENNIS"
                ? `${side}, ${hasLocalized ? combinedProximity : "Check"}`
                : side;

        return (
            <Box component="div">
                <Typography color="primary.main" variant="h3">
                    Position
                </Typography>
                <Typography color="primary.main" variant="h4">
                    {description}
                </Typography>
            </Box>
        );
    }, [selected, side, hasLocalized, combinedProximity]);

    const message =
        "Click 'Check Location' below to have the camera system verify the trainer is positioned correctly.";

    const workoutToRender: WorkoutForVisualizer | undefined =
        React.useMemo(() => {
            if (workout) {
                return {
                    player: workout.player ?? [],
                    shots: [],
                    trainer: workout.trainer,
                    localized:
                        hasLocalized && position
                            ? {
                                  ...position,
                                  heightIn: workout.trainer.heightIn,
                              }
                            : undefined,
                };
            }

            return undefined;
        }, [hasLocalized, position, workout]);

    if (!workout) {
        return null;
    }

    return (
        <Dialog {...props} fullWidth>
            <CloseableDialogTitle
                onClose={() => {
                    if (isLocalizing) {
                        cancel();
                    }
                    onClose();
                }}
            >
                {summary}
            </CloseableDialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <Typography variant="caption">{message}</Typography>
                    <ResizableWorkoutVisualizer
                        workout={workoutToRender}
                        positionProximity={combinedProximity}
                        improveMode={improveMode}
                    />
                    {selected === "PLATFORM_TENNIS" && (
                        <LocalizationProgress
                            localizationError={error}
                            hintText={combinedProximity}
                            cancel={() => cancel()}
                            update={() => {
                                setHasLocalized();
                                updatePosition(
                                    {
                                        ...workout.trainer,
                                        heightIn:
                                            workout?.trainer.heightIn ??
                                            safeHeight,
                                    },
                                    oneShot,
                                );
                            }}
                            localizationStatus={localizationStatus}
                            positionRequested={hasLocalized}
                            delta={delta}
                        />
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
