import * as React from "react";

import { useCurrentUser } from "../../hooks/currentUser";
import { usePairingContext } from "../../hooks/pairingStatus";
import { useStatus } from "../../hooks/status";
import LowerHeadDialog from "../LowerHeadDialog";
import { shouldSolicitFeedback } from "../SessionFeedbackDialog";
import UnpairDialog from "../UnpairDialog";
import useDialog from "../useDialog";

const enum UnpairSteps {
    LowerArm,
    Unpair,
    SolicitFeedback,
    CleanUp,
    Done,
}

export default function NoConfirmUnpairFlow(): React.JSX.Element | null {
    const { unpair } = usePairingContext();
    const { setDialogType } = useDialog();
    const { isAdmin } = useCurrentUser();
    const { status } = useStatus();
    const [step, setStep] = React.useState(UnpairSteps.LowerArm);

    React.useEffect(() => {
        if (step === UnpairSteps.CleanUp) {
            setDialogType("DisconnectCleanupChecklist");
        }
    }, [step, setDialogType, unpair]);

    if (step === UnpairSteps.LowerArm) {
        return (
            <LowerHeadDialog
                message="The trainer arm is moving to the lowest position for storage."
                reason="unpair"
                onLowered={() => setStep(UnpairSteps.Unpair)}
                onStopped={() => setDialogType(null)}
                onError={() => setStep(UnpairSteps.Unpair)} // if we can't lower, continue with shutdown
            />
        );
    }

    if (step === UnpairSteps.Unpair) {
        return (
            <UnpairDialog
                onUnpair={() => {
                    unpair();
                    setStep(UnpairSteps.SolicitFeedback);
                }}
            />
        );
    }

    if (step === UnpairSteps.SolicitFeedback) {
        // don't prompt for feedback if the user is an admin
        if (isAdmin()) {
            setStep(UnpairSteps.CleanUp);
        }

        // if we shouldn't prompt for feedback, continue with to cleanup
        if (!shouldSolicitFeedback(status?.session)) {
            setStep(UnpairSteps.CleanUp);
        }

        setDialogType("DisconnectFeedback");
    }

    return null;
}
