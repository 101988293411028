import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { v4 as uuid } from "uuid";

import type { CuratedWorkoutShot } from "@volley/shared/apps/curated-workout-models";

import { PositionLike } from "../../../util/position-types";
import VerticalDragDrop from "../../common/DragAndDrop/VerticalDragDrop";
import { useSelectedSport } from "../../common/context/sport";
import { mph2mps, mps2mph } from "../util/conversions";

import SpinIllustration from "./Accordions/SpinIllustration";
import ShotDeleteConfirmation from "./ShotDeleteConfirmation";
import ShotEditModal from "./ShotEditModal";

const Header = styled(Paper)({
    backgroundColor: "#f5f5f5",
    color: "#707070",
}) as typeof Paper;

const VerticallyCenteredText = styled(Typography)({
    height: "60px",
    lineHeight: "60px",
    display: "block",
    flexBasis: "24%",
    textAlign: "center",
}) as typeof Typography;

const Item = styled(Box)(({ theme }) => ({
    height: 80,
    lineHeight: "60px",
    borderBottomColor: theme.palette.grey[100],
    borderBottomStyle: "solid",
    borderBottomWidth: "2px",
})) as typeof Box;

const SelectedItem = styled(Paper)(({ theme }) => ({
    height: 120,
    lineHeight: "60px",
    borderColor: theme.palette.info.main,
    borderStyle: "solid",
    borderWidth: "2px",
    marginTop: "-2px",
    marginBottom: "5px",
})) as typeof Paper;

interface ListItemDetailProps {
    shot: CuratedWorkoutShot;
    selected?: boolean;
}

function ListItemDetail({
    shot,
    selected = false,
}: ListItemDetailProps): React.JSX.Element {
    const {
        launchSpeed,
        launchSpeedVariance,
        pan,
        panVariance,
        tilt,
        tiltVariance,
        spinDirection,
        spinLevel,
        intervalOverride,
    } = shot;
    return (
        <Stack
            spacing={1}
            justifyContent="space-evenly"
            direction="row"
            sx={{
                color: selected ? "info.main" : "primary.main",
            }}
        >
            <VerticallyCenteredText sx={{ flexBasis: "5%", pt: 0.5 }}>
                <DragIndicatorIcon fontSize="small" />
            </VerticallyCenteredText>
            <VerticallyCenteredText variant="h4">
                {mps2mph(launchSpeed).toFixed(1)}
                &nbsp;
                {launchSpeedVariance > 0 ? "V" : ""}
            </VerticallyCenteredText>
            <VerticallyCenteredText variant="h4">
                {tilt}
                &deg;&nbsp;
                {tiltVariance > 0 ? "V" : ""}
            </VerticallyCenteredText>
            <VerticallyCenteredText variant="h4">
                {pan}
                &deg;&nbsp;
                {panVariance > 0 ? "V" : ""}
            </VerticallyCenteredText>
            <VerticallyCenteredText variant="h4">
                <Box component="div" sx={{ paddingTop: "10px" }}>
                    <SpinIllustration
                        imgScale={0.2}
                        spinIntensity={spinLevel ?? 0}
                        spinOrientation={-spinDirection}
                    />
                </Box>
            </VerticallyCenteredText>
            <VerticallyCenteredText variant="h4">
                {intervalOverride ?? "-"}
            </VerticallyCenteredText>
        </Stack>
    );
}

function ListItemWithName({
    shot,
    selected = false,
}: ListItemDetailProps): React.JSX.Element {
    const { name, launchSpeed } = shot;
    return (
        <Stack
            spacing={1}
            justifyContent="space-evenly"
            direction="row"
            sx={{
                color: selected ? "info.main" : "primary.main",
            }}
        >
            <VerticallyCenteredText sx={{ flexBasis: "5%" }}>
                <DragIndicatorIcon fontSize="small" />
            </VerticallyCenteredText>
            <VerticallyCenteredText
                sx={{ flexBasis: "70%", textAlign: "start" }}
            >
                {name}
            </VerticallyCenteredText>
            <VerticallyCenteredText sx={{ flexBasis: "25%" }}>
                {mps2mph(launchSpeed).toFixed(1)}
            </VerticallyCenteredText>
        </Stack>
    );
}

interface ListItemProps {
    shot: CuratedWorkoutShot;
    variant: "name" | "detail";
    selected: string | null;
    onClick: (id: string) => void;
    onEdit: (id: string) => void;
    onDuplicate: (id: string) => void;
}

function ListItem({
    shot,
    variant,
    selected,
    onClick,
    onEdit,
    onDuplicate,
}: ListItemProps): React.JSX.Element {
    const { id } = shot;

    if (selected === id) {
        return (
            <Fade key={id} in appear timeout={500}>
                <SelectedItem onClick={() => onClick(id)} elevation={4} square>
                    {variant === "name" ? (
                        <ListItemWithName shot={shot} selected />
                    ) : (
                        <ListItemDetail shot={shot} selected />
                    )}

                    <Stack direction="row" spacing={2} sx={{ px: 1 }}>
                        <Button
                            color="info"
                            variant="contained"
                            onClick={() => onEdit(id)}
                            fullWidth
                        >
                            Edit
                        </Button>
                        <Button
                            color="info"
                            variant="contained"
                            onClick={() => onDuplicate(id)}
                            fullWidth
                        >
                            Duplicate
                        </Button>
                    </Stack>
                </SelectedItem>
            </Fade>
        );
    }

    return (
        <Item key={id} onClick={() => onClick(id)}>
            {variant === "name" ? (
                <ListItemWithName shot={shot} />
            ) : (
                <ListItemDetail shot={shot} />
            )}
        </Item>
    );
}

interface ShotListProps {
    defaultInterval: number;
    plannedPosition: PositionLike | undefined;
    height: number;
    shots: CuratedWorkoutShot[];
    updateShots: (updated: CuratedWorkoutShot[]) => void;
}

export default function ShotList({
    defaultInterval,
    plannedPosition,
    height,
    shots,
    updateShots,
}: ShotListProps): React.JSX.Element {
    const { limits } = useSelectedSport();
    const [selected, setSelected] = React.useState<string | null>(null);
    const [editing, setEditing] = React.useState<
        Partial<CuratedWorkoutShot> | undefined
    >();
    const [deleting, setDeleting] = React.useState<
        CuratedWorkoutShot | undefined
    >();

    const toggleSelected = React.useCallback(
        (id: string) => {
            if (id === selected) {
                setSelected(null);
            } else {
                setSelected(id);
            }
        },
        [selected, setSelected],
    );

    const handleEdit = React.useCallback(
        (id: string) => {
            const shotToEdit = shots.find((s) => s.id === id);
            setEditing(shotToEdit);
        },
        [shots, setEditing],
    );

    const handleDelete = React.useCallback(
        (id: string) => {
            const match = shots.find((s) => s.id === id);
            setDeleting(match);
        },
        [shots, setDeleting],
    );

    const handleDeleteConfirmed = React.useCallback(
        (id: string) => {
            const updated: CuratedWorkoutShot[] = [];
            shots.forEach((s) => {
                if (s.id !== id) {
                    updated.push(s);
                }
            });
            setDeleting(undefined);
            setEditing(undefined);
            updateShots(updated);
        },
        [shots, setDeleting, setEditing, updateShots],
    );

    const handleDuplicate = React.useCallback(
        (id: string) => {
            const dupIndex = shots.findIndex((s) => s.id === id);
            const toDup = shots[dupIndex];
            if (toDup !== undefined) {
                const dup = {
                    ...toDup,
                    id: uuid(),
                };
                shots.splice(dupIndex + 1, 0, dup);
                updateShots(shots);
            }
        },
        [shots, updateShots],
    );

    const handleSave = React.useCallback(
        (shot: CuratedWorkoutShot) => {
            const index = shots.findIndex((s) => s.id === shot.id);
            if (index >= 0) {
                shots.splice(index, 1, shot);
            } else {
                shots.push(shot);
            }
            updateShots(shots);
            setEditing(undefined);
        },
        [shots, setEditing, updateShots],
    );

    const allShotsHaveNames = shots.length && shots.every((s) => !!s.name);

    return (
        <>
            <Paper elevation={4} component={Stack}>
                <Header elevation={0} square>
                    <Stack direction="row" spacing={1}>
                        <Box sx={{ flexBasis: "5%" }} />
                        {allShotsHaveNames ? (
                            <>
                                <VerticallyCenteredText
                                    variant="h4"
                                    sx={{
                                        flexBasis: "70%",
                                        textAlign: "start",
                                    }}
                                >
                                    Name
                                </VerticallyCenteredText>
                                <VerticallyCenteredText
                                    variant="h4"
                                    sx={{ flexBasis: "25%" }}
                                >
                                    Speed
                                </VerticallyCenteredText>
                            </>
                        ) : (
                            <>
                                <VerticallyCenteredText
                                    sx={{
                                        lineHeight: "30px",
                                    }}
                                    variant="h4"
                                >
                                    Speed (mph)
                                </VerticallyCenteredText>
                                <VerticallyCenteredText variant="h4">
                                    Arc
                                </VerticallyCenteredText>
                                <VerticallyCenteredText variant="h4">
                                    Aim
                                </VerticallyCenteredText>
                                <VerticallyCenteredText variant="h4">
                                    Spin
                                </VerticallyCenteredText>
                                <VerticallyCenteredText variant="h4">
                                    Pad
                                </VerticallyCenteredText>
                            </>
                        )}
                    </Stack>
                </Header>
                <VerticalDragDrop
                    items={shots}
                    setItems={updateShots}
                    jsxElements={shots.map((s) => (
                        <ListItem
                            key={s.id}
                            shot={s}
                            onClick={() => toggleSelected(s.id)}
                            onDuplicate={() => handleDuplicate(s.id)}
                            onEdit={() => handleEdit(s.id)}
                            selected={selected}
                            variant={allShotsHaveNames ? "name" : "detail"}
                        />
                    ))}
                />
                <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    py={2}
                >
                    <Fab
                        color="secondary"
                        onClick={() =>
                            setEditing({
                                launchSpeed: mph2mps(
                                    limits.speed.minLaunchSpeed,
                                ),
                                launchSpeedVariance: 0,
                                pan: 0,
                                panVariance: 0,
                                spinDirection: 0,
                                spinLevel: 0,
                                tilt: 0,
                                tiltVariance: 0,
                            })
                        }
                    >
                        <AddIcon />
                    </Fab>
                </Box>
            </Paper>
            {editing !== undefined && (
                <ShotEditModal
                    defaultInterval={defaultInterval}
                    plannedPosition={plannedPosition}
                    shot={editing}
                    height={height}
                    onCancel={() => setEditing(undefined)}
                    onDelete={(id) => handleDelete(id)}
                    onSave={(id) => handleSave(id)}
                />
            )}
            <ShotDeleteConfirmation
                shot={deleting}
                onCancel={() => setDeleting(undefined)}
                onConfirm={(id) =>
                    id ? handleDeleteConfirmed(id) : setDeleting(undefined)
                }
            />
        </>
    );
}
