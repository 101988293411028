import * as React from "react";
import { Routes, Route } from "react-router";

import Pair from "./Pair";

export default function Sessions(): React.JSX.Element {
    return (
        <Routes>
            <Route path="pair/*" element={<Pair />} />
        </Routes>
    );
}
