import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

export interface SingleShotSaveAsDialogProps {
    defaultName: string;
    open: boolean;
    title: "New" | "Save" | "Duplicate" | "Save As";
    onClose: () => void;
    onConfirm: (name?: string, description?: string) => Promise<void>;
}

export default function SingleShotSaveAsDialog({
    defaultName,
    open,
    title,
    onClose,
    onConfirm,
}: SingleShotSaveAsDialogProps): React.JSX.Element {
    const [name, setName] = React.useState(defaultName);
    const [description, setDescription] = React.useState("");

    const saveButtonText = React.useMemo(() => {
        if (title === "Duplicate" || title === "Save As") {
            return "Save";
        }

        if (title !== "New" && defaultName === name) {
            return "Overwrite";
        }

        return "Save As...";
    }, [title, defaultName, name]);

    React.useEffect(() => {
        setName(defaultName);
    }, [defaultName]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: "form",
                onSubmit: async (e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    await onConfirm(name.trim(), description.trim());
                    onClose();
                },
            }}
        >
            <DialogTitle>{`${title} Shot`}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Shot Name"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    fullWidth
                    multiline
                    variant="standard"
                    value={description}
                    onChange={(e) => setDescription(e.currentTarget.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" variant="contained" color="secondary">
                    {saveButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
