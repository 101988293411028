import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import cdnUrl from "../CampaignClubs/cdnUrl";
import useIntercom from "../hooks/useIntercom";

export interface RemoveBallProps {
    onBallRemoved: () => void;
}
export default function TiltCalibrationFailureRemoveBall({
    onBallRemoved,
}: RemoveBallProps): React.JSX.Element {
    const intercom = useIntercom();

    return (
        <>
            <DialogTitle sx={{ p: 2 }} color="primary.main" variant="h4">
                Remove Stuck Ball
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    It is now safe for you to remove the ball. Once the ball has
                    been removed, press done to power the trainer back up.
                </Typography>
                <img
                    src={cdnUrl("help-dialogs/tilt_calibration_help.jpg")}
                    alt="Remove ball"
                    style={{ width: "100%" }}
                />
            </DialogContent>
            <DialogActions>
                <Stack width="100%">
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={onBallRemoved}
                        sx={{ mb: 2 }}
                    >
                        Done
                    </Button>
                    <Button
                        onClick={() => {
                            intercom.newMessage();
                        }}
                        startIcon={<HelpIcon />}
                    >
                        Need help? Chat with support.
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}
