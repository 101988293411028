import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CreateWorkoutDialog from "./CreateWorkoutDialog";

const WIDTH = 140;
const HEIGHT = 112;

export default function CreateWorkoutCard(): React.JSX.Element | null {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Card
                sx={{
                    minWidth: WIDTH,
                    width: WIDTH,
                    height: HEIGHT,
                    backgroundColor: (t) => t.palette.primary.main,
                    borderRadius: "10px",
                    borderLeftWidth: "5px",
                    borderLeftStyle: "solid",
                    borderLeftColor: (t) => t.palette.secondary.main,
                }}
                onClick={() => setOpen(true)}
            >
                <CardContent
                    sx={{
                        pt: 1,
                        pr: 1,
                        pl: 1,
                        pb: 0,
                    }}
                >
                    <Typography
                        sx={{
                            textTransform: "none",
                            fontWeight: (t) => t.typography.fontWeightMedium,
                        }}
                        variant="body2"
                        color="white"
                    >
                        New Workout
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <AddIcon
                            sx={{
                                fontSize: "64px",
                                color: (t) => t.palette.secondary.main,
                            }}
                        />
                    </Stack>
                </CardContent>
            </Card>
            <CreateWorkoutDialog open={open} onClose={() => setOpen(false)} />
        </>
    );
}
