import * as React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useStatus } from "../../hooks/status";
import useDialog from "../useDialog";

import { ActionListItem } from "./listItems";

export default function WifiListItem(): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { status } = useStatus();

    const name = status?.system.wifi?.essid ?? "Not Connected";

    return (
        <ActionListItem
            action={() => {
                setDialogType("WifiCredentials");
            }}
            priority={false}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h4">Trainer WiFi:</Typography>
                <Typography
                    variant="h4"
                    color="success.main"
                    textAlign="right"
                    pl={2}
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: 200,
                    }}
                >
                    {name}
                </Typography>
            </Stack>
        </ActionListItem>
    );
}
