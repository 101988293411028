import * as React from "react";
import { Route, Routes, createBrowserRouter, Outlet } from "react-router";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router/dom";

import logger from "../log";
import theme from "../theme";

import Auth from "./Auth";
import BallCabinetUnlock from "./BallCabinetUnlock";
import DialogProvider from "./Dialog/DialogProvider";
import HorizontalScrollHome from "./HorizontalScrollHome";
import Login from "./Login";
import Sessions from "./Sessions";
import PairDialog from "./Sessions/Pair/PairDialog";
import StackIndicator from "./StackIndicator";
import Tutorials from "./Tutorials/Tutorials";
import Loading from "./common/Loading";
import ReactLazyError from "./common/ReactLazyError";
import RequireAdmin from "./common/RequireAdmin";
import RequireAuth from "./common/RequireAuth";
import UserPage from "./common/UserPage";
import { SportProvider } from "./common/context/sport";
import { CurrentUserProvider } from "./hooks/currentUser";
import { DebugProvider } from "./hooks/debugView";
import { PairingProvider } from "./hooks/pairingStatus";
import { StatusProvider } from "./hooks/status";
import { IntercomProvider } from "./hooks/useIntercom";
import { LocalizationProvider } from "./hooks/usePosition";
import { TrainerFeatureProvider } from "./hooks/useTrainerFeatures";
import { UpdateProvider } from "./hooks/useUpdate";
import { UserEventsProvider } from "./hooks/useUserEvents";

function handleImportError(e: Error) {
    logger.error(`Error lazy loading component: ${e.message}`, undefined, e);
    return { default: () => <ReactLazyError /> };
}

const Account = React.lazy(() => import("./Account").catch(handleImportError));
const Admin = React.lazy(() => import("./Admin").catch(handleImportError));
const PlayerPTI = React.lazy(() =>
    import("./PTI/PlayerPTI").catch(handleImportError),
);
const Signup = React.lazy(() => import("./Signup").catch(handleImportError));
const Trainer = React.lazy(() => import("./Trainer").catch(handleImportError));

function ContextProviders({
    children,
}: React.PropsWithChildren): React.JSX.Element {
    return (
        <CurrentUserProvider>
            <PairingProvider>
                <DebugProvider>
                    <StatusProvider>
                        <SportProvider>
                            <TrainerFeatureProvider>
                                <IntercomProvider>
                                    <LocalizationProvider>
                                        <UpdateProvider>
                                            <DialogProvider>
                                                <UserEventsProvider>
                                                    {children}
                                                </UserEventsProvider>
                                            </DialogProvider>
                                        </UpdateProvider>
                                    </LocalizationProvider>
                                </IntercomProvider>
                            </TrainerFeatureProvider>
                        </SportProvider>
                    </StatusProvider>
                </DebugProvider>
            </PairingProvider>
        </CurrentUserProvider>
    );
}

function Root(): React.JSX.Element {
    return (
        <Routes>
            {/* Authenticated Routes */}
            <Route
                element={
                    <ContextProviders>
                        <RequireAuth>
                            <Outlet />
                            <PairDialog />
                        </RequireAuth>
                    </ContextProviders>
                }
            >
                {/* Pages with the UserPage wrapper */}
                <Route
                    element={
                        <UserPage>
                            <Outlet />
                        </UserPage>
                    }
                >
                    <Route path="*" element={<HorizontalScrollHome />} />
                    <Route
                        path="/content/*"
                        element={
                            <React.Suspense fallback={<Loading />}>
                                <Trainer />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/player-pti/*"
                        element={
                            <React.Suspense fallback={<Loading />}>
                                <PlayerPTI />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/account"
                        element={
                            <React.Suspense fallback={<Loading />}>
                                <Account />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/ball-cabinet-unlock"
                        element={<BallCabinetUnlock />}
                    />
                </Route>

                {/* Routes that implement their own window decorations */}
                <Route
                    path="/admin/*"
                    element={
                        <RequireAdmin>
                            <React.Suspense fallback={<Loading />}>
                                <Admin />
                            </React.Suspense>
                        </RequireAdmin>
                    }
                />
                <Route path="/sessions/*" element={<Sessions />} />
                <Route path="/tutorials/*" element={<Tutorials />} />
            </Route>

            {/* Unauthenticated Routes */}
            <Route
                element={
                    <CurrentUserProvider>
                        <Outlet />
                    </CurrentUserProvider>
                }
            >
                <Route
                    path="/signup/*"
                    element={
                        <React.Suspense fallback={<Loading />}>
                            <Signup />
                        </React.Suspense>
                    }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/auth/*" element={<Auth />} />
            </Route>
        </Routes>
    );
}

const router = createBrowserRouter([{ path: "*", element: <Root /> }]);

export default function App(): React.JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <StackIndicator />
            <RouterProvider router={router} />
        </ThemeProvider>
    );
}
