import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { expandLabelToComponents } from "../../util/buildLabel";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useCurrentUser } from "../hooks/currentUser";
import { useStatus } from "../hooks/status";
import { useAppWorkoutStatus } from "../hooks/useAppWorkoutStatus";
import { useTrainerFeatures } from "../hooks/useTrainerFeatures";
import { useUpdate } from "../hooks/useUpdate";

import useDialog from "./useDialog";

function VersionGridItem({
    children,
}: {
    children: React.ReactNode;
}): React.JSX.Element {
    return (
        <Grid sx={{ display: "flex", alignItems: "center" }} size={6}>
            {children}
        </Grid>
    );
}

function VersionHeader({
    children,
}: {
    children: React.ReactNode;
}): React.JSX.Element {
    return (
        <Typography align="left" variant="h5" component="dt">
            {children}
        </Typography>
    );
}

function VersionValue({
    children,
}: {
    children: React.ReactNode;
}): React.JSX.Element {
    return (
        <Typography align="left" component="dd" sx={{ ml: 2 }}>
            {children}
        </Typography>
    );
}

export default function AboutDialog(): React.JSX.Element {
    const { status } = useStatus();
    const { hasAvailableBuilds, updateRequired } = useUpdate();
    const { isAdmin } = useCurrentUser();
    const { setDialogType } = useDialog();
    const trainerFeatures = useTrainerFeatures();
    const { playState } = useAppWorkoutStatus();

    const showAvailableUpdate =
        hasAvailableBuilds && trainerFeatures.includes("userUpdate");

    const updatesReady = React.useMemo(() => {
        if (!status) return false;

        if (!status.update.systems) return false;

        const { control, vision } = status.update.systems;

        if (!control || !vision) return false;

        return control.readyToInstall && vision.readyToInstall;
    }, [status]);

    const batteryLevelGood = React.useMemo(() => {
        const batteryLevel = status?.trainer?.battery?.level;
        return batteryLevel && batteryLevel >= 35;
    }, [status?.trainer?.battery?.level]);

    const buildLabel = status?.softwareBuildLabel ?? "";
    let softwareVersion = "Unknown";
    if (buildLabel) {
        const components = expandLabelToComponents(buildLabel);
        softwareVersion = `${components.major}.${components.minor}.${components.patch}`;
        softwareVersion += components.alpha ? `${components.release}` : "";
    }

    return (
        <>
            <CloseableDialogTitle onClose={() => setDialogType(null)}>
                About
            </CloseableDialogTitle>
            <DialogContent dividers sx={{ p: 2 }}>
                <Grid container>
                    <Grid size={12}>
                        <Grid container component="dl">
                            <VersionGridItem>
                                <VersionHeader>Trainer ID</VersionHeader>
                            </VersionGridItem>
                            <VersionGridItem>
                                <VersionValue>
                                    {status?.clientId ?? "?"}
                                </VersionValue>
                            </VersionGridItem>

                            <VersionGridItem>
                                <VersionHeader>Trainer Version</VersionHeader>
                            </VersionGridItem>
                            <VersionGridItem>
                                <VersionValue>{softwareVersion}</VersionValue>
                            </VersionGridItem>

                            <VersionGridItem>
                                <VersionHeader>Vision Version</VersionHeader>
                            </VersionGridItem>
                            <VersionGridItem>
                                <VersionValue>
                                    {status?.vision.visionSoftwareVersion ??
                                        "?"}
                                </VersionValue>
                            </VersionGridItem>
                        </Grid>
                    </Grid>
                    {showAvailableUpdate && (
                        <Grid container alignItems="center">
                            <Grid sx={{ mt: 2 }} size={12}>
                                <Box
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography variant="h6" component="h2">
                                        Update Available!
                                    </Typography>
                                    {!updatesReady && (
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            color="success"
                                            sx={{ ml: 1 }}
                                        >
                                            But NOT Ready to Install
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                            <Grid sx={{ mt: 2 }} size={12}>
                                <Box
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={
                                            !batteryLevelGood ||
                                            playState === "playing"
                                        }
                                        onClick={() => setDialogType("Update")}
                                    >
                                        <Typography variant="h6" component="h2">
                                            Start Update
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>

                            {batteryLevelGood && playState !== "playing" && (
                                <Grid sx={{ mt: 2 }} size={12}>
                                    {updatesReady && (
                                        <Typography
                                            variant="body1"
                                            component="p"
                                        >
                                            A new version of the software is
                                            available. Please update the
                                            trainer.
                                        </Typography>
                                    )}
                                    {!updatesReady && (
                                        <Typography
                                            variant="body1"
                                            component="p"
                                        >
                                            A new version of the software is
                                            available, but it is not ready to
                                            install. The trainer will first have
                                            to download the update. This may
                                            take some time.
                                        </Typography>
                                    )}
                                    {!isAdmin && updateRequired && (
                                        <Typography
                                            variant="body1"
                                            component="p"
                                        >
                                            This update is required to continue
                                            using the trainer.
                                        </Typography>
                                    )}
                                </Grid>
                            )}

                            {!batteryLevelGood && (
                                <Grid sx={{ mt: 2 }} size={12}>
                                    <Box
                                        component="div"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="p"
                                            color="error"
                                            textAlign="center"
                                        >
                                            A new version of the software is
                                            available, however the battery level
                                            is too low to update.
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}

                            {playState === "playing" && (
                                <Grid sx={{ mt: 2 }} size={12}>
                                    <Box
                                        component="div"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="p"
                                            color="error"
                                            textAlign="center"
                                        >
                                            A new version of the software is
                                            available, however the trainer is
                                            playing a workout.
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </>
    );
}
