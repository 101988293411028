import * as React from "react";

import usePaginatedData from "../../../../../hooks/usePaginatedData";

import { SessionForHistory } from "./models";

interface UseSessionsHistoryProps {
    workoutId: number;
    count?: number;
}

export default function useSessionsHistory({
    workoutId,
    count = 10,
}: UseSessionsHistoryProps) {
    const { data } = usePaginatedData<SessionForHistory>(
        `/api/sessions/history/${workoutId}`,
        {
            initialRowsPerPage: count,
        },
    );

    const [sessionHistory, setSessionHistory] = React.useState<
        SessionForHistory[] | null
    >(null);
    const [activeSessionId, setActiveSessionId] = React.useState<number | null>(
        null,
    );

    React.useEffect(() => {
        if (data) {
            setSessionHistory(data);
            setActiveSessionId(data[0]?.id);
        }
    }, [data]);

    return { history: sessionHistory, activeSessionId, setActiveSessionId };
}
