import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import type { CuratedWorkoutShot } from "@volley/shared/apps/curated-workout-models";

import { relativeSpinToLabel } from "./Accordions/SpinAccordion";

interface ShotDeleteConfirmationProps {
    shot?: CuratedWorkoutShot | undefined;
    onCancel: () => void;
    onConfirm: (id: string | undefined) => void;
}

function ParamSummary(value: number, variance: number) {
    return `${value}${variance ? " V" : ""}`;
}

function ShotSummary(shot: CuratedWorkoutShot | undefined): string {
    if (shot === undefined) {
        return "Unknown";
    }
    const summaries = [
        ParamSummary(shot.launchSpeed, shot.launchSpeedVariance),
        ParamSummary(shot.tilt, shot.tiltVariance),
        ParamSummary(shot.pan, shot.panVariance),
        relativeSpinToLabel(shot.spinDirection),
    ];
    return summaries.join(", ");
}

export default function ShotDeleteConfirmation({
    shot,
    onCancel,
    onConfirm,
}: ShotDeleteConfirmationProps): React.JSX.Element {
    return (
        <Dialog open={shot !== undefined}>
            <DialogTitle>Are You Sure?</DialogTitle>
            <DialogContent>
                {`Delete the shot? "${ShotSummary(shot)}. Once deleted, you can't recover it.`}
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Box
                        sx={{
                            flexBasis: "50%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={() => onCancel()}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            flexBasis: "50%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={() => onConfirm(shot?.id)}
                            variant="contained"
                        >
                            Delete
                        </Button>
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
