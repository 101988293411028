import * as React from "react";

import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import type { Sport } from "@volley/physics/dist/models";
import type { Coordinate } from "@volley/shared/vision-models";

import type { LocalizationStatus } from "../../../../../hooks/usePosition";
import LocalizationProgress from "../../../../Localization/LocalizationProgress";

const BYPASS_STATUSES: LocalizationStatus[] = [
    "cancelling",
    "error",
    "idle",
    "unavailable",
];

interface Props {
    sport: Sport;
    hintText: string;
    localizationStatus: LocalizationStatus;
    localizationError: string | undefined;
    positionRequested: boolean;
    delta: Coordinate | undefined;
    onDone: () => void;
    onCancel: () => void;
    onBypass: () => void;
    improveMode?: "xy" | "yaw";
}

export default function ModuleStartMoveTrainer({
    sport,
    hintText,
    onCancel,
    onDone,
    onBypass,
    delta,
    localizationError,
    localizationStatus,
    positionRequested,
    improveMode,
}: Props): React.JSX.Element {
    const improveText =
        improveMode === "yaw"
            ? "Aim trainer along the green line"
            : "Move trainer to the position";

    if (sport === "PLATFORM_TENNIS") {
        const buttonText = positionRequested ? "RETRY" : "CHECK POSITION";
        return (
            <>
                {localizationStatus === "idle" && (
                    <Typography>{`${improveText} shown above then press ${buttonText}.`}</Typography>
                )}
                <LocalizationProgress
                    localizationError={localizationError}
                    hintText={hintText}
                    cancel={onCancel}
                    update={onDone}
                    localizationStatus={localizationStatus}
                    positionRequested={positionRequested}
                    delta={delta}
                />
                {positionRequested &&
                    BYPASS_STATUSES.includes(localizationStatus) && (
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            onClick={onBypass}
                        >
                            Proceed
                        </Button>
                    )}
            </>
        );
    }

    return (
        <>
            <Typography>{`${improveText} shown above then press DONE.`}</Typography>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<DoneIcon />}
                onClick={onDone}
            >
                Done
            </Button>
        </>
    );
}
