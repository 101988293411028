import * as React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useStatus } from "../../hooks/status";
import useDialog from "../useDialog";

import { ActionListItem } from "./listItems";

export default function BallBinListItem(): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { status } = useStatus();

    if (
        status?.fault &&
        (status.fault.failures[0].type === "FeedFailure" ||
            status.fault.failures[0].type === "FeedFailureNoPowerDrop")
    ) {
        const dialogType =
            status.fault.failures[0].type === "FeedFailureNoPowerDrop"
                ? "BallJamNoPowerDrop"
                : "BallJam";
        return (
            <ActionListItem action={() => setDialogType(dialogType)}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">Ball Jam:</Typography>
                    <Typography variant="h4" color="text.primary">
                        Resolve
                    </Typography>
                </Stack>
            </ActionListItem>
        );
    }

    if (status?.fault && status.fault.failures[0].type === "BallBinEmpty") {
        return (
            <ActionListItem action={() => setDialogType("RefillBallBin")}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">Ball Bin:</Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            color: "text.primary",
                        }}
                    >
                        Refill
                    </Typography>
                </Stack>
            </ActionListItem>
        );
    }

    return (
        <ActionListItem
            action={() => {
                setDialogType("ManualRefillBallBin");
            }}
            priority={false}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4">Ball Bin:</Typography>
                <Typography variant="h4" color="success.main">
                    Refill
                </Typography>
            </Stack>
        </ActionListItem>
    );
}
