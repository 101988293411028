import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import useRanges from "../../../hooks/ranges";

import AccordionSlider from "./AccordionSlider";

type AimVariation = "none" | "slight" | "moderate" | "large";

type VariationText = AimVariation | "yes";

type AimType = "Aim" | "Arc";

const KnownAimVariation: Record<AimVariation, number> = {
    none: 0,
    slight: 2,
    moderate: 8,
    large: 15,
};

function variationText(test: number): VariationText {
    const match = Object.keys(KnownAimVariation).find(
        (k) => KnownAimVariation[k as AimVariation] === test,
    );
    return (match as AimVariation) ?? "none";
}

export interface AimProps {
    label: AimType;
    selectedAim: number;
    onAimChanged: (value: number) => void;
    selectedAimVariation?: number;
    onAimVariationChanged?: (value: number) => void;
    disabled?: boolean;
}

export default function Aim({
    label,
    selectedAim,
    onAimChanged,
    selectedAimVariation,
    onAimVariationChanged,
    disabled = false,
}: AimProps): React.JSX.Element {
    const { pan, tilt } = useRanges();
    const aimRange = label === "Aim" ? pan : tilt;
    const marks = React.useMemo(
        () => [
            {
                value:
                    aimRange.min +
                    Math.floor((10 / 100) * Math.abs(aimRange.min)),
                label: label === "Aim" ? "Left" : "Down",
            },
            {
                value: 0,
                label: label === "Aim" ? "Center" : "Level",
            },
            {
                value: aimRange.max - Math.floor((10 / 100) * aimRange.max),
                label: label === "Aim" ? "Right" : "Up",
            },
        ],
        [label, aimRange.min, aimRange.max],
    );
    const options = React.useMemo(
        () =>
            label === "Arc"
                ? Object.keys(KnownAimVariation).slice(0, 2)
                : Object.keys(KnownAimVariation),
        [label],
    );

    return (
        <Stack spacing={3}>
            <Box component="div" flexGrow={1}>
                <AccordionSlider
                    disabled={disabled}
                    min={aimRange.min}
                    max={aimRange.max}
                    marks={marks}
                    step={0.5}
                    value={selectedAim}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(v) => `${v}°`}
                    onChange={(_, v) => onAimChanged(v as number)}
                    sx={{
                        display: "table",
                        margin: "0px auto 20px auto",
                        maxWidth: "90%",
                        ".MuiSlider-track": {
                            color: "primary.light",
                        },
                        "& .MuiSlider-valueLabel": {
                            fontSize: "32px",
                        },
                    }}
                />
            </Box>
            {selectedAimVariation !== undefined && onAimVariationChanged && (
                <Stack spacing={1} direction="row">
                    <Typography>Variance</Typography>
                    <ButtonGroup>
                        {options.map((k: string) => {
                            const selected =
                                k === variationText(selectedAimVariation);
                            return (
                                <Button
                                    key={`${label}-${k}`}
                                    disabled={disabled}
                                    variant={
                                        selected ? "contained" : "outlined"
                                    }
                                    sx={{
                                        color: selected
                                            ? "common.white"
                                            : "primary.light",
                                        backgroundColor: selected
                                            ? "primary.light"
                                            : "common.white",
                                        fontSize: "10px",
                                        "&:hover": {
                                            bgcolor: "primary.light",
                                        },
                                    }}
                                    onClick={() => {
                                        onAimVariationChanged(
                                            KnownAimVariation[
                                                k as AimVariation
                                            ],
                                        );
                                    }}
                                >
                                    {k}
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </Stack>
            )}
        </Stack>
    );
}
