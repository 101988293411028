import * as React from "react";

import Drawer from "@mui/material/Drawer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithSummary as AppWorkout } from "@volley/data";
import type {
    AppParameters,
    AppState,
    PlayState,
} from "@volley/shared/app-models";
import type {
    CuratedWorkoutConfig,
    LevelNumber,
    LeveledWorkoutConfig,
} from "@volley/shared/apps/curated-workout-models";
import type { SingleShotConfig } from "@volley/shared/apps/single-shot-models";
import type { AnyJSON, JSONObject } from "@volley/shared/common-models";

import logger from "../../../log";
import fetchApi, {
    pairedFetchApi,
    logFetchError,
    FetchError,
} from "../../../util/fetchApi";
import { PositionLike } from "../../../util/position-types";
import useDialog from "../../Dialog/useDialog";
import { useSelectedSport } from "../../common/context/sport";
import { usePhysicsModelContext } from "../../hooks/PhysicsModelProvider";
import { useStatus } from "../../hooks/status";
import { useAppWorkoutStatus } from "../../hooks/useAppWorkoutStatus";
import usePrevious from "../../hooks/usePrevious";
import { useTrainerFeatures } from "../../hooks/useTrainerFeatures";
import { mirrorTrainer } from "../Position/util";

import LatestPositionState from "./apps/shared/latestPosition";
import trimShots, { isMultiLevelWorkout, trimShot } from "./util";

type AppWorkoutPlayStatus =
    | "stopped"
    | "requestingPlay"
    | "playRequested"
    | "requestingPause"
    | "pauseRequested"
    | "requestingStop"
    | "stopRequested"
    | "playing"
    | "paused"
    | "error";

export type CaptureStatus =
    | "Complete"
    | "Idle"
    | "Requested"
    | "Recording"
    | "Starting"
    | "NothingToRecord";

export interface AppWorkoutPlayState {
    start: (manualTrim?: number, manualBoost?: number) => Promise<void>;
    pause: () => Promise<void>;
    stop: () => Promise<void>;
    captureVideo: () => Promise<void>;
    captureStatus: CaptureStatus;
    diagnostics: Record<string, string | number> | null;
    playState: PlayState;
    statusText: string;
    workoutFinished: boolean;
    requestError: string | null;
    workoutState: AppState | null;
    workout: AppWorkout | null;
    pauseDisabled: boolean;
    playDisabled: boolean;
    stopDisabled: boolean;
    captureDisabled: boolean;
    playInitiated: boolean;
}

interface WorkoutDiagnosticsProps {
    headerText: string;
    diagnostics: Record<string, string | number>;
}

export interface AppWorkoutPlayParams {
    workout: AppWorkout | null;
    parameters: AppParameters;
    localizedPosition?: PositionLike;
    level?: LevelNumber;
}

export interface ButtonState {
    pauseDisabled: boolean;
    playDisabled: boolean;
    stopDisabled: boolean;
}

const BASE_URL = "/api/apps/workouts";

export default function useAppWorkoutPlay({
    workout,
    parameters,
    localizedPosition,
    level,
}: AppWorkoutPlayParams): AppWorkoutPlayState {
    const features = useTrainerFeatures();
    const { currentWorkout, playState, recordingState, diagnostics } =
        useAppWorkoutStatus();
    const { status: trainerStatus } = useStatus();
    const { setDialogType } = useDialog();
    const [stateChangeError, setStateChangeError] = React.useState<
        string | null
    >(null);
    const [status, setStatus] = React.useState<AppWorkoutPlayStatus>("stopped");
    const [workoutFinished, setWorkoutFinished] = React.useState(false);
    const [workoutState, setWorkoutState] = React.useState<AppState | null>(
        currentWorkout?.appState || null,
    );
    const stateChangeRequested = React.useRef<number | null>(null);
    const hidden = React.useRef<boolean>(false);
    const [playInitiated, setPlayInitiated] = React.useState(false);
    const { physicsModelName } = usePhysicsModelContext();
    const { selected: selectedSport } = useSelectedSport();
    const [captureStatus, setCaptureStatus] =
        React.useState<CaptureStatus>("Idle");
    const captureDisabled = React.useMemo(
        () => captureStatus === "Requested" || captureStatus === "Recording",
        [captureStatus],
    );

    const [buttonState, setButtonState] = React.useState<ButtonState>({
        playDisabled: false,
        pauseDisabled: true,
        stopDisabled: true,
    });

    const [expectedState, setExpectedState] =
        React.useState<PlayState>("stopped");

    React.useEffect(() => {
        const handleVisibilityChange = () => {
            if (
                stateChangeRequested.current &&
                hidden.current &&
                !document.hidden
            ) {
                stateChangeRequested.current = performance.now();
            }

            hidden.current = document.hidden;
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, []);

    React.useEffect(() => {
        if (currentWorkout !== null) {
            setWorkoutState(currentWorkout.appState);
        }
    }, [currentWorkout]);

    const previousState = usePrevious<PlayState>(playState);
    React.useEffect(() => {
        if (stateChangeRequested.current) {
            const diff = performance.now() - stateChangeRequested.current;
            logger.info(`Waiting confirmation: ${status} ${diff}ms`);
            const maxOffset = 10000;
            if (diff > maxOffset) {
                setStateChangeError(
                    `Status hasn't updated within ${maxOffset}ms`,
                );
                stateChangeRequested.current = null;
                setStatus("error");
                logger.warn(
                    `Workout Status hasn't updated within ${maxOffset}ms - setting status to error`,
                );
                return;
            }

            if (playState === expectedState) {
                logger.info(`Transitioning from ${status} -> ${playState}`);
                setExpectedState(playState);
                stateChangeRequested.current = null;
                setStatus(playState);
                setStateChangeError(null);
            } else if (expectedState === "paused" && playState === "stopped") {
                logger.info("Requested pause, but workout has been stopped");
                setExpectedState(playState);
                stateChangeRequested.current = null;
                setStatus(playState);
                setStateChangeError(null);
            } else {
                logger.info(
                    `Expected play status: ${expectedState}, got: ${playState} - continuing to wait`,
                );
            }
        } else {
            if (previousState === "playing" && playState === "stopped") {
                setWorkoutFinished(true);
            }
            setStatus(playState);
            if (playState === "playing" && !playInitiated) {
                setPlayInitiated(true);
            }
        }
    }, [
        previousState,
        playInitiated,
        playState,
        status,
        expectedState,
        trainerStatus?.timestamp,
    ]);

    React.useEffect(() => {
        if (playState !== "stopped") {
            setPlayInitiated(true);
        }
    }, [playState]);

    React.useEffect(() => {
        if (status.endsWith("Requested") || status.startsWith("requesting")) {
            logger.info(`Disabling all buttons due to play state: ${status}`);
            setButtonState({
                pauseDisabled: true,
                playDisabled: true,
                stopDisabled: true,
            });
            return;
        }

        if (status === "playing" && stateChangeRequested.current === null) {
            logger.info("Playing - play disabled, pause & stop: enabled");
            setButtonState({
                pauseDisabled: false,
                playDisabled: true,
                stopDisabled: false,
            });
        }

        if (status === "paused" && stateChangeRequested.current === null) {
            logger.info("Paused - pause disabled, play & stop: enabled");
            setButtonState({
                pauseDisabled: true,
                playDisabled: false,
                stopDisabled: false,
            });
        }

        if (status === "stopped" && stateChangeRequested.current === null) {
            logger.info("Stopped - stop disabled, play & pause: enabled");
            setPlayInitiated(false);
            setButtonState({
                pauseDisabled: true,
                playDisabled: false,
                stopDisabled: true,
            });
        }

        if (status === "error") {
            logger.info("Error playing workout - enabling buttons");
            setPlayInitiated(false);
            setButtonState({
                pauseDisabled: true,
                playDisabled: false,
                stopDisabled: true,
            });
        }
    }, [status, stateChangeRequested]);

    const statusText = React.useMemo(() => {
        logger.info(`Status changed to ${status}`);
        switch (status) {
            case "pauseRequested":
            case "requestingPause":
                return `Pausing ${workout?.name ?? "Workout"}`;
            case "paused":
                return `${workout?.name ?? "Workout"} Paused`;
            case "playRequested":
            case "requestingPlay":
                return `Loading ${workout?.name ?? "Workout"}`;
            case "playing":
                return `Playing ${workout?.name ?? "Workout"}`;
            case "requestingStop":
            case "stopRequested":
                return `Stopping ${workout?.name ?? "Workout"}`;
            case "stopped":
                return `${workout?.name ?? "Workout"} Stopped`;
            default:
                return `${workout?.name ?? "Workout"} Loading...`;
        }
    }, [status, workout?.name]);

    const start = React.useCallback(
        async (manualTrim?: number, manualBoost?: number) => {
            if (trainerStatus?.trainer.powerState !== "L0") {
                setDialogType("Calibrating");
                return;
            }

            if (workout === null) {
                setStateChangeError("Workout is not set.");
                return;
            }

            setStatus("playRequested");
            setExpectedState("playing");
            setPlayInitiated(true);
            stateChangeRequested.current = performance.now();
            let plannedPosition = {
                x: workout.positionX,
                y: workout.positionY,
                yaw: workout.positionYaw,
            };
            if (parameters.mirrored || parameters.playMode === "mirror") {
                logger.info("Using mirrored trainer position");
                const withHeight = {
                    ...plannedPosition,
                    heightIn: workout.positionHeight,
                };
                const mirrored = mirrorTrainer(withHeight, selectedSport);
                const { x, y, yaw } = mirrored;
                plannedPosition = { x, y, yaw };
            }

            let localConfig = workout.config;
            const localParameters = parameters;
            if (isMultiLevelWorkout(workout) && level) {
                localConfig = (
                    workout.config as unknown as LeveledWorkoutConfig
                ).levels[level] as unknown as JSONObject;
            }

            if (manualTrim) {
                logger.info(
                    `Applying ${manualTrim} degrees of trim to workout`,
                );
                if (
                    [
                        "User Workout",
                        "Curated Workout",
                        "Multi-Level Workout",
                    ].includes(workout.appName)
                ) {
                    const config =
                        localConfig as unknown as CuratedWorkoutConfig;
                    if (config.shots) {
                        const trimmed = trimShots(manualTrim, config.shots);
                        const updatedConfig = {
                            ...config,
                            shots: trimmed,
                        };

                        localConfig = updatedConfig as unknown as JSONObject;
                    }
                } else if (
                    ["Freeplay", "Guided Freeplay"].includes(workout.name)
                ) {
                    const config = localConfig as unknown as SingleShotConfig;
                    const trimmed = trimShot(manualTrim, config.shot);
                    const updatedConfig = {
                        ...config,
                        shot: trimmed,
                    };

                    localConfig = updatedConfig as unknown as JSONObject;
                }
            }

            if (manualBoost) {
                logger.info(
                    `Applying ${manualBoost} level of boost to workout`,
                );
                if (
                    [
                        "User Workout",
                        "Curated Workout",
                        "Multi-Level Workout",
                    ].includes(workout.appName)
                ) {
                    const config =
                        localConfig as unknown as CuratedWorkoutConfig;
                    if (config.shots) {
                        const boosted = config.shots.map((s) => ({
                            ...s,
                            launchSpeed:
                                s.launchSpeed * ((100 + manualBoost) / 100),
                        }));
                        const updatedConfig = {
                            ...config,
                            shots: boosted,
                        };

                        localConfig = updatedConfig as unknown as JSONObject;
                    }
                } else if (
                    ["Freeplay", "Guided Freeplay"].includes(workout.name)
                ) {
                    const config = localConfig as unknown as SingleShotConfig;
                    const boosted = {
                        ...config.shot,
                        launchSpeed:
                            config.shot.launchSpeed *
                            ((100 + manualBoost) / 100),
                    };

                    const updatedConfig = {
                        ...config,
                        shot: boosted,
                    };

                    localConfig = updatedConfig as unknown as JSONObject;
                }
            }
            logger.info(
                `Starting workout with physics model: ${physicsModelName}`,
            );

            // FIXME: This needs to be cleaned up as we deprecate multi-shot/curated workouts
            let appWorkoutId = workout.appId;
            const params = { ...localParameters };
            // appId 7 is a 'shared' workout
            if (appWorkoutId === 7) {
                try {
                    const result = await fetchApi<number>(
                        `/api/app-workouts/original/${workout.id}`,
                    );
                    appWorkoutId = result;
                } catch (e) {
                    logFetchError(e);
                    setStateChangeError("Failed to load original app ID");
                    return;
                }
            }

            if (appWorkoutId === 9) {
                // this code is temporary, we'll modify coach to handle this app ID
                // in the future... but each level in a multi-level workout is the
                // same data structure as the deprecated 'curated' workouts, so we're
                // basically extracting the level and sending that to coach as a
                // curated workout
                appWorkoutId = 4;
                params.level = level as unknown as AnyJSON;
            }

            try {
                LatestPositionState.setPosition({
                    ...plannedPosition,
                    heightIn: workout.positionHeight,
                });
                await pairedFetchApi(
                    trainerStatus?.clientId,
                    BASE_URL,
                    "POST",
                    {
                        id: workout.id,
                        name: workout.name,
                        appId: appWorkoutId,
                        config: localConfig,
                        parameters: params,
                        headHeight: workout.positionHeight,
                        physicsModel: physicsModelName,
                        sport: selectedSport,
                        plannedPosition:
                            appWorkoutId === 1 ? undefined : plannedPosition,
                        localizedPosition,
                    },
                );
                setStatus("playRequested");
            } catch (ex) {
                if ((ex as Error).name === "AbortError") {
                    setStatus(playState);
                    setExpectedState(playState);
                } else {
                    setStatus("error");
                    logFetchError(ex);
                    stateChangeRequested.current = null;
                    if (ex instanceof Error) {
                        setStateChangeError(ex.message);
                    } else {
                        setStateChangeError(
                            "Unexpected error starting workout.",
                        );
                    }
                }
            }
        },
        [
            trainerStatus?.trainer.powerState,
            trainerStatus?.clientId,
            workout,
            playState,
            parameters,
            physicsModelName,
            setDialogType,
            selectedSport,
            localizedPosition,
            level,
        ],
    );

    const pause = React.useCallback(async () => {
        if (!["paused", "pauseRequested", "requestingPause"].includes(status)) {
            setStatus("requestingPause");
            setExpectedState("paused");
            stateChangeRequested.current = performance.now();
            try {
                await pairedFetchApi(
                    trainerStatus?.clientId,
                    `${BASE_URL}/pause`,
                    "POST",
                );
                setStatus("pauseRequested");
            } catch (ex) {
                if ((ex as Error).name === "AbortError") {
                    setStatus(playState);
                    setExpectedState(playState);
                } else {
                    setStatus("error");
                    stateChangeRequested.current = null;
                    setStateChangeError(
                        "Error encounted while pausing workout",
                    );
                    logFetchError(ex);
                }
            }
        }
    }, [status, trainerStatus?.clientId, playState]);

    const stop = React.useCallback(async () => {
        if (!["stopped", "stopRequested", "requestingStop"].includes(status)) {
            setStatus("requestingStop");
            setExpectedState("stopped");
            stateChangeRequested.current = performance.now();
            try {
                await pairedFetchApi(
                    trainerStatus?.clientId,
                    `${BASE_URL}/stop`,
                    "POST",
                );
                setStatus("stopRequested");
            } catch (ex) {
                if ((ex as Error).name === "AbortError") {
                    setStatus(playState);
                    setExpectedState(playState);
                } else {
                    setStatus("error");
                    stateChangeRequested.current = null;
                    setStateChangeError("Error stopping workout");
                    logFetchError(ex);
                }
            }
        }
    }, [status, trainerStatus?.clientId, playState]);

    React.useEffect(() => {
        // older software versions don't report the recording status,
        // so here we just set a timeout to re-enable the button after a pause
        if (
            !features.includes("captureStatus") &&
            captureStatus === "Recording"
        ) {
            logger.info(
                "Trainer doesn't support upload status, using timeout to renable capture.",
            );
            const timeout = setTimeout(() => {
                setCaptureStatus("Idle");
            }, 3000);

            return () => clearTimeout(timeout);
        }

        return () => {};
    }, [captureStatus, features]);

    React.useEffect(() => {
        if (features.includes("captureStatus")) {
            if (
                captureStatus === "Requested" &&
                recordingState === "recording"
            ) {
                logger.info(
                    "Capture requested, state 'recording' -> transitioning to Recording",
                );
                setCaptureStatus("Recording");
            }

            if (
                captureStatus === "Recording" &&
                recordingState === "inactive"
            ) {
                logger.info(
                    "Capture state inactive, -> transitioning to Complete",
                );
                setCaptureStatus("Complete");
            }
        }
    }, [features, captureStatus, recordingState]);

    const captureVideo = React.useCallback(async () => {
        // If capture is idle or we are in a retry-able state
        const statusValid =
            captureStatus === "Idle" ||
            captureStatus === "Complete" ||
            captureStatus === "NothingToRecord" ||
            captureStatus === "Starting";
        // Vision is running and camera is online
        const visionUp =
            trainerStatus?.vision?.serviceState === "Running" &&
            trainerStatus?.vision?.camera.state === "Online";
        // We can record
        if (statusValid && visionUp) {
            logger.info("Requesting clip capture");
            if (features.includes("captureStatus")) {
                setCaptureStatus("Requested");
            } else {
                setCaptureStatus("Recording");
            }
            try {
                await pairedFetchApi(
                    trainerStatus?.clientId,
                    `${BASE_URL}/capture`,
                    "POST",
                );
            } catch (ex) {
                if (ex instanceof FetchError && ex.message === "NO_CLIPS") {
                    logger.info("Setting capture status to NothingToRecord");
                    setCaptureStatus("NothingToRecord");
                } else {
                    if ((ex as Error).name === "AbortError") {
                        logger.info("Capture request aborted by client");
                    } else {
                        logFetchError(ex);
                    }
                    setCaptureStatus("Idle");
                }
            }
        } else if (
            trainerStatus?.vision?.serviceState === "Fault" ||
            trainerStatus?.vision?.camera.state === "Offline"
        ) {
            // The trainer will have a fault and camera state will be offline
            // We can potentially improve this in the future - some fault states don't prevent video capture
            setDialogType("VisionFaultDialog");
        } else if (trainerStatus?.vision?.serviceState === "Starting") {
            // We're still waiting on vision to start up and show camera online
            setCaptureStatus("Starting");
        } else {
            const cameraState = `Camera State: ${trainerStatus?.vision?.camera.state}`;
            const serviceState = `Vision Service State: ${trainerStatus?.vision?.serviceState}`;
            const internal = `Internal hook capture state: ${captureStatus}`;
            logger.error(
                `Ignoring request to capture clips - ${serviceState}, ${cameraState}, ${internal}`,
            );
            logger.warn("Resetting captureStatus to 'Idle'");
            setCaptureStatus("Idle");
        }
    }, [
        captureStatus,
        features,
        setDialogType,
        trainerStatus?.clientId,
        trainerStatus?.vision?.serviceState,
        trainerStatus?.vision?.camera.state,
    ]);

    return {
        start,
        pause,
        stop,
        captureVideo,
        captureStatus,
        diagnostics,
        playState,
        statusText,
        workoutFinished,
        requestError: stateChangeError,
        workoutState,
        workout,
        playInitiated,
        captureDisabled,
        ...buttonState,
    };
}

export function WorkoutDiagnostics({
    diagnostics,
    headerText,
}: WorkoutDiagnosticsProps): React.JSX.Element {
    return (
        <TableContainer
            sx={{
                padding: "10px",
            }}
        >
            <Typography variant="h4">{headerText}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell align="right">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(diagnostics).map((k) => (
                        <TableRow key={k}>
                            <TableCell>{k}</TableCell>
                            <TableCell align="right">
                                {diagnostics[k]}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function WorkoutDiagnosticsDrawer({
    open,
    diagnostics,
    headerText,
}: WorkoutDiagnosticsProps & { open: boolean }): React.JSX.Element {
    return (
        <Drawer
            anchor="top"
            variant="persistent"
            open={open}
            sx={{
                ".MuiDrawer-paper": {
                    height: "20%",
                },
            }}
        >
            <WorkoutDiagnostics
                headerText={headerText}
                diagnostics={diagnostics}
            />
        </Drawer>
    );
}
