import {
    ServeResult,
    TargetAOI,
} from "@volley/shared/apps/serveandvolley-models";

const MIN_SCORABLE_SERVES = 5;

// Weights for different AOI sections
const WEIGHT_FRONT = 80; // front 2/3 of AOI
const WEIGHT_BACK_THIRD = 90; // back 1/3 of AOI
const WEIGHT_DEEP_BACK = 100; // back 2' of AOI
const WEIGHT_SERVICE_BOX = 70; // in service box but missed AOI

const DEEP_BACK_DISTANCE = 0.6096; // 2 feet in meters

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTargetAOI(target: any): target is TargetAOI {
    return typeof target !== "string" && target.aoi !== undefined;
}

// Extracts the AOI properties from a serve's target.
function getAoi(target: TargetAOI): {
    lowerRightY: number;
    upperLeftY: number;
} {
    return target.aoi;
}

function isInDeepBack(serve: ServeResult): boolean {
    if (!serve.position || !isTargetAOI(serve.target)) return false;

    const { lowerRightY, upperLeftY } = getAoi(serve.target);
    const minY = lowerRightY + DEEP_BACK_DISTANCE;

    // deep back is defined as serves between minY and the top of the AOI
    return serve.position.y >= minY && serve.position.y <= upperLeftY;
}

function isInBackThird(serve: ServeResult): boolean {
    if (!serve.position || !isTargetAOI(serve.target)) return false;

    const { lowerRightY, upperLeftY } = getAoi(serve.target);
    const third = (upperLeftY - lowerRightY) / 3;
    const minY = lowerRightY + third;

    // back third covers from one-third up from the bottom to the top of the AOI
    return serve.position.y >= minY && serve.position.y <= upperLeftY;
}

export function score(serves: ServeResult[]): number | null {
    if (serves.length < MIN_SCORABLE_SERVES) {
        return null;
    }

    const counts = {
        deepBack: 0,
        backThird: 0,
        front: 0,
        serviceBox: 0,
    };

    serves.forEach((serve) => {
        // Skip serves that are out of the service box entirely, they don't count.
        if (!serve.inServiceBox) return;

        // If serve is in the service box but not in the AOI, count as serviceBox.
        if (!serve.inAoi) {
            counts.serviceBox++;
            return;
        }

        // TODO - need to deal with "string" targetAOI
        if (!isTargetAOI(serve.target) || !serve.position) {
            // Optionally log a warning or handle this case if needed.
            return;
        }

        // Classify serve based on its position within the AOI.
        if (isInDeepBack(serve)) {
            counts.deepBack++;
        } else if (isInBackThird(serve)) {
            counts.backThird++;
        } else {
            counts.front++;
        }
    });

    // Calculate the total score based on the counts and weights.
    const totalServes = serves.length;
    const totalScore =
        (counts.front / totalServes) * WEIGHT_FRONT +
        (counts.backThird / totalServes) * WEIGHT_BACK_THIRD +
        (counts.deepBack / totalServes) * WEIGHT_DEEP_BACK +
        (counts.serviceBox / totalServes) * WEIGHT_SERVICE_BOX;

    return totalScore;
}
