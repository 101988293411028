import * as React from "react";

import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";

import useUnclosableDialog from "./common/useUnclosableDialog";
import useDialog from "./useDialog";

export default function EStopDialog(): React.JSX.Element {
    useUnclosableDialog();
    const { status } = useStatus();
    const { setDialogType } = useDialog();
    const [dismissed, setDismissed] = React.useState(false);

    // We're ready to continue when we no longer have an EStop fault
    // and we're "Uncalibrated". Note: had to add wait until "Uncalibrated" because
    // we'll clear fault, move to L0 but can be in a NODE_BOOT motion state
    // when we try to calibrate, which will fail. This waits until the trainsition
    // is complete and trainer can be calibrated.
    const clear = React.useMemo(
        () =>
            !status?.fault?.failures.some((f) => f.type === "EStop") &&
            status?.trainer.calibrated === "Uncalibrated",
        [status],
    );

    React.useEffect(() => {
        if (dismissed && clear) {
            setDialogType("Calibrating");
        }
    }, [clear, dismissed, setDialogType]);

    // If we're no longer in an EStop fault, dismiss the dialog,
    // this can happen if the user clears fault by using "resume" button
    React.useEffect(() => {
        // only clear if we have't already dismissed, manual dismiss has its own flow
        if (
            !dismissed &&
            !status?.fault?.failures.some((f) => f.type === "EStop")
        ) {
            setDialogType(null);
        }
    }, [dismissed, setDialogType, status?.fault?.failures]);

    const clearEStopFault = React.useCallback(async () => {
        setDismissed(true);
        try {
            await pairedFetchApi(status?.clientId, "/api/clear-fault", "POST");
        } catch (ex) {
            logFetchError(ex, "Failed to clear fault dismissing EStopDialog");
        }
    }, [status?.clientId]);

    const shutdown = React.useCallback(() => {
        setDialogType("NoConfirmShutdown");
    }, [setDialogType]);

    return (
        <>
            <CloseableDialogTitle variant="h4">
                E-Stop Button Pressed
            </CloseableDialogTitle>
            <DialogContent dividers>
                <Stack>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={shutdown}
                        sx={{ mb: 3 }}
                        disabled={dismissed}
                    >
                        Shutdown Trainer
                    </Button>
                    <Typography variant="caption" textAlign="center">
                        Hit the button by accident?
                    </Typography>
                    <Button
                        size="small"
                        onClick={clearEStopFault}
                        color="info"
                        loading={dismissed}
                        sx={{ lineHeight: 0.75 }}
                    >
                        Dismiss
                    </Button>
                </Stack>
            </DialogContent>
        </>
    );
}
