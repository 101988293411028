// Copyright 2021 Volley LLC, All Rights Reserved.
// Volley CONFIDENTIAL

import * as React from "react";

import Button from "@mui/material/Button";

import { logFetchError, pairedFetchApi } from "../../../util/fetchApi";
import { useStatus } from "../../hooks/status";

export default function MotionStatus(): React.JSX.Element {
    const { status: trainerStatus } = useStatus();
    const [error, setError] = React.useState<string | null>(null);
    const [status, setStatus] = React.useState<unknown>(null);

    const fetchStatus = React.useCallback(async () => {
        const response = await pairedFetchApi<unknown>(
            trainerStatus?.clientId,
            "/api/status/motion",
        );
        setError(null);
        setStatus(response);
    }, [trainerStatus?.clientId]);

    React.useEffect(() => {
        fetchStatus().catch((e) => {
            logFetchError(e);
            setError(e instanceof Error ? e.message : JSON.stringify(e));
        });
    }, [fetchStatus]);

    if (status === null) {
        return <div>Fetching ...</div>;
    }

    if (error !== null) {
        return (
            <div>
                Error:
                {error}
            </div>
        );
    }

    return (
        <div style={{ margin: "10px" }}>
            <Button variant="outlined" onClick={fetchStatus}>
                Refresh
            </Button>
            <pre>{JSON.stringify(status, null, 2)}</pre>
        </div>
    );
}
