import * as React from "react";

import { useCurrentUser } from "../../hooks/currentUser";
import { usePairingContext } from "../../hooks/pairingStatus";
import { useStatus } from "../../hooks/status";
import LowerHeadDialog from "../LowerHeadDialog";
import PoweringOffDialog from "../PoweringOffDialog";
import { shouldSolicitFeedback } from "../SessionFeedbackDialog";
import useDialog from "../useDialog";

const enum ShutdownSteps {
    LowerArm,
    PowerDown,
    SolicitFeedback,
    CleanUp,
}

export default function NoConfirmShutdownFlow(): React.JSX.Element | null {
    const { unpair } = usePairingContext();
    const { setDialogType } = useDialog();
    const { isAdmin } = useCurrentUser();
    const { status } = useStatus();
    const [step, setStep] = React.useState(ShutdownSteps.PowerDown);

    React.useEffect(() => {
        if (step === ShutdownSteps.CleanUp) {
            setDialogType("ShutdownCleanupChecklist");
            unpair();
        }
    }, [step, setDialogType, unpair]);

    if (step === ShutdownSteps.LowerArm) {
        return (
            <LowerHeadDialog
                message="The trainer arm is moving to the lowest position for storage."
                reason="shutdown"
                onLowered={() => setStep(ShutdownSteps.PowerDown)}
                onStopped={() => setDialogType(null)}
                onError={() => setStep(ShutdownSteps.PowerDown)} // if we can't lower, continue with shutdown
            />
        );
    }

    if (step === ShutdownSteps.PowerDown) {
        return (
            <PoweringOffDialog
                onPoweredOff={() => setStep(ShutdownSteps.SolicitFeedback)}
                full
            />
        );
    }

    if (step === ShutdownSteps.SolicitFeedback) {
        // don't prompt for feedback if the user is an admin
        if (isAdmin()) {
            setStep(ShutdownSteps.CleanUp);
        }

        // if we shouldn't prompt for feedback, continue with to cleanup
        if (!shouldSolicitFeedback(status?.session)) {
            setStep(ShutdownSteps.CleanUp);
        }

        setDialogType("ShutdownFeedback");
    }

    return null;
}
