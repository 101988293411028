import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { fetchApi } from "../util";

import FourDigitInput from "./common/FourDigitInput";

interface ChallengeResponse {
    unlockCode: string;
}

export default function BallCabinetUnlock(): React.JSX.Element {
    const [challengeKey, setChallengeKey] = React.useState("");
    const [unlockCode, setUnlockCode] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const onChange = (value: string) => {
        setChallengeKey(value);
    };

    React.useEffect(() => {
        if (challengeKey.replace(/\D/g, "").length !== 4) {
            setUnlockCode(null);
            return;
        }
        setLoading(true);
        setErrorMessage("");

        const search = new URLSearchParams({ challengeKey });
        fetchApi<ChallengeResponse>(
            `/api/ball-cabinets/unlock-code?${search.toString()}`,
        )
            .then((data) => setUnlockCode(data.unlockCode))
            .catch((e) => setErrorMessage((e as Error).message))
            .finally(() => setLoading(false));
    }, [challengeKey]);

    return (
        <Paper sx={{ p: 2, minHeight: "90vh" }}>
            <Stack spacing={2}>
                <Typography variant="h3" component="h1">
                    Ball Cabinet Unlock
                </Typography>
                <Typography>
                    Enter the 4-digit code from the cabinet’s display
                </Typography>
                <FourDigitInput value={challengeKey} onChange={onChange} />
                {errorMessage && (
                    <Typography color="error">{errorMessage}</Typography>
                )}
                {loading && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                )}
                {unlockCode && (
                    <>
                        <Typography>
                            Great! Now enter the following code on the display
                            to unlock the cabinet.
                        </Typography>
                        <Typography
                            align="center"
                            sx={{
                                letterSpacing: 30,
                                fontFamily: "monospace",
                                fontSize: "5rem",
                            }}
                        >
                            {unlockCode}
                        </Typography>
                    </>
                )}
            </Stack>
        </Paper>
    );
}
