import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import {
    type KeenSliderOptions,
    type TrackDetails,
    useKeenSlider,
} from "keen-slider/react";

import type { Sport } from "../../../common/context/sport";
import { useCurrentUser } from "../../../hooks/currentUser";
import { useTrainerFeatures } from "../../../hooks/useTrainerFeatures";

import AccordionSlider from "./AccordionSlider";

interface SportRange {
    min: number;
    max: number;
}

const RANGE_BY_SPORT: Record<Sport, SportRange> = {
    PLATFORM_TENNIS: { min: -35, max: 35 },
    PADEL: { min: -35, max: 35 },
    TENNIS: { min: -35, max: 35 },
    PICKLEBALL: { min: -35, max: 35 },
};

const WHEEL_SIZE = 20;

const getCacheKey = (cacheKey: string) => `volley:speedAdjustments:${cacheKey}`;

type Props = Readonly<{
    cacheKey: string;
    sport: Sport;
    onChange: (value?: number) => void;
    value?: number;
    disabled?: boolean;
}>;

// Leaving this code in case we choose the dial variant instead of the slider
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SpeedAdjustmentControl: React.FC<Props> = ({
    cacheKey,
    sport,
    onChange,
    value,
    disabled = false,
}) => {
    const theme = useTheme();
    const range = RANGE_BY_SPORT[sport];
    const slides = range.max - range.min + 1;

    const rangeValueFromIndex = React.useCallback(
        (i: number) => range.min + (((i % slides) + slides) % slides),
        [range.min, slides],
    );

    const indexFromRangeValue = React.useCallback(
        (rv: number) => (((rv - range.min) % slides) + slides) % slides,
        [range.min, slides],
    );

    const [sliderState, setSliderState] = React.useState<TrackDetails | null>(
        null,
    );
    const options = React.useRef<KeenSliderOptions>({
        slides: {
            number: slides,
            origin: "auto",
            perView: 1,
        },
        initial: value ? indexFromRangeValue(value) : Math.floor(slides / 2),
        loop: false,
        dragSpeed: 2.6,
        detailsChanged: (s) => {
            setSliderState(s.track.details);
        },
        disabled,
        rubberband: true,
        mode: "free-snap",
    });

    const [sliderRef, slider] = useKeenSlider(options.current);
    const [radius, setRadius] = React.useState(0);

    React.useEffect(() => {
        if (slider.current) setRadius(slider.current.size / 2);
    }, [slider]);

    React.useEffect(() => {
        if (slider.current)
            slider.current.update({ ...slider.current.options, disabled });
    }, [slider, disabled]);

    const sliderAbsValue = slider.current?.track.details.abs;

    React.useEffect(() => {
        if (!sliderAbsValue) return;
        const rangeValue = rangeValueFromIndex(sliderAbsValue);
        const key = getCacheKey(cacheKey);
        if (sliderAbsValue !== Math.floor(slides / 2)) {
            window.sessionStorage.setItem(key, rangeValue.toString());
        } else {
            window.sessionStorage.removeItem(key);
        }
        onChange(rangeValue || undefined);
    }, [sliderAbsValue, onChange, rangeValueFromIndex, cacheKey, slides]);

    const slideValues = React.useMemo(() => {
        if (!sliderState) return [];
        const values = [];
        for (let i = 0; i < slides; i++) {
            const distance = sliderState.slides[i].distance;
            const rotate =
                Math.abs(distance) > WHEEL_SIZE / 2
                    ? 180
                    : distance * (360 / WHEEL_SIZE) * -1;
            const style = {
                color:
                    i === sliderState.abs &&
                    sliderState.abs !== Math.floor(slides / 2)
                        ? theme.palette.warning.main
                        : undefined,
                transform: `rotateY(${rotate}deg) translateZ(-${radius}px)`,
            };
            const formattedValue = rangeValueFromIndex(i);
            values.push({ style, value: formattedValue });
        }
        return values;
    }, [
        sliderState,
        radius,
        slides,
        rangeValueFromIndex,
        theme.palette.warning.main,
    ]);

    return (
        <Stack spacing={1}>
            <Typography id="speed-adjustment-label" variant="caption">
                Speed Adjustment
            </Typography>
            <div
                className="wheel__container"
                style={{
                    display: "block",
                    height: "40px",
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",
                    WebkitFontSmoothing: "antialiased",
                    MozOsxFontSmoothing: "antialiased",
                }}
                ref={sliderRef}
            >
                <div
                    className="wheel__shadow-start"
                    style={{
                        position: "absolute",
                        background:
                            "linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%)",
                        width: "calc(42% + 2px)",
                        transform: `translateZ(${radius}px)`,
                        top: 0,
                        left: 0,
                        height: "100%",
                        borderRight: `0.5px solid rgba(0, 0, 0, 0.3)`,
                        zIndex: 5,
                    }}
                />
                <div
                    className="wheel__inner"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        perspective: "1000px",
                        transformStyle: "preserve-3d",
                        height: "100%",
                        width: "100%",
                        perspectiveOrigin: "50% calc(50% - 100px)",
                        background: disabled ? "#ddd" : "#fff",
                    }}
                >
                    <div
                        className="wheel__slides"
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        {slideValues.map(({ style, value: v }) => (
                            <div
                                className="wheel__slide"
                                key={v}
                                onTouchStart={() => {}}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    backfaceVisibility: "hidden",
                                    fontSize: "20px",
                                    fontWeight: 400,
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    justifyContent: "center",
                                    userSelect: "none",
                                    WebkitUserSelect: "none",
                                    ...style,
                                }}
                            >
                                <span>
                                    {`${v > 0 ? "+" : ""}${v}${v === 0 ? "" : "%"}`}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="wheel__shadow-end"
                    style={{
                        position: "absolute",
                        background:
                            "linear-gradient(to left, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%)",
                        width: "calc(42% + 2px)",
                        transform: `translateZ(${radius}px)`,
                        top: 0,
                        right: 0,
                        height: "100%",
                        borderLeft: `0.5px solid rgba(0, 0, 0, 0.3)`,
                        zIndex: 5,
                    }}
                />
            </div>
        </Stack>
    );
};

const SpeedAdjustmentSlider: React.FC<Props> = ({
    cacheKey,
    sport,
    onChange,
    value,
    disabled = false,
}) => {
    const range = RANGE_BY_SPORT[sport];

    const marks = React.useMemo(() => {
        const stepSize = (range.max - range.min) / 4;
        const marks = [];
        for (let i = 0; i < stepSize; i++) {
            marks.push(range.min + stepSize * i);
        }
        return marks.map((m) => ({
            value: m,
            label: `${m < 0 ? Math.floor(m) : Math.round(m)}%`,
        }));
    }, [range.min, range.max]);

    React.useEffect(() => {
        const key = getCacheKey(cacheKey);
        if (!value) {
            window.sessionStorage.removeItem(key);
            return;
        }
        window.sessionStorage.setItem(key, value.toString());
    }, [value, cacheKey]);

    return (
        <Stack direction="row" spacing={1} sx={{ pb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography id="speed-adjustment-label" variant="caption">
                    Speed Adjust
                </Typography>
            </Box>
            <Box flexGrow={1}>
                <AccordionSlider
                    disabled={disabled}
                    min={range.min}
                    max={range.max}
                    marks={marks}
                    step={1}
                    value={value ? Math.round(value) : 0}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(v) => `${Math.round(v)}%`}
                    onChange={(_, v) => onChange(v as number)}
                    sx={{
                        display: "table",
                        margin: "0px auto 0 auto",
                        maxWidth: "90%",
                        ".MuiSlider-track": {
                            color: "primary.light",
                        },
                        "& .MuiSlider-valueLabel": {
                            fontSize: "32px",
                        },
                    }}
                />
            </Box>
        </Stack>
    );
};

const SpeedAdjustment: React.FC<Props> = (props) => {
    const { features: userFeatures } = useCurrentUser();
    const { cacheKey, onChange, value } = props;
    const valuePercent = value ? value * 100 : undefined;
    const trainerFeatures = useTrainerFeatures();

    const onSpeedChange = React.useCallback(
        (v?: number) => onChange(v ? v / 100 : undefined),
        [onChange],
    );

    // Runs on mount or if cache key changes
    React.useEffect(() => {
        const cachedValue = window.sessionStorage.getItem(
            getCacheKey(cacheKey),
        );
        if (cachedValue) {
            const parsedValue = parseInt(cachedValue, 10);
            if (parsedValue) {
                onSpeedChange(parsedValue);
            }
        }
    }, [cacheKey, onSpeedChange]);

    if (
        !trainerFeatures.includes("speedAdjustment") ||
        !userFeatures.includes("SPEED_ADJUSTMENT")
    )
        return null;

    return (
        <SpeedAdjustmentSlider
            {...props}
            value={valuePercent}
            onChange={onSpeedChange}
        />
    );
};

export default SpeedAdjustment;
