/* eslint-disable import/no-cycle */

import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";

import { findApp } from "..";
import logger from "../../../../../log";
import fetchApi, { logFetchError } from "../../../../../util/fetchApi";
import { AppListItemProps } from "../types";

export default function SharedWorkoutCard({
    onClick,
    workout,
}: AppListItemProps): React.JSX.Element | null {
    const [loading, setLoading] = React.useState(false);
    const [originalAppId, setOriginalAppId] = React.useState<number | null>(
        null,
    );
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        async function getOriginalAppId() {
            const result = await fetchApi<number>(
                `/api/app-workouts/original/${workout.id}`,
            );
            return result;
        }
        if (!loading) {
            setLoading(true);
            getOriginalAppId()
                .then((appId) => {
                    setOriginalAppId(appId);
                })
                .catch((e) => {
                    logFetchError(e);
                    setError("Failed to load original app ID");
                });
        }
    }, [workout, loading]);

    if (error) {
        return null;
    }

    if (originalAppId === null) {
        return <CircularProgress />;
    }

    const app = findApp(originalAppId);
    if (app === null) {
        logger.error(`Failed to find app with ID ${originalAppId}`);
        return null;
    }
    return (
        <app.CardComponent
            onClick={onClick}
            workout={{ ...workout, appId: originalAppId }}
        />
    );
}
