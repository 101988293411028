import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    CoordLike,
    PositionLike,
    CoordSys,
    CoordWithSys,
} from "../../../../util/position-types";
import { physicsToCourt } from "../../../../util/positionUtil";
import { useSelectedSport } from "../../../common/context/sport";
import PlayerCourtPosition from "../../Position/PlayerCourtPosition";

interface PlayerAccordionProps extends Omit<AccordionProps, "children"> {
    selectedTrainerPosition?: PositionLike | undefined;
    selectedPlayerPosition?: CoordLike | undefined;
    onPlayerPositionChanged: (value: CoordLike) => void;
}

type HelpTextType = "unset" | "unsaved" | "confirmed";

const HelpText: Record<HelpTextType, string> = {
    unset: "Press the spot on the court opposite the trainer where the player will start when the workout begins",
    unsaved: '"Set Player Position" will confirm the location',
    confirmed: "Position has been set",
};

export default function PlayerAccordion({
    selectedTrainerPosition,
    selectedPlayerPosition,
    onPlayerPositionChanged,
    ...props
}: PlayerAccordionProps): React.JSX.Element {
    const { selected: selectedSport } = useSelectedSport();
    const sys: CoordSys =
        selectedSport === "PLATFORM_TENNIS" ? "court" : "physics";
    const defaultPosition = selectedPlayerPosition
        ? { ...selectedPlayerPosition, sys }
        : undefined;
    const [userPlayerPosition, setUserPlayerPosition] =
        React.useState(defaultPosition);
    const [confirmDisabled, setConfirmDisabled] = React.useState(true);
    const [helpText, setHelpText] = React.useState(HelpText.unset);

    React.useEffect(() => {
        if (selectedPlayerPosition) {
            setUserPlayerPosition({ ...selectedPlayerPosition, sys });
        }
    }, [selectedPlayerPosition, sys]);

    const handlePositionChange = React.useCallback(
        (updated: CoordWithSys) => {
            if (updated) {
                setConfirmDisabled(false);
                setUserPlayerPosition(updated);
                setHelpText(HelpText.unsaved);
            }
        },
        [setUserPlayerPosition, setConfirmDisabled],
    );

    const handleSaveClick = React.useCallback(() => {
        if (userPlayerPosition !== undefined) {
            if (selectedSport === "PLATFORM_TENNIS") {
                const court = physicsToCourt(userPlayerPosition);
                onPlayerPositionChanged(court);
            } else {
                onPlayerPositionChanged(userPlayerPosition);
            }
            setConfirmDisabled(true);
            setHelpText(HelpText.confirmed);
        }
    }, [userPlayerPosition, selectedSport, onPlayerPositionChanged]);

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {props.expanded && (
                    <Typography color="primary.main" variant="h3">
                        Player Position
                    </Typography>
                )}
                {!props.expanded && (
                    <Box>
                        <Typography color="primary.main" variant="h4">
                            Player Position
                        </Typography>
                        <Typography color="primary.main" variant="h3">
                            {selectedPlayerPosition ? "Set" : "Not Set"}
                        </Typography>
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={3}>
                    <Typography variant="body1">{helpText}</Typography>
                    <PlayerCourtPosition
                        playerPositions={
                            userPlayerPosition ? [userPlayerPosition] : []
                        }
                        scale={selectedSport === "PICKLEBALL" ? 0.6 : 0.8}
                        size="FULL"
                        sport={selectedSport}
                        trainerPosition={selectedTrainerPosition}
                        onPointSelected={(p) => handlePositionChange(p)}
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        color="secondary"
                        disabled={confirmDisabled}
                        onClick={() => handleSaveClick()}
                    >
                        Set Player Position
                    </Button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
