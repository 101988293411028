import * as React from "react";
import { useParams, Routes, Route } from "react-router";

import Stack from "@mui/material/Stack";

import type { AppWorkoutWithSummary as AppWorkout } from "@volley/data";

import { logFetchError } from "../../../../../../util/fetchApi";
import Loading from "../../../../../common/Loading";
import { useCurrentUser } from "../../../../../hooks/currentUser";
import { useStatus } from "../../../../../hooks/status";
import { useTrainerFeatures } from "../../../../../hooks/useTrainerFeatures";
import FeatureNotifier from "../../../Shared/FeatureNotifier";
import useAppWorkouts from "../../../db";
import { KnownFeatures, makeWorkoutCompatible } from "../../../util";

import ModuleCompatibilityAlert from "./ModuleCompatibilityAlert";
import ModuleOverview from "./ModuleOverview";
import ModuleStart from "./ModuleStart";

export default function ModulePlay(): React.JSX.Element {
    const { id } = useParams<{ id: string }>();
    const validId = parseInt(id ?? "", 10);
    const { status } = useStatus();
    const features = useTrainerFeatures();
    const { loadModule, loading } = useAppWorkouts();
    const { currentUser } = useCurrentUser();
    const [missingFeatures, setMissingFeatures] = React.useState<
        KnownFeatures[]
    >([]);
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);

    const fetchWorkout = React.useCallback(async () => {
        if (!validId) return;
        const loaded = await loadModule(validId);
        if (loaded) {
            const { workout: data } = loaded;
            setWorkout(data);
        }
    }, [loadModule, validId]);

    React.useEffect(() => {
        fetchWorkout().catch((e) =>
            logFetchError(e, "Failed to fetch workout."),
        );
    }, [fetchWorkout]);

    React.useEffect(() => {
        if (!workout || !features.length) return;

        const { missing, workout: updated } = makeWorkoutCompatible(
            workout,
            features,
        );
        if (missing.length > 0) {
            setWorkout({ ...updated });
            setMissingFeatures(missing);
        }
    }, [workout, features, currentUser]);

    if (!workout || loading) {
        return <Loading />;
    }

    if (status && !features.includes("modules")) {
        return <ModuleCompatibilityAlert />;
    }

    return (
        <Stack
            spacing={1}
            pb={12}
            minHeight="calc(100vh - 66px)"
            bgcolor="white"
            px={1}
        >
            <Routes>
                <Route path="" element={<ModuleOverview workout={workout} />} />
                <Route
                    path="start/*"
                    element={<ModuleStart workout={workout} />}
                />
            </Routes>
            <FeatureNotifier missing={missingFeatures} />
        </Stack>
    );
}
