import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useSelectedSport } from "../../../common/context/sport";
import useRanges from "../../../hooks/ranges";

import AccordionSlider from "./AccordionSlider";

export interface ManualTrimProps {
    disabled: boolean;
    manualTrim: number;
    setManualTrim: (updated: number) => void;
}

export default function ManualTrim({
    disabled,
    manualTrim,
    setManualTrim,
}: ManualTrimProps): React.JSX.Element | null {
    const { selected: selectedSport } = useSelectedSport();
    const { pan: aimRange } = useRanges();

    const trimMarks = React.useMemo(
        () => [
            {
                value:
                    aimRange.min +
                    Math.floor((10 / 100) * Math.abs(aimRange.min)),
                label: "Left",
            },
            {
                value: 0,
                label: "Center",
            },
            {
                value: aimRange.max - Math.floor((10 / 100) * aimRange.max),
                label: "Right",
            },
        ],
        [aimRange.min, aimRange.max],
    );

    if (selectedSport === "PLATFORM_TENNIS") {
        return null;
    }

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography
                variant="caption"
                sx={{
                    minWidth: "30px",
                }}
            >
                Aim
            </Typography>
            <Box
                component="div"
                sx={{
                    flexGrow: 1,
                }}
            >
                <AccordionSlider
                    disabled={disabled}
                    min={aimRange.min}
                    max={aimRange.max}
                    marks={trimMarks}
                    step={0.25}
                    value={manualTrim}
                    track={false}
                    onChange={(_, v) => setManualTrim(v as number)}
                    onChangeCommitted={(_, v) => setManualTrim(v as number)}
                    sx={{
                        display: "table",
                        margin: "0px auto 20px auto",
                        maxWidth: "80%",
                        ".MuiSlider-track": {
                            color: "primary.light",
                        },
                        "& .MuiSlider-valueLabel": {
                            fontSize: "32px",
                        },
                    }}
                    valueLabelDisplay="auto"
                />
            </Box>
        </Stack>
    );
}
