import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AppWorkoutWithSummary as AppWorkout } from "@volley/data";

interface WorkoutDeleteConfirmationProps {
    workout: AppWorkout | null;
    onCancel: () => void;
    onConfirm: () => void;
}

export default function WorkoutDeleteConfirmation({
    workout,
    onCancel,
    onConfirm,
}: WorkoutDeleteConfirmationProps): React.JSX.Element {
    const handleDeleteClicked = React.useCallback(() => {
        if (workout === null) {
            onCancel();
        } else {
            onConfirm();
        }
    }, [workout, onCancel, onConfirm]);
    return (
        <Dialog open={workout !== null}>
            <DialogTitle>Are You Sure?</DialogTitle>
            <DialogContent>
                {`Delete the workout "${workout?.name ?? "Unnamed"}?" Once deleted, you can't recover it.`}
                {workout?.contentProvider && (
                    <Typography color="warning">
                        This workout is currently published. Deleting it will
                        make it unavailable to other users.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "50%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button fullWidth onClick={() => onCancel()}>
                            Cancel
                        </Button>
                    </Box>
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "50%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={() => handleDeleteClicked()}
                            variant="contained"
                            color="secondary"
                        >
                            Delete
                        </Button>
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
