import * as React from "react";
import { useLocation } from "react-router";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";

import logger from "../../log";
import { hasTiltCalibrationFailure } from "../../util/failures";
import {
    APTAIdDialog,
    FirstTimeAPTAIdDialog,
} from "../PTI/Dialogs/APTAIdDialog";
import { useCurrentUser } from "../hooks/currentUser";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";
import usePrevious from "../hooks/usePrevious";
import { useTrainerFeatures } from "../hooks/useTrainerFeatures";
import { useUpdate } from "../hooks/useUpdate";

import AboutDialog from "./AboutDialog";
import ArmMovingDialog from "./ArmMovingDialog";
import CalibratingDialog from "./CalibratingDialog";
import DeviceDialog from "./DeviceDialog";
import DialogContext, { DialogType } from "./DialogContext";
import EStopDialog from "./EStopDialog";
import FatalErrorDialog from "./FatalErrorDialog";
import FinishedDialog from "./FinishedDialog";
import HardwareFailureDialog from "./HardwareFailureDialog";
import HardwareShutdownDialog from "./HardwareShutdownDialog";
import LowBatteryWarning from "./LowBatteryWarning";
import NeedHelpDialog from "./NeedHelp";
import PowerDownDialog from "./PowerDownDialog";
import ServiceUnavailableDialog from "./ServiceUnavailableDialog";
import {
    DisconnectFeedbackDialog,
    ShutdownFeedbackDialog,
} from "./SessionFeedbackDialog";
import {
    ShutdownChecklistDialog,
    DisconnectCleanupChecklistDialog,
} from "./ShutdownChecklistDialog";
import {
    SportSelectorWelcome,
    SportSelectorChange,
} from "./SportSelectorWelcome";
import SportUnavailableDialog from "./SportUnavailable";
import {
    VisionFaultRestartFlow,
    VisionFaultRestartServeAndVolleyFlow,
} from "./VisionFaultRestartFlow";
import WifiCredentialsDialog from "./WifiCredentialsDialog";
import BallJamFlow from "./flows/BallJamFlow";
import ManualRefillBallBinFlow from "./flows/ManualRefillBallBinFlow";
import NoConfirmShutdownFlow from "./flows/NoConfirmShutdownFlow";
import NoConfirmUnpairFlow from "./flows/NoConfirmUnpairFlow";
import NoPowerDropBallJamFlow from "./flows/NoPowerDropBallJamFlow";
import ObstructionFlow from "./flows/ObstructionFlow";
import RefillBallBinFlow from "./flows/RefillBallBinFlow";
import RemovedBatteryFlow from "./flows/RemovedBatteryFlow";
import ReplaceBatteryFlow, {
    NoConfirmReplaceBatteryFlow,
} from "./flows/ReplaceBatteryFlow";
import ReportTrainerIssueFlow from "./flows/ReportTrainerIssueFlow";
import ShutdownFlow from "./flows/ShutdownFlow";
import TiltCalibrationFailureFlow from "./flows/TiltCalibrationFailureFlow";
import UnpairFlow from "./flows/UnpairFlow";
import UpdateFlow, {
    ForceUpdateFlow,
    UserUpdateFlow,
} from "./flows/UpdateFlow";

interface Props {
    children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getDialogChildren(type: DialogType | null): React.FC<any> {
    switch (type) {
        case "ClearObstructions":
            return ObstructionFlow;
        case "RefillBallBin":
            return RefillBallBinFlow;
        case "ManualRefillBallBin":
            return ManualRefillBallBinFlow;
        case "ReplaceBatteries":
            return ReplaceBatteryFlow;
        case "BallJam":
            return BallJamFlow;
        case "BallJamNoPowerDrop":
            return NoPowerDropBallJamFlow;
        case "Unpair":
            return UnpairFlow;
        case "NoConfirmUnpair":
            return NoConfirmUnpairFlow;
        case "Shutdown":
            return ShutdownFlow;
        case "NoConfirmShutdown":
            return NoConfirmShutdownFlow;
        case "RemovedBattery":
            return RemovedBatteryFlow;
        case "Update":
            return UpdateFlow;
        case "ForceUpdate":
            return ForceUpdateFlow;
        case "UserUpdate":
            return UserUpdateFlow;
        case "TiltCalibrationFailure":
            return TiltCalibrationFailureFlow;

        case "About":
            return AboutDialog;
        case "APTAId":
            return APTAIdDialog;
        case "InitialAPTAId":
            return FirstTimeAPTAIdDialog;
        case "EStop":
            return EStopDialog;
        case "FatalError":
            return FatalErrorDialog;
        case "Finished":
            return FinishedDialog;
        case "ArmMoving":
            return ArmMovingDialog;
        case "Calibrating":
            return CalibratingDialog;
        case "HardwareFailure":
            return HardwareFailureDialog;
        case "HardwareShutdown":
            return HardwareShutdownDialog;
        case "PowerDown":
            return PowerDownDialog;
        case "ServiceUnavailable":
            return ServiceUnavailableDialog;
        case "ShutdownCleanupChecklist":
            return ShutdownChecklistDialog;
        case "ShutdownFeedback":
            return ShutdownFeedbackDialog;
        case "DisconnectCleanupChecklist":
            return DisconnectCleanupChecklistDialog;
        case "DisconnectFeedback":
            return DisconnectFeedbackDialog;
        case "ShutdownHardwareChecklist":
            return ShutdownChecklistDialog;
        case "WifiCredentials":
            return WifiCredentialsDialog;
        case "NeedHelp":
            return NeedHelpDialog;
        case "VisionFaultDialog":
            return VisionFaultRestartFlow;
        case "VisionFaultServeAndVolleyDialog":
            return VisionFaultRestartServeAndVolleyFlow;
        case "InvalidSportSelection":
            return SportUnavailableDialog;
        case "SportSelectorWelcome":
            return SportSelectorWelcome;
        case "SportSelectorChange":
            return SportSelectorChange;
        case "LowBatteryWarning":
            return LowBatteryWarning;
        case "NoConfirmReplaceBatteries":
            return NoConfirmReplaceBatteryFlow;
        case "ReportIssue":
            return ReportTrainerIssueFlow;
        default:
            return function EmptyComponent() {
                return <></>;
            };
    }
}

const showDeviceOnClose: DialogType[] = [
    "Finished",
    "Shutdown",
    "Unpair",
    "HardwareFailure",
];
const showWhileDisconnected: DialogType[] = [
    "APTAId",
    "InitialAPTAId",
    "ShutdownCleanupChecklist",
    "Cleanup",
    "DisconnectCleanupChecklist",
    "ShutdownHardwareChecklist",
    "DisconnectFeedback",
    "ShutdownFeedback",
    "NeedHelp",
    "SportSelectorWelcome",
    "SportSelectorChange",
];

const DialogProvider = React.memo(({ children }: Props) => {
    const { pathname } = useLocation();
    const { status: pairingStatus, sessionId } = usePairingContext();
    const { isAdmin, features } = useCurrentUser();
    const trainerFeatures = useTrainerFeatures();
    const { status, networkStateCode } = useStatus();
    const { updateRequired, buildsReadyToInstall: buildsReadyToIntall } =
        useUpdate();
    const [stateDialogType, setDialogType] = React.useState<DialogType | null>(
        null,
    );
    const onClose = React.useCallback(() => {
        if (stateDialogType && showDeviceOnClose.includes(stateDialogType)) {
            setDialogType("Device");
        } else {
            setDialogType(null);
        }
    }, [stateDialogType]);
    const [dialogProps, setDialogProps] = React.useState<
        Omit<DialogProps, "open">
    >({ onClose });
    const [drawerHeight, setDrawerHeight] = React.useState("100vh");
    const [disableOnClose, setDisableOnClose] = React.useState(false);
    const [ignoreProximity, setIgnoreProximity] = React.useState(false);
    const [openedRefill, setOpenedRefill] = React.useState(false);
    const [openedBallJam, setOpenedBallJam] = React.useState(false);
    const [openedRemoved, setOpenRemoved] = React.useState(false);
    const [batteryWarningDismissed, setBatteryWarningDismissed] =
        React.useState(false);
    const [tiltCalibrationDismissed, setTiltCalibrationDismissed] =
        React.useState(false);

    const forceDeviceDialog = React.useMemo(() => {
        if (pairingStatus !== "paired" || pathname.startsWith("/admin")) {
            return false;
        }

        if (stateDialogType === "ReportIssue") {
            return false;
        }

        // if there's a fault we force the dialog open, with a few exceptions
        if (status?.fault) {
            if (status.fault.failures.every((f) => f.type === "SafetyIssue")) {
                return !ignoreProximity;
            }

            if (status.fault.failures.every((f) => f.type === "BallBinEmpty")) {
                return false;
            }

            if (status.fault.failures.every((f) => f.source === "vision")) {
                return status?.trainer?.calibrated === "Uncalibrated";
            }

            return true;
        }

        if (status?.ready === "INIT") {
            return true;
        }

        return (
            status?.trainer?.calibrated !== "Calibrated" ||
            networkStateCode !== "Connected"
        );
    }, [
        pairingStatus,
        pathname,
        stateDialogType,
        status?.fault,
        status?.ready,
        status?.trainer?.calibrated,
        networkStateCode,
        ignoreProximity,
    ]);

    const dialogType =
        stateDialogType === null && forceDeviceDialog
            ? "Device"
            : stateDialogType;
    const DialogChildren = React.useMemo(
        () => getDialogChildren(dialogType),
        [dialogType],
    );

    const prevDialogType = usePrevious(dialogType);

    React.useEffect(() => {
        if (prevDialogType !== dialogType) {
            logger.info(
                `Changing dialog from ${prevDialogType ?? "none"} to ${dialogType || "none"}`,
            );
        }
    }, [prevDialogType, dialogType]);

    React.useEffect(() => {
        if (pathname.toLowerCase().startsWith("/admin")) {
            setDialogType(null);
            return;
        }

        if (
            !status?.ready &&
            dialogType &&
            !showWhileDisconnected.includes(dialogType)
        ) {
            logger.info(
                "Trainer status unavailable - dismissing all dialogs except cleanup",
            );
            setDialogType(null);
            return;
        }

        if (pairingStatus !== "paired") {
            return;
        }

        if (updateRequired && !isAdmin()) {
            // if we're below the min required version, show the update dialog
            if (dialogType !== "Shutdown" && dialogType !== "Unpair") {
                logger.info("Showing update dialog");
                setDialogType("ForceUpdate");
                return;
            }
        }

        // we automatically open the user update dialog if:
        //  1. builds are ready to install
        //  2. user has the USER_UPDATE feature
        //  3. we haven't shown the dialog yet this session
        if (
            buildsReadyToIntall &&
            !isAdmin() &&
            features.includes("USER_UPDATE") &&
            sessionId
        ) {
            const lastSessionChecked = sessionStorage.getItem(
                "builds_ready_to_install_last_session",
            );
            const sessionChecked = lastSessionChecked === sessionId.toString();

            if (
                dialogType !== "Shutdown" &&
                dialogType !== "Unpair" &&
                !sessionChecked &&
                status?.ready !== "INIT"
            ) {
                logger.info("Showing forced short update dialog");
                setDialogType("UserUpdate");
                sessionStorage.setItem(
                    "builds_ready_to_install_last_session",
                    sessionId.toString(),
                );
                return;
            }
        }

        // if we're in the middle of an update, show the update dialog
        if (status?.update?.inProgress) {
            setDialogType("Update");
            return;
        }

        if (
            networkStateCode === "ServiceConnectionDown" ||
            networkStateCode === "TrainerConnectionDown" ||
            networkStateCode === "ConnectionReestablishing"
        ) {
            if (dialogType !== "ServiceUnavailable") {
                logger.warn("Showing ServiceUnavailable dialog", {
                    networkStateCode,
                });
                setDialogType("ServiceUnavailable");
            }
            return;
        }

        const fault = status?.fault;
        if (fault?.failures.some((f) => f.type === "EStop")) {
            // if we're shutting down, ignore EStop
            if (stateDialogType !== "NoConfirmShutdown") {
                logger.info("Showing EStop dialog");
                setDialogType("EStop");
                return;
            }
        }

        if (fault?.failures.some((f) => f.type === "RemovedBattery")) {
            // don't open battery removed dialog if we're in an update flow
            if (
                !openedRemoved &&
                dialogType !== "Update" &&
                dialogType !== "BallJamNoPowerDrop"
            ) {
                logger.warn("Showing RemovedBattery dialog", { fault });
                setOpenRemoved(true);
                setDialogType("RemovedBattery");
                return;
            }
        }

        if (fault?.failures.some((f) => f.type === "HardwareShutdown")) {
            logger.warn(
                "Showing ShutdownCleanupChecklist dialog due to hardware shutdown",
                { fault },
            );
            setDialogType("ShutdownCleanupChecklist");
            return;
        }

        if (fault?.failures.some((f) => f.type === "BallBinEmpty")) {
            // only open refill dialog if we haven't opened it yet
            if (!openedRefill) {
                setOpenedRefill(true);
                setDialogType("RefillBallBin");
            }
        }

        if (
            fault?.failures.length === 1 &&
            (fault.failures[0].type === "FeedFailure" ||
                fault.failures[0].type === "FeedFailureNoPowerDrop")
        ) {
            // only open refill dialog if we haven't opened it yet
            if (!openedBallJam) {
                logger.info("Show EmptyBallBin dialog", { fault });
                setOpenedBallJam(true);
                if (fault.failures[0].type === "FeedFailureNoPowerDrop") {
                    setDialogType("BallJamNoPowerDrop");
                } else {
                    setDialogType("BallJam");
                }
            }
        }

        // special case for gate sensor failure for older trainers who don't
        // yet propogate gate sensor failures as a FeedFailureNoPowerDrop
        if (
            fault?.failures.length === 1 &&
            fault.failures[0].type === "Other" &&
            fault.failures[0].message === "Gate Sensor Failure"
        ) {
            if (!openedBallJam) {
                logger.info("Show EmptyBallBin dialog", { fault });
                setOpenedBallJam(true);
                setDialogType("BallJamNoPowerDrop");
            }
        }

        // special case for tilt servo fault due to a ball in the head during calibration
        if (
            !tiltCalibrationDismissed &&
            hasTiltCalibrationFailure(fault, trainerFeatures)
        ) {
            logger.info(`Showing TiltCalibrationFailure workflow.`);
            setDialogType("TiltCalibrationFailure");
        }

        // no faults, reset if we've opened refill
        if (!fault || fault?.failures.every((f) => f.source === "vision")) {
            setOpenedRefill(false);
            setOpenedBallJam(false);
            setOpenRemoved(false);
        }

        if (status?.ready === "INIT") {
            // if we're doing a battery swap or a battery was removed, we'll enter INIT state
            // but we want to stay in those flows
            // also, if there's a hardware failure, we want to show that dialog
            // or any of the shutdown/unpair dialogs
            if (
                dialogType === "ReplaceBatteries" ||
                dialogType === "RemovedBattery" ||
                dialogType === "BallJamNoPowerDrop" ||
                dialogType === "HardwareFailure" ||
                dialogType === "Finished" ||
                dialogType === "Shutdown" ||
                dialogType === "NoConfirmShutdown" ||
                dialogType === "Unpair" ||
                dialogType === "NoConfirmUnpair"
            ) {
                return;
            }

            setDialogType("Device");
        }
    }, [
        dialogType,
        networkStateCode,
        openedRefill,
        openedBallJam,
        openedRemoved,
        pairingStatus,
        pathname,
        stateDialogType,
        status?.softwareBuildLabel,
        status?.fault,
        status?.ready,
        status?.vision.serviceState,
        status?.update?.inProgress,
        trainerFeatures,
        updateRequired,
        isAdmin,
        buildsReadyToIntall,
        features,
        sessionId,
        tiltCalibrationDismissed,
    ]);

    const value = React.useMemo(
        () => ({
            dialogType,
            forceDeviceDialog,
            ignoreProximity,
            batteryWarningDismissed,
            onClose,
            setDialogProps,
            setDisableOnClose,
            setDialogType,
            setDrawerHeight,
            setIgnoreProximity,
            setBatteryWarningDismissed,
            setTiltCalibrationDismissed,
        }),
        [
            dialogType,
            forceDeviceDialog,
            ignoreProximity,
            batteryWarningDismissed,
            onClose,
        ],
    );

    // Reset dialog props on every `dialogType` change
    React.useEffect(() => {
        setDialogProps({ onClose, fullWidth: true });
    }, [dialogType, onClose]);

    const modalOpen = dialogType !== null && dialogType !== "Device";
    const drawerOpen = dialogType !== null && dialogType === "Device";

    return (
        <DialogContext.Provider value={value}>
            {children}
            <Dialog
                {...dialogProps}
                onClose={disableOnClose ? undefined : dialogProps.onClose}
                open={modalOpen}
            >
                {modalOpen && <DialogChildren />}
            </Dialog>
            <Drawer
                role={drawerOpen ? "dialog" : undefined}
                open={drawerOpen}
                anchor="top"
                onClose={
                    forceDeviceDialog ? undefined : () => setDialogType(null)
                }
                variant="temporary"
                hideBackdrop={false}
                sx={{
                    "& .MuiDrawer-paper": {
                        backgroundColor: "#D0D2D8",
                        height: drawerHeight,
                    },
                }}
            >
                <DeviceDialog />
            </Drawer>
        </DialogContext.Provider>
    );
});

DialogProvider.displayName = "DialogProvider";

export default DialogProvider;
