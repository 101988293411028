import * as React from "react";
import { useNavigate } from "react-router";

import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import StartIcon from "@mui/icons-material/Start";
import UndoIcon from "@mui/icons-material/Undo";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { ModuleConfig, ModuleShot } from "@volley/shared/apps/module-models";

import type { TrainerPositionLike } from "../../../../../../../util/position-types";
import ModuleEditValidationErrors from "../ModuleEditValidationErrors";
import { WorkoutAction, WorkoutForm } from "../reducer";

import PositionControls from "./PositionControls";
import Shots from "./Shots";
import WorkoutControls from "./WorkoutControls";

type TabValue = "shots" | "position" | "details";

interface Props {
    workout: WorkoutForm;
    dispatch: React.Dispatch<WorkoutAction>;
    onCancel: () => void;
    onDone: () => Promise<void>;
    onDuplicate: () => Promise<void>;
    onDelete: () => void;
    onChangeVisualizedShotIds: (shotIds: string[] | null) => void;
}

export default function EditControls({
    workout,
    dispatch,
    onCancel,
    onDone,
    onDuplicate,
    onDelete,
    onChangeVisualizedShotIds,
}: Props): React.JSX.Element {
    const navigate = useNavigate();
    const [tab, setTab] = React.useState<TabValue>("details");
    const [editingShot, setEditingShot] = React.useState(false);
    const [moreAnchorEl, setMoreAnchorEl] = React.useState<HTMLElement | null>(
        null,
    );
    const config = workout.config as unknown as ModuleConfig;

    const canPlay = workout.mode === "edit" && workout.id && !editingShot;

    const updateShots = (shots: ModuleShot[]) => {
        dispatch({
            type: "updateConfig",
            value: { ...config, shots },
        });
    };

    const updateRandomize = (randomize: boolean) => {
        dispatch({
            type: "updateConfig",
            value: { ...config, randomize },
        });
    };

    const onPlayNow = async () => {
        if (canPlay) {
            await onDone();
            navigate(
                `/content/apps/workouts/plugin/play/${workout.appId}/${workout.id}`,
            );
        }
    };

    const onMoreOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        setMoreAnchorEl(e.currentTarget);
    };

    const onMoreClose = () => {
        setMoreAnchorEl(null);
    };

    const onMoreClick = (cb: () => void) => {
        cb();
        onMoreClose();
    };

    React.useEffect(() => {
        if (tab !== "shots") {
            onChangeVisualizedShotIds(null);
            setEditingShot(false);
        }
    }, [tab, onChangeVisualizedShotIds]);

    const position = React.useMemo<TrainerPositionLike>(
        () => ({
            x: workout.positionX,
            y: workout.positionY,
            yaw: workout.positionYaw,
            heightIn: workout.positionHeight,
        }),
        [
            workout.positionX,
            workout.positionY,
            workout.positionYaw,
            workout.positionHeight,
        ],
    );

    const doneDisabled = !(workout.isOwner || workout.isEditor);

    return (
        <Stack>
            <Tabs
                value={tab}
                onChange={(_: unknown, value: TabValue) => setTab(value)}
                variant="fullWidth"
                sx={{
                    "& .MuiTab-root": {
                        fontSize: 10,
                        p: 0,
                        minHeight: 32,
                    },
                    minHeight: 32,
                    mb: 1,
                }}
            >
                <Tab label="Details" value="details" />
                <Tab label="Shots" value="shots" />
                <Tab label="Position" value="position" />
            </Tabs>
            {!tab && <Divider />}
            {tab === "shots" && (
                <Shots
                    shots={config.shots}
                    position={position}
                    interval={config.interval}
                    updateShots={updateShots}
                    randomize={config.randomize}
                    updateRandomize={updateRandomize}
                    setEditingShot={setEditingShot}
                    onChangeVisualizedShotIds={onChangeVisualizedShotIds}
                />
            )}
            {tab === "position" && (
                <PositionControls workout={workout} dispatch={dispatch} />
            )}
            {tab === "details" && (
                <WorkoutControls workout={workout} dispatch={dispatch} />
            )}
            {workout.validationErrors && (
                <ModuleEditValidationErrors errors={workout.validationErrors} />
            )}
            {!editingShot && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    py={3}
                    px={1}
                >
                    <IconButton onClick={onMoreOpen}>
                        <MoreHorizIcon />
                    </IconButton>
                    <Menu
                        anchorEl={moreAnchorEl}
                        open={!!moreAnchorEl}
                        onClose={onMoreClose}
                    >
                        <MenuItem
                            onClick={() => onMoreClick(onDuplicate)}
                            disabled={!canPlay}
                        >
                            <ListItemIcon>
                                <CopyAllIcon />
                            </ListItemIcon>
                            <ListItemText>Duplicate</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => onMoreClick(onCancel)}
                            disabled={!workout.isOwner || !workout.isEditor}
                        >
                            <ListItemIcon>
                                <UndoIcon />
                            </ListItemIcon>
                            <ListItemText>Revert Changes</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => onMoreClick(onDelete)}
                            disabled={!canPlay || !workout.isOwner}
                        >
                            <ListItemIcon>
                                <DeleteIcon color="error" />
                            </ListItemIcon>
                            <ListItemText sx={{ color: "error.main" }}>
                                Delete
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                    {canPlay && (
                        <Button onClick={onPlayNow} startIcon={<StartIcon />}>
                            Play Now
                        </Button>
                    )}
                    <Button
                        onClick={onDone}
                        startIcon={<DoneIcon />}
                        disabled={doneDisabled}
                    >
                        {`${workout.isDirty ? "*" : ""}Done`}
                    </Button>
                </Stack>
            )}
        </Stack>
    );
}
