import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { round } from "../../../../util/positionUtil";
import { pluralize } from "../../../../util/text";
import SlimNumberInput from "../../util/SlimNumberInput";
import AccordionSlider from "../Shared/AccordionSlider";

function range(start: number, end: number, step: number): number[] {
    const result = [];
    for (let i = start; i <= end; i += step) {
        result.push(i);
    }
    return result;
}

interface ShotTimingAccordionProps extends Omit<AccordionProps, "children"> {
    firstBounce: number | null;
    defaultInterval: number;
    selectedInterval: number;
    onIntervalChanged: (value: number) => void;
}

const FEED_STEP = 0.25;

export default function ShotTimingAccordion({
    firstBounce,
    defaultInterval,
    selectedInterval,
    onIntervalChanged,
    ...props
}: ShotTimingAccordionProps): React.JSX.Element {
    const theme = useTheme();

    const roundedFirstBounce = React.useMemo(() => {
        if (firstBounce) {
            return round(firstBounce, 0.25);
        }

        return null;
    }, [firstBounce]);

    const diff = React.useMemo(
        () => selectedInterval - defaultInterval,
        [selectedInterval, defaultInterval],
    );

    const rateMarks = React.useMemo(
        () =>
            range(1, 10, 1).map((v) => {
                if (v % 2 === 0 || v === defaultInterval || v === firstBounce) {
                    return { label: `${v}`, value: v };
                }

                return { label: "", value: v };
            }),
        [firstBounce, defaultInterval],
    );

    const sliderStyles = React.useMemo(() => {
        const darkGrey = theme.palette.grey[500];
        const lightGrey = theme.palette.grey[200];
        const darkGreyBreak = (defaultInterval - 1) * (100 / 9);
        const baseStyle: Record<string, unknown> = {
            zIndex: 1,
            "& .MuiSlider-rail": {
                opacity: 1,
                background: `linear-gradient(90deg, ${lightGrey} ${darkGreyBreak}%, ${darkGrey} 0%)`,
            },
        };
        range(1, 10, 1).forEach((v) => {
            const key = `& .MuiSlider-markLabel[data-index="${v}"]`;
            let color = v >= defaultInterval ? "primary.main" : "grey";
            let fontWeight = v >= defaultInterval ? "400" : "100";
            if (v + 1 === defaultInterval) {
                color = "primary.light";
                fontWeight = "800";
            }
            if (v + 1 === round(firstBounce ?? 0)) {
                color = "secondary.main";
                fontWeight = "800";
            }
            baseStyle[key] = { color, fontWeight };
        });
        return baseStyle;
    }, [defaultInterval, theme, firstBounce]);

    const summary = React.useMemo(() => {
        const baseMessage = "Minimum Shot Interval";
        const interval =
            selectedInterval < defaultInterval
                ? defaultInterval
                : selectedInterval;
        return `${baseMessage} ${interval} sec`;
    }, [defaultInterval, selectedInterval]);

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {!props.expanded && (
                    <Box component="div">
                        <Typography variant="h4" color="primary.main">
                            Shot Timing
                        </Typography>
                        <Typography variant="h5" color="primary.main">
                            {summary}
                        </Typography>
                    </Box>
                )}
                {props.expanded && (
                    <Typography variant="h3" color="primary.main">
                        Shot Timing
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    <Box component="div">
                        <Typography sx={{ display: "inline" }}>
                            The default feed rate is{" "}
                        </Typography>
                        <Typography
                            sx={{ display: "inline" }}
                            color="info.main"
                            fontWeight={800}
                        >
                            {defaultInterval}
                        </Typography>
                        <Typography sx={{ display: "inline" }}>
                            {" "}
                            seconds.
                        </Typography>
                    </Box>

                    {roundedFirstBounce && (
                        <Box component="div">
                            <Typography sx={{ display: "inline" }}>
                                Time to first bounce is{" "}
                            </Typography>
                            <Typography
                                sx={{ display: "inline" }}
                                color="secondary.main"
                                fontWeight={800}
                            >
                                {roundedFirstBounce}
                            </Typography>
                            <Typography sx={{ display: "inline" }}>
                                {` ${pluralize(roundedFirstBounce, "second")}`}
                            </Typography>
                        </Box>
                    )}

                    <SlimNumberInput
                        minValue={defaultInterval}
                        maxValue={10}
                        decimalPrecision={2}
                        showDecimal
                        onChange={(value) => {
                            onIntervalChanged(value);
                        }}
                        incrementValue={FEED_STEP}
                        value={selectedInterval}
                    />

                    <AccordionSlider
                        min={1}
                        max={10}
                        marks={rateMarks}
                        step={FEED_STEP}
                        value={selectedInterval}
                        track={false}
                        onChange={(_, v) =>
                            onIntervalChanged(
                                Math.max(v as number, defaultInterval),
                            )
                        }
                        onChangeCommitted={(_, v) =>
                            onIntervalChanged(
                                Math.max(v as number, defaultInterval),
                            )
                        }
                        sx={sliderStyles}
                    />

                    {selectedInterval > defaultInterval && (
                        <Box component="div">
                            <Typography sx={{ display: "inline" }}>
                                This throw will pause{" "}
                            </Typography>
                            <Typography
                                sx={{ display: "inline" }}
                                color="info.main"
                                fontWeight={800}
                            >
                                {diff}
                            </Typography>
                            <Typography sx={{ display: "inline" }}>
                                {` ${pluralize(diff, "second")} longer than default before the next throw.`}
                            </Typography>
                        </Box>
                    )}
                    {selectedInterval <= defaultInterval && (
                        <Typography>
                            This throw will use the default feed rate.
                        </Typography>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
