import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    setPhysicsModel,
    getPhysicsModel,
    launchHeightInches2HeadHeight,
    physics2localization,
} from "@volley/physics/dist/conversions";
import { PhysicsModelName } from "@volley/physics/dist/models";
import { VisionStatus } from "@volley/shared/coach-models";
import { FullPosition } from "@volley/shared/vision-models";

import logger from "../../../../../log";
import { degToRad } from "../../../../../util/positionUtil";
import CloseableDialogTitle from "../../../../common/CloseableDialogTitle";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import { WorkoutForVisualizer } from "../../../../common/Visualizer/types";
import { useSelectedSport, Sport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useStatus } from "../../../../hooks/status";
import useIntercom from "../../../../hooks/useIntercom";
import { useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import usePrevious from "../../../../hooks/usePrevious";

import ContactSupport from "./ContactSupport";

interface PlannedPosition {
    x: number;
    y: number;
    yaw: number;
    heightIn: number;
}

type LocalizationResult = "fail" | "good" | "improve";

const DISTANCE_DELTA_ALLOWED_BY_SPORT = {
    PLATFORM_TENNIS: 500,
    PADEL: 500,
    TENNIS: 500,
    PICKLEBALL: 500,
};

const HEIGHT_DELTA_ALLOWED_BY_SPORT = {
    PLATFORM_TENNIS: 100,
    PADEL: 100,
    TENNIS: 150,
    PICKLEBALL: 100,
};

const YAW_DELTA_ALLOWED_BY_SPORT = {
    PLATFORM_TENNIS: 15,
    PADEL: 4,
    PICKLEBALL: 4,
    TENNIS: 2,
};

function absoluteDiff(a: number, b: number) {
    if ((a > 0 && b > 0) || (a < 0 && b < 0)) {
        return (
            Math.max(Math.abs(a), Math.abs(b)) -
            Math.min(Math.abs(a), Math.abs(b))
        );
    }

    return Math.abs(a) + Math.abs(b);
}

function isCloseEnough(
    plannedPosition: PlannedPosition,
    position: FullPosition,
    sport: Sport,
    physicsModelName: PhysicsModelName,
    maxTrainerLiftHeight: number,
    compareHeight = true,
): boolean {
    const distanceDeltaAllowed = DISTANCE_DELTA_ALLOWED_BY_SPORT[sport];
    const heightDeltaAllowed = HEIGHT_DELTA_ALLOWED_BY_SPORT[sport];
    const yawDeltaAllowed = YAW_DELTA_ALLOWED_BY_SPORT[sport];

    let comparePosition = { ...plannedPosition };
    if (sport !== "PLATFORM_TENNIS") {
        logger.info(
            `Converting Planned Position: ${JSON.stringify(comparePosition)} for comparison`,
        );
        setPhysicsModel(physicsModelName, sport);
        const asLocalized = physics2localization({
            x: comparePosition.x,
            y: comparePosition.y,
            z: launchHeightInches2HeadHeight(comparePosition.heightIn),
        });
        comparePosition = {
            x: asLocalized.x,
            y: asLocalized.y,
            yaw: degToRad(comparePosition.yaw),
            heightIn: comparePosition.heightIn,
        };
        logger.info(
            `Updated Compare Position: ${JSON.stringify(comparePosition)}`,
        );
    }

    logger.info(`Localized Position: ${JSON.stringify(position)}`);

    const distance = Math.sqrt(
        (position.x - comparePosition.x) ** 2 +
            (position.y - comparePosition.y) ** 2,
    );
    const distanceGood = distance <= distanceDeltaAllowed;

    let heightDiff = 0;
    let heightGood = true;
    const physicsModel = getPhysicsModel();
    const launchPointToCameraDistanceMeters =
        physicsModel.trainerGeometry.cameraOffsetFromHead.z -
        physicsModel.trainerGeometry.launchOriginOffset.z;
    if (compareHeight) {
        logger.info("Comparing height as part of localization validation");
        const launchPointToCameraDistanceMeters =
            physicsModel.trainerGeometry.cameraOffsetFromHead.z -
            physicsModel.trainerGeometry.launchOriginOffset.z;
        // compute planned camera height in mm from launch position height in inches
        const plannedHeightAdjusted =
            comparePosition.heightIn * 25.4 +
            launchPointToCameraDistanceMeters * 1000.0;
        heightDiff = Math.abs(position.z - plannedHeightAdjusted);
        heightGood = heightDiff <= heightDeltaAllowed;
    } else {
        // check to make sure the Z value is still reasonable based on trainer geometry
        logger.info("Checking if height is within trainer limits");
        const maxZ =
            maxTrainerLiftHeight * 25.4 +
            launchPointToCameraDistanceMeters * 1000.0;
        heightDiff = Math.abs(maxZ - position.z);
        heightGood = position.z <= maxZ;
        if (!heightGood) {
            logger.info(
                `Localized height too tall! Max Z: ${maxZ} (based on trainer max lift height), Localized Z: ${position.z}, Height Diff: ${heightDiff}`,
            );
        }
    }

    const yawDiff = absoluteDiff(plannedPosition.yaw, position.yaw);
    const yawGood = yawDiff < degToRad(yawDeltaAllowed);

    logger.info("[serveAndVolley] closeEnough summary", {
        plannedPosition,
        position,
        distance,
        distanceGood,
        heightDiff,
        heightGood,
        yawGood,
        yawDiff,
    });

    // return true;
    return distanceGood && heightGood && yawGood;
}

function useLocalization(
    dialogOpen: boolean,
    plannedPosition: PlannedPosition,
    force: boolean,
    onLocalized: (result: LocalizationResult) => void,
    localizationHeightIn = plannedPosition.heightIn,
) {
    const [hasTriedOneShot, setHasTriedOneShot] = React.useState(false);
    const [hasTriedSmart, setHasTriedSmart] = React.useState(false);
    const { selected: sport } = useSelectedSport();
    const { physicsModelName } = usePhysicsModelContext();
    const { liftRange } = useLift();

    const { delta, improve, position, updatePosition, localizationStatus } =
        usePosition();

    const previousLocalizationStatus = usePrevious(localizationStatus);

    // internal plannedPosition may have a heightIn that's different from the planned HeightIn
    const internalPlannedPosition = React.useMemo(() => {
        return { ...plannedPosition, heightIn: localizationHeightIn };
    }, [plannedPosition, localizationHeightIn]);

    const hintPosition = React.useMemo(() => {
        if (sport === "PLATFORM_TENNIS") {
            return internalPlannedPosition;
        }

        setPhysicsModel(physicsModelName as PhysicsModelName, sport);

        const asLocalized = physics2localization({
            x: internalPlannedPosition.x,
            y: internalPlannedPosition.y,
            z: launchHeightInches2HeadHeight(internalPlannedPosition.heightIn),
        });

        const convertedHint = {
            x: asLocalized.x,
            y: asLocalized.y,
            yaw: degToRad(internalPlannedPosition.yaw),
            heightIn: internalPlannedPosition.heightIn,
        };
        logger.info(
            `Converted Hint Position: ${JSON.stringify(convertedHint)}`,
        );
        return convertedHint;
    }, [physicsModelName, internalPlannedPosition, sport]);

    // Handle localization status changes
    React.useEffect(() => {
        if (
            localizationStatus === "idle" &&
            previousLocalizationStatus === "localizing"
        ) {
            const shouldDoSmart = false;
            if (shouldDoSmart) {
                // If the one-shot localization failed, try smart localization
                updatePosition(hintPosition, false);
                setHasTriedSmart(true);
            } else {
                // if we don't have a position, we failed
                if (!position) {
                    onLocalized("fail");
                    return;
                }

                // if this is the result of smart localizaton, don't compare the height - the Z distance returned is not necessarily the same as the planned height
                const compareHeight = hasTriedSmart ? false : true;
                logger.info(
                    `Checking for close enough - including height in comparison: ${compareHeight ? "yes" : "no"}`,
                );
                // if we're not close enough, we need to improve
                if (
                    !isCloseEnough(
                        internalPlannedPosition,
                        position as FullPosition,
                        sport,
                        physicsModelName as PhysicsModelName,
                        liftRange.max,
                        compareHeight,
                    )
                ) {
                    onLocalized("improve");
                    return;
                }

                // Otherwise, we're good
                onLocalized("good");
            }
        }
    }, [
        delta,
        improve,
        hasTriedOneShot,
        hasTriedSmart,
        hintPosition,
        liftRange,
        localizationStatus,
        physicsModelName,
        sport,
        onLocalized,
        position,
        internalPlannedPosition,
        previousLocalizationStatus,
        updatePosition,
    ]);

    // Handle localization when the dialog opens
    React.useEffect(() => {
        if (dialogOpen && !hasTriedOneShot) {
            // If there's a position already and we're not forcing, no need to localize
            if (position && !force) {
                onLocalized("good");
                return;
            }

            // Trigger the one-shot localization
            updatePosition(hintPosition, true);
            setHasTriedOneShot(true);
        }
    }, [
        dialogOpen,
        force,
        hasTriedOneShot,
        onLocalized,
        hintPosition,
        position,
        updatePosition,
    ]);

    const retry = React.useCallback(() => {
        const oneShot = sport === "PLATFORM_TENNIS";
        logger.info(
            `Retrying localization for ${sport} with ${oneShot ? "one-shot" : "smart"}`,
        );
        updatePosition(hintPosition, oneShot);
        setHasTriedSmart(true);
    }, [hintPosition, sport, updatePosition]);

    // Reset the state when the dialog closes
    React.useEffect(() => {
        if (!dialogOpen) {
            setHasTriedOneShot(false);
            setHasTriedSmart(false);
        }
    }, [dialogOpen]);

    return {
        localizationStatus,
        retry,
    };
}

interface LocalizationFailedProps {
    sport: Sport;
}
function LocalizationFailedContent({
    sport,
}: LocalizationFailedProps): React.JSX.Element {
    return (
        <Box component="div">
            <Typography mb={2}>Please confirm the following:</Typography>
            <Typography component="ul" mb={2}>
                <li>
                    The camera is not pointed into harsh lighting conditions
                    (sunrise or sunset)
                </li>
                <li>
                    The trainer is positioned according to the photo under View
                    Instructions
                </li>
                <li>Ad/Deuce appropriately selected</li>
                <li>
                    The camera is not obstructed by the cover or another object
                </li>
                <li>
                    {`There are no other lines on the ${sport === "TENNIS" ? "tennis" : "platform"} court (pickleball
                    lines or chalk)`}
                </li>
            </Typography>
            <ContactSupport />
        </Box>
    );
}

function LocalizationInProgressContent(): React.JSX.Element {
    return (
        <Stack alignItems="center" direction="column">
            <Typography variant="body1" mb={2}>
                The trainer is confirming it is in the correct place before
                starting.
            </Typography>
            <CircularProgress size={80} color="info" />
        </Stack>
    );
}

interface LocalizationImproveContentProps {
    workout: WorkoutForVisualizer | undefined;
}
function LocalizationImproveContent({
    workout,
}: LocalizationImproveContentProps): React.JSX.Element {
    const [showHelp, setShowHelp] = React.useState(false);

    return (
        <Box>
            <ResizableWorkoutVisualizer
                workout={workout}
                maxHeight={225}
                positionProximity="Improve"
                improveMode="xy"
            />
            <Box component="div">
                <Typography
                    variant="body2"
                    color="primary.main"
                    display="inline"
                >
                    Trainer Position:
                </Typography>
                <Typography variant="body2" color="info" display="inline">
                    Improve
                </Typography>
                <IconButton
                    size="small"
                    edge="end"
                    color="info"
                    aria-label="help"
                    onClick={() => {
                        setShowHelp(true);
                    }}
                >
                    <HelpIcon />
                </IconButton>
            </Box>
            <Dialog open={showHelp} style={{ whiteSpace: "pre-wrap" }}>
                <DialogTitle>Improve Trainer Position</DialogTitle>
                <DialogContent>
                    Please move the trainer closer to the transparent trainer by
                    following the arrow. Click the green button to retry.
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => setShowHelp(false)}
                    >
                        Got it!
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

interface Props {
    dialogOpen: boolean;
    onLocalized: (result: LocalizationResult) => void;
    onCanceled: () => void;
    onUsePlanned?: () => void;
    plannedPosition: PlannedPosition;
    force: boolean;
    workout?: WorkoutForVisualizer;
    localizationHeightIn?: number;
}

function skipOrSupport(
    sport: Sport,
    vision?: VisionStatus,
): "none" | "skip" | "support" {
    if (!vision) {
        logger.info("Status unvailable - no buttons yet");
        return "none";
    }

    if (sport !== "TENNIS") {
        logger.info(`No skip or support buttons shown for ${sport}`);
        return "none";
    }

    const { camera } = vision;

    if (!camera.orientation) {
        // This trainer is running 3.10.4 or older
        logger.info(
            "No camera orientation available - no skip/support buttons",
        );
        return "none";
    }

    if (camera.orientation && camera.orientationMode === undefined) {
        // This is a 3.11.5 trainer - there are a handful of them and they
        // have all been manually configured to work well enough without
        // a successful localization - they can skip
        logger.info(
            "Camera orientation available, but no mode specified - showing skip",
        );
        return "skip";
    }

    logger.info(
        `Validating orientation data for mode: ${camera.orientationMode}`,
    );
    switch (camera.orientationMode) {
        // This is a trainer running a 3.12 series release
        case "Camera IMU": {
            // configured to use IMU values, and reporting non-default IMU values
            if (
                camera.orientationIMU?.pitchDeg !== -999 &&
                camera.orientationIMU?.rollDeg !== -999
            ) {
                logger.info(
                    `Allowing skip: ${JSON.stringify(camera.orientationIMU)}`,
                );
                return "skip";
            }
            logger.info(
                `Showing 'contact support' button: ${JSON.stringify(camera.orientationIMU)}`,
            );
            return "support";
        }
        case "Factory Default":
            logger.info(
                "Showing 'contact support' button for factory default mode",
            );
            return "support";
        case "Previous Localization": {
            // configured to use Previous "known good" localization pitch/roll
            if (
                camera.orientationPreviousLocalized?.pitchDeg !== -999 &&
                camera.orientationPreviousLocalized?.rollDeg !== -999
            ) {
                logger.info(
                    `Allowing skip: ${JSON.stringify(camera.orientationPreviousLocalized)}`,
                );
                return "skip";
            }
            logger.info(
                `Showing 'contact support' button: ${JSON.stringify(camera.orientationPreviousLocalized)}`,
            );
            return "support";
        }
        case "Trainer Override":
            // Hard coded override values in coach config
            logger.info("Allowing skip for trainer override pitch/roll");
            return "skip";
        default:
            logger.info(
                "Showing nothing if camera orientation mode is missing",
            );
            return "none";
    }
}

export default function LocalizingDialog({
    dialogOpen,
    onLocalized,
    onCanceled,
    onUsePlanned,
    plannedPosition,
    force,
    workout,
    localizationHeightIn = plannedPosition.heightIn,
}: Props) {
    const intercom = useIntercom();
    const { status } = useStatus();
    const { selected: sport } = useSelectedSport();
    const [result, setResult] = React.useState<
        LocalizationResult | undefined
    >();
    const { localizationStatus, retry } = useLocalization(
        dialogOpen,
        plannedPosition,
        force,
        (r) => {
            setResult(r);
        },
        localizationHeightIn,
    );

    React.useEffect(() => {
        if (!dialogOpen) {
            setResult(undefined);
        }
    }, [dialogOpen]);

    React.useEffect(() => {
        if (result === "good") {
            logger.info("[serveAndVolley] localized result", { result });
            onLocalized(result);
        }
    }, [onLocalized, result]);

    const onRetry = React.useCallback(() => {
        setResult(undefined);
        retry();
    }, [retry]);

    const title = React.useMemo(() => {
        switch (result) {
            case "good":
                return "Position Confirmed";
            case "fail":
                return "Failed to Confirm Position";
            case "improve":
                return "Position Needs Improvement";
            default:
                return "Confirming Position";
        }
    }, [result]);

    const skipOrSupportButtons = React.useMemo(() => {
        const result = skipOrSupport(sport, status?.vision);

        switch (result) {
            case "none":
                return null;
            case "skip":
                return (
                    <Button
                        variant="contained"
                        color="info"
                        onClick={onUsePlanned}
                        fullWidth
                    >
                        Skip
                    </Button>
                );
            case "support":
                return (
                    <>
                        <Button
                            variant="contained"
                            color="info"
                            onClick={() => {
                                intercom.newMessage();
                            }}
                            fullWidth
                            startIcon={<HelpIcon />}
                        >
                            Contact Support
                        </Button>
                        <Button
                            variant="contained"
                            color="info"
                            onClick={onCanceled}
                            fullWidth
                        >
                            Dismiss
                        </Button>
                    </>
                );
        }
    }, [status?.vision, sport, onUsePlanned, onCanceled, intercom]);

    return (
        <Dialog open={dialogOpen} fullWidth>
            <CloseableDialogTitle>
                <Typography variant="h6">{title}</Typography>
            </CloseableDialogTitle>
            <DialogContent>
                {localizationStatus === "localizing" && (
                    <LocalizationInProgressContent />
                )}

                {result === "improve" && (
                    <LocalizationImproveContent workout={workout} />
                )}
                {result === "fail" && (
                    <LocalizationFailedContent sport={sport} />
                )}
            </DialogContent>
            <DialogActions>
                <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                    {localizationStatus === "localizing" && (
                        <Button
                            variant="contained"
                            color="error"
                            onClick={onCanceled}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    )}

                    {result && onUsePlanned === undefined && (
                        <Button
                            variant="contained"
                            color="info"
                            onClick={() => onLocalized(result)}
                            fullWidth
                        >
                            Dismiss
                        </Button>
                    )}

                    {(result === "fail" || result === "improve") && (
                        <>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onRetry}
                                fullWidth
                            >
                                Retry
                            </Button>
                            {skipOrSupportButtons}
                        </>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
