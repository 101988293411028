import * as React from "react";
import { Doughnut } from "react-chartjs-2";

import Box from "@mui/material/Box";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartTypeRegistry,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { HistoryColors } from "./common";
import { ServeAndVolleyResultSummary } from "./models";

// Extend Chart.js types to include our custom plugin options
// to avoid TypeScript type errors
declare module "chart.js" {
    interface PluginOptionsByType<
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        TType extends keyof ChartTypeRegistry = "doughnut",
    > {
        centerText?: {
            totalServes: number;
        };
    }
}

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

// Custom plugin to render text in the center of the doughnut
const centerTextPlugin = {
    id: "centerText",
    afterDraw: (chart: ChartJS) => {
        const {
            ctx,
            chartArea: { width, height },
        } = chart;
        ctx.save();

        // Retrieve total serves from plugin options
        const totalServes =
            chart.config.options?.plugins?.centerText?.totalServes;
        const mainText = totalServes !== undefined ? `${totalServes}` : "";
        const subText = "Serves";

        // Calculate main font size for the number (large text)
        const mainFontSize = Math.min(height / 4, 30);
        ctx.font = `${mainFontSize}px sans-serif`;
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#000";

        const mainTextWidth = ctx.measureText(mainText).width;
        const mainTextX = (width - mainTextWidth) / 2;

        // Place main text a little above the center
        const mainTextY = height / 2 - mainFontSize / 4;
        ctx.fillText(mainText, mainTextX, mainTextY);

        // Calculate subtext font size (smaller text)
        const subFontSize = Math.min(height / 8, 16);
        ctx.font = `${subFontSize}px sans-serif`;
        const subTextWidth = ctx.measureText(subText).width;
        const subTextX = (width - subTextWidth) / 2;

        // Place subtext underneath main text with a little spacing
        const subTextY = mainTextY + mainFontSize / 1.5;
        ctx.fillText(subText, subTextX, subTextY);

        ctx.restore();
    },
};

interface ChartSummaryProps {
    summary: ServeAndVolleyResultSummary;
}

export default function ChartSummary({
    summary,
}: ChartSummaryProps): JSX.Element {
    const { in: inCount, out, miss, total } = summary;
    const safeTotal = total === 0 ? 1 : total;

    const donutData = React.useMemo(
        () => ({
            labels: ["In", "Out", "Miss"],
            datasets: [
                {
                    data: [
                        Math.round((inCount / safeTotal) * 100),
                        Math.round((out / safeTotal) * 100),
                        Math.round((miss / safeTotal) * 100),
                    ],
                    backgroundColor: [
                        HistoryColors.in,
                        HistoryColors.out,
                        HistoryColors.miss,
                    ],
                },
            ],
        }),
        [inCount, out, miss, safeTotal],
    );

    const donutOptions = React.useMemo(
        () => ({
            maintainAspectRatio: false,
            plugins: {
                centerText: {
                    totalServes: total,
                },
                datalabels: {
                    display: true,
                    color: "#fff",
                    formatter: (value: number) =>
                        value === 0 ? "" : `${value}%`,
                    font: {
                        weight: "bold" as const,
                    },
                },
                legend: {
                    position: "bottom" as const,
                    labels: { boxWidth: 12 },
                    onClick: () => {}, // Disable legend click to avoid filtering
                },
            },
        }),
        [total],
    );

    return (
        <Box width="100%" height="200px">
            <Doughnut
                data={donutData}
                options={donutOptions}
                plugins={[centerTextPlugin]}
            />
        </Box>
    );
}
