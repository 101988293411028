import * as React from "react";

import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import type { CuratedWorkoutShot } from "@volley/shared/apps/curated-workout-models";

interface Props {
    availableShots: CuratedWorkoutShot[];
    selectedShots: CuratedWorkoutShot[];
    setSelectedShots: (shots: CuratedWorkoutShot[]) => void;
    setAnimatedShot: (shot: CuratedWorkoutShot | null) => void;
    disabled?: boolean;
}

const BasicShotSelector: React.FC<Props> = ({
    availableShots,
    selectedShots,
    setSelectedShots,
    setAnimatedShot,
    disabled = false,
}) => {
    const onSelect = (selectedShot: CuratedWorkoutShot) => {
        const currentIndex = selectedShots.findIndex(
            (s) => s.id === selectedShot.id,
        );
        const nextSelected = [...selectedShots];
        if (currentIndex === -1) nextSelected.push(selectedShot);
        else nextSelected.splice(currentIndex, 1);
        setSelectedShots(nextSelected);
    };

    return (
        <div style={{ paddingTop: 12, paddingBottom: 64 }}>
            <List sx={{ pt: 0 }}>
                {availableShots.map((shot) => {
                    const labelId = `checkbox-list-label-${shot.id}`;
                    const checked = !!selectedShots.find(
                        (s) => s.id === shot.id,
                    );
                    return (
                        <ListItem
                            key={shot.id}
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    aria-label="start animation"
                                    onClick={() => setAnimatedShot(shot)}
                                    disabled={disabled}
                                >
                                    <SlowMotionVideoIcon />
                                </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButton
                                role={undefined}
                                onClick={() => onSelect(shot)}
                                dense
                                disabled={disabled}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={shot.name}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};

export default BasicShotSelector;
