import * as React from "react";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
    open?: boolean;
    problems: string[];
    workoutId?: number;
    cancel?: () => void;
}

export default function ProblemsDialog({
    open = true,
    problems,
    workoutId,
    cancel,
}: Props): React.JSX.Element {
    const navigate = useNavigate();

    return (
        <Dialog open={open} fullWidth maxWidth={false}>
            <DialogTitle variant="h4" color="primary.main">
                Problems With This Workout
            </DialogTitle>
            <DialogContent dividers>
                <Stack spacing={3}>
                    <Typography variant="body2">
                        The following issues were found:
                    </Typography>
                    <ul>
                        {problems.map((p) => (
                            <li key={p}>
                                <Typography variant="body2">{p}</Typography>
                            </li>
                        ))}
                    </ul>
                    <Box>
                        {workoutId && (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() =>
                                    navigate(`../edit/${workoutId}`, {
                                        replace: true,
                                    })
                                }
                            >
                                Edit Workout
                            </Button>
                        )}
                        {cancel !== undefined && (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() => cancel()}
                            >
                                Fix Issues
                            </Button>
                        )}
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
