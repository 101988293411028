import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import type {
    PlayMode,
    PlayerPosition,
} from "@volley/shared/dist/apps/app-common-models";

import ResizableWorkoutVisualizer from "../../../common/Visualizer/ResizableWorkoutVisualizer";
import { CourtAOI as VisualizerAOI } from "../../../common/Visualizer/models";
import { VisualizerShot } from "../../../common/Visualizer/types";
import type { Sport } from "../../../common/context/sport";
import useRanges from "../../../hooks/ranges";
import { PositionWithHeight } from "../../../hooks/usePosition";
import { mirrorPlayer, mirrorTrainer } from "../../Position/util";

interface WorkoutVisualizerAccordionProps
    extends Omit<AccordionProps, "children"> {
    trainerPosition?: PositionWithHeight;
    playerPosition?: PlayerPosition;
    shots?: VisualizerShot[];
    playMode: PlayMode;
    selectedSport: Sport;
    AOIs?: VisualizerAOI[];
}

export default function WorkoutVisualizerAccordion({
    trainerPosition,
    playerPosition,
    playMode,
    shots = [],
    selectedSport,
    AOIs = [],
    ...props
}: WorkoutVisualizerAccordionProps): React.JSX.Element {
    const { lift } = useRanges();

    const rendererAOIs = React.useMemo(() => {
        if (!AOIs) {
            return [];
        }
        return AOIs;
    }, [AOIs]);

    const rendererPlayerPositions = React.useMemo(() => {
        if (!playerPosition) {
            return [];
        }

        switch (playMode) {
            case "dual":
                return [
                    playerPosition,
                    mirrorPlayer(playerPosition, selectedSport),
                ];
            case "mirror":
                return [mirrorPlayer(playerPosition, selectedSport)];
            default:
                return [playerPosition];
        }
    }, [playerPosition, playMode, selectedSport]);

    const rendererTrainer = React.useMemo(() => {
        if (!trainerPosition) {
            return {
                x: 0,
                y: 2,
                yaw: 0,
                heightIn: lift.min,
            };
        }

        if (playMode === "mirror") {
            return mirrorTrainer(trainerPosition, selectedSport);
        }

        return trainerPosition;
    }, [lift.min, playMode, selectedSport, trainerPosition]);

    const rendererShots = React.useMemo(() => {
        if (!shots) {
            return [];
        }

        const mapped: VisualizerShot[] = shots.map((s) => ({
            ...s,
            pan: playMode === "mirror" ? -s.pan : s.pan,
        }));

        if (playMode === "dual") {
            shots.forEach((s) =>
                mapped.push({
                    ...s,
                    pan: -s.pan,
                }),
            );
        }

        return mapped;
    }, [shots, playMode]);

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                    color="primary.main"
                    variant={props.expanded ? "h4" : "h3"}
                >
                    Visualizer
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ResizableWorkoutVisualizer
                    workout={{
                        trainer: rendererTrainer,
                        player: rendererPlayerPositions,
                        shots: rendererShots,
                        AOIs: rendererAOIs,
                    }}
                    positionProximity="Good"
                />
            </AccordionDetails>
        </Accordion>
    );
}
