import * as React from "react";
import { useNavigate } from "react-router";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { VolleyButton } from "../common/buttons";

export default function ForgotPassword(): React.JSX.Element {
    const navigate = useNavigate();
    const theme = useTheme();
    const [email, setEmail] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [error, setError] = React.useState("");

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setLoading(true);
            setError("");

            const auth = getAuth();

            try {
                await sendPasswordResetEmail(auth, email);
                setDialogOpen(true);
            } catch {
                setError(
                    "There was an error sending the password reset email.",
                );
            } finally {
                setLoading(false);
            }
        },
        [email],
    );

    const onChangeEmail = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            setEmail(e.currentTarget.value);
        },
        [],
    );

    const onDialogConfirm = React.useCallback(() => {
        setDialogOpen(false);
        navigate("/login");
    }, [navigate]);

    return (
        <>
            <Typography variant="h3" component="h2">
                Forgot your Password?
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" mb={2}>
                Enter the email address associated with your Volley account and
                we&apos;ll send you instructions to reset your password
            </Typography>
            <Stack spacing={4} component="form" onSubmit={onSubmit}>
                <TextField
                    type="email"
                    autoComplete="email"
                    name="email"
                    label="Email"
                    placeholder="Email address"
                    value={email}
                    onChange={onChangeEmail}
                    fullWidth
                />
                <VolleyButton
                    type="submit"
                    disabled={loading || !/^\S+@\S+\.\S+$/.test(email)}
                >
                    Continue
                </VolleyButton>
                {error && (
                    <Typography variant="body2" color="error.main">
                        {error}
                    </Typography>
                )}
            </Stack>
            <Dialog open={dialogOpen} disableEscapeKeyDown>
                <CloseableDialogTitle onClose={onDialogConfirm}>
                    <Typography variant="h4">Password Reset Sent</Typography>
                </CloseableDialogTitle>
                <DialogContent dividers>
                    <DialogContentText
                        variant="body2"
                        my={2}
                        align="center"
                        color="black"
                    >
                        A link to reset your password has been sent to your
                        email address. After you reset your password, proceed
                        with login.
                    </DialogContentText>
                    <VolleyButton onClick={onDialogConfirm}>
                        Back to login
                    </VolleyButton>

                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ mt: 2 }}
                        textAlign="center"
                    >
                        Didn’t see the email? Even in spam?{" "}
                        <Link
                            color={theme.palette.info.main}
                            underline="none"
                            component="button"
                            onClick={() => setDialogOpen(false)}
                            sx={{ cursor: "pointer", fontWeight: "bold" }}
                        >
                            Send again.
                        </Link>
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
}
