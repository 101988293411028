import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { Tag, TagCategoryWithRelations } from "@volley/data";

export interface TagCategorySelectProps {
    selectedTags: Record<number, Tag[]>;
    tagCategory: TagCategoryWithRelations;
    updateSelected: (tags: Record<number, Tag[]>) => void;
}

export default function TagCategorySelect({
    selectedTags,
    tagCategory,
    updateSelected,
}: TagCategorySelectProps): React.JSX.Element {
    const [open, setOpen] = React.useState(false);

    let selectedValue: string | string[] = "";
    if (tagCategory.mode === "SINGLE") {
        selectedValue =
            Object.hasOwn(selectedTags, tagCategory.id) &&
            selectedTags[tagCategory.id].length > 0
                ? selectedTags[tagCategory.id][0].name
                : "";
    } else {
        selectedValue = Object.hasOwn(selectedTags, tagCategory.id)
            ? selectedTags[tagCategory.id].map((t) => t.name)
            : [];
    }
    return (
        <FormControl fullWidth>
            <Select
                id="demo-multiple-chip"
                multiple={tagCategory.mode === "MULTIPLE"}
                fullWidth
                open={open}
                value={selectedValue}
                onChange={(e) => {
                    const updated = { ...selectedTags };
                    if (tagCategory.mode === "SINGLE") {
                        const match = tagCategory.tags.find(
                            (tag) => tag.name === e.target.value,
                        );
                        updated[tagCategory.id] = match ? [match] : [];
                    }
                    if (tagCategory.mode === "MULTIPLE") {
                        updated[tagCategory.id] = tagCategory.tags.filter((t) =>
                            e.target.value.includes(t.name),
                        );
                    }
                    updateSelected(updated);
                }}
                IconComponent={KeyboardArrowDownIcon}
                input={
                    <Input
                        id="select-multiple-chip"
                        fullWidth
                        disableUnderline
                        placeholder={tagCategory.label}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "20px",
                            padding: "2px",
                            minHeight: "45px",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: (t) => t.palette.primary.dark,
                        }}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    />
                }
                renderValue={(selected) => {
                    if (typeof selected === "string") {
                        return (
                            <Stack direction="row" spacing={1}>
                                {tagCategory.tags
                                    .filter((t) => t.name === selected)
                                    .map((value) => (
                                        <Chip
                                            key={value.id}
                                            label={value.label}
                                            sx={{
                                                minWidth: "80px",
                                            }}
                                            onDelete={() => {
                                                const updated = {
                                                    ...selectedTags,
                                                };
                                                updated[tagCategory.id] = [];
                                                updateSelected(updated);
                                            }}
                                        />
                                    ))}
                            </Stack>
                        );
                    }

                    return (
                        <Stack direction="row" spacing={1}>
                            {tagCategory.tags
                                .filter((t) => selected.includes(t.name))
                                .map((value) => (
                                    <Chip
                                        key={value.id}
                                        label={value.label}
                                        sx={{
                                            minWidth: "80px",
                                        }}
                                        onDelete={() => {
                                            const updated = { ...selectedTags };
                                            updated[tagCategory.id] =
                                                selectedTags[
                                                    tagCategory.id
                                                ].filter(
                                                    (t) =>
                                                        t.name !== value.name,
                                                );
                                            updateSelected(updated);
                                        }}
                                    />
                                ))}
                        </Stack>
                    );
                }}
            >
                {tagCategory.tags
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((tag) => (
                        <MenuItem key={tag.id} value={tag.name}>
                            {tag.label}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
}
