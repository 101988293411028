import * as React from "react";

import { usePairingContext } from "../../hooks/pairingStatus";
import ConfirmDialog from "../ConfirmDialog";
import LowerHeadDialog from "../LowerHeadDialog";
import UnpairDialog from "../UnpairDialog";
import useDialog from "../useDialog";

const enum UnpairSteps {
    Confirm,
    LowerArm,
    Unpair,
    CleanUp,
    Done,
}

export default function UnpairFlow(): React.JSX.Element | null {
    const { setDialogType } = useDialog();
    const { unpair } = usePairingContext();
    const [step, setStep] = React.useState(UnpairSteps.Confirm);

    React.useEffect(() => {
        if (step === UnpairSteps.CleanUp) {
            setDialogType("DisconnectCleanupChecklist");
            unpair();
        }
    }, [step, setDialogType, unpair]);

    if (step === UnpairSteps.Confirm) {
        return (
            <ConfirmDialog
                title="Confirm Disconnect"
                contentText="This action will end your session but keeps the trainer on for the next person."
                buttonLabel="Disconnect"
                onConfirm={() => setStep(UnpairSteps.LowerArm)}
            />
        );
    }

    if (step === UnpairSteps.LowerArm) {
        return (
            <LowerHeadDialog
                message="The trainer arm is moving to the lowest position for storage."
                reason="shutdown"
                onLowered={() => setStep(UnpairSteps.Unpair)}
                onStopped={() => setDialogType(null)}
                onError={() => setStep(UnpairSteps.Unpair)} // if we can't lower, continue with shutdown
            />
        );
    }

    if (step === UnpairSteps.Unpair) {
        return <UnpairDialog onUnpair={() => setStep(UnpairSteps.CleanUp)} />;
    }

    return null;
}
