import * as React from "react";

import ReportIssueConfirmed from "../ReportIssueConfirmed";
import ReportIssueDialog from "../ReportIssueDialog";
import ReportIssueError from "../ReportIssueError";
import useDialog from "../useDialog";

const enum ReportTrainerIssueSteps {
    Start,
    IssueReported,
    Error,
    Done,
}

export default function ReportTrainerIssueFlow(): React.JSX.Element | null {
    const { setDialogType } = useDialog();
    const [step, setStep] = React.useState<ReportTrainerIssueSteps>(
        ReportTrainerIssueSteps.Start,
    );

    switch (step) {
        case ReportTrainerIssueSteps.Start:
            return (
                <ReportIssueDialog
                    onSubmitted={() =>
                        setStep(ReportTrainerIssueSteps.IssueReported)
                    }
                    onError={() => setStep(ReportTrainerIssueSteps.Error)}
                />
            );
        case ReportTrainerIssueSteps.IssueReported:
            return (
                <ReportIssueConfirmed
                    onDone={() => setStep(ReportTrainerIssueSteps.Done)}
                />
            );
        case ReportTrainerIssueSteps.Error:
            return (
                <ReportIssueError
                    onDone={() => setStep(ReportTrainerIssueSteps.Done)}
                />
            );
        case ReportTrainerIssueSteps.Done:
            setDialogType(null);
    }

    return null;
}
