import * as React from "react";
import { useNavigate } from "react-router";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function NotAvailable(): React.JSX.Element {
    const navigate = useNavigate();
    return (
        <Stack>
            <Typography variant="h3">Sorry!</Typography>
            <Typography>
                It looks like we can&apos;t find what you&apos;re looking for.
            </Typography>
            <Button color="secondary" fullWidth onClick={() => navigate(-1)}>
                Go Back
            </Button>
        </Stack>
    );
}
