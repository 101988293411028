import * as React from "react";

import Button from "@mui/material/Button";

import {
    ServeResult,
    TargetAOI,
} from "@volley/shared/apps/serveandvolley-models";

import { score } from "../score";

import { SessionForHistory, SessionForHistoryWorkoutPlay } from "./models";

interface SessionSummaryProps {
    session: SessionForHistory;
    active?: boolean;
    onClick: () => void;
}

/**
 * Helper function to extract serve results from a single workout play.
 *
 * It maps each result so that if the target is a string, it looks for a corresponding targetAOI from params
 * and replaces it with the found targetAOI object. This is required for older workout results that didn't
 * embed the targetAOI in the result.
 */
function getServeResultsFromWorkout(
    workoutPlay: SessionForHistoryWorkoutPlay,
): ServeResult[] {
    if (!workoutPlay.workoutResult || !workoutPlay.workoutResult.resultData) {
        return [];
    }

    return workoutPlay.workoutResult.resultData.map((result: ServeResult) => {
        // Check if the target is a string and replace it with the corresponding targetAOI if found.
        if (typeof result.target === "string") {
            const matchingTarget = workoutPlay.params.targetAOIs.find(
                (t: TargetAOI) => t.name === result.target,
            );
            return {
                ...result,
                target: matchingTarget ?? result.target,
            };
        }
        return result;
    });
}

export default function SessionSummary({
    session,
    active = false,
    onClick,
}: SessionSummaryProps): JSX.Element {
    // Compute the session score by aggregating serve results from each workout play.
    const sessionScore = React.useMemo(() => {
        // Flatten all serve results from each workout play
        const allServeResults: ServeResult[] = session.WorkoutPlay.reduce(
            (acc: ServeResult[], workoutPlay: SessionForHistoryWorkoutPlay) => {
                const serveResults = getServeResultsFromWorkout(workoutPlay);
                return acc.concat(serveResults);
            },
            [],
        );

        // Calculate and round the score if it exists.
        const computedScore = score(allServeResults);
        return computedScore != null
            ? Math.round(computedScore)
            : computedScore;
    }, [session.WorkoutPlay]);

    return (
        <Button
            onClick={onClick}
            size="small"
            sx={{
                width: 64,
                height: 64,
                flexShrink: 0,
                borderRadius: "50%",
                border: active ? "6px solid" : "2px solid",
                borderColor: active ? "primary.main" : "grey.400",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                fontWeight: active ? "bold" : "normal",
            }}
        >
            {sessionScore ?? "-"}
        </Button>
    );
}
