import * as React from "react";

import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface Identifiable {
    id: string;
}

interface Props<T extends Identifiable> {
    items: T[];
    setItems: (items: T[]) => void;
    jsxElements: React.JSX.Element[];
}

const SortableItem: React.FC<React.PropsWithChildren<Identifiable>> = ({
    id,
    children,
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
};

const Item = React.forwardRef<HTMLDivElement, Identifiable>(
    ({ id, ...props }, ref) => {
        return (
            <div {...props} ref={ref}>
                {id}
            </div>
        );
    },
);

Item.displayName = "Item";

export default function VerticalDragDrop<T extends Identifiable>({
    items,
    setItems,
    jsxElements,
}: Props<T>): React.JSX.Element {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 20,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(
        null,
    );

    const onDragStart = (event: DragStartEvent) => {
        const { active } = event;
        setActiveId(active.id);
    };

    const onDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        // Dropped outside the list
        if (!over) return;

        if (active.id !== over.id) {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over.id);
            setItems(arrayMove(items, oldIndex, newIndex));
        }

        setActiveId(null);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                {jsxElements.map((el, i) => (
                    <SortableItem key={el.key} id={items[i].id}>
                        {el}
                    </SortableItem>
                ))}
            </SortableContext>
            <DragOverlay>
                {activeId
                    ? jsxElements[items.findIndex((i) => i.id === activeId)]
                    : null}
            </DragOverlay>
        </DndContext>
    );
}
