import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";

interface Props<T extends string | number | boolean> {
    label: string;
    disabled?: boolean;
    options: { value: T; label: string }[];
    selected: T;
    setOption: (option: T) => void;
    labelWrapperSx?: SxProps<Theme>;
    toggleButtonSx?: SxProps<Theme>;
    wrapperSx?: SxProps<Theme>;
}

export default function OptionSelector<T extends string | number | boolean>({
    label,
    labelWrapperSx = {},
    disabled = false,
    options,
    selected,
    setOption,
    toggleButtonSx = {},
    wrapperSx = {},
}: Props<T>): React.JSX.Element {
    return (
        <Stack direction="row" spacing={0.5} alignItems="center" sx={wrapperSx}>
            <Box component="div" sx={labelWrapperSx}>
                <Typography variant="caption" sx={{ minWidth: "30px" }}>
                    {label}
                </Typography>
            </Box>
            <Box component="div" sx={toggleButtonSx}>
                <ButtonGroup
                    disabled={disabled}
                    fullWidth
                    size="small"
                    color="primary"
                >
                    {options.map((option) => (
                        <Button
                            key={option.label}
                            variant={
                                selected === option.value
                                    ? "contained"
                                    : "outlined"
                            }
                            size="small"
                            sx={{
                                padding: "2px",
                                color:
                                    selected === option.value
                                        ? "common.white"
                                        : "primary.light",
                                backgroundColor:
                                    selected === option.value
                                        ? "primary.light"
                                        : "common.white",
                                fontSize: "10px",
                                "&:hover": {
                                    bgcolor: "primary.light",
                                },
                            }}
                            onClick={() => setOption(option.value)}
                        >
                            {option.label}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>
        </Stack>
    );
}
