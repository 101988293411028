import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

interface DetailsAccordionProps extends Omit<AccordionProps, "children"> {
    name: string;
    onNameChanged: (value: string) => void;
    overview: string;
    onOverviewChanged: (value: string) => void;
}

export default function DetailsAccordion({
    name,
    onNameChanged,
    overview,
    onOverviewChanged,
    ...props
}: DetailsAccordionProps): React.JSX.Element {
    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {props.expanded && (
                    <Typography color="primary.main" variant="h3">
                        Details
                    </Typography>
                )}
                {!props.expanded && (
                    <Box component="div">
                        <Typography color="primary.main" variant="h4">
                            Details
                        </Typography>
                        <Typography color="primary.main" variant="h3">
                            {name}
                        </Typography>
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={3}>
                    <TextField
                        label="Workout Name"
                        value={name}
                        onChange={(e) => onNameChanged(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Overview"
                        multiline
                        value={overview}
                        placeholder="Overview displayed with your workout."
                        onChange={(e) => onOverviewChanged(e.target.value)}
                        fullWidth
                    />
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
