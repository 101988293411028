import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import StarIcon from "@mui/icons-material/Star";
import AppBar from "@mui/material/AppBar";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { AppWorkoutWithSummary } from "@volley/data";

import ProviderAvatar from "./ProviderAvatar";

export interface DetailsButtonProps {
    workout: AppWorkoutWithSummary;
}

export default function DetailsButton({
    workout,
}: DetailsButtonProps): React.JSX.Element {
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    return (
        <>
            <IconButton
                sx={{
                    padding: "2px",
                    width: "20px",
                    height: "20px",
                }}
                onClick={() => setDetailsOpen(true)}
                size="small"
                color="primary"
            >
                <InfoIcon />
            </IconButton>
            <Dialog open={detailsOpen} fullScreen>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h6" flexGrow={1}>
                            {workout.name}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setDetailsOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    sx={{
                        paddingTop: 8,
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="h6">Overview:</Typography>
                        {workout.overview !== "" ? (
                            <Typography>{workout.overview}</Typography>
                        ) : (
                            <Typography>Not Specified</Typography>
                        )}
                        {workout.description !== "" && (
                            <>
                                <Typography variant="h6">
                                    Description:
                                </Typography>
                                <Typography>
                                    {workout.description === ""}
                                </Typography>
                            </>
                        )}
                        {workout.contentProvider && (
                            <>
                                <Typography variant="h6">Publisher:</Typography>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <ProviderAvatar
                                        providerName={
                                            workout.contentProvider.name
                                        }
                                        providerLabel={
                                            workout.contentProvider.label
                                        }
                                    />
                                    <Typography>
                                        {workout.contentProvider.label}
                                    </Typography>
                                </Stack>
                                {workout.contentProvider.contentProviderPublishers
                                    .filter(
                                        (p) =>
                                            p.user.email === workout.createdBy,
                                    )
                                    .map((p) => (
                                        <Typography
                                            key={`${p.user.firstName}-${p.user.lastName}`}
                                        >
                                            {`Created By: ${p.user.firstName} ${p.user.lastName}`}
                                        </Typography>
                                    ))}
                            </>
                        )}
                        <Typography variant="h6">Tags:</Typography>
                        {workout.workoutTags?.length > 0 ? (
                            <Stack spacing={1} direction="row">
                                {workout.workoutTags.map((tag) => (
                                    <Chip
                                        key={tag.tag.id}
                                        variant="filled"
                                        size="small"
                                        color="info"
                                        label={`${tag.tag.label}`}
                                        sx={{
                                            boxShadow:
                                                "2px 2px 2px 0px rgba(0,0,0,0.75)",
                                        }}
                                    />
                                ))}
                            </Stack>
                        ) : (
                            <Typography>No Tags</Typography>
                        )}
                        <Typography variant="h6">My Stats:</Typography>
                        <Stack direction="row" spacing={1}>
                            {workout._count?.favorites > 0 && (
                                <Chip
                                    icon={<StarIcon />}
                                    variant="filled"
                                    size="small"
                                    color="primary"
                                    label="My Favorites"
                                    sx={{
                                        boxShadow:
                                            "2px 2px 2px 0px rgba(0,0,0,0.75)",
                                    }}
                                />
                            )}
                            {workout._count?.workoutPlays > 0 ? (
                                <Chip
                                    icon={<SportsTennisIcon />}
                                    label={workout._count.workoutPlays}
                                    color="secondary"
                                    size="small"
                                    sx={{
                                        boxShadow:
                                            "2px 2px 2px 0px rgba(0,0,0,0.75)",
                                    }}
                                />
                            ) : (
                                <Chip
                                    icon={<SportsTennisIcon />}
                                    label="Unplayed"
                                    color="secondary"
                                    size="small"
                                    sx={{
                                        boxShadow:
                                            "2px 2px 2px 0px rgba(0,0,0,0.75)",
                                    }}
                                />
                            )}
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}
