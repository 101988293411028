import * as React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useStatus } from "../../hooks/status";
import useDialog from "../useDialog";

import { ActionListItem, InfoListItem } from "./listItems";

export default function ObstructionListItem(): React.JSX.Element {
    const { setDialogType } = useDialog();
    const { status } = useStatus();

    // TODO - cbley - specific type of safety issue?
    if (status?.fault && status.fault.failures[0].type === "SafetyIssue") {
        return (
            <ActionListItem action={() => setDialogType("ClearObstructions")}>
                Clear Obstructions
            </ActionListItem>
        );
    }

    return (
        <InfoListItem>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4">Obstructions:</Typography>
                <Typography variant="h4" color="success.main">
                    Clear
                </Typography>
            </Stack>
        </InfoListItem>
    );
}
