import * as React from "react";

import { AOI } from "@volley/shared/apps/app-common-models";
import {
    ServeResult,
    TargetAOI,
} from "@volley/shared/apps/serveandvolley-models";

import logger from "../../../../../log";
import { StyleOptions } from "../../../../Admin/Clips/canvasDrawing";
import {
    drawAOI,
    drawBallForPlatformTennis,
    createHalfCourtConversion,
    createInverseHalfCourtConversion,
    drawBallForTennis,
    drawCourt,
} from "../../../../Admin/Clips/primitiveDrawing";
import { useSelectedSport } from "../../../../common/context/sport";

export interface Serve {
    position: { x: number; y: number };
    inAoi: boolean;
    inServiceBox: boolean;
    speed?: number;
}

interface CourtProps {
    serves: ServeResult[];
    highlightServes?: ServeResult[];
    activeServiceBox?: AOI;
    activeServiceBoxColor?: string;
    targetAOIs: TargetAOI[] | AOI[];
    targetAOIColor?: string;
    highlightAOI?: TargetAOI | AOI;
    highlightColor?: string;
    onAOIClick?: (aoi: TargetAOI | AOI) => void;
    width?: number;
    height?: number;
}

function styleForHighlightedServe(serve: ServeResult): StyleOptions {
    const base: StyleOptions = {
        strokeStyle: "white",
        lineWidth: 2,
        fillStyle: "white",
    };

    // color red if not in AOI or service box, green if in AOI, yellow if in service box
    let color = "red";
    if (serve.inAoi) {
        color = "green";
    } else if (serve.inServiceBox) {
        color = "yellow";
    }

    return {
        ...base,
        strokeStyle: color,
        fillStyle: color,
    };
}

export default function Court({
    serves,
    highlightServes = [],
    activeServiceBox,
    activeServiceBoxColor,
    targetAOIs,
    targetAOIColor,
    highlightAOI,
    highlightColor,
    onAOIClick,
    width = 300,
    height = 300,
}: CourtProps) {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const { selected: sport } = useSelectedSport();

    React.useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) {
            logger.error("Canvas not found");
            return;
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) {
            logger.error("Canvas context not found");
            return;
        }

        // Clear the entire canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const halfCourtConversion = createHalfCourtConversion(
            ctx,
            sport,
            "far",
        );

        drawCourt(ctx, sport, "far");

        // if we have an active service box, draw it
        if (activeServiceBox) {
            drawAOI(
                ctx,
                activeServiceBox,
                {
                    strokeStyle: activeServiceBoxColor || "yellow",
                    lineWidth: 1,
                    fillStyle: "rgba(255, 255, 0, 0.05)",
                },
                sport,
                halfCourtConversion,
            );
        }

        // Draw all AOIs
        targetAOIs.forEach((targetAOI) => {
            // targetAOI can be either an AOI or a TargetAOI
            const aoi = "aoi" in targetAOI ? targetAOI.aoi : targetAOI;
            drawAOI(
                ctx,
                aoi,
                {
                    strokeStyle: targetAOIColor || "gray",
                    lineWidth: 2,
                    fillStyle: "rgba(0, 0, 0, 0.10)",
                },
                sport,
                halfCourtConversion,
            );
        });

        // Draw the highlighted AOI
        if (highlightAOI) {
            const aoi = "aoi" in highlightAOI ? highlightAOI.aoi : highlightAOI;
            drawAOI(
                ctx,
                aoi,
                {
                    strokeStyle: highlightColor || "white",
                    lineWidth: 2,
                    fillStyle: "rgba(255, 255, 255, 0.10)",
                },
                sport,
                halfCourtConversion,
            );
        }
        // }

        // Draw all highlighted serves
        const ballDrawFn =
            sport === "TENNIS" ? drawBallForTennis : drawBallForPlatformTennis;

        highlightServes.forEach((result) => {
            if (result.position === undefined) return;

            ballDrawFn(
                ctx,
                { ...result.position, z: 0 },
                styleForHighlightedServe(result),
                halfCourtConversion,
            );
        });
    }, [
        activeServiceBox,
        activeServiceBoxColor,
        highlightAOI,
        highlightColor,
        highlightServes,
        serves,
        targetAOIColor,
        targetAOIs,
        sport,
    ]);

    const handleCanvasClick = React.useCallback(
        (event: React.MouseEvent<HTMLCanvasElement>) => {
            const canvas = canvasRef.current;
            if (!canvas) return;

            const ctx = canvas.getContext("2d");
            if (!ctx) return;

            const inverseHalfCourtConversion = createInverseHalfCourtConversion(
                ctx,
                sport,
                "far",
            );

            // Get canvas size and bounding rectangle
            const rect = canvas.getBoundingClientRect();
            // Scale the click coordinates in case canvas dimensions differ from CSS size
            const scaleX = canvas.width / rect.width;
            const scaleY = canvas.height / rect.height;

            const clickX = (event.clientX - rect.left) * scaleX;
            const clickY = (event.clientY - rect.top) * scaleY;

            const courtClickPoint = inverseHalfCourtConversion({
                x: clickX,
                y: clickY,
                z: 0,
            });

            const clickedAOI = targetAOIs.find((target) => {
                const aoi = "aoi" in target ? target.aoi : target;
                const { upperLeftX, upperLeftY, lowerRightX, lowerRightY } =
                    aoi;
                return (
                    courtClickPoint.x >= upperLeftX &&
                    courtClickPoint.x <= lowerRightX &&
                    courtClickPoint.y <= upperLeftY &&
                    courtClickPoint.y >= lowerRightY
                );
            });

            if (clickedAOI && onAOIClick) {
                onAOIClick(clickedAOI);
            }
        },
        [sport, targetAOIs, onAOIClick],
    );

    return (
        <canvas
            ref={canvasRef}
            width={width}
            height={height}
            onClick={handleCanvasClick}
        />
    );
}
