import * as React from "react";

import Typography from "@mui/material/Typography";

export interface LabelProps {
    text: string;
}

export default function Label({ text }: LabelProps): React.JSX.Element {
    return (
        <Typography
            component="span"
            sx={{
                textShadow: "1px 1px 5px black",
                paddingLeft: "8px",
                paddingRight: "8px",
            }}
            variant="button"
            color="white"
            textAlign="center"
        >
            {text}
        </Typography>
    );
}
