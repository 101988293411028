import { BufferAttribute, Vector2, Color } from "three";
import * as THREE from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";

const padelModelPath = new URL(
    "../../../static/models/courts/padel-court-transformed.glb",
    import.meta.url,
).toString();
const pickleballModelPath = new URL(
    "../../../static/models/courts/pickleball-court-transformed.glb",
    import.meta.url,
).toString();
const platformModelPath = new URL(
    "../../../static/models/courts/platform-court-transformed.glb",
    import.meta.url,
).toString();
const tennisModelPath = new URL(
    "../../../static/models/courts/tennis-court-transformed.glb",
    import.meta.url,
).toString();
const playerModelPath = new URL(
    "../../../static/models/player/figure-with-racquet-transformed.glb",
    import.meta.url,
).toString();
const padelTonedBlueCourtImage = new URL(
    "../../../static/models/textures/padel-blue-twotone.jpg",
    import.meta.url,
).toString();
const padelBlueCourtImage = new URL(
    "../../../static/models/textures/padel-blue.jpg",
    import.meta.url,
).toString();
const padelGreenCourtImage = new URL(
    "../../../static/models/textures/padel-green.jpg",
    import.meta.url,
).toString();
const pickleballCourtImage = new URL(
    "../../../static/models/textures/pickeball-court.jpg",
    import.meta.url,
).toString();
const platformCourtImage = new URL(
    "../../../static/models/textures/platform-court.jpg",
    import.meta.url,
).toString();
const tennisCourtImage = new URL(
    "../../../static/models/textures/tennis-court.jpg",
    import.meta.url,
).toString();
const armModelPath = new URL(
    "../../../static/models/trainer/arm-transformed.glb",
    import.meta.url,
).toString();
const baseModelPath = new URL(
    "../../../static/models/trainer/base-transformed.glb",
    import.meta.url,
).toString();
const headModelPath = new URL(
    "../../../static/models/trainer/head-transformed.glb",
    import.meta.url,
).toString();
const throwerModelPath = new URL(
    "../../../static/models/trainer/thrower-transformed.glb",
    import.meta.url,
).toString();
import type { Sport } from "../context/sport";

// After transforming glb models wih gltfjsx the surface UV mappings are might be skewed
// So we will set the `uv` mapping attribute of the important geometries on our owm
const padelSurfaceUVMapping = new BufferAttribute(
    new Float32Array([1, 0, 0, 0, 1, 1, 0, 1]),
    2,
);
const platformSurfaceUVMapping = new BufferAttribute(
    new Float32Array([0, 1, 0, 0, 1, 1, 1, 0]),
    2,
);
const tennisSurfaceUVMapping = new BufferAttribute(
    new Float32Array([1, 0, 0, 0, 1, 1, 0, 1]),
    2,
);
const pickleballSurfaceUVMapping = new BufferAttribute(
    new Float32Array([1, 0, 0, 0, 1, 1, 0, 1]),
    2,
);
// Court 3D models by the sport
export const CourtSurfaceMappings: Record<Sport, BufferAttribute> = {
    PADEL: padelSurfaceUVMapping,
    PLATFORM_TENNIS: platformSurfaceUVMapping,
    TENNIS: tennisSurfaceUVMapping,
    PICKLEBALL: pickleballSurfaceUVMapping,
};

export const BannerSurfaceUVMapping = new BufferAttribute(
    new Float32Array([1, 1, 1, 0, 0, 1, 0, 0]),
    2,
);
//                                                                   up    [1, 0, 0, 0, 1, 1, 0, 1]
//                                                                   left  [0, 1, 0, 0, 1, 1, 1, 0]
//                                                                   down  [0, 1, 1, 1, 0, 0, 1, 0]
//                                                                   right [1, 1, 1, 0, 0, 1, 0, 0]

// Buffer geometry and default material for all of the shots tracks
export const lineMaterial = new LineMaterial({
    color: 0xff5733,
    linewidth: 0.1, // in world units with size attenuation, pixels otherwise
    worldUnits: true,
    vertexColors: false,
    transparent: true,
    opacity: 0.9,
    resolution: new Vector2(256, 256),
    alphaToCoverage: false,
});

// Court surface textures
export enum TextureColor {
    DEFAULT = "DEFAULT",
    GREEN = "GREEN",
    TONEDBLUE = "TONEDBLUE",
}
type AtLeastOne<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export const CourtTextures: Record<
    Sport,
    AtLeastOne<Record<TextureColor, string>, TextureColor.DEFAULT>
> = {
    PADEL: {
        DEFAULT: padelBlueCourtImage,
        GREEN: padelGreenCourtImage,
        TONEDBLUE: padelTonedBlueCourtImage,
    },
    PLATFORM_TENNIS: { DEFAULT: platformCourtImage },
    TENNIS: { DEFAULT: tennisCourtImage },
    PICKLEBALL: { DEFAULT: pickleballCourtImage },
};

// Court 3D models by the sport
export const Court3DModels: Record<Sport, string> = {
    PADEL: padelModelPath,
    PLATFORM_TENNIS: platformModelPath,
    TENNIS: tennisModelPath,
    PICKLEBALL: pickleballModelPath,
};

// Player 3D model
export const player3DModel = playerModelPath;

// Trainer 3D model parts
export type TrainerElements = "BASE" | "ARM" | "HEAD" | "THROWER";
export const TrainerModelParts: Record<TrainerElements, string> = {
    BASE: baseModelPath,
    ARM: armModelPath,
    HEAD: headModelPath,
    THROWER: throwerModelPath,
};

export type TrainerRenderType = "GHOST" | "FULL" | "GRAY";
export const TrainerColors: Record<TrainerRenderType, THREE.Color> = {
    FULL: new Color("#010101"),
    GHOST: new Color("#FFFFFF"),
    GRAY: new Color("#3CE97C"),
};
