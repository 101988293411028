// Copyright Volley LLC, All Rights Reserved.
// Volley CONFIDENTIAL

import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import {
    CoordLike,
    PositionLike,
    Coord,
    CoordWithSys,
} from "../../../util/position-types";
import { useSelectedSport } from "../../common/context/sport";
import { useDebugMode } from "../../hooks/debugView";

import {
    clearCanvas,
    drawImage,
    drawImageCentered,
    drawImageRotated,
    drawImageRotatedWithLabel,
    drawImageWithLabel,
    Label,
    LabelLocation,
} from "./canvas";
import { CourtImages, CourtTable, IconDimensions } from "./constants";
import { CourtSize, HitBox, ScaledDimensions } from "./types";
import { pointInPolygon, projected2Image } from "./util";

const markerImage = new URL(
    "../../../static/img/indicator-circle-green.png",
    import.meta.url,
).toString();
const trainerFilledWhite = new URL(
    "../../../static/img/indicator-filled-white.png",
    import.meta.url,
).toString();
const trainerRingGreen = new URL(
    "../../../static/img/indicator-open-green.png",
    import.meta.url,
).toString();
const playerImage = new URL(
    "../../../static/img/indicator-p.png",
    import.meta.url,
).toString();
const trainerVGreen = new URL(
    "../../../static/img/indicator-v-green.png",
    import.meta.url,
).toString();

export type PositionMode = "vision" | "manual";

interface Props {
    interactive?: boolean;
    mode: PositionMode;
    size: CourtSize;
    scale: number;
    points: CoordWithSys[];
    selectedPosition?: PositionLike;
    trainerAlpha?: number;
    targetPosition?: PositionLike;
    matchingPosition?: PositionLike;
    playerPositions?: CoordLike[];
    debug?: boolean;
    onPointSelected?: (selected?: CoordWithSys[]) => void;
}

type ExpectedImage =
    | "court"
    | "marker"
    | "ring-green"
    | "filled-white"
    | "v-green"
    | "player";

type ImageStatus = "loading" | "loaded" | "failed";

type ImageState = Record<ExpectedImage, ImageStatus>;

interface ImageAction {
    image: ExpectedImage;
    value: ImageStatus;
}

function imageLoadedReducer(
    state: ImageState,
    action: ImageAction,
): ImageState {
    const updated = { ...state };
    updated[action.image] = action.value;
    return updated;
}

const defaultImageLoadedState: Record<ExpectedImage, ImageStatus> = {
    court: "loading",
    marker: "loading",
    player: "loading",
    "ring-green": "loading",
    "filled-white": "loading",
    "v-green": "loading",
};

export default function TrainerCourtPosition({
    interactive = true,
    mode,
    points,
    trainerAlpha,
    selectedPosition,
    scale,
    size,
    targetPosition,
    matchingPosition,
    playerPositions,
    onPointSelected,
    debug = false,
}: Props): React.JSX.Element {
    const { selected: sport } = useSelectedSport();
    const [imageLoadedState, dispatch] = React.useReducer(
        imageLoadedReducer,
        defaultImageLoadedState,
    );
    const [latestClick, setLatestClick] = React.useState<Coord | null>(null);
    const [selectedMarker, setSelectedMarker] =
        React.useState<CoordWithSys | null>(null);
    const { enabled: debugEnabled } = useDebugMode();

    const plotRef = React.useRef<HTMLCanvasElement>(null);
    const courtImageRef = React.useRef<HTMLImageElement>(null);
    const markerImageRef = React.useRef<HTMLImageElement>(null);
    const playerImageRef = React.useRef<HTMLImageElement>(null);
    const vGreenImageRef = React.useRef<HTMLImageElement>(null);
    const filledWhiteImageRef = React.useRef<HTMLImageElement>(null);
    const ringGreenImageRef = React.useRef<HTMLImageElement>(null);

    const showLoading = Object.values(imageLoadedState).some(
        (v) => v === "loading",
    );

    const showLoadingError = Object.values(imageLoadedState).some(
        (v) => v === "failed",
    );

    const courtImage = CourtImages[size][sport];

    const scaled = React.useMemo<ScaledDimensions>(() => {
        const original = CourtTable[sport];

        const height = original.height[size];
        const heightMeters = original.heightMeters[size];

        const yOffset = original.yOffset[size];

        return {
            width: original.width * scale,
            widthPPM: (original.width * scale) / original.widthMeters,
            height: height * scale,
            heightPPM: (height * scale) / heightMeters,
            xOffset: original.xOffset * scale,
            yOffset: yOffset * scale,
            trainerMarkerHeight: IconDimensions.trainerMarkerHeight * scale,
            trainerMarkerWidth: IconDimensions.trainerMarkerWidth * scale,
            trainerRingHeight: IconDimensions.trainerRingHeight * scale,
            trainerRingWidth: IconDimensions.trainerRingWidth * scale,
            trainerSolidHeight: IconDimensions.trainerSolidHeight * scale,
            trainerSolidWidth: IconDimensions.trainerSolidWidth * scale,
            markerHeight: IconDimensions.circleMarkerHeight * scale,
            markerWidth: IconDimensions.circleMarkerWidth * scale,
        };
    }, [scale, size, sport]);

    const hitBoxes = React.useMemo(() => {
        if (mode === "vision") {
            return [];
        }

        if (points) {
            const targets: HitBox[] = points.map((s) => {
                const { x, y } = projected2Image(s, scaled);
                return {
                    x,
                    y,
                    w: Math.floor(scaled.markerWidth * 1.25),
                    h: Math.floor(scaled.markerHeight * 1.25),
                    courtPosition: s,
                };
            });

            return targets;
        }

        return null;
    }, [mode, points, scaled]);

    const downloadCanvasImage = React.useCallback(() => {
        const plot = plotRef.current as HTMLCanvasElement;
        const imageData = plot.toDataURL("image/png");
        const link = document.createElement("a");
        link.download = `canvas_export_${new Date().toLocaleString()}.png`;
        link.href = imageData;
        link.dataset.downloadurl = ["image/png", link.download, link.href].join(
            ":",
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    const handleCanvasClick = (
        event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    ) => {
        const plot = plotRef.current as HTMLCanvasElement;
        const rect = plot.getBoundingClientRect();
        const x = Math.round(
            ((event.clientX - rect.left) / (rect.right - rect.left)) *
                scaled.width,
        );
        const y = Math.round(
            ((event.clientY - rect.top) / (rect.bottom - rect.top)) *
                scaled.height,
        );

        setLatestClick({ x, y });
    };

    React.useEffect(() => {
        if (selectedPosition) {
            const { x, y } = selectedPosition;
            const sys = sport === "PLATFORM_TENNIS" ? "court" : "physics";
            setSelectedMarker({ x, y, sys });
        } else {
            setSelectedMarker(null);
        }
    }, [selectedPosition, sport]);

    React.useEffect(() => {
        // if there is a new click position, check to see if there are matching positions
        if (latestClick !== null && interactive) {
            const { x, y } = latestClick;
            setLatestClick(null);
            const matches = hitBoxes?.filter((hb) => pointInPolygon(x, y, hb));

            if (matches?.length) {
                setSelectedMarker(matches[0].courtPosition);
                if (onPointSelected) {
                    onPointSelected(matches.map((m) => m.courtPosition));
                }
            }
        }
    }, [hitBoxes, interactive, latestClick, onPointSelected]);

    React.useEffect(() => {
        const plot = plotRef.current as HTMLCanvasElement;
        const ctx = plot.getContext("2d") as CanvasRenderingContext2D;
        const { width, height } = scaled;
        const origin = { x: 0, y: 0 };
        clearCanvas(ctx, scaled);

        if (imageLoadedState.court === "loaded") {
            const img = courtImageRef.current as HTMLImageElement;
            drawImage(ctx, img, { location: origin, width, height });
        }

        if (
            imageLoadedState.marker === "loaded" &&
            points &&
            hitBoxes?.length
        ) {
            const marker = markerImageRef.current as HTMLImageElement;

            let alpha = 1;
            let pointsToDraw = [...points];
            if (selectedMarker) {
                const { x, y } = projected2Image(selectedMarker, scaled);

                const match = hitBoxes?.find((hb) => pointInPolygon(x, y, hb));

                if (match) {
                    alpha = 0.6;
                    pointsToDraw = points.filter((p) => {
                        if (
                            p.x === match.courtPosition.x &&
                            p.y === match.courtPosition.y
                        ) {
                            return false;
                        }

                        return true;
                    });
                }
            }

            pointsToDraw.forEach((s) => {
                const coord = projected2Image(s, scaled);

                drawImageCentered(ctx, marker, {
                    height: scaled.markerHeight,
                    location: coord,
                    width: scaled.markerWidth,
                    alpha,
                    dropShadow: true,
                });
            });

            ctx.restore();
        }

        if (hitBoxes && debug) {
            hitBoxes.forEach((hb) => {
                ctx.strokeRect(hb.x - hb.w / 2, hb.y - hb.h / 2, hb.w, hb.h);
            });
        }

        if (
            imageLoadedState["ring-green"] === "loaded" &&
            mode === "vision" &&
            targetPosition
        ) {
            const ring = ringGreenImageRef.current as HTMLImageElement;
            const { x, y } = targetPosition;
            const sys = sport === "PLATFORM_TENNIS" ? "court" : "physics";
            const coord = projected2Image({ x, y, sys }, scaled);

            drawImageRotatedWithLabel(
                ctx,
                ring,
                {
                    height: scaled.trainerRingHeight,
                    location: coord,
                    width: scaled.trainerRingWidth,
                    dropShadow: true,
                    yaw: targetPosition.yaw,
                    xOffset: 0.5,
                    yOffset: 2 / 3,
                },
                {
                    text: "TRAINER",
                    dropShadow: true,
                },
            );
        }

        if (imageLoadedState["filled-white"] === "loaded" && selectedPosition) {
            const ring = filledWhiteImageRef.current as HTMLImageElement;
            const { x, y } = selectedPosition;
            const sys = sport === "PLATFORM_TENNIS" ? "court" : "physics";
            const coord = projected2Image({ x, y, sys }, scaled);

            const drawAttr = {
                height: scaled.trainerSolidHeight,
                location: coord,
                width: scaled.trainerSolidWidth,
                dropShadow: true,
                yaw: selectedPosition.yaw,
                xOffset: 0.5,
                yOffset: 2 / 3,
                alpha: trainerAlpha || 1,
            };

            drawImageRotated(ctx, ring, drawAttr);
        }

        if (
            imageLoadedState["ring-green"] === "loaded" &&
            imageLoadedState["v-green"] === "loaded" &&
            matchingPosition
        ) {
            const ring = ringGreenImageRef.current as HTMLImageElement;
            const { x, y } = matchingPosition;
            const sys = sport === "PLATFORM_TENNIS" ? "court" : "physics";
            const coord = projected2Image({ x, y, sys }, scaled);

            drawImageRotated(ctx, ring, {
                height: scaled.trainerRingHeight,
                location: coord,
                width: scaled.trainerRingWidth,
                dropShadow: true,
                yaw: matchingPosition.yaw,
                xOffset: 0.5,
                yOffset: 2 / 3,
            });

            if (imageLoadedState["v-green"] === "loaded") {
                const v = vGreenImageRef.current as HTMLImageElement;
                drawImageCentered(ctx, v, {
                    height: scaled.trainerMarkerHeight,
                    width: scaled.trainerMarkerWidth,
                    location: coord,
                    dropShadow: true,
                });
            }
        }

        if (imageLoadedState.player === "loaded" && playerPositions) {
            const player = playerImageRef.current as HTMLImageElement;
            const players = playerPositions.map((p) => {
                const playerWithSys: CoordWithSys = {
                    ...p,
                    sys: sport === "PLATFORM_TENNIS" ? "court" : "physics",
                };
                return projected2Image(playerWithSys, scaled);
            });

            players.forEach((p, i) => {
                let placement;
                if (players.length > 1) {
                    placement = i % 2 === 0 ? "left" : "right";
                }

                const label: Label = {
                    text: players.length > 1 ? `P${i + 1}` : "P1",
                    color: "WHITE",
                    dropShadow: true,
                    position: placement as LabelLocation,
                };

                drawImageWithLabel(
                    ctx,
                    player,
                    {
                        location: p,
                        centered: true,
                        height: scaled.markerWidth,
                        width: scaled.markerWidth,
                        dropShadow: true,
                    },
                    label,
                );
            });
        }
    }, [
        debug,
        hitBoxes,
        imageLoadedState,
        targetPosition,
        mode,
        points,
        trainerAlpha,
        selectedPosition,
        playerPositions,
        matchingPosition,
        scaled,
        selectedMarker,
        sport,
    ]);

    if (showLoadingError) {
        return (
            <Box component="div">
                <Typography variant="h3" align="center">
                    Unable to load required images.
                </Typography>
            </Box>
        );
    }

    return (
        <Box component="div">
            <Box
                component="div"
                sx={{
                    width: `${scaled.width}px`,
                    height: `${scaled.height}px`,
                    margin: "auto",
                    textAlign: "center",
                    position: "relative",
                    background: showLoading ? "rgba(0, 0, 0, 0.2)" : "white",
                }}
            >
                {showLoading && (
                    <CircularProgress
                        sx={{
                            color: "info",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                )}
                <img
                    alt="Court Diagram"
                    style={{ display: "none" }}
                    onLoad={() => dispatch({ image: "court", value: "loaded" })}
                    onError={() =>
                        dispatch({ image: "court", value: "failed" })
                    }
                    ref={courtImageRef}
                    src={courtImage}
                />
                <img
                    alt="Volley V (green)"
                    style={{ display: "none" }}
                    onLoad={() =>
                        dispatch({ image: "v-green", value: "loaded" })
                    }
                    onError={() =>
                        dispatch({ image: "v-green", value: "failed" })
                    }
                    ref={vGreenImageRef}
                    src={trainerVGreen as string}
                />
                <img
                    alt="Ring (green)"
                    style={{ display: "none" }}
                    onLoad={() =>
                        dispatch({ image: "ring-green", value: "loaded" })
                    }
                    onError={() =>
                        dispatch({ image: "ring-green", value: "failed" })
                    }
                    ref={ringGreenImageRef}
                    src={trainerRingGreen as string}
                />
                <img
                    alt="Filled (white)"
                    style={{ display: "none" }}
                    onLoad={() =>
                        dispatch({ image: "filled-white", value: "loaded" })
                    }
                    onError={() =>
                        dispatch({ image: "filled-white", value: "failed" })
                    }
                    ref={filledWhiteImageRef}
                    src={trainerFilledWhite as string}
                />
                <img
                    alt="Position Marker"
                    style={{ display: "none" }}
                    onLoad={() =>
                        dispatch({ image: "marker", value: "loaded" })
                    }
                    onError={() =>
                        dispatch({ image: "marker", value: "failed" })
                    }
                    ref={markerImageRef}
                    src={markerImage as string}
                />
                <img
                    alt="Player Marker"
                    style={{ display: "none" }}
                    onLoad={() =>
                        dispatch({ image: "player", value: "loaded" })
                    }
                    onError={() =>
                        dispatch({ image: "player", value: "failed" })
                    }
                    ref={playerImageRef}
                    src={playerImage as string}
                />
                <canvas
                    title="Court"
                    ref={plotRef}
                    width={scaled.width}
                    height={scaled.height}
                    onClick={handleCanvasClick}
                />
            </Box>
            {debugEnabled && (
                <Button
                    fullWidth
                    variant="contained"
                    onClick={downloadCanvasImage}
                >
                    Download Canvas
                </Button>
            )}
        </Box>
    );
}
