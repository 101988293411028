import * as React from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { HistoryColors } from "./common";
import { ServeAndVolleyResultSummary } from "./models";

interface SummaryCardProps {
    label: string;
    value: number;
    color: string;
}

function SummaryCard({ label, value, color }: SummaryCardProps): JSX.Element {
    return (
        <Paper
            elevation={3}
            sx={{
                p: 2,
                flex: "1 1 125px",
                minWidth: "125px",
                textAlign: "center",
            }}
        >
            <Typography variant="subtitle1" color="textSecondary">
                {label}
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: 600 }} style={{ color }}>
                {value}
            </Typography>
        </Paper>
    );
}

interface SummaryCardsProps {
    summary: ServeAndVolleyResultSummary;
}

export default function SummaryCards({
    summary,
}: SummaryCardsProps): JSX.Element {
    const { in: inCount, out: outCount, miss: missCount, total } = summary;

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 2,
                width: "100%",
                mb: 4,
                "& > :nth-of-type(odd)": {
                    justifySelf: "end",
                },
                "& > :nth-of-type(even)": {
                    justifySelf: "start",
                },
            }}
        >
            <SummaryCard label="Shots" value={total} color="text.primary" />
            <SummaryCard label="In" value={inCount} color={HistoryColors.in} />
            <SummaryCard
                label="Out"
                value={outCount}
                color={HistoryColors.out}
            />
            <SummaryCard
                label="Miss"
                value={missCount}
                color={HistoryColors.miss}
            />
        </Box>
    );
}
